import React, { useEffect } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { openSourceByBaseType } from '../../../helpers/assistantSourcesUtils';

import Modal from '../../../design-system/Modal/Modal';
import Loading from '../../../components/Loading';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import DiscussLineIcon from '../../../design-system/Icons/DiscussLineIcon';
import ArrowRightUpLineIcon from '../../../design-system/Icons/ArrowRightUpLineIcon';
import SourceInfoModalDetailsBlock from '../SourceInfoModalDetailsBlock/SourceInfoModalDetailsBlock';
import SourceInfoModalTextUsedBlock from '../SourceInfoModalTextUsedBlock/SourceInfoModalTextUsedBlock';
import { Button } from '../../../design-system';

const SourceInfoModal = ({
    baseId,
    baseDetail,
    setBaseDetail,
    source,
    nodes,
    toggleLogFeedbackModal,
    onClose,
}) => {
    const { isMobile } = useResponsiveBreakpoints();

    useEffect(() => {
        const fetchBaseDetail = async () => {
            try {
                const { data } = await client.get(API.ROUTES.knowledge.base + baseId + '/');
                setBaseDetail(data);
            } catch (error) {
                onClose();
            }
        };

        if (!baseDetail) {
            fetchBaseDetail();
        }
    }, []);

    const handleLogFeedbackClick = () => {
        toggleLogFeedbackModal();
        onClose();
    };

    const maxHeight = Math.min(528, Math.floor(window.innerHeight * 0.9));

    const containerClassName = classNames('flex flex-col overflow-hidden');

    const header = (
        <ModalHeader title={source.label} onClose={onClose} outlined customPaddings="px-5 py-3" />
    );

    const content = (
        <>
            {baseDetail && (
                <div className="py-5 px-5 flex-grow overflow-y-auto flex flex-col gap-6">
                    <SourceInfoModalDetailsBlock baseDetail={baseDetail} />
                    <SourceInfoModalTextUsedBlock nodes={nodes} />
                </div>
            )}

            {!baseDetail && (
                <div className="flex items-center justify-center pt-8 pb-4 md:pt-7 md:pb-4">
                    <Loading withText={false} />
                </div>
            )}
        </>
    );

    const controls = (
        <div className="flex flex-col-reverse md:flex-row md:justify-end md:items-center md:px-5 md:py-3 gap-2 md:gap-8">
            <Button
                type={isMobile ? 'neutral' : 'link'}
                size="sm"
                text="Log Feedback"
                leadingIcon={DiscussLineIcon}
                onClick={handleLogFeedbackClick}
            />
            <Button
                type="secondary"
                size="sm"
                text="View Full Source"
                trailingIcon={ArrowRightUpLineIcon}
                onClick={() => openSourceByBaseType(source)}
            />
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                maxHeight="92vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={header}
                withCloseButton={false}
                bottomButtonsGroup={controls}
                customGap={8}
            >
                {content}
            </MobilePopup>
        );
    }

    return (
        <Modal size="mediumPlus" onClose={onClose} resetPadding>
            <div className={containerClassName} style={{ maxHeight }}>
                {header}

                {content}

                {controls}
            </div>
        </Modal>
    );
};

export default SourceInfoModal;
