import React, { useEffect } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../../services/library-api';

import { openLinkInNewTab } from '../../../../helpers/openLinkInNewTab';
import { applyOpacityToHex } from '../../../../helpers/applyOpacityToHex';

import { Badge, Button, ButtonIcon } from '../../../index';
import { DeleteBin4LineIcon, FlashlightFillIcon } from '../../../Icons';
import RichTextArea from '../../../RichTextArea/RichTextArea';
import EditLineIcon from '../../../Icons/EditLineIcon';
import GenericBlockLoadingState from '../../GenericBlockLoadingState/GenericBlockLoadingState';

const ConfirmedAgentBlock = ({
    isEditable,
    agentId,
    label,
    agentDetails,
    setAgentDetails,
    handleDeleteBlock,
    setIsConfirmedMode,
}) => {
    useEffect(() => {
        const fetchAgentDetails = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.library.clientProcess}${agentId}/?shared=true`
                );
                setAgentDetails(agentId, () => data);
            } catch (e) {}
        };

        if (!agentDetails && agentId) {
            fetchAgentDetails();
        }
    }, [agentId, agentDetails]);

    if (!agentDetails) {
        return <GenericBlockLoadingState icon={FlashlightFillIcon} />;
    }

    const { id, name, icon_color, description } = agentDetails;

    const badgeText = (
        <>
            <span className="font-body-bold text-body-bold-xs uppercase">Agent:</span> {name}
        </>
    );

    const cardContainerClassName = classNames(
        'flex-1 flex flex-col gap-2.5 border border-neutral-200 rounded-2 p-2.5',
        {
            'max-w-[calc(100%-32px)]': isEditable,
            'max-w-full': !isEditable,
        }
    );

    return (
        <div className="w-full max-w-full flex gap-1 items-start">
            <div className={cardContainerClassName}>
                <div className="max-w-fit">
                    <Badge
                        text={badgeText}
                        color={applyOpacityToHex(icon_color, 20)}
                        leadingIcon={FlashlightFillIcon}
                        leadingIconColor={icon_color}
                    />
                </div>

                <div className="flex flex-col-reverse min-[515px]:flex-row min-[515px]:items-center lg:items-start lg:flex-col-reverse xl:flex-row gap-y-2.5 xl:items-center justify-between gap-x-3">
                    <p className="font-body-bold text-body-bold-l text-neutral-500">{label}</p>

                    <div className="min-w-fit">
                        <Button
                            type="secondary"
                            size="xs"
                            text="Start New Job"
                            trailingIcon={FlashlightFillIcon}
                            onClick={() => openLinkInNewTab(`/agent/${id}/start`)}
                        />
                    </div>
                </div>

                {description && (
                    <RichTextArea
                        value={description}
                        previewOnly
                        autoExpand
                        isBorderHidden
                        minHeight={32}
                    />
                )}

                <div>
                    <Button
                        type="link"
                        size="xs"
                        text="Manage Agent Settings"
                        onClick={() => openLinkInNewTab(`/agent/${id}`)}
                    />
                </div>
            </div>

            {isEditable && (
                <div className="flex flex-col gap-2">
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={handleDeleteBlock}
                    />

                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={EditLineIcon}
                        onClick={() => setIsConfirmedMode(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default ConfirmedAgentBlock;
