import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { HISTORY_TAB } from '../../../constants/assistant';

import HighlightedIcon from '../../../design-system/HighlightedIcon/HighlightedIcon';
import EditBoxLineIcon from '../../../design-system/Icons/EditBoxLineIcon';
import { ButtonIcon } from '../../../design-system';
import { Robot2LineIcon } from '../../../design-system/Icons';

const ChatbotCard = ({ chatbot }) => {
    const { id, name, description } = chatbot;

    const navigate = useNavigate();

    const openChatWithBot = () => navigate(`/assistant?tab=${HISTORY_TAB.bots}&bot=${id}`);

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full w-full rounded-2 bg-neutral-50',
        'grid grid-rows-[auto_auto] md:grid-rows-1 grid-cols-[32px_minmax(100px,_1fr)_28px] gap-3 2xl:gap-4 items-center'
    );

    return (
        <div className={cardContainerClassName}>
            <div className="h-full place-content-center">
                <HighlightedIcon icon={Robot2LineIcon} variant="light_purple" />
            </div>

            <div className="flex flex-col gap-1">
                <p className="font-body-bold text-body-bold-s text-black">{name}</p>
                <p className="font-body text-body-regular-xs text-neutral-500 line-clamp-1">
                    {description}
                </p>
            </div>

            <ButtonIcon type="link" size="xs" icon={EditBoxLineIcon} onClick={openChatWithBot} />
        </div>
    );
};

export default ChatbotCard;
