import React, { memo } from 'react';
import classNames from 'classnames';

import { useOverlayState } from '../../../../hooks/useOverlayState';
import { SOURCE_CARD_STATUS, SOURCE_CARD_STATUS_MAPPING } from '../../../../constants/bot';

import { SvgIcon } from '../../../../design-system';
import { More2FillIcon } from '../../../../design-system/Icons';
import SourceIcon from '../SourceIcon/SourceIcon';
import EditSourceModal from '../EditSourceModal/EditSourceModal';
import SourceCardStatusBlock from '../SourceCardStatusBlock/SourceCardStatusBlock';
import SourceMoreOptionsPopup from '../SourceMoreOptionsPopup/SourceMoreOptionsPopup';

const SourceCard = ({ source, currentSourcesIdsList = [], setUpdatedSources, setBotDetail }) => {
    const { id, name, type, ingestion_status, base, description } = source;
    const { status, updated_at } = ingestion_status;

    const { isOpened, open: openPopup, close: closePopup } = useOverlayState();
    const {
        isOpened: isEditModalOpened,
        open: openEditModal,
        close: closeEditModal,
    } = useOverlayState();

    const sourceStatus = SOURCE_CARD_STATUS_MAPPING[status];

    const cardContainerClassName = classNames(
        'px-3 py-2 lg:p-2 max-w-full w-full rounded-2 bg-white shadow-inset-1 transition-shadow',
        'grid grid-rows-[auto_auto] md:grid-rows-1 grid-cols-[32px_minmax(100px,_1fr)_24px] md:grid-cols-[32px_minmax(80px,_1fr)_185px_24px] lg:grid-rows-[auto_auto] xl:grid-rows-1 lg:grid-cols-[32px_minmax(100px,_1fr)_24px] xl:grid-cols-[32px_minmax(80px,_1fr)_185px_24px] gap-y-2 gap-x-2 md:gap-x-3 lg:gap-x-2 2xl:gap-x-3 items-center',
        {
            'shadow-red-500': sourceStatus === SOURCE_CARD_STATUS.error,
            'shadow-transparent': sourceStatus !== SOURCE_CARD_STATUS.error,
            'opacity-50': sourceStatus === SOURCE_CARD_STATUS.in_progress,
        }
    );

    return (
        <li className="w-full max-w-full relative">
            <div className={cardContainerClassName}>
                <div className="h-full place-content-center">
                    <SourceIcon type={type} isHighlighted />
                </div>

                <p className="font-body-bold text-body-bold-s text-black truncate">{name}</p>

                <div className="flex items-center justify-start md:justify-end lg:justify-start xl:justify-end gap-1 pt-2 md:p-0 lg:pt-2 xl:p-0 border-t-1 md:border-0 lg:border-t-1 xl:border-0 border-neutral-200 row-start-2 row-end-2 col-span-full md:row-start-auto md:row-end-auto md:col-span-1 lg:row-start-2 lg:row-end-2 lg:col-span-full xl:row-start-auto xl:row-end-auto xl:col-span-1">
                    <SourceCardStatusBlock status={status} updatedAt={updated_at} />
                </div>

                <div className="relative">
                    <button
                        className="flex items-center focus:outline-neutral-200"
                        onClick={openPopup}
                    >
                        <SvgIcon color="#1F2125" icon={More2FillIcon} size="large" />
                    </button>
                </div>
            </div>

            {isOpened && (
                <SourceMoreOptionsPopup
                    baseId={base.id}
                    sourceId={id}
                    sources={currentSourcesIdsList || []}
                    setUpdatedSources={setUpdatedSources}
                    isSyncInProgress={sourceStatus === SOURCE_CARD_STATUS.in_progress}
                    openEditModal={openEditModal}
                    onClose={closePopup}
                />
            )}

            {isEditModalOpened && (
                <EditSourceModal
                    id={id}
                    name={name}
                    description={description}
                    setBotDetail={setBotDetail}
                    onClose={closeEditModal}
                />
            )}
        </li>
    );
};

export default memo(SourceCard);
