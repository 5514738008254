import React, { useEffect, useRef, useState } from 'react';

import { useOverlayState } from '../../../hooks/useOverlayState';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { openSourceByBaseType } from '../../../helpers/assistantSourcesUtils';

import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import SourceInfoModal from '../SourceInfoModal/SourceInfoModal';
import ArrowRightUpLineIcon from '../../../design-system/Icons/ArrowRightUpLineIcon';
import SourceBadgeLeadingIcon from '../SourceBadgeLeadingIcon/SourceBadgeLeadingIcon';
import { Badge } from '../../../design-system';
import { InformationLineIcon } from '../../../design-system/Icons';

const AssistantMessageSource = ({
    source,
    isSimplifiedVersion = false,
    isCustomChatType = false,
    toggleLogFeedbackModal,
}) => {
    const { label, icon_color, base_type, link, nodes, base_id } = source;
    const linkType = link?.type;

    const trailingIconRef = useRef(null);
    const [distanceToRight, setDistanceToRight] = useState(0);

    const { isOpened, open: openSourceInfoModal, close: closeSourceInfoModal } = useOverlayState();
    const [baseDetail, setBaseDetail] = useState(null);

    const hasNodes = isCustomChatType && !!nodes?.length && !!base_id;

    const { isDesktop } = useResponsiveBreakpoints();

    const handleSourceBadgeClick = (source) => {
        if (hasNodes) {
            openSourceInfoModal();
            return;
        }

        openSourceByBaseType(source);
    };

    const onBadgeClick = isSimplifiedVersion ? null : () => handleSourceBadgeClick(source);

    const isTooltipDisplayed = isDesktop && hasNodes;
    const tooltipText =
        isTooltipDisplayed && nodes?.length > 5
            ? 'Click to view 5+ text chunks'
            : 'Click to visit source';

    useEffect(() => {
        if (trailingIconRef.current && isTooltipDisplayed) {
            const rect = trailingIconRef.current.getBoundingClientRect();
            const screenWidth = window.innerWidth;
            setDistanceToRight(screenWidth - rect.right);
        }
    }, []);

    const tooltipPosition = distanceToRight < 200 ? 'top-right' : 'top-left';

    const trailingIcon = hasNodes ? (
        <div ref={trailingIconRef}>
            <ToolTip
                text={tooltipText}
                isShown={false}
                useInternalHoverStateToShowToolTip={isDesktop}
                position={tooltipPosition}
                shift="-8px"
            >
                <SvgIcon icon={InformationLineIcon} size="medium" color="#5E6470" />
            </ToolTip>
        </div>
    ) : (
        <SvgIcon icon={ArrowRightUpLineIcon} size="medium" color="#5E6470" />
    );

    return (
        <li className="max-w-full">
            <Badge
                text={label}
                color={icon_color}
                leadingItem={<SourceBadgeLeadingIcon baseType={base_type} linkType={linkType} />}
                trailingItem={isSimplifiedVersion ? null : trailingIcon}
                onBadgeClick={onBadgeClick}
            />

            {isOpened && (
                <SourceInfoModal
                    baseId={base_id}
                    baseDetail={baseDetail}
                    setBaseDetail={setBaseDetail}
                    source={source}
                    nodes={nodes}
                    toggleLogFeedbackModal={toggleLogFeedbackModal}
                    onClose={closeSourceInfoModal}
                />
            )}
        </li>
    );
};

export default AssistantMessageSource;
