import { useCallback, useEffect, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../services/assistant-api';
import { wsFailReconnectErrorMessage } from '../services/websocket';

import { CHAT_ROLE } from '../constants/assistant';
import { generateUUID } from '../helpers/generateUUID';

export const useUserChatMessage = ({
    chatMessages,
    setChatMessages,
    setMessageStreamState,
    isNewChat,
    handleMessageSubmitResponse,
    tryReconnectIfWebsocketClosed,
    startListeningWebSockets,
    getSubmittingMessageRequestBody,
    getRegenerateMessageRequestBody,
    emptyCanceledAssistantMessage,
    setEmptyCanceledAssistantMessage,
    isStreamCanceled,
    prepareForSubmitMessageRequest,
}) => {
    const controller = useRef(new AbortController());
    const containerRef = useRef(null);

    const [submittedUserMessage, setSubmittedUserMessage] = useState(null); // submitted but not saved yet user message (post request is pending)

    const [errorAlert, setErrorAlert] = useState(null);
    const [messageSubmissionErrorData, setMessageSubmissionErrorData] = useState(null);

    const removedDataOnMessageRegenerate = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(null);

    useEffect(() => {
        return () => {
            controller.current?.abort();
        };
    }, []);

    const startListeningWSOrHandleResponseIfCanceled = useCallback(
        ({ data, messagesToAppend }) => {
            const isCancelledBeforeStartStreaming = !!isStreamCanceled.current;

            if (!isCancelledBeforeStartStreaming) {
                startListeningWebSockets(data);
            }

            const canceledAssistantMessage = {
                ...data.assistant_message,
                default_version: { ...data.assistant_message.default_version, status: 'success' },
            };

            if (isCancelledBeforeStartStreaming) {
                messagesToAppend.push(canceledAssistantMessage);
                isStreamCanceled.current = false;
                setEmptyCanceledAssistantMessage(null);
            }
        },
        [startListeningWebSockets, setEmptyCanceledAssistantMessage]
    );

    const handleMessageSubmitErrors = useCallback(
        ({ error, submittedUserMessage, isRegenerate = false, role, wsClosedError }) => {
            const errorMessage = error.response?.data?.error;
            const isBadRequestErrorWithErrorMessage =
                error.response?.status === 400 && errorMessage && typeof errorMessage === 'string';

            if (isRegenerate) {
                const { removedMessages, scrollPosition } =
                    removedDataOnMessageRegenerate.current || {};

                setChatMessages((messages) => {
                    if (role === CHAT_ROLE.user) {
                        return [...(messages?.slice(0, -1) || []), ...(removedMessages || [])];
                    }
                    return [...messages, ...(removedMessages || [])];
                });
                if (scrollPosition !== null && scrollPosition !== undefined) {
                    setScrollPosition(scrollPosition);
                }

                removedDataOnMessageRegenerate.current = null;
            }

            if (isBadRequestErrorWithErrorMessage) {
                let formattedErrorMessage = errorMessage;

                if (errorMessage.includes('Organization does not have access to model gpt-4o')) {
                    formattedErrorMessage = 'Please add an OpenAI API key to use this assistant';
                }

                setMessageSubmissionErrorData({
                    submittedUserMessage,
                    errorMessage: formattedErrorMessage,
                });
            }

            if (wsClosedError) {
                if (submittedUserMessage) {
                    setMessageSubmissionErrorData({ submittedUserMessage });
                }

                const isCancelledBeforeStartStreaming = !!isStreamCanceled.current;

                if (isCancelledBeforeStartStreaming) {
                    isStreamCanceled.current = false;
                    setEmptyCanceledAssistantMessage(null);
                }
            }

            setErrorAlert({
                message: 'Oops! Something went wrong. Please reload and try again.',
            });

            setMessageStreamState({
                isAssistantLoading: false,
                isStreamRunning: false,
            });

            setSubmittedUserMessage(null);
        },
        [setErrorAlert, setChatMessages, setMessageStreamState, setMessageSubmissionErrorData]
    );

    const submitChatMessage = async (message) => {
        const submittedMessage = {
            id: `temp-${generateUUID()}`,
            role: CHAT_ROLE.user,
            default_version: {
                content: message,
            },
        };

        try {
            setMessageSubmissionErrorData(null);

            // show user temporary submitted message until the request returns the user message
            setSubmittedUserMessage(submittedMessage);
            setMessageStreamState({
                isAssistantLoading: true,
                isStreamRunning: false,
            });

            await tryReconnectIfWebsocketClosed();

            const newController = new AbortController();
            controller.current = newController;

            prepareForSubmitMessageRequest && prepareForSubmitMessageRequest({ isNewChat });
            const requestBody = getSubmittingMessageRequestBody({ message, isNewChat });

            const { data } = await client.post(API.ROUTES.assistant.chatMessage, requestBody, {
                signal: newController.signal,
            });

            setSubmittedUserMessage(null);
            const messagesToAppend = [data.user_message];

            startListeningWSOrHandleResponseIfCanceled({ data, messagesToAppend });
            handleMessageSubmitResponse({ data, messagesToAppend, isNewChat });
        } catch (error) {
            if (error.message === 'canceled') {
                return; // the next request is loading
            }

            handleMessageSubmitErrors({
                error,
                submittedUserMessage: submittedMessage.default_version.content,
                wsClosedError: error.message === wsFailReconnectErrorMessage,
            });
        }
    };

    const handleRegenerateChatMessage = useCallback(
        async ({ id, role, content }) => {
            try {
                setMessageSubmissionErrorData(null);
                setMessageStreamState({
                    isAssistantLoading: true,
                    isStreamRunning: false,
                });

                const newController = new AbortController();
                controller.current = newController;

                setChatMessages((messages) => {
                    const lastMessageIndex = messages.findIndex((message) => message.id === id);

                    if (lastMessageIndex === -1) {
                        return messages;
                    }

                    const slicedChat = messages.slice(0, lastMessageIndex);
                    const removedMessages = messages.slice(lastMessageIndex);

                    let scrollPosition = null;
                    const container = containerRef.current?.children?.[0]?.children?.[0];

                    if (container) {
                        scrollPosition = container.scrollTop;
                    }
                    removedDataOnMessageRegenerate.current = { removedMessages, scrollPosition };

                    if (role === CHAT_ROLE.user) {
                        const originalUserMessage = messages[lastMessageIndex];
                        const updatedUserMessage = {
                            ...originalUserMessage,
                            default_version: {
                                ...originalUserMessage.default_version,
                                content,
                                status: 'success',
                            },
                        };
                        return [...slicedChat, updatedUserMessage];
                    }
                    return slicedChat;
                });

                await tryReconnectIfWebsocketClosed();

                const requestBody = getRegenerateMessageRequestBody({ role, message: content });

                const { data } = await client.patch(
                    `${API.ROUTES.assistant.chatMessage}${id}/`,
                    requestBody,
                    {
                        signal: newController.signal,
                    }
                );

                removedDataOnMessageRegenerate.current = null;

                startListeningWSOrHandleResponseIfCanceled({ data, messagesToAppend: [] });
                handleMessageSubmitResponse({ data, messagesToAppend: [], isNewChat: false });
            } catch (error) {
                if (error?.message === 'canceled') {
                    return; // the next request is loading
                }
                handleMessageSubmitErrors({
                    error,
                    isRegenerate: true,
                    role,
                    wsClosedError: error?.message === 'Websocket connection is closed',
                });
            }
        },
        [
            setChatMessages,
            setMessageStreamState,
            handleMessageSubmitResponse,
            removedDataOnMessageRegenerate,
            handleMessageSubmitErrors,
            setMessageSubmissionErrorData,
            getRegenerateMessageRequestBody,
        ]
    );

    const displayedChatMessages = [...(chatMessages || [])];
    if (submittedUserMessage) {
        displayedChatMessages.push(submittedUserMessage);
    }
    if (emptyCanceledAssistantMessage) {
        displayedChatMessages.push(emptyCanceledAssistantMessage);
    }

    return {
        submitChatMessage,
        handleRegenerateChatMessage,
        displayedChatMessages,
        setSubmittedUserMessage,
        messageSubmissionErrorData,
        setMessageSubmissionErrorData,
        controller,
        errorAlert,
        setErrorAlert,
        scrollPosition,
        setScrollPosition,
        containerRef,
    };
};
