import React from 'react';

import NewJobFormField from './NewJobFormField/NewJobFormField';

const NewJobForm = ({
    formData,
    setFormData,
    containerGap,
    allowDocsMapping = true,
    isFormDisabled = false,
}) => {
    return (
        <>
            {!!formData?.length && (
                <div className="flex flex-col" style={{ gap: `${containerGap}px` }}>
                    {formData.map((input) => (
                        <NewJobFormField
                            key={input.key}
                            input={input}
                            setFormData={setFormData}
                            allowDocsMapping={allowDocsMapping}
                            withActionButtons={false}
                            isFormDisabled={isFormDisabled}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default NewJobForm;
