export const isFileSizeValid = ({
    file,
    maxSizeInBytes = 0,
    minSizeInBytes = 0,
    handleFileWrongSize = () => {},
}) => {
    if (!maxSizeInBytes && !minSizeInBytes) {
        return true; // if there are no limits return valid
    }

    if (!maxSizeInBytes) {
        if (file.size < minSizeInBytes) {
            handleFileWrongSize('min');
            return false;
        }
    }

    if (!minSizeInBytes) {
        if (file.size > maxSizeInBytes) {
            handleFileWrongSize('max');
            return false;
        }
    }

    if (file.size > maxSizeInBytes || file.size < minSizeInBytes) {
        handleFileWrongSize('range');
        return false;
    }

    return true;
};

export const isFileFormatValid = ({ file, availableFormats }) => {
    return availableFormats.some((format) =>
        file.name.toLowerCase().endsWith(format.toLowerCase())
    );
};

export function extractFormatsFromPattern(pattern) {
    if (!pattern) return null;

    const match = pattern.match(/\.\\?\(([^)]+)\)|\\?\(([^)]+)\)/i);
    if (match) {
        const extensions = match[1] || match[2];
        return extensions.split('|').map((format) => {
            return format.startsWith('.') ? format.trim() : `.${format.trim()}`;
        });
    }

    const singleMatch = pattern.match(/\.([a-z]+)\$$/i);
    if (singleMatch) {
        return ['.' + singleMatch[1].trim()];
    }

    return [];
}

export function megabytesToBytes(mb) {
    if (!mb) return 0;

    return mb * (1024 * 1024);
}

export function bytesToMegabytes(bytes) {
    if (!bytes) return 0;

    const result = bytes / (1024 * 1024);
    return parseFloat(result.toFixed(2));
}

export function isFile(obj) {
    return obj instanceof File;
}
