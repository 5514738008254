import React, { useState } from 'react';
import classNames from 'classnames';

import { CHAT_TYPE } from '../../../constants/assistant';
import { modelsNameMapping } from '../../../constants/modelsNameMapping';

import { handleCopyFormattedText } from '../../../helpers/handleCopyFormattedText';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import ToolTip from '../../../design-system/ToolTip/ToolTip';
import CopyIcon from '../../../design-system/Icons/CopyIcon';
import CopyAlert from '../../../design-system/CopyAlert/CopyAlert';
import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import RefreshCwIcon from '../../../design-system/Icons/RefreshCwIcon';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import QuickAddToDocModal from '../../../components/QuickAddToDocModal/QuickAddToDocModal';
import LogMessageFeedbackManager from '../LogMessageFeedbackManager/LogMessageFeedbackManager';
import AssistantMessageVersionSwitcher from '../AssistantMessageVersionSwitcher/AssistantMessageVersionSwitcher';
import { Sparkling2FillIcon } from '../../../design-system/Icons';
import { ButtonIcon, SvgIcon } from '../../../design-system';

const AssistantMessageActionButtons = ({
    message,
    setChatMessages,
    onRegenerateMessageClick,
    isRegenerateDisabled,
    onSuccessFeedbackShare,
    containerRef,
    isSimplifiedVersion = false,
}) => {
    const {
        default_version: { id: currentVersionId, content, model, type },
        versions,
    } = message;

    const [copyAlert, setCopyAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);
    const [isAddToDocModalOpened, setIsAddToDocModalOpened] = useState(false);

    const { isDesktop } = useResponsiveBreakpoints();

    const regenerateButton = (
        <ToolTip
            text="Regenerate"
            position="top-left"
            shift="-2px"
            isShown={false}
            useInternalHoverStateToShowToolTip={isDesktop}
        >
            <ButtonIcon
                type="neutral"
                size="xs"
                icon={RefreshCwIcon}
                state={isRegenerateDisabled ? 'disabled' : 'default'}
                onClick={onRegenerateMessageClick}
            />
        </ToolTip>
    );

    const hasMultipleVersions = versions?.length > 1;

    if (isSimplifiedVersion) {
        return (
            <div className="flex items-center gap-2">
                {regenerateButton}

                {hasMultipleVersions && (
                    <AssistantMessageVersionSwitcher
                        currentVersionId={currentVersionId}
                        versions={versions || []}
                        setChatMessages={setChatMessages}
                    />
                )}
            </div>
        );
    }

    const currentModel = modelsNameMapping[model];
    const withFeedbackButton = type === CHAT_TYPE.custom;

    const copyFormattedMessage = () => {
        const node = containerRef.current?.querySelector('.wmde-markdown');
        if (node) {
            handleCopyFormattedText({
                node,
                setCopyAlert,
            });
        }
    };

    return (
        <>
            <div className="flex items-center gap-2">
                <ToolTip
                    text="Copy Message"
                    position="top-left"
                    shift="-2px"
                    isShown={false}
                    useInternalHoverStateToShowToolTip={isDesktop}
                >
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={CopyIcon}
                        onClick={copyFormattedMessage}
                    />
                </ToolTip>

                {regenerateButton}

                <ToolTip
                    text="Quick Add"
                    position="top-left"
                    shift="-2px"
                    isShown={false}
                    useInternalHoverStateToShowToolTip={isDesktop}
                >
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={FileTextLineIcon}
                        onClick={() => setIsAddToDocModalOpened(true)}
                    />
                </ToolTip>

                {withFeedbackButton && (
                    <LogMessageFeedbackManager
                        message={message}
                        onSuccessFeedbackShare={onSuccessFeedbackShare}
                    />
                )}

                <div className={classNames(!isDesktop && 'flex-1 truncate')}>
                    <ToolTip
                        text={currentModel}
                        position="top-left"
                        shift="-2px"
                        isShown={false}
                        useInternalHoverStateToShowToolTip={isDesktop}
                    >
                        <div className="flex items-center truncate max-w-full">
                            <div className="w-[28px] min-w-[28px] h-[28px] flex items-center justify-center">
                                <SvgIcon color="#1F2125" icon={Sparkling2FillIcon} size="medium" />
                            </div>
                            {!isDesktop && (
                                <p className="font-body text-body-regular-xs text-black truncate">
                                    {currentModel}
                                </p>
                            )}
                        </div>
                    </ToolTip>
                </div>

                {hasMultipleVersions && (
                    <AssistantMessageVersionSwitcher
                        currentVersionId={currentVersionId}
                        versions={versions || []}
                        setChatMessages={setChatMessages}
                    />
                )}
            </div>

            {isAddToDocModalOpened && (
                <QuickAddToDocModal
                    content={content}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => setIsAddToDocModalOpened(false)}
                />
            )}

            <CopyAlert copyAlert={copyAlert} setCopyAlert={setCopyAlert} />
            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </>
    );
};

export default AssistantMessageActionButtons;
