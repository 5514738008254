import { createContext, useState } from 'react';

export const RichTextWithDocsMappingContext = createContext();

export const RichTextWithDocsMappingProvider = ({ children }) => {
    const [docsMapping, setDocsMapping] = useState({});
    const [selectDocumentModal, setSelectDocumentModal] = useState({ variableName: null });
    const [isDisabled, setIsDisabled] = useState(false);

    const [docMappingCheckSignal, setDocMappingCheckSignal] = useState(0);

    const triggerDocMappingCheck = () => setDocMappingCheckSignal((prev) => prev + 1);

    const openSelectDocumentModal = ({ variableName }) => setSelectDocumentModal({ variableName });

    const closeSelectDocumentModal = () => setSelectDocumentModal({ variableName: null });

    return (
        <RichTextWithDocsMappingContext.Provider
            value={{
                docsMapping,
                setDocsMapping,
                selectDocumentModal,
                openSelectDocumentModal,
                closeSelectDocumentModal,
                isDisabled,
                setIsDisabled,
                docMappingCheckSignal,
                triggerDocMappingCheck,
            }}
        >
            {children}
        </RichTextWithDocsMappingContext.Provider>
    );
};
