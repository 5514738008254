import React from 'react';

import useUser from '../../../hooks/useUser';

import { CHAT_TYPE } from '../../../constants/assistant';

const ChatInvitingView = ({ chatType, selectedCustomBot, isSimplifiedVersion = false }) => {
    const { user } = useUser();

    const greetings = user?.first_name ? `Hey ${user.first_name}!` : 'Hey there!';

    let chatInviteText;
    switch (chatType) {
        // case CHAT_TYPE.divibot:
        //     chatInviteText = 'Start chatting with DiviBot - your agency assistant';
        //     break;
        case CHAT_TYPE.generic:
            chatInviteText = 'Start chatting with AI';

            break;
        case CHAT_TYPE.custom:
            chatInviteText = `Start Chatting with ${selectedCustomBot?.name}`;
            break;
        default:
            chatInviteText = 'Start chatting with AI';
            break;
    }

    return (
        <div className="px-5 sm:px-8 h-4/5 flex items-center justify-center">
            <div>
                {!isSimplifiedVersion && (
                    <p className="font-heading-bold text-heading-bold-m text-neutral-500 text-center">
                        {greetings}
                    </p>
                )}
                <p className="font-heading-regular text-heading-regular-m text-neutral-500 text-center">
                    {chatInviteText}
                </p>
            </div>
        </div>
    );
};

export default ChatInvitingView;
