import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/assistant-api';
import useSWRRequest from '../../../hooks/useSWRRequest';

import { useAssistantChatThreadContext } from '../../../hooks/useAssistantChatThreadContext';
import { useFetchAssistantPreselectedDocs } from '../../../hooks/useFetchAssistantPreselectedDocs';

import MainChatArea from '../MainChatArea/MainChatArea';
import MainPanelHeader from '../MainPanelHeader/MainPanelHeader';
import AiModelSelector from '../AIModelSelector/AIModelSelector';
import MainPanelContainer from '../MainPanelContainer/MainPanelContainer';

const ChatThreadPanel = ({ historyPanelExpandedState, setChatThreadsHistoryList }) => {
    const { chatThreadId } = useParams();
    const { selectedCustomBot, chatThreadData, setChatThreadData } =
        useAssistantChatThreadContext();

    const [searchParams] = useSearchParams();
    const promptId = searchParams.get('prompt');
    const botId = searchParams.get('bot');

    const { preselectedDocsData, isPreselectedDocsLoading } = useFetchAssistantPreselectedDocs();

    const updateChatThreadName = async (updatedName) => {
        setChatThreadData((prevData) => ({ ...prevData, name: updatedName }));
        setChatThreadsHistoryList((chatThreads) =>
            chatThreads.map((chatThread) =>
                chatThread.id === chatThreadId ? { ...chatThread, name: updatedName } : chatThread,
            ),
        );

        if (chatThreadId) {
            await client.patch(`${API.ROUTES.assistant.chatThread}${chatThreadId}/`, {
                name: updatedName,
            });
        }
    };

    const shouldFetchPrompt = promptId && !chatThreadId;
    const { data, error } = useSWRRequest({
        client,
        route: shouldFetchPrompt
            ? `${API.ROUTES.assistant.chatPrompt}${promptId}/?shared=true`
            : null,
    });

    const isThreadDataLoading = chatThreadId && !chatThreadData;
    const isPromptLoading = promptId && !data && !error;
    const isBotLoading = botId === selectedCustomBot?.id && !selectedCustomBot.name;

    const isPageLoading =
        isThreadDataLoading || isPromptLoading || isBotLoading || isPreselectedDocsLoading;

    return (
        <MainPanelContainer
            historyPanelExpandedState={historyPanelExpandedState}
            isLoading={isPageLoading}
        >
            <MainPanelHeader
                key={chatThreadData?.id ? chatThreadData?.id + 'header' : 'new_chat_header'}
                name={chatThreadData?.name}
                panelEntity="chat"
                handleUpdateName={updateChatThreadName}
                historyPanelExpandedState={historyPanelExpandedState}
                selector={<AiModelSelector />}
            />
            <MainChatArea
                setChatThreadsHistoryList={setChatThreadsHistoryList}
                preselectedDocsData={preselectedDocsData}
            />
        </MainPanelContainer>
    );
};

export default ChatThreadPanel;
