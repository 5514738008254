import { API } from 'constants';
import client from '../services/user-api';
import operateClient from '../services/operate-api';

import {
    defaultErrorMessage,
    emptyFieldErrorMessage,
    invalidInputErrorMessage,
} from '../constants/errorMessages';
import { ACTION_TYPE_INITIAL_VALUE, NEW_JOB_INPUT_ACTION_TYPE } from '../constants/startNewJobForm';

import { normalizeMarkdown, prepareMarkdownOnLoad } from './normalizeMarkdown';
import {
    getRichTextWithLinkedDocsValueForSubmit,
    getRichTextWithDocsMappingFormattedInitialValue,
} from './richTextEditorWithDocsMappingUtils';
import { checkIfInputtedJsonIsValid, getMappedFormErrors } from './settingsFormUtils';

const actionsWithArrayValue = [
    NEW_JOB_INPUT_ACTION_TYPE.multi_db,
    NEW_JOB_INPUT_ACTION_TYPE.multi_input,
    NEW_JOB_INPUT_ACTION_TYPE.multi_select,
    NEW_JOB_INPUT_ACTION_TYPE.multi_dropdown,
];

// these functions are used for the Start New Job, Start New Project forms and Base Entry form

export const getStartNewJobFormDataInitialState = ({
    inputs,
    allowDocsMapping = true,
    replaceOnlyFilledTags = true,
}) => {
    return (
        inputs?.map((input) => {
            const { action, value } = input;

            const formattedInput = { ...input, state: 'default' };

            if (action === NEW_JOB_INPUT_ACTION_TYPE.richtext_input && allowDocsMapping) {
                const { value: newValue, docsMapping } =
                    getRichTextWithDocsMappingFormattedInitialValue({
                        initialValue: value,
                        replaceOnlyFilledTags,
                    });
                return { ...formattedInput, value: newValue, docs_mapping: docsMapping };
            }

            if (action === NEW_JOB_INPUT_ACTION_TYPE.richtext_input && !allowDocsMapping) {
                return { ...formattedInput, value: prepareMarkdownOnLoad(value) };
            }

            if (!value) {
                const initialValue = ACTION_TYPE_INITIAL_VALUE[action];
                return { ...formattedInput, value: initialValue };
            }

            return formattedInput;
        }) || []
    );
};

export const mergeValuesIntoInputs = (inputs, values) => {
    return inputs.map((input) => {
        const value = values[input.key];

        return { ...input, value };
    });
};

export const checkIsRequiredFieldFilled = (item) => {
    const isFieldNotFilled =
        (actionsWithArrayValue.includes(item.action) && !item.value?.length) ||
        (!actionsWithArrayValue.includes(item.action) &&
            !item.value &&
            item.value !== 0 &&
            item.value !== false);

    return item.is_required ? !isFieldNotFilled : true;
};

const getDefaultEmptyFormFieldErrorMessage = (action) => {
    switch (action) {
        case NEW_JOB_INPUT_ACTION_TYPE.multi_input:
            return 'Please add at least one input.';
        case NEW_JOB_INPUT_ACTION_TYPE.multi_dropdown:
            return 'Please select at least one option.';
        case NEW_JOB_INPUT_ACTION_TYPE.single_select:
            return 'Please select an option.';
        case NEW_JOB_INPUT_ACTION_TYPE.multi_select:
            return 'Please select an option.';
        case NEW_JOB_INPUT_ACTION_TYPE.multi_db:
            return 'Please select at least one option.';
        case NEW_JOB_INPUT_ACTION_TYPE.single_dropdown:
            return 'Please select an option.';
        case 'nested-input': // should not be the case
            return 'Please add at least one Item.';
        case NEW_JOB_INPUT_ACTION_TYPE.single_file:
            return 'Please upload a file.';
        default:
            return emptyFieldErrorMessage;
    }
};

export const checkIfAllRequiredInputsAreFilled = (formData) => {
    return formData.every((item) => checkIsRequiredFieldFilled(item));
};

export const checkIsFormDataValid = (formData, setFormData) => {
    const { areErrorFields, updatedFormData } = formData.reduce(
        (acc, item) => {
            // check if inputted Json is valid
            const invalidJsonError = checkIfInputtedJsonIsValid(acc, item);
            if (invalidJsonError) {
                return invalidJsonError;
            }

            // check if all required fields are filled
            const isRequiredFieldFilled = checkIsRequiredFieldFilled(item);
            if (!isRequiredFieldFilled) {
                return {
                    areErrorFields: true,
                    updatedFormData: [
                        ...acc.updatedFormData,
                        {
                            ...item,
                            state: 'error',
                            errorMessage: getDefaultEmptyFormFieldErrorMessage(item.action),
                        },
                    ],
                };
            }

            return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
        },
        { areErrorFields: false, updatedFormData: [] }
    );

    if (areErrorFields) {
        setFormData(updatedFormData);
        return false; // means that there is a form data error
    }

    return true; // means that there are no form data errors
};

export const getFormattedFormValue = (input, docs_mapping) => {
    let formattedValue = input.value;

    // make value type "number" for action === 'single-input' && type === 'number' (note: we do not have to do this for item.type === 'integer' as that value is already parsedInt)
    if (
        input.action === NEW_JOB_INPUT_ACTION_TYPE.single_input &&
        input.type === 'number' &&
        input.value
    ) {
        formattedValue = parseFloat(input.value);
    }

    // parse Json value
    if (
        input.action === NEW_JOB_INPUT_ACTION_TYPE.json_input &&
        input.value &&
        typeof input.value === 'string'
    ) {
        formattedValue = JSON.parse(input.value);
    }

    // replace docs variables by corresponding ids
    if (input.action === NEW_JOB_INPUT_ACTION_TYPE.richtext_input && docs_mapping) {
        formattedValue = getRichTextWithLinkedDocsValueForSubmit({
            value: input.value,
            docsMapping: docs_mapping,
        });
    }

    if (input.action === NEW_JOB_INPUT_ACTION_TYPE.richtext_input && !docs_mapping) {
        formattedValue = normalizeMarkdown(input.value);
    }

    return formattedValue;
};

export const formatInputsToFormData = (formData) =>
    formData.map(({ state, errorMessage, docs_mapping, ...input }) => ({
        ...input,
        value: getFormattedFormValue(input, docs_mapping),
    }));

export const formatInputsToDictionaryFormDataView = (formData) =>
    formData.reduce(
        (acc, { state, errorMessage, docs_mapping, ...input }) => ({
            ...acc,
            [input.key]: getFormattedFormValue(input, docs_mapping),
        }),
        {}
    );

export const handleRequestErrors = ({ error, setErrorAlert, formData, setFormData }) => {
    const inputsErrors = error.response?.data?.inputs;

    if (inputsErrors) {
        const updatedFormData = getMappedFormErrors(inputsErrors, formData, 'key');
        setFormData(updatedFormData);
    } else {
        setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.statusCode });
    }
};

export const getMappedClientStartNewJobFormErrors = (inputsErrorArr, formData) => {
    const errorKeysMap = new Map(inputsErrorArr.map((item) => [item.key, item]));

    const updatedFormData = formData.map((item) => {
        // if there is an error message for current field set it
        const currentFieldErrorObject = errorKeysMap.get(item.key);
        if (currentFieldErrorObject) {
            return {
                ...item,
                state: 'error',
                errorMessage: currentFieldErrorObject?.error || invalidInputErrorMessage,
            };
        } else {
            return item;
        }
    });

    return updatedFormData;
};

export const handleClientStartNewJobRequestErrors = ({
    error,
    setErrorAlert,
    formData,
    setFormData,
}) => {
    const inputsErrors = error.response?.data?.error;

    if (Array.isArray(inputsErrors)) {
        // handle inputs error
        const updatedFormData = getMappedClientStartNewJobFormErrors(inputsErrors, formData);
        setFormData(updatedFormData);
    }
    setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.statusCode });
};

// should be wrapped with try{} catch{} block
export const replaceFilesWithCDNUrls = async (formData) => {
    if (!formData) return [];

    const updatedFormData = await Promise.all(
        formData.map(async (input) => {
            const shouldSaveInCDN =
                input.action === NEW_JOB_INPUT_ACTION_TYPE.single_file &&
                input.value &&
                typeof input.value === 'object';
            if (shouldSaveInCDN) {
                const formData = new FormData();
                formData.append('file', input.value);

                const { data } = await client.post(API.ROUTES.user.uploadFile, formData);
                return { ...input, value: data.file };
            }

            return input;
        })
    );

    return updatedFormData;
};

export const startNewJob = async ({
    formData,
    setFormData,
    processId,
    setNewJobId = () => {},
    setStartNewJobState,
    setErrorAlert,
}) => {
    const isFormDataValid = checkIsFormDataValid(formData, setFormData);
    if (!isFormDataValid) {
        return;
    }

    try {
        setStartNewJobState('loading');
        setNewJobId(null);

        const formDataWithCDNUrls = await replaceFilesWithCDNUrls(formData);
        const inputs = formatInputsToFormData(formDataWithCDNUrls);
        const requestBody = { process: processId, auto: true, inputs };

        const { data } = await operateClient.post(API.ROUTES.operate.thread, requestBody);
        setNewJobId(data.id);

        setStartNewJobState('success');
    } catch (error) {
        setStartNewJobState('default');
        handleClientStartNewJobRequestErrors({ error, setErrorAlert, formData, setFormData });
    }
};
