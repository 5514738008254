import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/assistant-api';
import knowledgeClient from '../../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { openLinkInNewTab } from '../../../../helpers/openLinkInNewTab';

import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const SourceMoreOptionsPopup = ({
    baseId,
    sourceId,
    sources,
    setUpdatedSources,
    isSyncInProgress,
    openEditModal,
    onClose,
}) => {
    const { botId } = useParams();

    const [actionLoading, setActionLoading] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleRemoveSource = async () => {
        if (actionLoading) return;

        try {
            const updatedSources = sources?.filter((id) => id !== sourceId) || [];

            setActionLoading('remove');

            const { data } = await client.patch(`${API.ROUTES.assistant.customBot}${botId}/`, {
                sources: updatedSources,
            });
            setUpdatedSources({ data });

            onClose();
        } catch (e) {
            setActionLoading(null);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const handleSyncSource = async () => {
        if (actionLoading) return;

        try {
            setActionLoading('sync');

            await knowledgeClient.post(`${API.ROUTES.knowledge.source}${sourceId}/sync/`);
            const { data } = await client.get(`${API.ROUTES.assistant.customBot}${botId}/`);
            setUpdatedSources({ data });

            onClose();
        } catch (e) {
            setActionLoading(null);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const handleGoToSource = () => {
        const url = `/base/${baseId}`;
        openLinkInNewTab(url);

        onClose();
    };

    const handleEditSource = () => {
        openEditModal();
        onClose();
    };

    return (
        <div className="absolute top-10 right-2 z-20 pb-5" onClick={(e) => e.preventDefault()}>
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Edit source"
                            onClick={handleEditSource}
                        />
                        {!isSyncInProgress && (
                            <ListOption
                                leadingIconName="loopRightFillIcon"
                                text="Sync source"
                                isLoading={actionLoading === 'sync'}
                                onClick={handleSyncSource}
                            />
                        )}
                        <ListOption
                            leadingIconName="shareBoxLine"
                            text="Go to Base"
                            onClick={handleGoToSource}
                        />
                        <ListOption
                            leadingIconName="deleteBack2LineIcon"
                            text="Remove source"
                            isLoading={actionLoading === 'remove'}
                            textColor="#E95B69"
                            iconColor="#E95B69"
                            onClick={handleRemoveSource}
                        />
                    </>
                }
                handleClose={onClose}
            />

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};
export default SourceMoreOptionsPopup;
