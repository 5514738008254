import React from 'react';

import { useWindowSize } from '../../../hooks/useWindowSize';

import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import { ListOption, ListOptionGroup } from '../../../design-system';

const BotCardMoreOptionsPopup = ({ onClose, openNewChatWithBot, openBotDetailPopup }) => {
    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 768;

    const popupWidth = screenWidth >= 1024 && screenWidth < 1280 ? 270 : 300;

    const handleAction = ({ e, action, panelClosableByClickOutside = true }) => {
        action();

        onClose(e, panelClosableByClickOutside);
    };

    const options = (
        <>
            <ListOption
                leadingIconName="editBoxLineIcon"
                text="New Chat"
                onClick={(e) => handleAction({ e, action: openNewChatWithBot })}
            />
            <ListOption
                leadingIconName="eyeIcon"
                text="View Details"
                onClick={(e) =>
                    handleAction({
                        e,
                        action: openBotDetailPopup,
                        panelClosableByClickOutside: false,
                    })
                }
            />
        </>
    );

    if (isMobile) {
        return (
            <MobilePopup onClose={onClose} closeButtonText="Cancel">
                <div className="flex flex-col gap-1">{options}</div>
            </MobilePopup>
        );
    }

    return (
        <div className="absolute top-[28px] right-[-12px] z-20 pb-5">
            <ListOptionGroup fixedWidth={popupWidth} listOptions={options} handleClose={onClose} />
        </div>
    );
};

export default BotCardMoreOptionsPopup;
