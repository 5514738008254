import React, { Fragment } from 'react';

import SourceInfoBlockWrapper from '../SourceInfoBlockWrapper/SourceInfoBlockWrapper';

const SourceInfoModalTextUsedBlock = ({ nodes }) => {
    const allNodesAreEmpty = nodes.every((node) => !node?.content);

    return (
        <SourceInfoBlockWrapper
            title="Text Used"
            description="The chatbot used the following pieces of information from this source:"
        >
            {!allNodesAreEmpty && (
                <ul className="flex flex-col gap-3">
                    {nodes.map((node) => {
                        const { node_id, content } = node || {};

                        if (!content) return <Fragment key={node_id}></Fragment>;

                        return (
                            <li
                                key={node_id}
                                className="p-2.5 w-full border-1 border-neutral-200 rounded-2"
                            >
                                <p className="font-body text-body-regular-s text-neutral-500 whitespace-pre-wrap">
                                    {content}
                                </p>
                            </li>
                        );
                    })}
                </ul>
            )}

            {allNodesAreEmpty && (
                <p className="font-body-bold text-body-bold-s text-neutral-400">
                    No text available
                </p>
            )}
        </SourceInfoBlockWrapper>
    );
};

export default SourceInfoModalTextUsedBlock;
