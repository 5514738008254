import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { useDrag, useDrop } from 'react-dnd';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import { getEmptyImage } from 'react-dnd-html5-backend';
import { fetchFolderLocation, handleMoveOnDrop } from '../../../helpers/docsDndUtils';

import { CONTENT_TYPE, DRAGGABLE_ITEM_TYPE } from '../../../constants/docs';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import FolderOrDocCard from '../FolderOrDocCard/FolderOrDocCard';

const DraggableFolderOrDocCard = ({ selectedItems, ...cardProps }) => {
    const { item, setSelectedItems } = cardProps;
    const ref = useRef(null);

    const [searchParams] = useSearchParams();

    const {
        location: currentPageLocation,
        setSidePanelFolders,
        setExpandedFolders,
        requestKeysToMutate,
        currentRequestKeyToMutate,
        isDragAndDropEnabled,
    } = useDocsPageContext() || {};

    const [errorAlert, setErrorAlert] = useState(null);

    const [{ isDragging }, drag, preview] = useDrag({
        type: DRAGGABLE_ITEM_TYPE.indexPageItem,
        item: () => {
            const activeDraggedItem = { ...item };

            let draggedItems;

            const isDraggedItemSelected = !!selectedItems?.[activeDraggedItem.id];
            if (isDraggedItemSelected) {
                draggedItems = Object.values(selectedItems);
            }

            if (!isDraggedItemSelected) {
                draggedItems = [activeDraggedItem];
            }

            const draggedItemsIds = draggedItems.map(({ id }) => id);

            return {
                draggedItems,
                draggedItemsIds,
                activeDraggedItem,
                isDraggingSelectedItems: isDraggedItemSelected,
                draggedItemsParentLocation: currentPageLocation,
            };
        },

        end: (data, monitor) => {
            const didDrop = monitor.didDrop();

            const { isDraggingSelectedItems } = data || {};

            if (didDrop && isDraggingSelectedItems) {
                setSelectedItems({});
            }
        },

        isDragging: (monitor) => {
            return monitor.getItem().draggedItemsIds.includes(item.id);
        },
        canDrag: () => isDragAndDropEnabled,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: [DRAGGABLE_ITEM_TYPE.indexPageItem, DRAGGABLE_ITEM_TYPE.sidePanelFolder],
        canDrop: () =>
            item.content_type === CONTENT_TYPE.folder && !isDragging && isDragAndDropEnabled,
        drop: async ({ draggedItems, draggedItemsIds, draggedItemsParentLocation }, monitor) => {
            const type = monitor.getItemType();

            const isSearchApplied = !!searchParams.get('search');

            let targetFolderLocation = [...currentPageLocation, { id: item.id, label: item.label }];

            if (isSearchApplied) {
                const location = await fetchFolderLocation({ folderId: item.id });
                if (location) {
                    targetFolderLocation = location;
                }
            }

            const targetFolder = { ...item, location: targetFolderLocation };

            await handleMoveOnDrop({
                targetFolder,
                draggedItems,
                draggedItemsIds,
                draggedItemsParentLocation,
                requestKeysToMutate,
                currentRequestKeyToMutate,
                setErrorAlert,
                setExpandedFolders,
                setSidePanelFolders,
                type,
            });
        },
        collect: (monitor) => {
            const canDrop = monitor.canDrop();
            return { isOver: canDrop && monitor.isOver() };
        },
    });

    useEffect(() => {
        preview(getEmptyImage(), {
            captureDraggingState: true,
        });
    }, []);

    drag(drop(ref));

    const className = classNames(
        isDragging ? 'opacity-50' : 'opacity-100',
        isOver && 'shadow-2 shadow-purple-500'
    );

    return (
        <div ref={ref} className={className}>
            <FolderOrDocCard {...cardProps} isDndOver={isOver} />

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default DraggableFolderOrDocCard;
