import React from 'react';

import Loading from '../../../components/Loading';
import BlockWrapper from '../BlockWrapper/BlockWrapper';
import PlaybookCard from '../PlaybookCard/PlaybookCard';
import PlayProcessIcon from '../../../design-system/Icons/PlayProcessIcon';

const YourTopPlaybooksBlock = ({ playbooks }) => {
    return (
        <BlockWrapper
            title="Your Top Playbooks"
            description="Explore your frequently utilized playbooks"
            isViewButtonDisplayed
            viewAllNavPath="/playbooks"
            customPadding="!p-0"
            titleCustomClassName="!text-heading-bold-s !mb-1"
        >
            {playbooks && (
                <>
                    {!!playbooks.length && (
                        <ul className="grid grid-cols-1 min-[600px]:grid-cols-2 gap-x-3 gap-y-4 min-[704px]:grid-cols-2 lg:grid-cols-3 lg:gap-x-4 min-[1200px]:grid-cols-4 min-[1800px]:grid-cols-5">
                            {playbooks.map((playbook) => (
                                <PlaybookCard key={playbook.id} playbook={playbook} />
                            ))}
                        </ul>
                    )}

                    {!playbooks.length && (
                        <div className="flex flex-col items-center justify-center gap-3 pb-3">
                            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full mx-auto">
                                <PlayProcessIcon width={32} height={32} color="#5E6470" />
                            </div>
                            <p className="font-heading-bold text-heading-bold-s text-neutral-500 text-center">
                                You don’t have any Playbooks yet.
                            </p>
                        </div>
                    )}
                </>
            )}

            {!playbooks && <Loading withText={false} />}
        </BlockWrapper>
    );
};

export default YourTopPlaybooksBlock;
