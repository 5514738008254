import React, { memo, useState } from 'react';

import { useOverlayState } from '../../../hooks/useOverlayState';

import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import ShareFeedbackModal from '../../../components/ShareFeedbackModal/ShareFeedbackModal';
import AssistantMessageSource from '../AssistantMessageSource/AssistantMessageSource';

const AssistantMessageSourcesBlock = ({
    toolsArray,
    currentVersionId,
    isSimplifiedVersion,
    onSuccessFeedbackShare,
    isCustomChatType = false,
}) => {
    const { isOpened: isLogFeedbackModalOpened, toggle: toggleLogFeedbackModal } =
        useOverlayState();
    const [successAlert, setSuccessAlert] = useState(null);

    const sources = toolsArray.reduce((acc, [_, tool]) => {
        const currentToolSources =
            tool.tool_output?.sources?.map((source) => ({
                ...source,
                icon_color: tool.icon_color,
                tool_type: tool.type,
            })) || [];
        acc.push(...currentToolSources);
        return acc;
    }, []);

    const areSources = !!sources.length;

    if (!areSources) {
        return <></>;
    }

    return (
        <div>
            <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase mb-2">
                Sources:
            </p>

            <ul className="flex items-center gap-2 flex-wrap">
                {sources.map((source, index) => (
                    <AssistantMessageSource
                        key={index}
                        source={source}
                        isSimplifiedVersion={isSimplifiedVersion}
                        isCustomChatType={isCustomChatType}
                        toggleLogFeedbackModal={toggleLogFeedbackModal}
                    />
                ))}
            </ul>

            {isLogFeedbackModalOpened && (
                <ShareFeedbackModal
                    target="chat_message_version"
                    targetId={currentVersionId}
                    setSuccessAlert={setSuccessAlert}
                    successAlertMessage="Feedback successfully submitted!"
                    onSuccessFeedbackShare={onSuccessFeedbackShare}
                    onClose={toggleLogFeedbackModal}
                />
            )}

            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </div>
    );
};

export default memo(AssistantMessageSourcesBlock);
