import React from 'react';

import { CONTENT_TYPE, CONTENT_TYPE_ICON } from '../../../constants/docs';

import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import classNames from 'classnames';

const FolderAndDocsDragPreview = ({ data }) => {
    const { activeDraggedItem, draggedItems } = data || {};

    const contentType = activeDraggedItem?.content_type || CONTENT_TYPE.document;
    const name = activeDraggedItem?.label || 'Untitled';

    const count = draggedItems?.length || 1;
    const areMultiple = count > 1;

    const Icon = CONTENT_TYPE_ICON[contentType] || FileTextLineIcon;

    const containerClassName = classNames(
        'w-[300px] h-[56px] relative',
        areMultiple &&
            "before:absolute before:content-[''] before:w-[calc(100%-8px)] before:h-[56px] before:bg-white before:rounded-2 before:border-2 before:border-neutral-200 before:transform before:translate-x-1 before:translate-y-1 before:z-[-1] after:absolute after:content-[''] after:w-[calc(100%-16px)] after:h-[56px] after:bg-white after:rounded-2 after:border-2 after:border-neutral-200 after:transform after:translate-x-2 after:-translate-y-[48px] after:z-[-2]"
    );

    return (
        <div className={containerClassName}>
            <div className="w-full h-full px-4 bg-white rounded-2 border-2 border-neutral-200 flex items-center gap-2 overflow-hidden">
                <Icon width={20} height={20} color="#754DCF" />

                <p className="flex-1 font-body text-body-regular-s text-neutral-500 truncate">
                    {name}
                </p>
            </div>

            {areMultiple && (
                <div className="absolute top-0 right-0 transform translate-x-[35%] -translate-y-[35%] rounded-full bg-black w-fit h-[26px] px-2.5 flex items-center font-body-bold text-body-bold-xs text-white">
                    {count}
                </div>
            )}
        </div>
    );
};

export default FolderAndDocsDragPreview;
