import React, { useEffect, useState } from 'react';

import { useSelectDocModalFetch } from '../../../hooks/useSelectDocModalFetch';

import { ROOT_FOLDER_INITIAL_DATA } from '../../../constants/selectDocModal';

import {
    collapseAllFolders,
    expandFoldersWithSelectedDocs,
} from '../../../helpers/selectDocModalUtils';

import SelectDocModal from './SelectDocModal/SelectDocModal';

const SelectDocBlock = ({
    selectedDocs,
    setSelectedTools,
    clearErrorMessage,
    textareaHeight,
    isModalOpened,
    setIsModalOpened,
}) => {
    const [foldersDocumentsData, setFoldersDocumentsData] = useState(ROOT_FOLDER_INITIAL_DATA);

    const { docsOptionsHookResponse, isLoading } = useSelectDocModalFetch({
        foldersDocumentsData,
        setFoldersDocumentsData,
    });

    useEffect(() => {
        if (isModalOpened) {
            const selectedDocsArray = Object.keys(selectedDocs || {});
            expandFoldersWithSelectedDocs({
                selectedDocs: selectedDocsArray,
                setFoldersDocumentsData,
            });
        }
    }, [isModalOpened]);

    const closeSelectDocModal = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setIsModalOpened(false);
        collapseAllFolders({ setFoldersDocumentsData });
    };

    return (
        <>
            {isModalOpened && (
                <SelectDocModal
                    foldersDocumentsData={foldersDocumentsData}
                    setFoldersDocumentsData={setFoldersDocumentsData}
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    selectedDocs={selectedDocs}
                    setSelectedTools={setSelectedTools}
                    clearErrorMessage={clearErrorMessage}
                    textareaHeight={textareaHeight}
                    isRootDataLoading={isLoading}
                    onClose={closeSelectDocModal}
                />
            )}
        </>
    );
};

export default SelectDocBlock;
