import {
    IMPORT_REGEX as DOCUMENT_TAG_IMPORT_REGEX,
    TAG_REGEX as DOCUMENT_TAG_REGEX,
} from '../constants/richTextEditorWithDocsMapping';
import { codeBlockRegex } from '../constants/regex_patterns';

// on markdown load
export function prepareMarkdownOnLoad(markdown) {
    if (!markdown || typeof markdown !== 'string') return '';

    return escapeUnescapedCurlyBraces(markdown);
}

// on markdown submit
export function normalizeMarkdown(markdown) {
    // replaces multiple consecutive newlines in markdown with two newlines to ensure consistent spacing
    return markdown?.replace(/\n{3,}/g, '\n\n');
}

const MAINTAINED_IMPORTS_EXPORTS = [DOCUMENT_TAG_IMPORT_REGEX];

const blockTags = 'p|div|ul|ol|li|table|tr|td|th|thead|tbody|pre|blockquote|form';
const inlineTags = 'span|a|strong|em|b|i|u|s|sup|sub|mark|label|textarea|select|code';
const selfClosingTags = 'br|hr|img|input|button';

const htmlTagRegex = new RegExp(
    `<(${blockTags}|${inlineTags})(?:\\s+[^>]+)?>[^<]+<\\/\\1>|` +
        `<(${selfClosingTags})(?:\\s+[^>]+)?\\s*\\/>|` +
        `<(h[1-6])(?:\\s+[^>]+)?>[^<]+<\\/\\3>`,
    `g`
);

// add slash (\) before < to avoid parsing as JSX
export function escapeInvalidAngleBrackets(markdown) {
    if (!markdown || typeof markdown !== 'string') return '';

    // check if markdown has < without a preceding \
    if (!/(?<!\\)</.test(markdown)) {
        return markdown;
    }

    const codeBlocks = [];
    const htmlTags = [];
    const customTags = [];

    let tempText = markdown.replace(codeBlockRegex, (match) => {
        codeBlocks.push(match);
        return `__CODEBLOCK__${codeBlocks.length - 1}__`;
    });

    tempText = tempText.replace(DOCUMENT_TAG_REGEX, (match) => {
        customTags.push(match);
        return `__CUSTOMTAG__${customTags.length - 1}__`;
    });

    tempText = tempText.replace(htmlTagRegex, (match) => {
        htmlTags.push(match);
        return `__HTMLTAG__${htmlTags.length - 1}__`;
    });

    tempText = tempText.replace(/(?<!\\)</g, '\\<');

    tempText = tempText.replace(/__CODEBLOCK__(\d+)__/g, (_, index) => codeBlocks[index]);
    tempText = tempText.replace(/__HTMLTAG__(\d+)__/g, (_, index) => htmlTags[index]);
    tempText = tempText.replace(/__CUSTOMTAG__(\d+)__/g, (_, index) => customTags[index]);

    tempText = tempText.replace(/\\<(br|hr)\s*\/?>/g, '<$1/>');

    return tempText;
}

// add slash (\) before { to avoid parsing as JSX
export function escapeUnescapedCurlyBraces(markdown) {
    if (!markdown || typeof markdown !== 'string') return '';

    // check if markdown has { without a preceding \
    if (!/(?<!\\){/.test(markdown)) {
        return markdown;
    }

    const codeBlocks = [];
    const importStatements = [];
    const htmlTags = [];

    let tempText = markdown.replace(codeBlockRegex, (match) => {
        codeBlocks.push(match);
        return `__CODEBLOCK__${codeBlocks.length - 1}__`;
    });

    MAINTAINED_IMPORTS_EXPORTS.forEach((regex) => {
        tempText = tempText.replace(regex, (match) => {
            importStatements.push(match);
            return `__IMPORTSTATEMENT__${importStatements.length - 1}__`;
        });
    });

    tempText = tempText.replace(htmlTagRegex, (match) => {
        htmlTags.push(match);
        return `__HTMLTAG__${htmlTags.length - 1}__`;
    });

    tempText = tempText.replace(/(?<!\\){/g, '\\{');

    tempText = tempText.replace(
        /__IMPORTSTATEMENT__(\d+)__/g,
        (_, index) => importStatements[index]
    );

    tempText = tempText.replace(/__CODEBLOCK__(\d+)__/g, (_, index) => codeBlocks[index]);

    tempText = tempText.replace(/__HTMLTAG__(\d+)__/g, (_, index) => htmlTags[index]);

    return tempText;
}

// Lines that start with export and import and contain invalid JS cause errors
// Therefore, all import and export statements that are not valid should are capitalized to avoid parsing JSX errors
export function escapeInvalidImportsExports(markdown) {
    if (!markdown || typeof markdown !== 'string') return '';

    if (!/\b(import|export)\b/.test(markdown)) {
        return markdown;
    }

    const importExportRegex = /^(import|export)\s.*$/gm;

    const codeBlocks = [];

    let tempText = markdown.replace(codeBlockRegex, (match) => {
        codeBlocks.push(match);
        return `__CODEBLOCK__${codeBlocks.length - 1}__`;
    });

    tempText = tempText.replace(importExportRegex, (match) => {
        let isValidImportExport = false;

        for (const regex of MAINTAINED_IMPORTS_EXPORTS) {
            if (regex.test(match)) {
                isValidImportExport = true;
                break;
            }
        }

        if (isValidImportExport) {
            return match;
        } else {
            return `${match.charAt(0).toUpperCase()}${match.slice(1)}`;
        }
    });
    tempText = tempText.replace(/__CODEBLOCK__(\d+)__/g, (_, index) => codeBlocks[index]);

    return tempText;
}
