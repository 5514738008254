import React from 'react';

const Loader2Icon = ({ width, height, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
            <mask id="path-1-inside-1_16877_321671" fill="white">
                <path d="M10.7123 3.28769L9.88732 4.11265C9.14842 3.37371 8.12758 2.91667 7 2.91667C4.74484 2.91667 2.91667 4.74484 2.91667 7C2.91667 9.25517 4.74484 11.0833 7 11.0833C9.25517 11.0833 11.0833 9.25517 11.0833 7H12.25C12.25 9.89952 9.89952 12.25 7 12.25C4.10051 12.25 1.75 9.89952 1.75 7C1.75 4.10051 4.10051 1.75 7 1.75C8.44976 1.75 9.76226 2.33763 10.7123 3.28769Z" />
            </mask>
            <path
                d="M10.7123 3.28769L11.773 4.34838L12.8337 3.28772L11.773 2.22702L10.7123 3.28769ZM9.88732 4.11265L8.82664 5.17329L9.88727 6.23395L10.948 5.17334L9.88732 4.11265ZM11.0833 7V5.5H9.58333V7H11.0833ZM12.25 7H13.75V5.5H12.25V7ZM9.65171 2.227L8.8267 3.05195L10.948 5.17334L11.773 4.34838L9.65171 2.227ZM10.948 3.05201C9.93928 2.04324 8.54138 1.41667 7 1.41667V4.41667C7.71379 4.41667 8.35755 4.70418 8.82664 5.17329L10.948 3.05201ZM7 1.41667C3.91641 1.41667 1.41667 3.91641 1.41667 7H4.41667C4.41667 5.57327 5.57327 4.41667 7 4.41667V1.41667ZM1.41667 7C1.41667 10.0836 3.91641 12.5833 7 12.5833V9.58333C5.57326 9.58333 4.41667 8.42674 4.41667 7H1.41667ZM7 12.5833C10.0836 12.5833 12.5833 10.0836 12.5833 7H9.58333C9.58333 8.42674 8.42674 9.58333 7 9.58333V12.5833ZM11.0833 8.5H12.25V5.5H11.0833V8.5ZM10.75 7C10.75 9.07109 9.07109 10.75 7 10.75V13.75C10.7279 13.75 13.75 10.7279 13.75 7H10.75ZM7 10.75C4.92893 10.75 3.25 9.07108 3.25 7H0.25C0.25 10.7279 3.27208 13.75 7 13.75V10.75ZM3.25 7C3.25 4.92893 4.92893 3.25 7 3.25V0.25C3.27208 0.25 0.25 3.27208 0.25 7H3.25ZM7 3.25C8.03596 3.25 8.97141 3.66809 9.65168 4.34836L11.773 2.22702C10.5531 1.00716 8.86356 0.25 7 0.25V3.25Z"
                fill={color}
                mask="url(#path-1-inside-1_16877_321671)"
            />
        </svg>
    );
};

export default Loader2Icon;
