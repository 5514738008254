import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import { useFormState } from '../../../hooks/useFormState';
import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { isFileFormatValid, isFileSizeValid, megabytesToBytes } from '../../../helpers/fileUtils';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import FileInput from '../../../design-system/FileInput/FileInput';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import { Button } from '../../../design-system';

const MAX_SIZE = megabytesToBytes(5);
const AVAILABLE_FORMATS = ['.pdf'];

const UploadFileModal = ({ onClose }) => {
    const { folderId } = useParams();

    const { formData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } = useFormState(
        { file: null }
    );

    const { currentRequestKeyToMutate } = useDocsPageContext() || {};

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const { isMobile } = useResponsiveBreakpoints();

    const formatFileName = (name) => {
        if (!name || typeof name !== 'string') return '';
        return name.replace(/[^a-zA-Z0-9._-]/g, ''); // Removes everything except letters, numbers, dots, hyphens, and underscores
    };

    const handleUploadFile = async () => {
        const { file } = formData;

        if (!file) {
            setFieldErrorMessages({ file: 'Please select a file.' });
            return;
        }

        try {
            const isWrongFormat = !isFileFormatValid({ file, availableFormats: AVAILABLE_FORMATS });
            const isWrongSize = !isFileSizeValid({ file, maxSizeInBytes: MAX_SIZE });
            const isInvalidFile = isWrongFormat || isWrongSize;
            if (isInvalidFile) {
                if (!fieldErrorMessages.file) {
                    setFieldErrorMessages({ file: 'Invalid file format or size.' });
                }
                return;
            }

            setIsLoading(true);
            const fileName = file.name;

            const requestBody = new FormData();
            requestBody.append('file', file);
            requestBody.append('label', fileName);
            requestBody.append('key', formatFileName(fileName));

            if (folderId) {
                requestBody.append('folder', folderId);
            }

            await client.post(API.ROUTES.knowledge.document, requestBody);
            if (currentRequestKeyToMutate) {
                await mutate(currentRequestKeyToMutate);
            }

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e?.response?.status });
        }
    };

    const setErrorMessage = (message) => {
        setFieldErrorMessages({ file: message });
    };

    const fileInput = (
        <FormFieldWrapper
            state={fieldErrorMessages.file ? 'error' : 'default'}
            errorMessage={fieldErrorMessages.file}
        >
            <FileInput
                handleFile={(file) => handleInputChange('file', file)}
                file={formData.file}
                formatsArray={['.pdf']}
                maxSizeInBytes={MAX_SIZE}
                setErrorMessage={setErrorMessage}
                errorNotificationMode="message"
                state={fieldErrorMessages.file ? 'error' : 'default'}
                placeholder="Drag & drop a file to upload or click to browse"
            />
        </FormFieldWrapper>
    );

    const submitButtonState = isLoading
        ? 'loading'
        : !formData.file || !!fieldErrorMessages.file
        ? 'disabled'
        : 'default';

    const controls = (
        <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between gap-2">
            <Button size="sm" type="neutral" text="Cancel" onClick={onClose} />
            <Button
                size="sm"
                type="secondary"
                text="Add File"
                state={submitButtonState}
                onClick={handleUploadFile}
            />
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                bottomButtonsGroup={controls}
                containerCustomPaddings="!pt-0 pb-5"
                contentCustomPaddings="!py-0"
                bottomButtonsCustomPaddings="px-5"
                withCloseButton={false}
                customGap={32}
                errorAlert={errorAlert}
                setErrorAlert={setErrorAlert}
            >
                <div className="flex flex-col gap-5">
                    <ModalHeader
                        title="Upload File"
                        outlined
                        customPaddings="px-5 py-3"
                        withEscapeButton={false}
                        onClose={onClose}
                    />
                    <div className="px-5">{fileInput}</div>
                </div>
            </MobilePopup>
        );
    }

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title="Upload File" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                {fileInput}

                {controls}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default UploadFileModal;
