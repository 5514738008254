import { realmPlugin, addImportVisitor$ } from '@mdxeditor/editor';
import { $createParagraphNode, $createTextNode } from 'lexical';

export const CatchAllVisitor = {
    testNode: () => true,
    visitNode: ({ mdastNode, actions }) => {
        const paragraph = $createParagraphNode();

        if (mdastNode.type === 'mdxFlowExpression' || mdastNode.type === 'mdxTextExpression') {
            paragraph.append($createTextNode(mdastNode.value));
        }

        actions.addAndStepInto(paragraph);
    },
    priority: -500,
};

export const catchAllPlugin = realmPlugin({
    init(realm) {
        realm.pub(addImportVisitor$, CatchAllVisitor);
    },
});
