import React from 'react';
import classNames from 'classnames';

import { sectionContainerStyles } from '../PlaybookDetailsPanel/PlaybookDetailsPanel';

import OrganizationTag from '../../../design-system/OrganizationTag/OrganizationTag';
import PlaybookInfoBadges from '../PlaybookInfoBadges/PlaybookInfoBadges';

const PlaybookMainInfo = ({ playbook }) => {
    const { name, description, process_count, sections, is_shared, organization, scenarios } =
        playbook;

    const containerClassName = classNames('flex flex-col gap-4', sectionContainerStyles);
    return (
        <div className={containerClassName}>
            {is_shared && <OrganizationTag name={organization?.name} />}

            <div>
                <p className="font-heading-regular font-[600] text-[20px] lg:text-[24px] leading-[26px] text-black mb-1">
                    {name}
                </p>
                <p className="font-body text-body-regular-s text-neutral-300">{description}</p>
            </div>

            <PlaybookInfoBadges
                processes={process_count}
                sections={sections}
                scenarios={scenarios}
            />
        </div>
    );
};

export default PlaybookMainInfo;
