import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import { exportDiagramAsPdf } from '../../../helpers/diagramsUtils';
import { exportDocumentAsPdf } from '../../../helpers/exportDocumentAsPdf';
import { escapeInvalidAngleBrackets } from '../../../helpers/normalizeMarkdown';

import { DOCUMENT_TYPE } from '../../../constants/docs';

import Diagram from '../../../design-system/Diagram/Diagram';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';

const DocAndDiagramExporter = forwardRef(({ id }, ref) => {
    const diagramRef = useRef(null);

    const [data, setData] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    useImperativeHandle(ref, () => ({
        exportAsPdf: async () => {
            let label = data?.label;
            let documentType = data?.file_type;
            let value = data?.default_version?.value;

            if (!data) {
                const { data: response } = await client.get(
                    `${API.ROUTES.knowledge.document}${id}/`,
                );
                label = response?.label;
                documentType = response?.file_type;

                let formattedValue = response?.default_version?.value;
                if (response?.file_type === DOCUMENT_TYPE.document) {
                    formattedValue = escapeInvalidAngleBrackets(response?.default_version?.value);
                }
                value = formattedValue;

                const formattedResponse = {
                    ...response,
                    default_version: { ...response.default_version, value: formattedValue },
                };
                setData(formattedResponse);
            }

            if (documentType === DOCUMENT_TYPE.diagram) {
                setTimeout(async () => {
                    await exportDiagramAsPdf({
                        diagramRef,
                        diagramName: label,
                        setErrorAlert,
                    });
                }, 100);
            }

            if (documentType === DOCUMENT_TYPE.document) {
                await exportDocumentAsPdf({ markdown: value, documentName: label, setErrorAlert });
            }
        },
    }));

    const isDiagram = data?.file_type === DOCUMENT_TYPE.diagram;
    const code = data?.default_version?.value;
    const label = data?.label;

    return (
        <>
            <div
                className="absolute z-[-1] opacity-0 h-[50px] max-h-[50px] overflow-auto"
                ref={diagramRef}
            >
                {isDiagram && <Diagram code={code} name={label} />}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
});

export default DocAndDiagramExporter;
