import { isValidJson } from './isValidJson';
import { generateUUID } from './generateUUID';
import { normalizeMarkdown, prepareMarkdownOnLoad } from './normalizeMarkdown';

import { API } from 'constants';
import client from '../services/user-api';
import { defaultErrorMessage, invalidJsonErrorMessage } from '../constants/errorMessages';

import {
    SETTING_ACTION_TYPE,
    ACTION_TYPE_INITIAL_VALUE,
    SHARED_SETTING_PLACEHOLDER,
    SHARED_SETTING_VALUE_REGEXP,
    ALLOWED_SETTING_ACTION_TYPES,
} from '../constants/settingsForm';
import {
    getRichTextWithDocsMappingInitialStateUsingPrevDocsMapping,
    getRichTextWithLinkedDocsValueForSubmit,
} from './richTextEditorWithDocsMappingUtils';

const ACTIONS_WITH_ARRAY_VALUE = [
    SETTING_ACTION_TYPE.multi_input,
    SETTING_ACTION_TYPE.multi_select,
    SETTING_ACTION_TYPE.multi_dropdown,
    SETTING_ACTION_TYPE.nested_input,
    SETTING_ACTION_TYPE.multi_db,
];

export const getSettingsFormDataInitialState = ({
    settings,
    allowDocsMapping = false,
    prevDocsMapping = null,
    replaceOnlyFilledTags = true,
}) => {
    return settings?.map((setting) => {
        const { action, value } = setting;

        const formattedSetting = { ...setting, state: 'default', errorMessage: null };

        if (action === SETTING_ACTION_TYPE.json_input && !value) {
            return { ...formattedSetting, value: {} };
        }

        if (action === SETTING_ACTION_TYPE.nested_input) {
            const formattedValue =
                value?.map((item, index) => ({
                    order: index + 1,
                    temporaryId: generateUUID(),
                    data: item,
                })) || [];
            return { ...formattedSetting, value: formattedValue };
        }

        if (ACTIONS_WITH_ARRAY_VALUE.includes(action) && !value) {
            return { ...formattedSetting, value: [] };
        }

        if (action === SETTING_ACTION_TYPE.toggle && !value) {
            return { ...formattedSetting, value: false };
        }

        if (action === SETTING_ACTION_TYPE.richtext_input) {
            if (allowDocsMapping) {
                const { value: formattedValue, docsMapping } =
                    getRichTextWithDocsMappingInitialStateUsingPrevDocsMapping({
                        initialValue: value || '',
                        prevDocsMapping: prevDocsMapping || {},
                        replaceOnlyFilledTags,
                    });

                return { ...formattedSetting, value: formattedValue, docs_mapping: docsMapping };
            }

            if (!value) {
                return { ...formattedSetting, value: '' };
            }

            return { ...formattedSetting, value: prepareMarkdownOnLoad(value) };
        }

        if (!value) {
            return { ...formattedSetting, value: ACTION_TYPE_INITIAL_VALUE[action] };
        }

        return formattedSetting;
    });
};

export const mergeValuesIntoSettingsFormData = (formData, valuesArray) => {
    // an object if format {name: value} for faster lookup
    const settingValuesMap =
        valuesArray?.reduce(
            (acc, { name, value }) => ({
                ...acc,
                [name]: value,
            }),
            {}
        ) || {};

    return formData?.map((item) => {
        // set the value from the valuesArray if it exists
        const updatedValue = settingValuesMap[item.name] || item.value;
        return { ...item, value: updatedValue };
    });
};

export const checkIsRequiredFieldFilled = (item, skipSharedFields = false) => {
    const { action, is_required, is_shared, value } = item;

    if (skipSharedFields && is_shared) return true;
    if (!is_required) return true;

    let isFieldNotFilled = false;

    if (ACTIONS_WITH_ARRAY_VALUE.includes(action) && !value?.length) {
        isFieldNotFilled = true;
    }

    if (!ACTIONS_WITH_ARRAY_VALUE.includes(action)) {
        isFieldNotFilled = !value && value !== 0 && value !== false;
    }

    return !isFieldNotFilled;
};

export const checkIsRequiredNonHiddenFieldFilled = (item, skipSharedFields = false) => {
    return item.is_hidden || checkIsRequiredFieldFilled(item, skipSharedFields);
};

export const checkIfAllRequiredFieldsFilled = ({ formData, skipSharedFields = false }) => {
    return formData?.every((field) => checkIsRequiredFieldFilled(field, skipSharedFields));
};

export const checkIfAllNonHiddenRequiredFieldsFilled = ({ formData, skipSharedFields = false }) => {
    return formData?.every(
        (field) => field.is_hidden || checkIsRequiredFieldFilled(field, skipSharedFields)
    );
};

export const checkIfDisplayedRequiredFieldsFilled = ({
    formData,
    areHiddenSettingsDisplayed = true,
    skipSharedFields = false,
}) => {
    if (!areHiddenSettingsDisplayed) {
        return checkIfAllNonHiddenRequiredFieldsFilled({ formData, skipSharedFields });
    }
    return checkIfAllRequiredFieldsFilled({ formData, skipSharedFields });
};

const addErrorToFormData = (acc, item, errorMessage) => ({
    areErrorFields: true,
    updatedFormData: [...acc.updatedFormData, { ...item, state: 'error', errorMessage }],
});

export const checkIfInputtedJsonIsValid = (acc, item) => {
    const { action, value } = item;

    if (action === SETTING_ACTION_TYPE.json_input && value && typeof value === 'string') {
        const isJsonValueValid = isValidJson(value);
        if (!isJsonValueValid) {
            return addErrorToFormData(acc, item, invalidJsonErrorMessage);
        }
    }
};

export const checkIsFormDataValid = ({
    settingsFormData,
    setSettingsFormData,
    skipCheckingHiddenSettings = false,
    skipCheckingSharedSettings = false,
}) => {
    const { areErrorFields, updatedFormData } = settingsFormData.reduce(
        (acc, item) => {
            if (skipCheckingHiddenSettings && item.is_hidden) {
                return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
            }

            // check if inputted Json is valid
            const invalidJsonError = checkIfInputtedJsonIsValid(acc, item);
            if (invalidJsonError) {
                return invalidJsonError;
            }

            // check if all required fields are filled
            const isRequiredFieldFilled = checkIsRequiredNonHiddenFieldFilled(
                item,
                skipCheckingSharedSettings
            );
            if (isRequiredFieldFilled) {
                return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
            } else {
                return {
                    areErrorFields: true,
                    updatedFormData: [...acc.updatedFormData, { ...item, state: 'error' }],
                };
            }
        },
        { areErrorFields: false, updatedFormData: [] }
    );

    if (areErrorFields) {
        setSettingsFormData(updatedFormData);
        return false; // means that there is a form data error
    }

    return true; // means that there are no form data errors
};

export const getFormattedSettingsFormValue = (item) => {
    const { action, type, value, docs_mapping } = item;
    let formattedValue = value;

    // make value type "number" for action === 'single-input' && type === 'number' (note: we do not have to do this for item.type === 'integer' as that value is already parsedInt)
    if (action === SETTING_ACTION_TYPE.single_input && type === 'number' && value) {
        formattedValue = parseFloat(item.value);
    }

    if (action === SETTING_ACTION_TYPE.json_input && value && typeof value === 'string') {
        formattedValue = JSON.parse(item.value);
    }

    if (action === SETTING_ACTION_TYPE.nested_input && value) {
        formattedValue = item.value?.map(({ data }) => ({ ...data }));
    }

    if (action === SETTING_ACTION_TYPE.richtext_input && value) {
        if (docs_mapping && !!Object.keys(docs_mapping || {}).length) {
            formattedValue = getRichTextWithLinkedDocsValueForSubmit({
                value,
                docsMapping: docs_mapping,
            });
        } else {
            formattedValue = normalizeMarkdown(value);
        }
    }

    return formattedValue;
};

// should be wrapped with try{} catch{} block
export const replaceFilesInFormData = async (formData) => {
    if (!formData) return [];

    const updatedFormData = await Promise.all(
        formData.map(async (setting) => {
            const shouldSaveInCDN =
                setting.action === SETTING_ACTION_TYPE.single_file &&
                setting.value &&
                typeof setting.value === 'object';
            if (shouldSaveInCDN) {
                const formData = new FormData();
                formData.append('file', setting.value);

                const { data } = await client.post(API.ROUTES.user.uploadFile, formData);
                return { ...setting, value: data.file };
            }

            return setting;
        })
    );

    return updatedFormData;
};

export const formatSettingsFormDataForRequestBody = (settingsFormData) => {
    return (settingsFormData || []).map((item) => {
        const formattedValue = getFormattedSettingsFormValue(item);
        return { name: item.name, value: formattedValue };
    });
};

export const removeRedundantFieldsExceptDocsMapping = (setting) => {
    const { state, errorMessage, placeholder, original_value, ...rest } = setting;
    return rest;
};

export const removeRedundantFieldsFromSetting = (setting) => {
    const { state, errorMessage, placeholder, original_value, docs_mapping, ...rest } = setting;
    return rest;
};

export const formatSettingsForRequestBody = (formData) => {
    return (formData || []).map((setting) => {
        const normalizedSetting = removeRedundantFieldsFromSetting(setting);
        const value = getFormattedSettingsFormValue(setting);
        return { ...normalizedSetting, value };
    });
};

export const getMappedFormErrors = (errorObject, formData, errorKey) => {
    const updatedFormData = formData.map((item) => {
        // if there is an error message for current field set it
        const currentFieldError = errorObject[item[errorKey]];
        if (currentFieldError) {
            const currentFieldErrorMessage =
                typeof currentFieldError === 'string' ? currentFieldError : currentFieldError?.[0];
            return {
                ...item,
                state: 'error',
                errorMessage: currentFieldErrorMessage,
            };
        } else {
            return item;
        }
    });

    return updatedFormData;
};

export const getMappedSettingsFormErrors = (error, settingsFormData) => {
    const settingsErrors = error.response?.data?.error?.settings;
    return getMappedFormErrors(settingsErrors, settingsFormData, 'name');
};

// --------------utils for settings form broken up into steps-------------------
export const getSetStepFormDataFunction = (order, setFormData) => {
    // function that set form data for a specific step
    return (cb) => {
        setFormData((prevData) => ({
            ...prevData,
            [order]: typeof cb === 'function' ? cb(prevData[order]) : cb,
        }));
    };
};

export const formatStepFormDataForRequestBody = (stepFormData) => {
    return (stepFormData || []).map((setting) => {
        const { is_shared, original_value } = setting;

        const normalizedSetting = removeRedundantFieldsFromSetting(setting);
        const formattedValue = getFormattedSettingsFormValue(setting);

        const value = is_shared && original_value ? original_value : formattedValue;
        return { ...normalizedSetting, value };
    });
};

export const checkIfEveryStepFormDataValid = ({
    formData,
    setFormData,
    skipCheckingHiddenSettings = true,
    skipCheckingSharedSettings = false,
}) => {
    const updatedStepsErrorData = {};

    const isValid = Object.entries(formData)
        .map(([order, stepFormData]) => {
            const setStepFormData = getSetStepFormDataFunction(+order, setFormData);
            const isDataValid = checkIsFormDataValid({
                settingsFormData: stepFormData,
                setSettingsFormData: setStepFormData,
                skipCheckingHiddenSettings,
                skipCheckingSharedSettings,
            });

            if (!isDataValid) {
                // set error state for whole step
                updatedStepsErrorData[order] = { isError: true };
            }
            return isDataValid;
        })
        .every(Boolean);

    return { isValid, updatedStepsErrorData };
};

export const getMappedStepsFormErrors = (errorObject, formData, settings) => {
    const updatedStepsErrorData = {};
    const updatedFormData = Object.entries(formData)
        .map(([order, stepFormData]) => {
            const currentStepName = settings.find((step) => step.order === +order)?.name;
            const currentStepError = errorObject[currentStepName];

            // handle step-specific field errors
            if (currentStepError && typeof currentStepError === 'object') {
                updatedStepsErrorData[order] = { isError: true };
                return [order, getMappedFormErrors(currentStepError, stepFormData, 'name')];
            }

            // handle step-specific non-field errors
            if (currentStepError && typeof currentStepError === 'string') {
                updatedStepsErrorData[order] = {
                    isError: true,
                    errorMessage: currentStepError,
                };
            }

            return [order, stepFormData];
        })
        .reduce((acc, [order, stepsFormData]) => ({ ...acc, [order]: stepsFormData }), {});

    return { updatedFormData, updatedStepsErrorData };
};

export const handleStepsFormErrors = ({
    e,
    setErrorBannerMessage,
    setStepsErrorData,
    setErrorAlert,
    formData,
    setFormData,
    settings,
    expandAllErrorsSteps,
}) => {
    const error = e.response?.data?.error;

    if (typeof error === 'string') {
        // display general error banner
        setErrorBannerMessage(error);
    } else if (error?.settings) {
        // handle step-specific errors
        const { updatedFormData, updatedStepsErrorData } = getMappedStepsFormErrors(
            error?.settings,
            formData,
            settings
        );
        setFormData(updatedFormData);
        if (expandAllErrorsSteps) {
            expandAllErrorsSteps(updatedStepsErrorData);
        }
        setStepsErrorData(updatedStepsErrorData);
        setErrorAlert({
            message:
                'It looks like an error occurred at some steps. Please navigate to those steps to find out the error details.',
        });
    } else {
        setErrorAlert({ message: defaultErrorMessage });
    }
};

export const replaceFileInStepsFormData = async (formData) => {
    const updatedData = await Promise.all(
        Object.entries(formData).map(async ([order, stepFormData]) => {
            const updatedStepFormData = await replaceFilesInFormData(stepFormData);
            return [order, updatedStepFormData];
        })
    );

    return updatedData.reduce(
        (acc, [order, stepFormData]) => ({ ...acc, [order]: stepFormData }),
        {}
    );
};

export const updateSettingsFormDataRemovingLabelsFromDocsMapping = (settings) => {
    return settings?.map((setting) => {
        const { action, docs_mapping } = setting;
        if (
            action === SETTING_ACTION_TYPE.richtext_input &&
            !!Object.values(docs_mapping || {}).length
        ) {
            return {
                ...setting,
                docs_mapping: Object.fromEntries(
                    Object.entries(docs_mapping).map(([variableName, doc]) => [
                        variableName,
                        doc && typeof doc === 'object' ? { id: doc.id } : doc,
                    ])
                ),
            };
        }
        return setting;
    });
};

export const updateStepsFormDataRemovingLabelsFromDocsMapping = (formData) => {
    return Object.fromEntries(
        Object.entries(formData).map(([key, settings]) => [
            key,
            updateSettingsFormDataRemovingLabelsFromDocsMapping(settings),
        ])
    );
};

export const extractPrevDocsMappingFromStepFormData = (stepFormData) => {
    const prevDocsMapping = stepFormData.reduce((acc, setting) => {
        const { action, docs_mapping } = setting;
        if (
            action === SETTING_ACTION_TYPE.richtext_input &&
            !!Object.keys(docs_mapping || {}).length
        ) {
            const formattedDocsMapping = Object.entries(docs_mapping).map(([_, doc]) => {
                const newKey = 'variable-' + generateUUID();
                return [newKey, doc];
            });
            return { ...acc, ...Object.fromEntries(formattedDocsMapping) };
        }
        return acc;
    }, {});

    return prevDocsMapping;
};

// --------------utils with is_shared logic-------------------

// use this function with is_shared logic (disable all is_shared inputs)
export const getInitialSettingsFormData = ({
    settings,
    shouldRemoveSharedValues = false,
    allowDocsMapping = false,
    prevDocsMapping = null,
    replaceOnlyFilledTags = true,
}) => {
    return settings?.map((setting) => {
        const { action, value, is_shared } = setting;
        const formattedSetting = { ...setting, state: 'default', errorMessage: null };
        if (is_shared) {
            formattedSetting.state = 'disabled';
        }

        if (is_shared && ALLOWED_SETTING_ACTION_TYPES.includes(action)) {
            // The value is received in the format: ${context.key}
            // An empty value is set for the `value` field, and the original string value is stored in the `original_value` field.
            // This approach is necessary because some input action types only support array values and cannot handle string values directly.
            const shouldReset =
                shouldRemoveSharedValues && SHARED_SETTING_VALUE_REGEXP.test(String(value));
            if (shouldReset) {
                const original_value = value;
                const resetedValue = ACTION_TYPE_INITIAL_VALUE[action];
                return {
                    ...formattedSetting,
                    value: resetedValue,
                    original_value,
                    placeholder: SHARED_SETTING_PLACEHOLDER,
                };
            }
        }

        if (action === SETTING_ACTION_TYPE.nested_input) {
            const formattedValue = Array.isArray(value)
                ? value?.map((item, index) => ({
                      order: index + 1,
                      temporaryId: generateUUID(),
                      data: item,
                  }))
                : [];
            return { ...formattedSetting, value: formattedValue };
        }

        if (action === SETTING_ACTION_TYPE.richtext_input) {
            if (allowDocsMapping) {
                const { value: formattedValue, docsMapping } =
                    getRichTextWithDocsMappingInitialStateUsingPrevDocsMapping({
                        initialValue: value,
                        prevDocsMapping: prevDocsMapping || {},
                        replaceOnlyFilledTags,
                    });

                return { ...formattedSetting, value: formattedValue, docs_mapping: docsMapping };
            }

            return { ...formattedSetting, value: prepareMarkdownOnLoad(value) };
        }

        if (!value && ALLOWED_SETTING_ACTION_TYPES.includes(action)) {
            const defaultValue = ACTION_TYPE_INITIAL_VALUE[action];
            return { ...formattedSetting, value: defaultValue };
        }

        return formattedSetting;
    });
};
