import * as API from '../constants/api';
import client from '../services/knowledge-api';

import { SORT_TYPES } from '../constants/sort';
import { CONTENT_TYPE } from '../constants/docs';
import { setDocsAndFoldersDataOnFetch } from '../helpers/selectDocModalUtils';

import { useFetchPaginatedDataWithExternalStates } from './useFetchPaginatedDataWithExternalStates';
import { useFetchOptionsForPaginatedSelectWithSWR } from './useFetchOptionsForPaginatedSelectWithSWR';

export const useSelectDocModalFetch = ({ foldersDocumentsData, setFoldersDocumentsData }) => {
    const docsOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.knowledge.document,
        searchParams: { ordering: SORT_TYPES.alphabeticalByLabel },
        formatResponseToOptions,
    });

    function formatResponseToOptions(results) {
        return results?.map(({ id, label, file_type, content_type }) => {
            let contentType = content_type;

            if (!content_type) {
                contentType = file_type || CONTENT_TYPE.document;
            }

            return { id, label, content_type: contentType };
        });
    }

    const setRootItems = ({ newResults, canLoadMore }) => {
        setDocsAndFoldersDataOnFetch({
            newResults,
            canLoadMore,
            location: [],
            setFoldersDocumentsData,
        });
    };

    const { pagination } = foldersDocumentsData;

    const { isLoading } = useFetchPaginatedDataWithExternalStates({
        client,
        route: API.ROUTES.knowledge.folderDocument,
        page: pagination.page,
        shouldFetch: !pagination.isFetched,
        updateStateAfterFetch: setRootItems,
    });

    return {
        docsOptionsHookResponse,
        isLoading,
    };
};
