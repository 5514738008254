import React from 'react';

import { API } from 'constants';
import client from '../../services/library-api';
import assistantClient from '../../services/assistant-api';

import useUser from '../../hooks/useUser';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useCustomPagination from '../../hooks/useCustomPagination';
import { useFetchOptionsForPaginatedSelect } from '../../hooks/useFetchOptionsForPaginatedSelect';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { SORT_TYPES } from '../../constants/sort';

import Loading from '../../components/Loading';
import YourChatbotsBlock from './YourChatbotsBlock/YourChatbotsBlock';
import ClientPageTitleBlock from '../../components/ClientPageTitleBlock/ClientPageTitleBlock';
import YourTopProcessesBlock from './YourTopProcessesBlock/YourTopProcessesBlock';
import YourTopPlaybooksBlock from './YourTopPlaybooksBlock/YourTopPlaybooksBlock';
import NewAgentSuggestionsBlock from './NewAgentSuggestionsBlock/NewAgentSuggestionsBlock';

const DashboardPage = () => {
    const { user } = useUser();

    const {
        options: processes,
        optionsLoading: processesLoading,
        setPage: setProcessRequestPage,
        canLoadMoreOptions: canLoadMoreProcesses,
        isFirstRequestCompleted: areProcessesLoaded,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.process,
        searchParams: { shared: true, ordering: SORT_TYPES.lastRun },
        limit: 8,
        autoFetchNextPage: true,
    });

    const { data: playbooks } = useCustomPagination({
        client: client,
        route: API.ROUTES.library.playbook,
        pageIndex: 0,
        searchParams: { ordering: SORT_TYPES.lastRun, shared: true },
        limit: 4,
    });

    const {
        options: agentsWithSuggestions,
        optionsLoading: suggestionsLoading,
        setPage: setAgentsWithSuggestionsPage,
        canLoadMoreOptions: canLoadMoreAgentsWithSuggestions,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.library.processSuggestions,
        searchParams: { shared: true },
    });

    const {
        options: chatbots,
        optionsLoading: chatbotsLoading,
        setPage: setChatbotsRequestPage,
        canLoadMoreOptions: canLoadMoreChatbots,
        isFirstRequestCompleted: isChatbotsFirstPageLoaded,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client: assistantClient,
        route: API.ROUTES.assistant.customBot,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        autoFetchNextPage: true,
    });

    useDocumentTitle('Dashboard');

    const loadNextProcessesPage = () => setProcessRequestPage((page) => page + 1);
    const loadNextChatbotsPage = () => setChatbotsRequestPage((page) => page + 1);

    const pageTitle = user?.first_name ? `Welcome, ${user.first_name}!` : 'Hello there!';

    const isPageDisplayed = user && areProcessesLoaded && playbooks;

    return (
        <div className="page-position bg-neutral-50 overflow-y-auto flex flex-col">
            {isPageDisplayed && (
                <>
                    <div className="py-5 px-5 md:px-6 xl:px-8 bg-white flex flex-col gap-5">
                        <ClientPageTitleBlock title={pageTitle} />
                        <YourTopPlaybooksBlock playbooks={playbooks} />
                    </div>

                    <div className="w-full max-w-full py-5 px-5 md:px-6 xl:p-8 grid grid-rows-[auto_auto] grid-cols-1 lg:grid-rows-1 lg:grid-cols-[minmax(200px,1fr)_minmax(200px,1fr)] l:grid-cols-[minmax(200px,1fr)_minmax(200px,1fr)] lg:items-start gap-y-5 gap-x-4 xl:gap-x-5">
                        <div className="flex flex-col gap-5 max-w-full">
                            <NewAgentSuggestionsBlock
                                agentsWithSuggestions={agentsWithSuggestions}
                                loading={suggestionsLoading}
                                hasNextPage={canLoadMoreAgentsWithSuggestions}
                                setNextPage={setAgentsWithSuggestionsPage}
                            />

                            <YourTopProcessesBlock
                                processes={processes}
                                hasNextPage={canLoadMoreProcesses}
                                loading={processesLoading}
                                loadNextProcessesPage={loadNextProcessesPage}
                            />
                        </div>

                        <YourChatbotsBlock
                            chatbots={chatbots}
                            hasNextPage={canLoadMoreChatbots}
                            loading={chatbotsLoading}
                            loadNextPage={loadNextChatbotsPage}
                            isFirstPageLoaded={isChatbotsFirstPageLoaded}
                        />
                    </div>
                </>
            )}

            {!isPageDisplayed && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default DashboardPage;
