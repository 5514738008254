import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useDrop } from 'react-dnd';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import { handleMoveOnDrop } from '../../../helpers/docsDndUtils';

import { ROOT_FOLDER_OPTION } from '../MoveToPanel/MoveToPanel';
import { DRAGGABLE_ITEM_TYPE } from '../../../constants/docs';

import HomeIcon from '../../../design-system/Icons/HomeIcon';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';

const RootFolderNavItem = () => {
    const navigate = useNavigate();
    const { folderId } = useParams();

    const {
        setLocation,
        setSidePanelFolders,
        setExpandedFolders,
        requestKeysToMutate,
        currentRequestKeyToMutate,
    } = useDocsPageContext();

    const [errorAlert, setErrorAlert] = useState(null);

    const [{ isOver }, drop] = useDrop({
        accept: [DRAGGABLE_ITEM_TYPE.indexPageItem, DRAGGABLE_ITEM_TYPE.sidePanelFolder],
        drop: async ({ draggedItems, draggedItemsIds, draggedItemsParentLocation }, monitor) => {
            const type = monitor.getItemType();

            const targetFolder = { ...ROOT_FOLDER_OPTION };

            await handleMoveOnDrop({
                targetFolder,
                draggedItems,
                draggedItemsIds,
                requestKeysToMutate,
                currentRequestKeyToMutate,
                draggedItemsParentLocation,
                setErrorAlert,
                setExpandedFolders,
                setSidePanelFolders,
                type,
            });
        },
        collect: (monitor) => ({ isOver: monitor.isOver() }),
    });

    const handleHomeClick = () => {
        navigate('/docs');
        setLocation([]);
    };

    const isHighlighted = !folderId;

    const homeButtonClassName = classNames(
        'px-2 py-2.5 flex items-center gap-2 rounded-2 hover:bg-neutral-50 shadow-inset-2',
        {
            'bg-white': !isHighlighted && !isOver,
            'bg-neutral-50': isHighlighted || isOver,
            'shadow-purple-500': isOver,
            'shadow-transparent': !isOver,
        }
    );

    return (
        <>
            <button
                onClick={handleHomeClick}
                className={homeButtonClassName}
                ref={drop}
                style={{
                    transition: 'box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out',
                }}
            >
                <HomeIcon width={20} height={20} color="#754DCF" />
                <p className="font-body text-body-regular-s text-neutral-500">Docs</p>
            </button>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default RootFolderNavItem;
