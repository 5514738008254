import React from 'react';
import classNames from 'classnames';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { capitalizeStr } from '../../../helpers/formatOrganizationDataForTable';

import ToolTip from '../../../design-system/ToolTip/ToolTip';
import EditableNameBlock from '../EditableNameBlock/EditableNameBlock';
import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon, ExpandRightLineIcon } from '../../../design-system/Icons';

const MainPanelHeader = ({
    name,
    panelEntity,
    handleUpdateName,
    selector,
    historyPanelExpandedState,
}) => {
    const [isSidePanelExpanded, setIsSidePanelExpanded] = historyPanelExpandedState;

    const { isMobile } = useResponsiveBreakpoints();

    const expandButtonContainerClassName = classNames('transition-all', {
        'w-[200px]': panelEntity === 'chat',
        'invisible opacity-0': isSidePanelExpanded,
        'visible opacity-100': !isSidePanelExpanded,
    });

    if (isMobile) {
        return (
            <div className="w-full max-w-full flex flex-col">
                <div className="px-4 py-3 flex justify-between items-center border-b-1 border-neutral-200">
                    <EditableNameBlock
                        name={name}
                        handleUpdateName={handleUpdateName}
                        defaultName={`New ${capitalizeStr(panelEntity)}`}
                    />

                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={CloseLineIcon}
                        onClick={() => setIsSidePanelExpanded(true)}
                    />
                </div>
                <div className="px-4 py-2 border-b-1 border-neutral-200 min-h-[57px]">
                    {selector}
                </div>
            </div>
        );
    }

    return (
        <div className="w-full max-w-full px-4 pt-3 pb-2 border-b-1 border-neutral-200 flex items-center justify-between gap-3">
            <div className={expandButtonContainerClassName}>
                <ToolTip
                    text="Expand"
                    position="top-left"
                    useInternalHoverStateToShowToolTip
                    shift="4px"
                >
                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={ExpandRightLineIcon}
                        onClick={() => setIsSidePanelExpanded(true)}
                    />
                </ToolTip>
            </div>

            <EditableNameBlock
                name={name}
                handleUpdateName={handleUpdateName}
                defaultName={`New ${capitalizeStr(panelEntity)}`}
            />

            {selector}
        </div>
    );
};

export default MainPanelHeader;
