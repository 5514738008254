import React, { useState } from 'react';

import { CHAT_ROLE } from '../../../constants/assistant';

import MessageWrapper from '../MessageWrapper/MessageWrapper';
import UserMessageEditState from '../UserMessageEditState/UserMessageEditState';
import UserMessageActionButtons from '../UserMessageActionButtons/UserMessageActionButtons';

const UserMessage = ({ message, isSimplifiedVersion = false, ...restProps }) => {
    const {
        id,
        default_version: { content },
    } = message;

    const [isEditing, setIsEditing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleStopEditing = () => {
        setIsEditing(false);
        setIsHovered(false);
    };

    const onMouseEnter = () => {
        if (isSimplifiedVersion) return;
        setIsHovered(true);
    };

    const onMouseLeave = () => {
        if (isSimplifiedVersion) return;
        setIsHovered(false);
    };

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <MessageWrapper role={CHAT_ROLE.user} isSimplifiedVersion={isSimplifiedVersion}>
                <div className="max-w-full flex flex-col gap-4">
                    {!isEditing && (
                        <p className="font-body text-body-regular-s text-neutral-500 whitespace-pre-wrap">
                            {content}
                        </p>
                    )}

                    {isEditing && (
                        <UserMessageEditState
                            id={id}
                            content={content}
                            stopEditing={handleStopEditing}
                            {...restProps}
                        />
                    )}

                    {!isSimplifiedVersion && (
                        <UserMessageActionButtons
                            message={message}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            isHovered={isHovered}
                        />
                    )}
                </div>
            </MessageWrapper>
        </div>
    );
};

export default UserMessage;
