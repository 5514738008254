import React, { forwardRef } from 'react';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import Modal from '../../../design-system/Modal/Modal';
import Loading from '../../../components/Loading';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';

const DetailsPopupLayout = (
    { isLoaded = false, onClose, header, children, bottomControls, loaderHeight = 70 },
    ref
) => {
    const { isMobile } = useResponsiveBreakpoints();

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                maxHeight="90vh"
                minHeight={isLoaded ? '40vh' : 0}
                containerCustomPaddings="pt-4 px-5 sm:px-6 pb-5"
                contentCustomPaddings="pt-4"
                bottomButtonsCustomPaddings="pt-3"
                topFixedContent={header}
                customGap={8}
                withCloseButton={false}
                bottomButtonsGroup={<div className="flex flex-col gap-2">{bottomControls}</div>}
            >
                {isLoaded && children}

                {!isLoaded && (
                    <div className="h-[60px] flex items-center justify-center">
                        <Loading withText={false} />
                    </div>
                )}
            </MobilePopup>
        );
    }

    return (
        <Modal
            size="large"
            customStyles="!rounded-2xl flex flex-col !overflow-hidden"
            onClose={onClose}
            resetPadding
        >
            <div
                ref={ref}
                className="flex flex-col gap-4 lg:gap-5 overflow-hidden relative z-60 p-5 sm:p-6 lg:p-8"
            >
                <div className="absolute top-[18px] right-[18px]">
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>

                <div className="pr-6">{header}</div>

                {isLoaded && (
                    <>
                        {children}

                        {bottomControls}
                    </>
                )}

                {!isLoaded && (
                    <div
                        className="flex items-center justify-center"
                        style={{ height: loaderHeight }}
                    >
                        <Loading withText={false} />
                    </div>
                )}
            </div>
        </Modal>
    );
};

const ForwardedDetailsPopupLayout = forwardRef(DetailsPopupLayout);

export default ForwardedDetailsPopupLayout;
