import { useEffect } from 'react';

const useScrollbarGutterAuto = () => {
    // remove html {scrollbar-gutter: stable} to display scrollbar correctly

    useEffect(() => {
        const htmlElement = document.documentElement;
        const originalScrollbarGutter = htmlElement.style.scrollbarGutter;

        htmlElement.style.scrollbarGutter = 'auto';

        return () => {
            htmlElement.style.scrollbarGutter = originalScrollbarGutter;
        };
    }, []);
};

export default useScrollbarGutterAuto;
