import React from 'react';

import { GOOGLE_IMAGE_PATH } from '../../../constants/bot';
import { BASE_TYPE, SOURCE_ICON, SOURCE_LINK_TYPE_ICON } from '../../../constants/assistant';

import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';

const SourceBadgeLeadingIcon = ({ baseType, linkType }) => {
    if (baseType === BASE_TYPE.google_folder) {
        return (
            <img
                src={GOOGLE_IMAGE_PATH}
                width={15}
                height={15}
                alt="Google logo"
                className="block h-[16px] max-h-[16px] min-w-4"
            />
        );
    }

    let icon = SOURCE_ICON[baseType] || ArchiveLineIcon;

    if (baseType === BASE_TYPE.document || baseType === BASE_TYPE.diviup_folder) {
        icon = SOURCE_LINK_TYPE_ICON[linkType] || FileTextLineIcon;
    }

    return <SvgIcon icon={icon} size="medium" color="#5E6470" />;
};

export default SourceBadgeLeadingIcon;
