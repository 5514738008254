import React, { useState } from 'react';

import { $createHeadingNode } from '@lexical/rich-text';
import { usePublisher } from '@mdxeditor/gurx';
import { $createParagraphNode } from 'lexical';

import { convertSelectionToNode$ } from '@mdxeditor/editor';

import { ListOptionGroup, SvgIcon } from '../../../index';
import { ArrowDownSLineIcon } from '../../../Icons';
import TextIcon from '../../../Icons/TextIcon';
import H1Icon from '../../../Icons/H1Icon';
import H2Icon from '../../../Icons/H2Icon';
import H3Icon from '../../../Icons/H3Icon';
import ParagraphIcon from '../../../Icons/ParagraphIcon';

const options = [
    { type: 'p', label: 'Paragraph', icon: ParagraphIcon },
    { type: 'h1', label: 'Heading 1', icon: H1Icon },
    { type: 'h2', label: 'Heading 2', icon: H2Icon },
    { type: 'h3', label: 'Heading 3', icon: H3Icon },
];

const ToolbarHeadings = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const convertSelectionToNode = usePublisher(convertSelectionToNode$);

    const onClose = (e) => {
        e?.stopPropagation();
        setIsPopupOpen(false);
    };

    const handleClick = (type) => {
        if (type === 'p') {
            convertSelectionToNode(() => $createParagraphNode());
        } else {
            convertSelectionToNode(() => $createHeadingNode(type));
        }

        onClose();
    };

    return (
        <div className="relative">
            <button
                className="flex p-[6px] hover:bg-neutral-50"
                onClick={() => setIsPopupOpen(true)}
            >
                <SvgIcon color="#1F2125" icon={TextIcon} size="medium" />
                <SvgIcon color="#1F2125" icon={ArrowDownSLineIcon} size="medium" />
            </button>
            {isPopupOpen && (
                <div className="absolute -left-1 z-30 top-[110%]">
                    <ListOptionGroup
                        additionalStyles="!p-2"
                        fixedWidth={170}
                        listOptions={
                            <>
                                {options.map((item) => {
                                    const { type, label, icon } = item;
                                    return (
                                        <button
                                            key={type}
                                            onClick={() => handleClick(type)}
                                            className="flex items-center gap-2 px-2 py-[5px] hover:bg-neutral-50 w-full rounded-2"
                                        >
                                            <SvgIcon color="#1F2125" icon={icon} size="medium" />
                                            <p className="text-body-regular-xs text-neutral-500 pt-[1px]">
                                                {label}
                                            </p>
                                        </button>
                                    );
                                })}
                            </>
                        }
                        handleClose={onClose}
                    />
                </div>
            )}
        </div>
    );
};

export default ToolbarHeadings;
