import React, { useRef, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import { useFormState } from '../../../../hooks/useFormState';

import { exportDiagramAsPdf, isDiagramSyntaxValid } from '../../../../helpers/diagramsUtils';

import {
    defaultErrorMessage,
    emptyFieldErrorMessage,
    invalidMermaidErrorMessage,
} from '../../../../constants/errorMessages';

import Diagram from '../../../../design-system/Diagram/Diagram';
import CodeEditor from '../../../../design-system/CodeEditor/CodeEditor';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import { Button } from '../../../../design-system';
import { EXPORT_AS_PDF_BUTTON_ID } from '../DocDetailMoreOptionsPopup/DocDetailMoreOptionsPopup';

const DiagramDetailMainContent = ({
    docDetail,
    setDocDetail,
    isDiagramEditing,
    setIsDiagramEditing,
}) => {
    const { id, label, default_version } = docDetail;
    const { value = '' } = default_version || {};

    const ref = useRef(null);

    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { value: value || '' },
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSave = async () => {
        try {
            const isEmpty = !formData.value;
            if (isEmpty) {
                setFieldErrorMessages({ value: emptyFieldErrorMessage });
                return;
            }

            const isMermaidValid = await isDiagramSyntaxValid(formData.value);
            if (!isMermaidValid) {
                setFieldErrorMessages({ value: invalidMermaidErrorMessage });
                return;
            }

            setIsLoading(true);

            const { data } = await client.patch(`${API.ROUTES.knowledge.document}${id}/`, {
                value: formData.value,
            });
            setDocDetail((prevData) => ({
                ...prevData,
                default_version: data.default_version,
            }));
            handleInputChange('value', data.default_version.value || '');

            setIsDiagramEditing(false);
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        handleInputChange('value', value || '');
        setIsDiagramEditing(false);
    };

    return (
        <div className="flex-grow flex flex-col gap-2 pb-3 xs:pb-[18px] sm:pb-3.5 lg:pb-7 min-[1190px]:pb-0">
            <div className={`flex-grow flex-col ${isDiagramEditing ? 'hidden' : 'flex'}`} ref={ref}>
                <Diagram code={value} name={label} />
            </div>

            {isDiagramEditing && (
                <div className={`flex-grow flex-col gap-3 ${isDiagramEditing ? 'flex' : 'hidden'}`}>
                    <CodeEditor
                        value={formData.value}
                        setValue={(value) => handleInputChange('value', value)}
                        state={fieldErrorMessages.value ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.value}
                    />

                    <div className="flex items-center justify-between gap-3">
                        <Button type="link" size="xs" text="Cancel" onClick={handleCancel} />
                        <Button
                            type="secondary"
                            size="xs"
                            text="Save"
                            state={isLoading ? 'loading' : 'default'}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            )}

            <button
                id={EXPORT_AS_PDF_BUTTON_ID}
                className="hidden"
                onClick={() =>
                    exportDiagramAsPdf({ diagramRef: ref, diagramName: label, setErrorAlert })
                }
            >
                Export as PDF
            </button>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default DiagramDetailMainContent;
