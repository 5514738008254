import { jsPDF } from 'jspdf';
import { autoTable } from 'jspdf-autotable';

import { marked } from 'marked';
import { defaultErrorMessage } from '../constants/errorMessages';

// Conversion constant: 1px = 0.75pt
const PX_TO_PT = 0.75;

const LINK_COLOR = '#754DCF';
const TEXT_COLOR = '#1F2125';
const CODE_COLOR = '#2E3440';
const CODE_BACKGROUND_COLOR = '#F0F5FE';

const pxToPt = (px) => px * PX_TO_PT;

const convertMarkdownToHTML = (markdown) => {
    marked.setOptions({
        breaks: true,
        gfm: true,
        headerIds: false,
        mangle: false,
    });

    const html = marked(markdown);
    return html;
};

const calculateLinesPerPage = (pdf, currentY, pageHeight, margin, fontSize) => {
    const lineHeight = fontSize * 1.5; // 150% line height
    const availableHeight = pageHeight - currentY - margin;
    return Math.floor(availableHeight / lineHeight);
};

const renderFormattedText = (pdf, element, x, y, width) => {
    const fontSize = pdf.getFontSize();
    const lineHeight = fontSize * 1.5;
    let currentY = y;
    let currentX = x;
    let maxY = y;
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = pxToPt(40);
    const spaceWidth = pdf.getTextWidth(' ');

    // First, collect and analyze all text
    const textParts = [];
    let lastWasText = false;
    let totalLines = 1;
    let currentLineWidth = 0;

    // Process text content and calculate lines
    const processTextContent = (text, style, url = null) => {
        const parts = text.split(/(\s+)/);
        parts.forEach((part) => {
            if (part) {
                if (part.trim() === '') {
                    if (lastWasText) {
                        textParts.push({
                            text: ' ',
                            style: 'space',
                            isSpace: true,
                            url,
                        });
                        currentLineWidth += spaceWidth;
                    }
                    lastWasText = false;
                } else {
                    textParts.push({
                        text: part,
                        style,
                        isSpace: false,
                        url,
                    });
                    applyTextStyle(style);
                    const wordWidth = pdf.getTextWidth(part);
                    if (currentLineWidth + wordWidth > width) {
                        totalLines++;
                        currentLineWidth = wordWidth;
                    } else {
                        currentLineWidth += wordWidth;
                    }
                    lastWasText = true;
                }
            }
        });
    };

    // Helper function to apply text style
    const applyTextStyle = (style) => {
        try {
            switch (style) {
                case 'bold':
                    try {
                        pdf.setFont('Inter', 'bold');
                    } catch (err) {
                        pdf.setFont('helvetica', 'bold');
                    }
                    break;
                case 'italic':
                    pdf.setFont('helvetica', 'italic');
                    break;
                case 'bolditalic':
                    pdf.setFont('helvetica', 'bolditalic');
                    break;
                case 'underline':
                case 'bold-underline':
                    try {
                        pdf.setFont(
                            style === 'bold-underline' ? 'Inter' : 'helvetica',
                            style === 'bold-underline' ? 'bold' : 'normal',
                        );
                    } catch (err) {
                        pdf.setFont('helvetica', style === 'bold-underline' ? 'bold' : 'normal');
                    }
                    break;
                case 'strikethrough':
                case 'bold-strikethrough':
                    try {
                        pdf.setFont(
                            style === 'bold-strikethrough' ? 'Inter' : 'helvetica',
                            style === 'bold-strikethrough' ? 'bold' : 'normal',
                        );
                    } catch (err) {
                        pdf.setFont(
                            'helvetica',
                            style === 'bold-strikethrough' ? 'bold' : 'normal',
                        );
                    }
                    break;
                case 'link':
                    pdf.setTextColor(LINK_COLOR);
                    try {
                        pdf.setFont('Inter', 'bold');
                    } catch (err) {
                        pdf.setFont('helvetica', 'bold');
                    }
                    break;
                default:
                    try {
                        pdf.setFont('Inter', 'normal');
                    } catch (err) {
                        pdf.setFont('helvetica', 'normal');
                    }
                    pdf.setTextColor(TEXT_COLOR);
            }
            if (style !== 'link') {
                pdf.setTextColor(TEXT_COLOR);
            }
        } catch (err) {
            pdf.setFont('helvetica', 'normal');
            pdf.setTextColor(TEXT_COLOR);
        }
    };

    // Process nodes to collect text parts
    for (const node of element.childNodes) {
        if (node.nodeType === Node.TEXT_NODE) {
            processTextContent(node.textContent, 'normal');
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            const tagName = node.tagName ? String(node.tagName).toLowerCase() : '';
            let style = 'normal';
            let url = null;

            const parentStyle = node.parentElement
                ? String(node.parentElement.tagName || '').toLowerCase()
                : '';
            const parentIsStrong = parentStyle === 'strong' || parentStyle === 'b';
            const parentIsEm = parentStyle === 'em' || parentStyle === 'i';

            if (tagName) {
                switch (tagName) {
                    case 'strong':
                    case 'b':
                        style = parentIsEm ? 'bolditalic' : 'bold';
                        break;
                    case 'em':
                    case 'i':
                        style = parentIsStrong ? 'bolditalic' : 'italic';
                        break;
                    case 'u':
                        style = 'underline';
                        break;
                    case 'del':
                    case 's':
                        style = 'strikethrough';
                        break;
                    case 'a':
                        style = 'link';
                        url = node.getAttribute('href');
                        break;
                }
            }

            if (node.querySelector('em, i, strong, b')) {
                const hasItalic = node.querySelector('em, i');
                const hasBold =
                    node.querySelector('strong, b') || tagName === 'strong' || tagName === 'b';

                if (hasItalic && hasBold) {
                    style = 'bolditalic';
                }
            }

            if (node.getAttribute('data-underline') === 'true') {
                style = 'underline';
            } else if (node.getAttribute('data-strikethrough') === 'true') {
                style = 'strikethrough';
            }

            processTextContent(node.textContent, style, url);
        }
    }

    // If text is short (3 lines or less) and doesn't fit on current page, move to next page
    const isShortText = totalLines <= 3;
    const availableHeight = pageHeight - currentY - margin;
    const requiredHeight = totalLines * lineHeight;

    if (isShortText && availableHeight < requiredHeight) {
        pdf.addPage();
        currentY = margin;
        maxY = margin;
    }

    // Reset for actual rendering
    currentX = x;
    let currentLine = [];

    // Process each text part
    for (let i = 0; i < textParts.length; i++) {
        const part = textParts[i];
        if (!part.text) continue;

        if (part.isSpace) {
            if (currentLine.length > 0) {
                currentLine.push(part);
                currentX += spaceWidth;
            }
            continue;
        }

        applyTextStyle(part.style);
        const wordWidth = pdf.getTextWidth(part.text);

        // Check if word fits on current line
        if (currentX + wordWidth <= x + width) {
            currentLine.push(part);
            currentX += wordWidth;
        } else {
            // If not a short text, check if we need a new page
            if (!isShortText) {
                const linesPerPage = calculateLinesPerPage(
                    pdf,
                    currentY,
                    pageHeight,
                    margin,
                    fontSize,
                );
                if (linesPerPage <= 1) {
                    pdf.addPage();
                    currentY = margin;
                    maxY = margin;
                }
            }

            // Render current line
            if (currentLine.length > 0) {
                let decorationState = {
                    underlineStart: null,
                    underlineWidth: 0,
                    strikethroughStart: null,
                    strikethroughWidth: 0,
                    lineX: x,
                };

                // First pass - render all text
                for (const linePart of currentLine) {
                    if (linePart.isSpace) {
                        if (decorationState.underlineStart !== null) {
                            decorationState.underlineWidth += spaceWidth;
                        }
                        if (decorationState.strikethroughStart !== null) {
                            decorationState.strikethroughWidth += spaceWidth;
                        }
                        decorationState.lineX += spaceWidth;
                        continue;
                    }

                    applyTextStyle(linePart.style);
                    pdf.text(linePart.text, decorationState.lineX, currentY);

                    if (linePart.style === 'link' && linePart.url) {
                        pdf.setLineWidth(0.5);
                        pdf.setDrawColor(117, 77, 207);
                        pdf.line(
                            decorationState.lineX,
                            currentY + 2,
                            decorationState.lineX + pdf.getTextWidth(linePart.text),
                            currentY + 2,
                        );
                        pdf.link(
                            decorationState.lineX,
                            currentY - fontSize,
                            pdf.getTextWidth(linePart.text),
                            fontSize,
                            {
                                url: linePart.url,
                                newWindow: true,
                            },
                        );
                    }

                    decorationState = handleTextDecoration(pdf, {
                        linePart,
                        decorationState,
                        lineX: decorationState.lineX,
                        currentY,
                        fontSize,
                    });
                }

                drawTextDecorations(pdf, { decorationState, currentY, fontSize });
            }

            // Start new line
            currentY += lineHeight;
            maxY = Math.max(maxY, currentY);
            currentX = x + wordWidth;
            currentLine = [part];
        }

        pdf.setFont('Inter', 'normal');
        pdf.setTextColor(TEXT_COLOR);
    }

    // Render last line if any
    if (currentLine.length > 0) {
        // Calculate if we need a new page for the last line
        const linesPerPage = calculateLinesPerPage(pdf, currentY, pageHeight, margin, fontSize);
        if (linesPerPage <= 0) {
            pdf.addPage();
            currentY = margin;
            currentX = x;
        }

        let decorationState = {
            underlineStart: null,
            underlineWidth: 0,
            strikethroughStart: null,
            strikethroughWidth: 0,
            lineX: x,
        };

        // Render the last line
        for (const linePart of currentLine) {
            if (linePart.isSpace) {
                if (decorationState.underlineStart !== null) {
                    decorationState.underlineWidth += spaceWidth;
                }
                if (decorationState.strikethroughStart !== null) {
                    decorationState.strikethroughWidth += spaceWidth;
                }
                decorationState.lineX += spaceWidth;
                continue;
            }

            applyTextStyle(linePart.style);
            pdf.text(linePart.text, decorationState.lineX, currentY);

            if (linePart.style === 'link' && linePart.url) {
                pdf.setLineWidth(0.5);
                pdf.setDrawColor(117, 77, 207);
                pdf.line(
                    decorationState.lineX,
                    currentY + 2,
                    decorationState.lineX + pdf.getTextWidth(linePart.text),
                    currentY + 2,
                );
                pdf.link(
                    decorationState.lineX,
                    currentY - fontSize,
                    pdf.getTextWidth(linePart.text),
                    fontSize,
                    {
                        url: linePart.url,
                        newWindow: true,
                    },
                );
            }

            decorationState = handleTextDecoration(pdf, {
                linePart,
                decorationState,
                lineX: decorationState.lineX,
                currentY,
                fontSize,
            });
        }

        drawTextDecorations(pdf, { decorationState, currentY, fontSize });

        maxY = Math.max(maxY, currentY);
    }

    return maxY;
};

function handleTextDecoration(pdf, { linePart, decorationState, lineX, currentY, fontSize }) {
    let state = { ...decorationState };

    // Handle underline
    if (linePart.style === 'underline') {
        if (state.underlineStart === null) {
            state.underlineStart = lineX;
        }
        state.underlineWidth += pdf.getTextWidth(linePart.text);
    } else if (state.underlineStart !== null) {
        pdf.setLineWidth(0.5);
        pdf.setDrawColor(0, 0, 0);
        pdf.line(
            state.underlineStart,
            currentY + 2,
            state.underlineStart + state.underlineWidth,
            currentY + 2,
        );
        state.underlineStart = null;
        state.underlineWidth = 0;
    }

    // Handle strikethrough
    if (linePart.style === 'strikethrough') {
        if (state.strikethroughStart === null) {
            state.strikethroughStart = lineX;
        }
        state.strikethroughWidth += pdf.getTextWidth(linePart.text);
    } else if (state.strikethroughStart !== null) {
        pdf.setLineWidth(0.5);
        pdf.setDrawColor(0, 0, 0);
        pdf.line(
            state.strikethroughStart,
            currentY - fontSize / 3,
            state.strikethroughStart + state.strikethroughWidth,
            currentY - fontSize / 3,
        );
        state.strikethroughStart = null;
        state.strikethroughWidth = 0;
    }

    state.lineX += pdf.getTextWidth(linePart.text);

    return state;
}

function drawTextDecorations(pdf, { decorationState, currentY, fontSize }) {
    const { underlineStart, underlineWidth, strikethroughStart, strikethroughWidth } =
        decorationState;

    // Draw final underline if needed
    if (underlineStart !== null) {
        pdf.setLineWidth(0.5);
        pdf.setDrawColor(0, 0, 0);
        pdf.line(underlineStart, currentY + 2, underlineStart + underlineWidth, currentY + 2);
    }

    // Draw final strikethrough if needed
    if (strikethroughStart !== null) {
        pdf.setLineWidth(0.5);
        pdf.setDrawColor(0, 0, 0);
        pdf.line(
            strikethroughStart,
            currentY - fontSize / 3,
            strikethroughStart + strikethroughWidth,
            currentY - fontSize / 3,
        );
    }
}

const renderParagraph = (pdf, element, yPosition, margin, width) => {
    const fontSize = pxToPt(14); // Convert 14px to pt
    pdf.setFontSize(fontSize);
    pdf.setFont('Inter', 'normal');

    const newY = renderFormattedText(pdf, element, margin, yPosition + fontSize, width);

    return newY;
};

const calculateTextLines = (text, width, pdf) => {
    const lines = pdf.splitTextToSize(text, width);
    return lines.length;
};

const renderList = (pdf, element, yPosition, margin, width, pageHeight, nestLevel = 0) => {
    const tagName = element.tagName ? String(element.tagName).toLowerCase() : '';
    if (!tagName) return yPosition;

    const isOrdered = tagName === 'ol';
    let currentY = yPosition;
    const fontSize = pxToPt(14);
    const lineHeight = fontSize * 1.5;
    pdf.setFontSize(fontSize);
    pdf.setFont('Inter', 'normal');

    // Calculate indentation based on nesting level
    const indentation = margin + nestLevel * pxToPt(20);

    // Process all child nodes
    const childNodes = Array.from(element.children);
    for (let i = 0; i < childNodes.length; i++) {
        const child = childNodes[i];
        const childTagName = child.tagName.toLowerCase();

        if (childTagName === 'li') {
            // Get all text content before nested lists
            const textContent = document.createElement('div');
            let textNodes = [];
            Array.from(child.childNodes).forEach((node) => {
                if (
                    node.nodeType === Node.TEXT_NODE ||
                    (node.nodeType === Node.ELEMENT_NODE &&
                        !['ul', 'ol'].includes(node.tagName.toLowerCase()))
                ) {
                    textNodes.push(node);
                }
            });

            textNodes.forEach((node) => {
                if (node.nodeType === Node.TEXT_NODE) {
                    const span = document.createElement('span');
                    span.textContent = node.textContent;
                    textContent.appendChild(span);
                } else {
                    textContent.appendChild(node.cloneNode(true));
                }
            });

            const prefix = isOrdered ? `${i + 1}. ` : '• ';
            const prefixWidth = pdf.getTextWidth(prefix);
            const textWidth = width - nestLevel * pxToPt(20) - prefixWidth;

            // Calculate total height of the list item
            const combinedText = textContent.textContent;
            const linesCount = calculateTextLines(combinedText, textWidth, pdf);
            const itemHeight = linesCount * lineHeight;

            // Check if we need a new page BEFORE rendering prefix
            const availableHeight = pageHeight - currentY - margin - 12;
            const isShortItem = linesCount <= 3;
            if (availableHeight < 2 * lineHeight || (isShortItem && availableHeight < itemHeight)) {
                // Start new page
                pdf.addPage();
                currentY = margin;
            }

            // Now we can safely render both prefix and content on the same page
            pdf.setTextColor(TEXT_COLOR);
            pdf.text(prefix, indentation, currentY + fontSize);

            if (textContent.hasChildNodes()) {
                // Render text content
                currentY = renderFormattedText(
                    pdf,
                    textContent,
                    indentation + prefixWidth,
                    currentY + fontSize,
                    textWidth,
                );
            } else {
                currentY += lineHeight;
            }

            // Process nested lists
            const nestedLists = Array.from(child.children).filter((node) =>
                ['ul', 'ol'].includes(node.tagName.toLowerCase()),
            );

            for (const nestedList of nestedLists) {
                currentY += pxToPt(8);
                // Check if nested list needs a new page
                if (pageHeight - currentY - margin < lineHeight) {
                    pdf.addPage();
                    currentY = margin;
                }
                currentY = renderList(
                    pdf,
                    nestedList,
                    currentY,
                    margin,
                    width,
                    pageHeight,
                    nestLevel + 1,
                );
            }

            // Add spacing after list item
            currentY += pxToPt(6);
        }
    }

    return currentY;
};

const renderCodeBlock = (pdf, element, yPosition, margin, width) => {
    const fontSize = pxToPt(12);
    pdf.setFontSize(fontSize);
    try {
        pdf.setFont('Inter', 'normal');
    } catch (err) {
        pdf.setFont('helvetica', 'normal');
    }
    pdf.setTextColor(CODE_COLOR);

    const text = element.textContent;
    const lines = pdf.splitTextToSize(text, width - pxToPt(20));
    const lineHeight = fontSize * 1.5;

    // Calculate the total height of the code block
    const blockHeight = lines.length * lineHeight + pxToPt(20);
    const availableHeight = pdf.internal.pageSize.getHeight() - yPosition - margin;

    // Draw code background to match editor style
    if (blockHeight > availableHeight) {
        // If it doesn't fit, split the lines across pages
        let currentY = yPosition + pxToPt(12);
        let isBlockStart = true;

        for (let i = 0; i < lines.length; i++) {
            // Check if we need a new page
            if (currentY + lineHeight > pdf.internal.pageSize.getHeight() - margin) {
                pdf.addPage();
                currentY = margin; // Reset Y position for new page
                isBlockStart = true;
            }

            // Draw the background for the code block
            pdf.setFillColor(CODE_BACKGROUND_COLOR);
            pdf.roundedRect(
                margin,
                currentY - pxToPt(12),
                width,
                isBlockStart ? lineHeight + pxToPt(24) : lineHeight + pxToPt(12),
                5,
                5,
                'F',
            );

            // Draw each line of the code block
            pdf.text(
                lines[i],
                margin + pxToPt(12),
                isBlockStart ? currentY + pxToPt(12) : currentY,
            );
            currentY += isBlockStart ? lineHeight + pxToPt(12) : lineHeight; // Move down for the next line
            isBlockStart = false;
        }

        return currentY; // Return the new Y position
    } else {
        // Draw code background to match editor style
        pdf.setFillColor(CODE_BACKGROUND_COLOR);
        pdf.roundedRect(margin, yPosition, width, blockHeight, 5, 5, 'F');

        let currentY = yPosition + pxToPt(28);
        for (let i = 0; i < lines.length; i++) {
            pdf.text(lines[i], margin + pxToPt(10), currentY);
            currentY += lineHeight; // Move down for the next line
        }

        return currentY;
    }
};

const renderHorizontalRule = (pdf, element, yPosition, margin, width) => {
    pdf.setDrawColor(207, 214, 229);
    pdf.setLineWidth(1);
    pdf.line(margin, yPosition + 5, margin + width, yPosition + 5);

    return yPosition + 10;
};

const renderTable = (pdf, element, yPosition, margin) => {
    const tableRows = Array.from(element.querySelectorAll('tr'));

    const headerRow = tableRows.length > 0 ? tableRows[0] : null;
    const headerCells = headerRow
        ? Array.from(headerRow.querySelectorAll('th, td')).map((cell) => ({
              content: cell.textContent,
              align: cell.getAttribute('align') || 'left',
          }))
        : [];

    const bodyRows = tableRows.slice(headerRow ? 1 : 0);
    const bodyData = bodyRows.map((row) =>
        Array.from(row.querySelectorAll('td')).map((cell) => ({
            content: cell.textContent,
            align: cell.getAttribute('align') || 'left',
        })),
    );

    const columnStyles = {};
    const numColumns = headerCells.length || (bodyData[0] ? bodyData[0].length : 0);

    for (let i = 0; i < numColumns; i++) {
        const headerAlign = headerCells[i]?.align;
        const bodyAlign = bodyData[0]?.[i]?.align;

        const alignment = headerAlign || bodyAlign || 'left';
        columnStyles[i] = {
            halign: alignment,
            cellWidth: 'auto',
        };
    }

    autoTable(pdf, {
        startY: yPosition + 10,
        head: headerCells.length > 0 ? [headerCells.map((cell) => cell.content)] : undefined,
        body: bodyData.map((row) => row.map((cell) => cell.content)),
        margin: { left: margin, right: margin },
        styles: {
            overflow: 'linebreak',
            cellPadding: 5,
            fontSize: pxToPt(14),
            font: 'Inter',
            lineColor: '#e0e1e6',
            lineWidth: 0.1,
            textColor: TEXT_COLOR,
            halign: 'left',
        },
        headStyles: {
            fillColor: '#FFFFFF',
            fontStyle: 'bold',
            halign: 'inherit',
        },
        alternateRowStyles: {
            fillColor: '#f6f8fa',
        },
        columnStyles,
        tableWidth: 'auto',
        pageBreak: 'auto',
        didParseCell: function (data) {
            const col = data.column.index;
            if (data.row.index === 0 && headerCells[col]) {
                data.cell.styles.halign = columnStyles[col].halign;
            }
        },
    });

    return pdf.lastAutoTable.finalY + 10;
};

const renderHeading = (pdf, element, yPosition, margin, width) => {
    const tagName = String(element.tagName || '').toLowerCase();
    if (!tagName) {
        return yPosition;
    }

    const level = parseInt(tagName.replace('h', ''));
    if (isNaN(level)) {
        return yPosition;
    }

    let fontSize;
    switch (level) {
        case 1:
            fontSize = pxToPt(20);
            break;
        case 2:
            fontSize = pxToPt(16);
            break;
        default:
            fontSize = pxToPt(14);
    }

    pdf.setFontSize(fontSize);

    const wrapper = document.createElement('div');
    wrapper.innerHTML = element.innerHTML;

    const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            // Wrap text nodes in strong
            const strong = document.createElement('strong');
            strong.textContent = node.textContent;
            node.parentNode.replaceChild(strong, node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            const tagName = node.tagName.toLowerCase();

            if (
                tagName === 'em' ||
                tagName === 'i' ||
                tagName === 'u' ||
                tagName === 'del' ||
                tagName === 's' ||
                tagName === 'a'
            ) {
                if (
                    !node.parentElement ||
                    (node.parentElement.tagName.toLowerCase() !== 'strong' &&
                        node.parentElement.tagName.toLowerCase() !== 'b')
                ) {
                    const strong = document.createElement('strong');

                    // Keep original element's style
                    if (tagName === 'u') {
                        strong.setAttribute('data-underline', 'true');
                    } else if (tagName === 'del' || tagName === 's') {
                        strong.setAttribute('data-strikethrough', 'true');
                    }

                    node.parentNode.insertBefore(strong, node);
                    strong.appendChild(node);
                }
            } else if (tagName !== 'strong' && tagName !== 'b') {
                // For any other element that's not already bold, wrap it in strong
                const strong = document.createElement('strong');
                node.parentNode.insertBefore(strong, node);
                strong.appendChild(node);
            }

            Array.from(node.childNodes).forEach(processNode);
        }
    };

    Array.from(wrapper.childNodes).forEach(processNode);

    return renderFormattedText(pdf, wrapper, margin, yPosition + fontSize, width);
};

const renderContentToPdf = (pdf, elements) => {
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = pxToPt(40);
    const usableWidth = pageWidth - 2 * margin;
    const fontSize = pxToPt(14);
    const lineHeight = fontSize * 1.5;
    let yPosition = margin;
    let lastElementType = null;

    // Process each element
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element.nodeType !== Node.ELEMENT_NODE) continue;

        const tagName = element.tagName.toLowerCase();

        // Calculate element metrics
        const elementText = element.textContent || '';
        const textWidth = usableWidth - (tagName === 'li' ? pxToPt(40) : 0);
        const lines = pdf.splitTextToSize(elementText, textWidth);

        const isShortElement = lines.length <= 3;
        const elementHeight =
            lines.length * lineHeight +
            (tagName === 'li' ? pxToPt(4) : tagName.match(/^h\d$/) ? pxToPt(10) : pxToPt(8));

        // Check if we need a new page
        const availableHeight = Math.floor(pageHeight - yPosition - margin);
        const shouldStartNewPage =
            availableHeight < lineHeight || (isShortElement && availableHeight < elementHeight);

        if (shouldStartNewPage) {
            pdf.addPage();
            yPosition = margin;
        }

        // Render element and get its actual end position
        const newY = renderElement(pdf, element, yPosition, margin, usableWidth, pageHeight);
        // Update position to start right after the previous element
        yPosition = newY;

        // Add appropriate spacing only if there's a next element
        if (i < elements.length - 1) {
            const nextElement = elements[i + 1];
            if (nextElement && nextElement.nodeType === Node.ELEMENT_NODE) {
                const nextTagName = nextElement.tagName.toLowerCase();
                let spacing = tagName.match(/^h\d$/)
                    ? pxToPt(16)
                    : tagName === 'li' && nextTagName === 'li'
                      ? pxToPt(8)
                      : pxToPt(12);

                if (nextTagName.match(/^h\d$/)) {
                    spacing = pxToPt(20);
                }

                // Check if adding spacing would cause a page break
                if (yPosition + spacing > pageHeight - margin) {
                    pdf.addPage();
                    yPosition = margin;
                } else {
                    yPosition += spacing;
                }
            }
        }

        lastElementType = tagName;
    }
};

const addCustomFonts = (pdf) => {
    try {
        // First set standard fonts as fallback
        pdf.setFont('helvetica', 'normal');

        // Try to add custom fonts
        const fonts = [
            { path: '/fonts/Inter-Regular.ttf', name: 'Inter', style: 'normal' },
            { path: '/fonts/Inter-Bold.ttf', name: 'Inter', style: 'bold' },
        ];

        for (const font of fonts) {
            try {
                pdf.addFont(font.path, font.name, font.style);
            } catch (err) {
                console.warn(`Failed to load font ${font.path}:`, err);
                // If Inter font fails, we'll fall back to helvetica
                switch (font.style) {
                    case 'normal':
                        pdf.setFont('helvetica', 'normal');
                        break;
                    case 'bold':
                        pdf.setFont('helvetica', 'bold');
                        break;
                }
            }
        }

        try {
            pdf.setFont('Inter', 'normal');
        } catch (err) {
            pdf.setFont('helvetica', 'normal');
        }
    } catch (err) {
        pdf.setFont('helvetica', 'normal');
    }
};

export const createPdfDocument = () => {
    const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
    });

    addCustomFonts(pdf);
    pdf.setFontSize(14);

    return pdf;
};

const renderElement = (pdf, element, yPosition, margin, width, pageHeight) => {
    if (!element || typeof element.tagName !== 'string') {
        return yPosition;
    }

    const tagName = String(element.tagName).toLowerCase();

    switch (tagName) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
            return renderHeading(pdf, element, yPosition, margin, width);

        case 'p':
            return renderParagraph(pdf, element, yPosition, margin, width);

        case 'ul':
        case 'ol':
            return renderList(pdf, element, yPosition, margin, width, pageHeight, 0);

        case 'pre':
            return renderCodeBlock(pdf, element, yPosition, margin, width);

        case 'table':
            return renderTable(pdf, element, yPosition, margin);

        case 'hr':
            return renderHorizontalRule(pdf, element, yPosition, margin, width);

        default:
            return renderParagraph(pdf, element, yPosition, margin, width);
    }
};

export const exportDocumentAsPdf = async ({ markdown, documentName, setErrorAlert }) => {
    if (!markdown) {
        setErrorAlert && setErrorAlert({ message: 'No document to export' });
        return;
    }

    try {
        const htmlContent = convertMarkdownToHTML(markdown);

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;

        const pdf = createPdfDocument();
        renderContentToPdf(pdf, tempDiv.children);

        pdf.save(`${documentName || 'Document'}.pdf`);
    } catch (error) {
        setErrorAlert && setErrorAlert({ message: defaultErrorMessage });
    }
};
