import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { API } from 'constants';
import templateClient from '../../../services/template-api';
import libraryClient from '../../../services/library-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import useUser from '../../../hooks/useUser';
import { useViewType } from '../../../hooks/useViewType';

import { wsFailReconnectErrorMessage } from '../../../services/websocket';
import { ListeningMultipleLoadingsContext } from '../../../contexts/websoketListeningMultipleLoadingsContext';

import { VIEW_TYPES } from '../../../constants/viewTypes';
import { ACCESS_TYPE } from '../../../constants/accessType';
import { NEW_SUGGESTION_ACCESS_STATE, SETTING_ACTION_TYPE } from '../../../constants/settingsForm';

import {
    checkIfEveryStepFormDataValid,
    extractPrevDocsMappingFromStepFormData,
    formatStepFormDataForRequestBody,
    getInitialSettingsFormData,
    handleStepsFormErrors,
    replaceFileInStepsFormData,
    updateStepsFormDataRemovingLabelsFromDocsMapping,
} from '../../../helpers/settingsFormUtils';
import { checkIfFormDataChanged } from '../../../helpers/checkIfFormDataChanged';

import ErrorBanner from '../../../design-system/ErrorBanner/ErrorBanner';
import SettingStepCollapsibleContainer from './SettingStepCollapsibleContainer/SettingStepCollapsibleContainer';
import SettingStepForm from './SettingStepForm/SettingStepForm';
import NavigationGuard from '../../NavigationGuard/NavigationGuard';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import { Button } from '../../../design-system';

ProcessOverviewSettingsTabSection.propTypes = {
    processDetails: PropTypes.object.isRequired,
    setProcessDetails: PropTypes.func.isRequired,
    shouldShowHiddenSettings: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'secondary']),
    shouldTrackChanges: PropTypes.bool,
    isFormDisabled: PropTypes.bool,
    triggerContextFormStateRefresh: PropTypes.func,
    allowSharing: PropTypes.bool,
    refreshFormDataDependencyArray: PropTypes.array,
};

// to use this component, wrap the page that use it with Provider: <WsMultipleLoadingsProvider messageType="update_process">
function ProcessOverviewSettingsTabSection({
    processDetails,
    setProcessDetails,
    shouldShowHiddenSettings = true,
    shouldTrackChanges = true,
    variant = 'primary',
    isFormDisabled = false,
    allowSharing = false,
    triggerContextFormStateRefresh = () => {},
    refreshFormDataDependencyArray = [],
}) {
    const { id: processId, access_type, default_version } = processDetails;
    const { settings, process_template_version: processTemplateVersionId } = default_version;
    const { user } = useUser();

    const tabContainerRef = useRef(null);

    // keeps form data in format {[step.order]: [stepFormData]}
    const [formData, setFormData] = useState(() => getFormDataInitialState(settings));

    function getFormDataInitialState(settings, prevFormData) {
        const flattedSettings = !prevFormData
            ? []
            : Object.values(prevFormData || {}).reduce((acc, step) => [...acc, ...step], []);
        const prevDocsMapping = extractPrevDocsMappingFromStepFormData(flattedSettings);

        return settings.reduce(
            (acc, { order, settings }) => ({
                ...acc,
                [order]: getInitialSettingsFormData({
                    settings,
                    shouldRemoveSharedValues: allowSharing,
                    allowDocsMapping: true,
                    prevDocsMapping,
                    replaceOnlyFilledTags: true,
                }),
            }),
            {}
        );
    }

    // originalFormData contains the form initial state and is used for tracking changes
    const originalFormData = useRef(formData);
    const [areChangesToSave, setAreChangesToSave] = useState(false);

    // keeps data in format {[step.order]: {Boolean (isCurrentStepRequiredFieldsFilled)}}
    // no need to set the initial state because in every SettingStepForm on Component mount useEffect is called and set the correct value
    // this state is used specifically to avoid checking all form fields when a field of a particular step changes.
    // validation is performed locally at the level of each step only if a field of that step is changed.
    const [areStepsRequiredFieldsFilled, setAreStepsRequiredFieldsFilled] = useState({});

    const allRequiredFieldsAreFilled = useMemo(
        () => Object.values(areStepsRequiredFieldsFilled).every(Boolean),
        [areStepsRequiredFieldsFilled]
    );

    useEffect(() => {
        try {
            const updatedInputData = refreshFormDataDependencyArray?.[0]?.inputData;

            if (updatedInputData) {
                const updatedFormDataEntries = Object.entries(formData).map(([step, data]) => {
                    const updateStepData = data.map((item) => {
                        if (item.name === updatedInputData.name) {
                            return getInitialSettingsFormData({
                                settings: [updatedInputData],
                                shouldRemoveSharedValues: allowSharing,
                                allowDocsMapping: true,
                                replaceOnlyFilledTags: true,
                            })[0];
                        }
                        return item;
                    });

                    return [step, updateStepData];
                });
                const updatedFormData = Object.fromEntries(updatedFormDataEntries);
                setFormData(updatedFormData);
                originalFormData.current = updatedFormData;
            }
        } catch (e) {
            console.log(e);
        }
    }, [...refreshFormDataDependencyArray]);

    const [isExpanded, setIsExpanded] = useState({});

    const [stepsErrorData, setStepsErrorData] = useState({}); // keeps data in format {[step.order]: {isError: true, errorMessage: "error Message"}}
    const [errorBannerMessage, setErrorBannerMessage] = useState(null); // used for general (not step-specific) errors
    const [errorAlert, setErrorAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    const { viewType } = useViewType();

    const isProcessCustom = !processTemplateVersionId;

    const {
        loadingHandlers,
        setLoadingHandlers,
        addRequestUuid,
        setShouldConnectWebsocket,
        tryReconnectIfWebsocketClosed,
    } = useContext(ListeningMultipleLoadingsContext);

    const [isLoading, setIsLoading] = useState(!!loadingHandlers[processId]);

    useEffect(() => {
        if (!isProcessCustom) {
            setShouldConnectWebsocket(true);
        }
    }, [isProcessCustom]);

    useEffect(() => {
        if (shouldTrackChanges) {
            const isFormDataChanged = checkIfFormDataChanged(originalFormData.current, formData);

            try {
                // sometimes the form data is not changed but isFormDataChanged = true
                // this happens while the docs mapping labels are loading
                const isFirstDiff = isFormDataChanged && !areChangesToSave;
                if (isFirstDiff) {
                    // check if only labels are added to docs mappings
                    const updatedFormData =
                        updateStepsFormDataRemovingLabelsFromDocsMapping(formData);
                    const updatedOriginalFormData =
                        updateStepsFormDataRemovingLabelsFromDocsMapping(originalFormData.current);

                    const isOnlyLabelsAdded = !checkIfFormDataChanged(
                        updatedOriginalFormData,
                        updatedFormData
                    );

                    if (isOnlyLabelsAdded) {
                        originalFormData.current = formData;
                        return;
                    }
                }
            } catch (e) {
                setAreChangesToSave(true);
            }

            if (isFormDataChanged !== areChangesToSave) {
                setAreChangesToSave(isFormDataChanged);
            }
        }
    }, [formData]);

    const getRequestBody = async () => {
        const updatedFormData = await replaceFileInStepsFormData(formData);
        const settings = Object.values(updatedFormData).reduce((acc, stepFormData, index) => {
            if (index === 0) {
                return acc;
            }
            return [...acc, ...formatStepFormDataForRequestBody(stepFormData)];
        }, []);

        return {
            name: formData['0'][0]?.value, // value from the "Process Name" step's input (first step)
            organization: user.organization.id,
            process: processId,
            settings,
        };
    };

    const expandAllErrorsSteps = (stepsErrorData) => {
        const expandedSteps = {};

        Object.entries(stepsErrorData).map(([order, stepErrorData]) => {
            if (stepErrorData?.isError) {
                expandedSteps[order] = true;
            }
        });

        setIsExpanded(expandedSteps);
    };

    const refreshOriginalFormData = () => {
        originalFormData.current = formData;
        setFormData((prevData) => ({ ...prevData })); // to trigger useEffect that checks if form data changed
    };

    const handleUpdateCustomProcessSettings = async () => {
        const name = formData['0'][0]?.value;
        await libraryClient.patch(`${API.ROUTES.library.process}${processId}/`, { name });
        setProcessDetails((prevData) => ({ ...prevData, name }));
        setSuccessAlert({ message: 'Agent settings successfully updated!' });
        setLoadingHandlers((prevState) => ({ ...prevState, [processId]: null }));
        setIsLoading(false);
        refreshOriginalFormData();
    };

    const handleUpdateDeployedFromTemplateProcessSettings = async () => {
        await tryReconnectIfWebsocketClosed();

        const requestBody = await getRequestBody();
        const { data } = await templateClient.patch(
            `${API.ROUTES.template.processTemplateVersion}${processTemplateVersionId}/deploy/`,
            requestBody
        );
        if (data.status === 'started' && data.request_uuid) {
            addRequestUuid(data.request_uuid);
        }
    };

    const refetchProcessData = () => {
        libraryClient
            .get(`${API.ROUTES.library.process}${processId}/`)
            .then(({ data }) => {
                setProcessDetails((prev) => ({
                    ...prev,
                    default_version: data.default_version,
                    name: data.name,
                }));
                setFormData((prevFormData) => {
                    const updatedFormData = getFormDataInitialState(
                        data.default_version.settings,
                        prevFormData
                    );
                    originalFormData.current = updatedFormData;
                    return updatedFormData;
                });
                triggerContextFormStateRefresh();

                setSuccessAlert({ message: 'Agent settings successfully updated!' });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleStatusChange = (message) => {
        if (message.status === 'success') {
            if (allowSharing) {
                refetchProcessData();
            }
            if (!allowSharing) {
                setIsLoading(false);
                setSuccessAlert({ message: 'Process settings successfully updated!' });
                refreshOriginalFormData();
            }
        }

        if (message.status === 'error') {
            setIsLoading(false);
            setErrorBannerMessage(message.error);
            setErrorAlert({ message: defaultErrorMessage });
        }
    };

    const updateSettings = async () => {
        // remove all step errors before submitting
        setStepsErrorData({});
        setErrorBannerMessage(null);

        try {
            // check if all required fields are filled and if all inputted data is valid (json-input has a valid json value)
            const { isValid, updatedStepsErrorData } = checkIfEveryStepFormDataValid({
                formData,
                setFormData,
                skipCheckingHiddenSettings: !shouldShowHiddenSettings,
                skipCheckingSharedSettings: allowSharing,
            });

            if (!isValid) {
                setStepsErrorData(updatedStepsErrorData);
                expandAllErrorsSteps(updatedStepsErrorData);
                setErrorAlert({ message: 'All required fields should be filled and valid.' });
                return;
            }

            setIsLoading(true);
            setLoadingHandlers((prevState) => ({ ...prevState, [processId]: handleStatusChange }));

            if (isProcessCustom) {
                await handleUpdateCustomProcessSettings();
            }
            if (!isProcessCustom) {
                await handleUpdateDeployedFromTemplateProcessSettings();
            }
        } catch (e) {
            setLoadingHandlers((prevState) => ({ ...prevState, [processId]: null }));
            setIsLoading(false);

            if (e.message === wsFailReconnectErrorMessage) {
                setErrorAlert({
                    message: 'Oops! Something went wrong. Please try submitting again.',
                });
                return;
            }

            handleStepsFormErrors({
                e,
                setErrorBannerMessage,
                setStepsErrorData,
                setErrorAlert,
                formData,
                setFormData,
                settings,
                expandAllErrorsSteps,
            });
        }
    };

    const sortArrayByOrder = (array) => array?.sort((a, b) => a.order - b.order) || [];

    // if step has just hidden settings, hide this step as well
    const sortedFilteredSettings = useMemo(() => {
        if (!shouldShowHiddenSettings) {
            const filteredSettings = settings.filter((step) => {
                const stepHasNonHiddenSettings = step.settings.find((item) => !item.is_hidden);
                return stepHasNonHiddenSettings;
            });

            return sortArrayByOrder(filteredSettings);
        }

        return sortArrayByOrder(settings);
    }, [settings]);

    const containerClassName = classNames('w-full flex flex-col gap-5', {
        'p-5 sm:p-6 bg-white rounded-2xl': variant === 'primary',
    });

    const updateButtonState = isFormDisabled
        ? 'disabled'
        : isLoading
        ? 'loading'
        : allRequiredFieldsAreFilled && (areChangesToSave || !shouldTrackChanges)
        ? 'default'
        : 'disabled';

    const _newSuggestionAccessState = (() => {
        if (viewType === VIEW_TYPES.client) {
            return NEW_SUGGESTION_ACCESS_STATE.off;
        }
        if (access_type === ACCESS_TYPE.shared) {
            return NEW_SUGGESTION_ACCESS_STATE.lockedOnly;
        }
        return NEW_SUGGESTION_ACCESS_STATE.on;
    })();

    return (
        <>
            {errorBannerMessage && <ErrorBanner errorMessage={errorBannerMessage} />}

            <div className={containerClassName} ref={tabContainerRef}>
                {variant === 'primary' && (
                    <p className="font-heading-bold text-heading-bold-s text-black">
                        Agent Settings
                    </p>
                )}

                <div className="flex flex-col gap-5">
                    {sortedFilteredSettings.map((stepDetails) => {
                        const newSuggestionAccessState = !!stepDetails.order
                            ? _newSuggestionAccessState
                            : NEW_SUGGESTION_ACCESS_STATE.off;

                        return (
                            <SettingStepCollapsibleContainer
                                key={stepDetails.order}
                                stepDetails={stepDetails}
                                isExpanded={isExpanded[stepDetails.order]}
                                setIsExpanded={setIsExpanded}
                                variant={variant}
                            >
                                <SettingStepForm
                                    stepFormData={formData[stepDetails.order]}
                                    setFormData={setFormData}
                                    stepOrder={stepDetails.order}
                                    setAreStepsRequiredFieldsFilled={
                                        setAreStepsRequiredFieldsFilled
                                    }
                                    currentStepErrorData={stepsErrorData[stepDetails.order]}
                                    instructions={stepDetails.instructions}
                                    tabContainerRef={tabContainerRef}
                                    shouldShowHiddenSettings={shouldShowHiddenSettings}
                                    variant={variant}
                                    isFormDisabled={isFormDisabled}
                                    processId={processId}
                                    allowSharing={!!stepDetails.order && allowSharing}
                                    newSuggestionAccessState={newSuggestionAccessState}
                                />
                            </SettingStepCollapsibleContainer>
                        );
                    })}
                </div>
                {variant === 'primary' && (
                    <div className="flex justify-end">
                        <Button
                            type="secondary"
                            size="xs"
                            text="Update Agent"
                            state={updateButtonState}
                            onClick={updateSettings}
                        />
                    </div>
                )}
                {variant === 'secondary' && (
                    <div className="flex justify-between items-center gap-1">
                        <Button
                            type="link"
                            size="md"
                            text="Go to Agent Detail Page"
                            onClick={() => window.open(`/agent/${processId}`, '_blank')}
                        />

                        <Button
                            type="secondary"
                            size="md"
                            text="Update Agent"
                            state={updateButtonState}
                            onClick={updateSettings}
                        />
                    </div>
                )}

                {variant === 'primary' && (
                    <NavigationGuard
                        when={isLoading}
                        promptMessage="Are you sure you want to leave? We’ll keep loading your process in the background."
                        customNavigationBlockerMessageSegment="We’ll keep loading your process in the background."
                    />
                )}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </>
    );
}

export default ProcessOverviewSettingsTabSection;
