import { useNavigate } from 'react-router-dom';

// import { useWrongOrgOrViewTypeNavBlocker } from './useWrongOrgOrViewTypeNavBlocker';

export const useHandlePageDataLoadError = () => {
    const navigate = useNavigate();

    // const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    const handlePageDataLoadError = ({ e, redirectPath, generalErrorHandler }) => {
        if (e?.message === 'canceled') {
            return; // the next request is loading
        }

        if (e?.response?.status === 404 || e?.status === 404) {
            navigate(redirectPath || '/');
            // setIsWrongOrg(true);
            // if (redirectPath) {
            //     setRedirectPath(redirectPath);
            // }
            return;
        }

        generalErrorHandler();
    };

    return { handlePageDataLoadError };
};
