import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import useSWRRequest from '../../../hooks/useSWRRequest';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useChatInputBox } from '../../../hooks/useChatInputBox';
import { useAssistantChatThreadContext } from '../../../hooks/useAssistantChatThreadContext';

import { CHAT_TYPE } from '../../../constants/assistant';

// import ChatInputDiviBotView from '../ChatInputDiviBotView/ChatInputDiviBotView';
import ChatInputGenericView from '../ChatInputGenericView/ChatInputGenericView';
import ChatInputBoxActionButtons from '../ChatInputBoxActionButtons/ChatInputBoxActionButtons';

const ChatInputBox = ({
    submitChatMessage,
    selectedTools,
    setSelectedTools,
    handleCancelStream,
    isAssistantMessageLoadingOrStreaming,
    isCancelStreamingLoading,
    messageSubmissionErrorData,
    setMessageSubmissionErrorData,
}) => {
    const { chatThreadId } = useParams();
    const { chatType, selectedAiModel, selectedCustomBot } = useAssistantChatThreadContext();

    const [searchParams] = useSearchParams();
    const [promptIdToFetch, setPromptIdToFetch] = useState(searchParams.get('prompt') || null);

    const { data, error } = useSWRRequest({
        client,
        route: promptIdToFetch
            ? `${API.ROUTES.assistant.chatPrompt}${promptIdToFetch}/?shared=true`
            : null,
    });

    const { height: screenHeight } = useWindowSize();

    const isModelSelected =
        (chatType !== CHAT_TYPE.custom && !!selectedAiModel) ||
        (chatType === CHAT_TYPE.custom && !!selectedCustomBot?.id);
    const isSendButtonDisabled = !isModelSelected || isCancelStreamingLoading;

    const {
        message,
        setMessage,
        handleMessageChange,
        handleSendMessage,
        handleSendMessageOnEnterPress,
        clearErrorMessage,
        sendButtonState,
        isExpandedMode,
        toggleExpandedMode,
        setIsExpandedMode,
        textareaRef,
        textareaContainerRef,
        handleFocusTextarea,
        isSwitchExpandedModeButton,
        isMobile,
    } = useChatInputBox({
        submitChatMessage,
        isLoading: isAssistantMessageLoadingOrStreaming,
        isSendButtonDisabled,
        messageErrorData: messageSubmissionErrorData,
        setMessageErrorData: setMessageSubmissionErrorData,
        height: screenHeight,
    });

    useEffect(() => {
        if (promptIdToFetch && data) {
            const message = data.default_version?.messages?.[0]?.content;
            setMessage(message);

            setPromptIdToFetch(null);
        }

        if (promptIdToFetch && error) {
            setPromptIdToFetch(null);
        }
    }, [data]);

    useEffect(() => {
        const inputtedUserMessage = messageSubmissionErrorData?.submittedUserMessage;

        if (inputtedUserMessage) {
            setMessage(inputtedUserMessage);
            setMessageSubmissionErrorData((prevData) => ({
                ...prevData,
                submittedUserMessage: null,
            }));
        }
    }, [messageSubmissionErrorData?.submittedUserMessage]);

    useEffect(() => {
        if (!!promptIdToFetch) return;

        if (!chatThreadId) {
            setMessage('');
            setMessageSubmissionErrorData(null);
        }
    }, [chatThreadId]);

    const handleAppendPromptToMessage = (prompt) => {
        setMessage((prevMessage) => (prevMessage ? `${prevMessage} \n${prompt}` : prompt));
        clearErrorMessage();
    };

    const commonButtonsGroup = (
        <ChatInputBoxActionButtons
            handleSendMessage={handleSendMessage}
            handleCancelStream={handleCancelStream}
            sendButtonState={sendButtonState}
            isSwitchExpandedModeButton={isSwitchExpandedModeButton}
            isExpandedMode={isExpandedMode}
            toggleExpandedMode={toggleExpandedMode}
            isLoading={isAssistantMessageLoadingOrStreaming}
        />
    );

    const commonProps = {
        message,
        setMessage,
        handleMessageChange,
        textareaRef,
        textareaContainerRef,
        commonButtonsGroup,
        handleFocusTextarea,
        handleSendMessageOnEnterPress,
        isMobile,
        isExpandedMode,
        setIsExpandedMode,
        handleAppendPromptToMessage,
        chatInputErrorMessage: messageSubmissionErrorData?.errorMessage,
    };

    // if (chatType === CHAT_TYPE.divibot) {
    //     return (
    //         <ChatInputDiviBotView
    //             {...commonProps}
    //             selectedTools={selectedTools}
    //             setSelectedTools={setSelectedTools}
    //             clearErrorMessage={clearErrorMessage}
    //         />
    //     );
    // }

    return (
        <ChatInputGenericView
            chatType={chatType}
            selectedCustomBot={selectedCustomBot}
            selectedTools={selectedTools}
            setSelectedTools={setSelectedTools}
            clearErrorMessage={clearErrorMessage}
            {...commonProps}
        />
    );
};

export default ChatInputBox;
