import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { emptyFieldErrorMessage } from '../../constants/errorMessages';

import Input from '../Input/Input';
import Loading from '../../components/Loading';
import CheckLineIcon from '../Icons/CheckLineIcon';
import { SvgIcon } from '../index';

const InputWithAutoSaveOnBlur = ({
    size = 'xs',
    name,
    label,
    isRequired = false,
    isDisabled = false,
    value,
    originalValue,
    placeholder = 'Not yet Entered',
    handleInputChange: _handleInputChange,
    handleSave,
    displaySaveStatus = true,
}) => {
    const inputWrapperRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const handleBlur = async () => {
        if (value === originalValue) return;

        if (isRequired && !value) {
            setErrorMessage(emptyFieldErrorMessage);

            const inputEl = inputWrapperRef.current.querySelector('input');
            inputEl && inputEl.focus();

            return;
        }

        try {
            setIsLoading(true);
            setIsSaved(false);

            await handleSave(value);

            setIsLoading(false);
            setIsSaved(true);
            setTimeout(() => setIsSaved(false), 1500);
        } catch (e) {
            setIsLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (isRequired && !value) return;

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            const inputEl = inputWrapperRef.current.querySelector('input');
            inputEl && inputEl.blur();
        }
    };

    const handleInputChange = (e) => {
        _handleInputChange(e);
        if (errorMessage) setErrorMessage(null);
    };

    const width = displaySaveStatus ? '90%' : `100%`;
    const inputState = isDisabled ? 'disabled' : errorMessage ? 'error' : 'default';

    return (
        <div className="relative" style={{ width }}>
            <div className="w-full" ref={inputWrapperRef}>
                <Input
                    size={size}
                    name={name}
                    label={label}
                    isRequired={isRequired}
                    value={value}
                    state={inputState}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
            </div>

            {displaySaveStatus && (
                <>
                    {isLoading && (
                        <div className="absolute bottom-[6px] left-[calc(100%+4px)]">
                            <Loading withText={false} />
                        </div>
                    )}
                    {isSaved && (
                        <div className="absolute bottom-[8px] left-[calc(100%+4px)]">
                            <SvgIcon color="#79AC78" icon={CheckLineIcon} size="large" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

InputWithAutoSaveOnBlur.propTypes = {
    size: PropTypes.oneOf(['md', 'sm', 'xs']).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    originalValue: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    handleInputChange: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    displaySaveStatus: PropTypes.bool,
};

export default InputWithAutoSaveOnBlur;
