import React from 'react';

import { openSourceBySourceType } from '../../../helpers/assistantSourcesUtils';

import SourceIcon from '../../BotsPage/BotDetailPage/SourceIcon/SourceIcon';
import SourceInfoBlockWrapper from '../SourceInfoBlockWrapper/SourceInfoBlockWrapper';

const SourceInfoModalDetailsBlock = ({ baseDetail }) => {
    const { source } = baseDetail;
    const { type, name, description, detail } = source;

    return (
        <SourceInfoBlockWrapper
            title="Source Details"
            description="This source was chosen by the chatbot from the following knowledge:"
        >
            <div className="px-4 py-3 flex items-center justify-between gap-2 border-1 border-neutral-200 rounded-2">
                <div className="flex flex-col gap-0.5 overflow-hidden">
                    <div className="flex gap-2 min-h-[16px]">
                        <SourceIcon type={type} />
                        <p className="font-body-bold text-body-bold-xs text-black my-auto">
                            {name}
                        </p>
                    </div>
                    <p className="font-body text-body-regular-xs text-neutral-500 truncate">
                        {description}
                    </p>
                </div>

                <button
                    type="button"
                    onClick={() => openSourceBySourceType({ source_type: type, detail })}
                    className="py-1.5 font-heading-bold font-semibold underline underline-offset-2 text-neutral-400"
                >
                    View
                </button>
            </div>
        </SourceInfoBlockWrapper>
    );
};

export default SourceInfoModalDetailsBlock;
