import React, { useState } from 'react';

import { handleCopyFormattedText } from '../../../../helpers/handleCopyFormattedText';

import CopyAlert from '../../../CopyAlert/CopyAlert';
import { SvgIcon } from '../../../index';
import { FileCopyLineIcon } from '../../../Icons';

const ToolbarCopyButton = ({ containerRef }) => {
    const [copyAlert, setCopyAlert] = useState(null);

    const copyFormattedTextToClipboard = async () => {
        if (!containerRef.current) return;

        const node = containerRef.current.querySelector('.mdxeditor-rich-text-editor');
        if (node) {
            await handleCopyFormattedText({
                node,
                setCopyAlert,
            });
        }
    };

    return (
        <>
            <button
                className="p-[6px] hover:bg-neutral-50 rounded-2"
                onClick={copyFormattedTextToClipboard}
            >
                <SvgIcon color="#1F2125" icon={FileCopyLineIcon} size="medium" />
            </button>

            <CopyAlert copyAlert={copyAlert} setCopyAlert={setCopyAlert} />
        </>
    );
};

export default ToolbarCopyButton;
