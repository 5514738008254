import React, { useEffect, useRef } from 'react';

import { useDragLayer } from 'react-dnd';

import { DRAGGABLE_ITEM_TYPE } from '../../../constants/docs';

import FolderAndDocsDragPreview from '../FolderAndDocsDragPreview/FolderAndDocsDragPreview';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
};

const getItemStyles = (currentOffset, difference) => {
    if (!currentOffset || difference === null) {
        return { display: 'none' };
    }

    const { x, y } = currentOffset;

    return {
        transform: `translate(${x + difference - 50}px, ${y}px)`,
        filter: 'drop-shadow(0 2px 12px rgba(0,0,0,0.45))',
    };
};

const FolderAndDocsDragLayer = () => {
    const cursorPos = useRef({ x: 0, y: 0 });
    const difference = useRef(null);

    const { itemType, isDragging, item, currentOffset, initialOffset } = useDragLayer(
        (monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        })
    );

    useEffect(() => {
        if (!isDragging) {
            difference.current = null;
        }
        if (isDragging) {
            if (!cursorPos.current?.x) {
                difference.current = 0;
            } else {
                difference.current = cursorPos.current?.x - initialOffset?.x;
            }
        }
    }, [isDragging]);

    useEffect(() => {
        const updateCursorPosition = (event) => {
            cursorPos.current = { x: event.clientX, y: event.clientY };
        };

        document.addEventListener('mousemove', updateCursorPosition);

        return () => {
            document.removeEventListener('mousemove', updateCursorPosition);
        };
    }, []);

    const renderItem = (type, item) => {
        switch (type) {
            case DRAGGABLE_ITEM_TYPE.indexPageItem:
                return <FolderAndDocsDragPreview data={item} />;
            case DRAGGABLE_ITEM_TYPE.sidePanelFolder:
                return <FolderAndDocsDragPreview data={item} />;
            default:
                return null;
        }
    };

    if (!isDragging) {
        return null;
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(currentOffset, difference.current)}>
                {renderItem(itemType, item)}
            </div>
        </div>
    );
};

export default FolderAndDocsDragLayer;
