import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useUser from '../../../../hooks/useUser';

import { CONTENT_TYPE, DOCUMENT_TYPE } from '../../../../constants/docs';

import { setSyncTimeout } from '../../../../helpers/setSyncTimeout';
import { startChatWithDocs } from '../../../../helpers/assistantUtils';
import { handleDuplicateItem } from '../../../../helpers/docsUtils';

import { ListOption, ListOptionGroup } from '../../../../design-system';

export const EXPORT_AS_PDF_BUTTON_ID = 'download-doc-button';

const DocDetailMoreOptionsPopup = ({
    id,
    setSuccessAlert,
    setErrorAlert,
    requestKeysToMutate,
    setActionModal,
    documentType,
    isDiagramEditing,
    setIsDiagramEditing,
    onClose,
}) => {
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handleClose = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose();
    };

    const duplicateDoc = async () => {
        await handleDuplicateItem({
            id,
            location,
            setSuccessAlert,
            setErrorAlert,
            setIsLoading,
            closePopup: handleClose,
            requestKeysToMutate,
            contentType: CONTENT_TYPE.document,
        });
    };

    const openActionModal = (action) => {
        onClose();
        setActionModal({ action });
    };

    const handleEditDiagram = () => {
        setIsDiagramEditing(true);
        onClose();
    };

    const handleDownloadAsPdf = async () => {
        const button = document.getElementById(EXPORT_AS_PDF_BUTTON_ID);
        if (button) {
            onClose();
            setSyncTimeout(() => button.click(), 0);
        }
    };

    const isDownloadButtonDisabled = documentType === DOCUMENT_TYPE.diagram && isDiagramEditing;

    const isStaff = user?.is_staff;

    return (
        <div className="absolute top-[120%] right-0 z-20 pb-5">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        {documentType === DOCUMENT_TYPE.diagram && isStaff && (
                            <ListOption
                                leadingIconName="editLineIcon"
                                text="Edit"
                                onClick={handleEditDiagram}
                            />
                        )}

                        <ListOption
                            leadingIconName="copyIcon"
                            text="Duplicate"
                            isLoading={isLoading}
                            onClick={duplicateDoc}
                        />
                        <ListOption
                            leadingIconName="folderTransferFillIcon"
                            text="Move to"
                            onClick={() => openActionModal('move')}
                        />
                        <ListOption
                            leadingIconName="inboxArchiveLineIcon"
                            text="Archive"
                            onClick={() => openActionModal('archive')}
                        />
                        <ListOption
                            leadingIconName="userSharedLineIcon"
                            text="Send to"
                            onClick={() => openActionModal('send')}
                        />
                        <ListOption
                            leadingIconName="robot2LineIcon"
                            text="Chat with doc"
                            onClick={() => startChatWithDocs({ navigate, idsArray: [id] })}
                        />
                        <ListOption
                            leadingIconName="downloadIcon"
                            text="Download as PDF"
                            isDisabled={isDownloadButtonDisabled}
                            onClick={handleDownloadAsPdf}
                        />
                    </>
                }
                handleClose={handleClose}
            />
        </div>
    );
};

export default DocDetailMoreOptionsPopup;
