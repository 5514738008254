import React, { useMemo } from 'react';

import useUser from '../../../hooks/useUser';

import { BASE_TYPE } from '../../../constants/base';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../../constants/organization';

import BaseCard from '../BaseCard/BaseCard';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';

const BasesList = ({ basesHookResponse, page, requestKeysToMutate }) => {
    const { data: bases, total, count } = basesHookResponse;

    const filteredBases = useMemo(
        () => bases?.filter(({ type }) => type !== BASE_TYPE.playbook_section) || [],
        [bases]
    );

    const { orgPlan, userRole } = useUser();
    const hasEditAccess =
        orgPlan === ORGANIZATION_PLAN.agency &&
        (userRole === USER_ROLE.admin || userRole === USER_ROLE.owner);

    return (
        <PaginatedContainer
            page={page}
            count={count}
            total={total}
            onlyShowPaginationIfMultiplePages
        >
            {!!filteredBases.length && (
                <ul className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-2 l:grid-cols-3 xl:gap-x-5 3xl:grid-cols-4">
                    {filteredBases.map((base) => (
                        <BaseCard
                            key={base.id}
                            base={base}
                            requestKeysToMutate={requestKeysToMutate}
                            hasEditAccess={hasEditAccess}
                        />
                    ))}
                </ul>
            )}
        </PaginatedContainer>
    );
};

export default BasesList;
