import React from 'react';

import panzoom from 'svg-pan-zoom';

import '../mermaid.css';

import { useInitMermaid } from '../useInitMermaid';

import Modal from '../../Modal/Modal';
import { ButtonIcon } from '../../index';
import { CloseLineIcon } from '../../Icons';

const FullScreenDiagramModal = ({ code, onClose }) => {
    const diagramRef = useInitMermaid({ code, handlePanZoom });

    function handlePanZoom(ref) {
        if (!ref.current) return;

        Promise.resolve().then(() => {
            const graphDiv = ref.current.querySelector('svg');
            if (!graphDiv) {
                return;
            }
            panzoom(graphDiv, {
                controlIconsEnabled: true,
                fit: true,
                center: true,
            });
        });
    }

    return (
        <Modal size="fullScreen" maxHeight="95%" resetPadding onClose={onClose}>
            <div className="relative w-[92vw] 3xl:max-w-[90vw] h-[92vh] 3xl:max-h-[90vh] px-4 pb-4 pt-6">
                <div className="absolute top-2 right-2 z-30">
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>

                <pre className="mermaid mermaid-full-screen w-full h-full" ref={diagramRef}>
                    {code}
                </pre>
            </div>
        </Modal>
    );
};

export default FullScreenDiagramModal;
