import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Badge, SvgIcon } from '../index';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../Icons';
import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';

SelectButton.propTypes = {
    size: PropTypes.oneOf(['md', 'sm', 'xs']),
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    selectedItem: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        badgeColor: PropTypes.oneOfType([
            PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red']),
            PropTypes.string,
        ]),
        leadingIcon: PropTypes.func,
        leadingIconColor: PropTypes.string,
    }),
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    isDropdownOpened: PropTypes.bool,
    toggleDropdownOpened: PropTypes.func,
    placeholder: PropTypes.string,
    showAsBadge: PropTypes.bool,
    badgeColor: PropTypes.oneOfType([
        PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red']),
        PropTypes.string,
    ]),
};

function SelectButton({
    size = 'xs',
    state = 'default',
    selectedItem,
    isDropdownOpened = false,
    toggleDropdownOpened = () => {},
    placeholder,
    showAsBadge = false,
    badgeColor,
    label,
    isRequired = false,
    errorMessage,
    tipText = '',
}) {
    const selectSize = {
        xs: 'h-10',
        sm: 'h-12',
        md: 'h-13',
    };

    const selectClassName = classNames(
        'flex gap-2 items-center w-full max-w-full rounded-2 px-3 border-1 focus:border-1 focus:outline-0 focus:placeholder-transparent transition-all ease-in',
        selectSize[size],
        {
            'border-neutral-300 bg-white': state === 'default',
            'border-red-500 bg-white': state === 'error',
            'border-neutral-200 bg-neutral-50': state === 'disabled',
            'py-2.5': !showAsBadge,
            'py-[6px]': showAsBadge,
        }
    );
    const selectedOptionClassName = classNames(
        'flex-1 truncate text-start font-body text-body-regular-s',
        {
            'text-neutral-500': selectedItem && state !== 'disabled',
            'text-neutral-300': !selectedItem || state === 'disabled',
        }
    );

    let selectedItemMarkup = null;

    if (!selectedItem?.name) {
        selectedItemMarkup = placeholder;
    }

    if (!!selectedItem?.name) {
        selectedItemMarkup = showAsBadge ? (
            <Badge
                color={selectedItem.badgeColor || badgeColor}
                text={selectedItem.name}
                leadingIcon={selectedItem.leadingIcon}
                leadingIconColor={selectedItem.leadingIconColor}
            />
        ) : (
            selectedItem?.name
        );
    }

    const selectIcon = isDropdownOpened ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    return (
        <FormFieldWrapper
            label={label}
            isRequired={isRequired}
            state={state}
            errorMessage={errorMessage}
            tipText={tipText}
            gap={4}
        >
            <button
                className={selectClassName}
                onClick={toggleDropdownOpened}
                disabled={state === 'disabled'}
            >
                <div className={selectedOptionClassName}>{selectedItemMarkup}</div>
                <SvgIcon color="#5E6470" icon={selectIcon} size="medium" />
            </button>
        </FormFieldWrapper>
    );
}

export default SelectButton;
