import React, { memo, useCallback, useMemo, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/assistant-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { useFormState } from '../../../../hooks/useFormState';
import { useManageSearchParams } from '../../../../hooks/useManageSearchParams';
import { prepareMarkdownOnLoad } from '../../../../helpers/normalizeMarkdown';

import { CHAT_SEARCH_PARAM_KEY } from '../BotPreviewPanel/BotPreviewPanel';

import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import InstructionsTextArea from '../InstructionsTextArea/InstructionsTextArea';
import InputWithAutoSaveOnBlur from '../../../../design-system/InputWithAutoSaveOnBlur/InputWithAutoSaveOnBlur';
import RichTextAreaWithAutoSaveOnBlur from '../../../../design-system/RichTextAreaWithAutoSaveOnBlur/RichTextAreaWithAutoSaveOnBlur';

const AboutSection = ({
    id,
    name,
    description,
    instructions,
    setBotDetail,
    setIsInstructionEditing,
}) => {
    const { deleteSearchParam } = useManageSearchParams();

    const openNewChat = useCallback(
        () => deleteSearchParam(CHAT_SEARCH_PARAM_KEY),
        [deleteSearchParam]
    );

    const formDataInitialState = useMemo(
        () => ({ name, description: prepareMarkdownOnLoad(description) }),
        []
    );

    const { formData, handleInputChange } = useFormState(formDataInitialState);
    const [errorAlert, setErrorAlert] = useState(null);

    const savedData = useRef(formDataInitialState);

    const handleNameAndDescriptionSave = async (field) => {
        try {
            const requestBody = { [field]: formData[field] };

            setBotDetail((detail) => ({ ...detail, [field]: formData[field] }));

            const { data: bot } = await client.patch(
                `${API.ROUTES.assistant.customBot}${id}/`,
                requestBody
            );

            setBotDetail((detail) => ({
                ...detail,
                [field]: bot[field],
                default_version: bot.default_version,
            }));
            if (field === 'description') {
                savedData.current[field] = prepareMarkdownOnLoad(bot[field]);
            } else {
                savedData.current[field] = bot[field];
            }
        } catch (e) {
            setErrorAlert({
                message: defaultErrorMessage,
                statusCode: e.response?.status,
            });
            handleInputChange(field, savedData.current[field]);
            setBotDetail((detail) => ({ ...detail, [field]: savedData.current[field] }));

            throw new Error(e);
        }
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <h2 className="font-heading-bold text-heading-bold-s text-black">About</h2>

            <InputWithAutoSaveOnBlur
                size="md"
                name="chatbot_name"
                label="Name"
                isRequired
                value={formData.name}
                originalValue={name}
                placeholder="Enter Chatbot name"
                handleInputChange={(e) => handleInputChange('name', e.target.value)}
                handleSave={() => handleNameAndDescriptionSave('name')}
                displaySaveStatus={false}
            />

            <RichTextAreaWithAutoSaveOnBlur
                label="Description"
                isRequired
                value={formData.description}
                originalValue={description}
                placeholder="Enter Chatbot description"
                handleChange={(value) => handleInputChange('description', value)}
                handleSave={() => handleNameAndDescriptionSave('description')}
                minHeight={180}
            />

            <InstructionsTextArea
                instructions={instructions}
                customBotId={id}
                openNewChat={openNewChat}
                setBotDetail={setBotDetail}
                onStartEditing={() => setIsInstructionEditing(true)}
                onStopEditing={() => setIsInstructionEditing(false)}
            />

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default memo(AboutSection);
