import React, { memo } from 'react';
import { DateTime } from 'luxon';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import { CONTENT_TYPE_ICON } from '../../../../constants/docs';

import { mutateDataAfterRenamingDoc } from '../../../../helpers/docsUtils';

import { SvgIcon } from '../../../../design-system';
import TextFieldWithCustomTextStyles, {
    emptyFieldError,
} from '../../../../design-system/TextFieldWithCustomTextStyles/TextFieldWithCustomTextStyles';

const DocDetailHeader = ({ docDetail, setDocDetail, docsRequestKeysToMutate }) => {
    const {
        id,
        label,
        last_updated_by: { name, updated_at },
        file_type,
    } = docDetail;

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy');

    const Icon = CONTENT_TYPE_ICON[file_type];

    const saveUpdatedName = async (label) => {
        if (!label) {
            throw new Error(emptyFieldError);
        }

        setDocDetail((prevData) => ({ ...prevData, label }));
        mutateDataAfterRenamingDoc({
            requestKeysToMutate: docsRequestKeysToMutate,
            docId: id,
            updatedLabel: label,
        });
        await client.patch(`${API.ROUTES.knowledge.document}${id}/`, { label });
    };

    return (
        <div className="w-full p-5 rounded-2 flex flex-col gap-5 bg-purple-100">
            <div className="w-full flex items-start gap-1 min-w-[32px]">
                <div className="w-[32px] min-w-[32px] h-[32px] flex items-center justify-center rounded-2 bg-purple-500">
                    <SvgIcon color="#EEEAF9" icon={Icon} size="medium" />
                </div>

                <div className="flex-grow my-auto">
                    <TextFieldWithCustomTextStyles
                        value={label}
                        minHeight={32}
                        isSaveOnBlur
                        isSaveOnEnterPress
                        handleSave={saveUpdatedName}
                        textStyle="font-body-bold text-body-bold-l text-black"
                        customTextFieldStyles="px-2 py-1 rounded-2 shadow-inset-1 shadow-transparent focus:shadow-purple-500 transition-all"
                    />
                </div>
            </div>

            <p className="font-body text-body-regular-s text-[#000000] opacity-70">
                Last updated on <span className="whitespace-nowrap">{formattedTime} |</span> By{' '}
                {name}
            </p>
        </div>
    );
};

export default memo(DocDetailHeader);
