export const getPopupTopPosition = ({ buttonRef, panelRef, shift = 24 }) => {
    let top = 100;
    if (buttonRef?.current && panelRef?.current) {
        const { top: buttonTopValue } = buttonRef.current.getBoundingClientRect();
        const { top: panelTopValue } = panelRef.current.getBoundingClientRect();
        top = buttonTopValue - panelTopValue + shift;
    }

    return { top };
};
