import React from 'react';

import { SELECTION_TYPE } from '../../../../constants/selection';

import DocsAndFoldersList from '../DocsAndFoldersList/DocsAndFoldersList';

const DocsListWithoutSearch = ({
    selectedDocs = {},
    foldersDocumentsData,
    setFoldersDocumentsData,
    handleChange,
    isRootDataLoading,
    selectionType = SELECTION_TYPE.multiple,
}) => {
    const setPage = (cb) => {
        setFoldersDocumentsData((prevData) => {
            const { pagination } = prevData;
            return {
                ...prevData,
                pagination: { ...pagination, page: cb(pagination.page), isFetched: false },
            };
        });
    };

    return (
        <DocsAndFoldersList
            data={foldersDocumentsData}
            setFoldersDocumentsData={setFoldersDocumentsData}
            selectedDocs={selectedDocs}
            handleChange={handleChange}
            setPage={setPage}
            isLoading={isRootDataLoading}
            isRoot={true}
            parentFolderLocation={[]}
            selectionType={selectionType}
        />
    );
};

export default DocsListWithoutSearch;
