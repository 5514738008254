import { mutate } from 'swr';

import { API } from 'constants';
import client from '../services/knowledge-api';

import { mutateKeyData } from './swrMutateUtils';
import {
    moveItemsAPIRequest,
    mutateCashedDataAfterMoveAction,
    getFoldersDataFromPromiseAllSettledResponse,
} from './docsUtils';
import {
    updateSidePanelStateAfterMovingItems,
    updateSidePanelStateAfterRemovingFolders,
} from './docsSidePanelUtils';

import { DRAGGABLE_ITEM_TYPE } from '../constants/docs';

export const handleMoveOnDrop = async ({
    targetFolder,
    draggedItems,
    draggedItemsIds,
    draggedItemsParentLocation,
    requestKeysToMutate,
    currentRequestKeyToMutate,
    setErrorAlert,
    setExpandedFolders,
    setSidePanelFolders,
    type,
}) => {
    try {
        const isSearchApplied =
            currentRequestKeyToMutate && currentRequestKeyToMutate.includes('query=');

        const isSameFolder =
            JSON.stringify(targetFolder.location) === JSON.stringify(draggedItemsParentLocation);

        const skipMoving =
            isSameFolder && (type === DRAGGABLE_ITEM_TYPE.sidePanelFolder || !isSearchApplied);
        if (skipMoving) return;

        if (currentRequestKeyToMutate && !isSearchApplied) {
            mutateKeyData({
                key: currentRequestKeyToMutate,
                revalidate: false,
                cb: (currentData) => {
                    const results = currentData.results.filter(
                        (item) => !draggedItemsIds.includes(item.id)
                    );
                    const count = currentData.count - draggedItemsIds.length;

                    return { ...currentData, results, count };
                },
            });
        }

        if (type === DRAGGABLE_ITEM_TYPE.sidePanelFolder) {
            updateSidePanelStateAfterRemovingFolders({
                removedFoldersIds: draggedItemsIds,
                parentLocation: draggedItemsParentLocation,
                setSidePanelFolders,
            });
        }

        const data = await moveItemsAPIRequest({
            targetFolder,
            itemsToMove: draggedItems,
            setErrorAlert,
        });

        const movedFolders = getFoldersDataFromPromiseAllSettledResponse({
            data,
            selectedItemsArray: draggedItems,
        });

        updateSidePanelStateAfterMovingItems({
            targetFolder,
            movedFolders,
            movedItemsParentLocation: draggedItemsParentLocation,
            isSearchApplied,
            setSidePanelFolders,
            setExpandedFolders,
        });

        await mutateCashedDataAfterMoveAction({
            requestKeysToMutate,
            currentRequestKeyToMutate,
            movedItemsParentLocation: draggedItemsParentLocation,
            targetFolderLocation: targetFolder.location,
            waitForMutate: false,
        });
    } catch (e) {
        if (currentRequestKeyToMutate) {
            mutate(currentRequestKeyToMutate);
        }
        setErrorAlert({
            message: 'Oops! Something went wrong while moving items. Please try again.',
        });
    }
};

export const fetchFolderLocation = async ({ folderId }) => {
    try {
        const { data } = await client.get(API.ROUTES.knowledge.folder + folderId + '/');
        return data.location;
    } catch (e) {
        return null;
    }
};
