import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PLAYBOOK_BUILDER_MODE } from '../../../../constants/playbookBuilder';

import { Button } from '../../../../design-system';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';

const StartNewProjectSuccessState = ({ scenarioName, newProjectId, onStartFromSameProject }) => {
    const navigate = useNavigate();
    const { playbookId } = useParams();

    const viewProjectDetails = () => {
        if (!newProjectId) return;

        navigate(`/project/${newProjectId}`);
    };

    const openPlaybookBuilder = () =>
        navigate(`/playbook/${playbookId}?mode=${PLAYBOOK_BUILDER_MODE.edit}`);

    return (
        <div className="px-8 w-full py-[80px] sm:py-[140px]">
            <div className="py-5 max-w-[460px] mx-auto flex flex-col gap-[40px]">
                <div className="flex flex-col items-center gap-3 md:gap-4 pb-[40px]">
                    <div className="flex flex-col items-center gap-3 md:gap-4 pb-3 md:pb-4 border-b-1 border-neutral-200">
                        <div className="w-[80px] h-[80px] min-w-[80px] bg-lime-500 rounded-full flex items-center justify-center">
                            <CheckLineIcon width={32} height={32} color="#FFFFFF" />
                        </div>
                        <p className="font-body text-body-regular-xs text-neutral-300 uppercase">
                            Project successfully started
                        </p>
                        <p className="font-heading-bold text-heading-bold-m text-black text-center">
                            {scenarioName} has been started!
                        </p>
                        <p className="font-body text-body-regular-s text-neutral-400 text-center">
                            Visit the project details to see the project agents’ progress.
                        </p>
                        <Button
                            type="link"
                            size="sm"
                            text="View Project Details"
                            onClick={viewProjectDetails}
                        />
                    </div>

                    <div className="flex flex-col gap-3 w-full">
                        <p className="font-body text-body-regular-s text-neutral-400 text-center">
                            Start a{' '}
                            <span className="font-body-bold text-body-bold-s">New Project</span>{' '}
                            from:
                        </p>
                        <div className="w-full flex gap-2 items-center">
                            <div className="min-w-[calc((100%-8px)/2)] flex flex-col">
                                <Button
                                    type="ghost"
                                    size="sm"
                                    text="Same Project"
                                    onClick={onStartFromSameProject}
                                />
                            </div>
                            <div className="min-w-[calc((100%-8px)/2)] flex flex-col">
                                <Button
                                    type="secondary"
                                    size="sm"
                                    text="Same Playbook"
                                    onClick={openPlaybookBuilder}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartNewProjectSuccessState;
