import { CHAT_TYPE, DOCUMENTS_QUERY_PARAM, TOOL_TYPE } from '../constants/assistant';

export const getFormattedSelectedTools = ({ selectedTools }) => {
    return Object.entries(selectedTools).map(([type, { details }]) => {
        if (type === TOOL_TYPE.add_documents) {
            return { type, details: { documents: Object.keys(details || {}) } };
        }
        return { type, details };
    });
};

export const appendModelAndToolsToRequestBody = ({
    requestBody,
    chatType,
    selectedAiModel,
    selectedCustomBot,
    selectedTools,
}) => {
    const updatedRequestBody = { ...requestBody, type: chatType };

    if (chatType === CHAT_TYPE.custom) {
        updatedRequestBody.custom_bot = selectedCustomBot?.id;
    }

    if (chatType !== CHAT_TYPE.custom) {
        updatedRequestBody.model = selectedAiModel;
    }

    const areToolsSelected = !!Object.keys(selectedTools).length;
    if (areToolsSelected) {
        updatedRequestBody.tools = getFormattedSelectedTools({ selectedTools });
    }

    return updatedRequestBody;
};

export const startChatWithDocs = ({ navigate, idsArray }) => {
    if (!Array.isArray(idsArray) || !idsArray?.length) {
        navigate(`/assistant`);
        return;
    }

    const idsString = idsArray.join(',');
    navigate(`/assistant?${DOCUMENTS_QUERY_PARAM}=${idsString}`);
};
