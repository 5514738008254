import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import apiClient from 'services/unauthorized-user-api';

import { Button } from 'design-system';
import Loading from 'components/Loading';

export default function ConfirmEmail() {
    const navigate = useNavigate();
    let { confirmationId } = useParams();
    const [apiResponse, setApiResponse] = useState({
        loading: false,
        verificationInvalid: false,
    });

    useEffect(() => {
        const verifyEmail = async () => {
            setApiResponse({
                loading: true,
                verificationInvalid: false,
            });
            const requestBody = { key: confirmationId };
            try {
                const { data: response } = await apiClient.post(
                    API.ROUTES.user.verifyEmail,
                    requestBody
                );
                const authKey = response.data?.key;
                localStorage.setItem('authKey', authKey);
                navigate('/dashboard');
                setApiResponse({
                    loading: false,
                    verificationInvalid: false,
                });
            } catch (e) {
                setApiResponse({
                    loading: false,
                    verificationInvalid: true,
                });
            }
        };

        verifyEmail();
    }, []);

    let content;
    if (apiResponse.loading) {
        content = <Loading text="Verifying your email" />;
    } else if (apiResponse.verificationInvalid) {
        content = (
            <div>
                <h2 className="font-heading-bold text-heading-bold-m text-center mb-[32px]">
                    The verification link you clicked is expired or invalid.
                </h2>
                <div className="flex justify-center">
                    <Button
                        type="link"
                        size="sm"
                        text="Click here to verify your email"
                        onClick={() => {
                            navigate('/email/verify');
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="EmailVerification py-[80px]">
            <div>{content}</div>
        </div>
    );
}
