import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { API } from 'constants';
import client from '../../../services/user-api';

import { isFileSizeValid, megabytesToBytes } from '../../../helpers/fileUtils';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { BLOCK_TYPE } from '../../../constants/playbookBuilder';

import EditLineIcon from '../../Icons/EditLineIcon';
import FileInput from '../../FileInput/FileInput';
import Alert from '../../Alert/Alert';
import { ButtonIcon, SvgIcon } from '../../index';
import { DeleteBin4LineIcon, ErrorWarningLineIcon } from '../../Icons';
import ImageWithFallback from '../../ImageWithFallback/ImageWithFallback';

ImageBlock.propTypes = {
    blockData: PropTypes.shape({
        order: PropTypes.number,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        temporaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        block_type: PropTypes.oneOf([BLOCK_TYPE.image]),
        properties: PropTypes.shape({
            image_url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        }).isRequired,
    }),
    editMode: PropTypes.bool,
    handleDeleteBlock: PropTypes.func.isRequired,
    handleBlockPropertyChange: PropTypes.func.isRequired,
};

const formatsArray = ['.jpg', '.jpeg', '.png'];
const maxSizeInBytes = megabytesToBytes(5);

function ImageBlock({ blockData, editMode = false, handleDeleteBlock, handleBlockPropertyChange }) {
    const {
        properties: { image_url },
    } = blockData;

    // the state contain current image URL saved in DB if it exists or uploaded image preview URL, if current image was changed or did not exist
    const [imagePreviewUrl, setImagePreviewUrl] = useState(image_url);
    const [imagePreviewDisplayingError, setImagePreviewDisplayingError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [controller, setController] = useState(new AbortController()); // state to manage the AbortController instance used for aborting requests

    const setImageUrlInBlockData = (image_url) => {
        handleBlockPropertyChange('image_url', image_url);
    };

    const saveFileOnCDNAndSetInBlockData = async (file) => {
        try {
            controller.abort(); // cancel the previous request if it's still active

            // create and set the new controller as the active one
            const newController = new AbortController();
            setController(newController);

            const formData = new FormData();
            formData.append('image', file);

            const { data } = await client.post(API.ROUTES.user.uploadImage, formData, {
                signal: newController.signal,
            });
            setImageUrlInBlockData(data.image);
        } catch (error) {
            if (error.message === 'canceled') {
                return; // the next request is loading
            }
            setErrorMessage(defaultErrorMessage);
            setImagePreviewUrl(image_url || null);
        }
    };

    const handleFileChange = (file) => {
        const temporaryUrl = URL.createObjectURL(file);
        setImagePreviewUrl(temporaryUrl);

        saveFileOnCDNAndSetInBlockData(file);
    };

    const handleFileWrongSize = () => {
        setErrorMessage(`File size exceeds 5 MB and cannot be uploaded.`);
    };

    const handleEditFile = (e) => {
        e.preventDefault();
        setErrorMessage(null);
        const file = e.target.files && e.target.files[0];

        if (file) {
            const isWrongSize = !isFileSizeValid({ file, maxSizeInBytes, handleFileWrongSize });
            if (isWrongSize) {
                return;
            }
            handleFileChange(file);
        }
    };

    return (
        <>
            {!imagePreviewUrl && (
                <div className="relative">
                    <FileInput
                        handleFile={handleFileChange}
                        maxSizeInBytes={maxSizeInBytes}
                        formatsArray={formatsArray}
                        limitationsLabel="Max. file size: 5MB - PNG, JPG"
                        view="extended"
                        state={editMode ? 'default' : 'disabled'}
                    />

                    {editMode && (
                        <div className="absolute top-2 right-2">
                            <ButtonIcon
                                type="neutral"
                                size="xs"
                                icon={DeleteBin4LineIcon}
                                onClick={handleDeleteBlock}
                            />
                        </div>
                    )}
                </div>
            )}

            {imagePreviewUrl && (
                <div
                    className={`relative flex items-center justify-center mx-auto ${
                        imagePreviewDisplayingError ? 'w-full' : 'w-fit'
                    }`}
                >
                    <ImageWithFallback
                        src={imagePreviewUrl}
                        alt="Image could not be loaded"
                        className="max-w-full w-auto max-h-[80vh] md:max-h-[60vh]"
                        altTextClassName={`font-body text-body-regular-m text-neutral-500 ${
                            editMode ? 'pt-9' : ''
                        }`}
                        onImageErrorChange={setImagePreviewDisplayingError}
                    />

                    {editMode && (
                        <div className="absolute top-2 right-2 flex items-center gap-2">
                            <label className="w-[28px] h-[28px] min-w-[28px] flex items-center justify-center rounded-2 bg-neutral-100 cursor-pointer">
                                <input
                                    type="file"
                                    className="hidden"
                                    accept={formatsArray?.join(', ')}
                                    onChange={handleEditFile}
                                />
                                <SvgIcon color="#1F2125" icon={EditLineIcon} size="medium" />
                            </label>

                            <ButtonIcon
                                type="neutral"
                                size="xs"
                                icon={DeleteBin4LineIcon}
                                onClick={handleDeleteBlock}
                            />
                        </div>
                    )}
                </div>
            )}

            {errorMessage && (
                <Alert
                    status="critical"
                    message={errorMessage}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorMessage(null)}
                    autoCloseInMS={3000}
                />
            )}
        </>
    );
}

export default memo(ImageBlock);
