import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useRemoveBgOnCopy from '../../../hooks/useRemoveBgOnCopy';
import { useOverlayState } from '../../../hooks/useOverlayState';
import { useHandlePageDataLoadError } from '../../../hooks/useHandlePageDataLoadError';
import { useSwrRequestKeysToMutateFromContext } from '../../../hooks/useSwrRequestKeysToMutateFromContext';

import Loading from '../../../components/Loading';
import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import DeleteBotModal from '../DeleteBotModal/DeleteBotModal';
import BotDetailHeader from './BotDetailHeader/BotDetailHeader';
import BotDetailMainContent from './BotDetailMainContent/BotDetailMainContent';
import EditConfigurationModal from './EditConfigurationModal/EditConfigurationModal';

const BotDetailPage = () => {
    const { botId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const backlinkHref = location.state?.from ?? '/bots';

    const [botDetail, setBotDetail] = useState(null);

    const [actionModal, setActionModal] = useState({ action: null });
    const { isOpened: isFeedbackPanelOpened, toggle: toggleFeedbackPanel } = useOverlayState();

    const {
        requestKeysToMutate: { bots: requestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const { handlePageDataLoadError } = useHandlePageDataLoadError();

    const [successAlert, setSuccessAlert] = useState(null);

    useEffect(() => {
        const fetchDocDetail = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.assistant.customBot}${botId}/`);
                setBotDetail(data);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    redirectPath: '/bots',
                    generalErrorHandler: () => navigate(backlinkHref),
                });
            }
        };

        fetchDocDetail();
    }, [botId]);

    useDocumentTitle(botDetail?.name);

    useRemoveBgOnCopy();

    return (
        <div className="page-position bg-neutral-50 flex flex-col overflow-y-auto lg:pb-0 z-10">
            {botDetail && (
                <>
                    <BotDetailHeader
                        botDetail={botDetail}
                        backlinkHref={backlinkHref}
                        setActionModal={setActionModal}
                        isFeedbackPanelOpened={isFeedbackPanelOpened}
                        toggleFeedbackPanel={toggleFeedbackPanel}
                    />

                    <BotDetailMainContent
                        botDetail={botDetail}
                        setBotDetail={setBotDetail}
                        isFeedbackPanelOpened={isFeedbackPanelOpened}
                        toggleFeedbackPanel={toggleFeedbackPanel}
                    />

                    {actionModal.action === 'delete' && (
                        <DeleteBotModal
                            id={botId}
                            botName={botDetail.name}
                            requestKeysToMutate={requestKeysToMutate}
                            onDeleteSuccess={() => navigate(backlinkHref)}
                            onClose={() => setActionModal({ action: null })}
                        />
                    )}

                    {actionModal.action === 'editConfig' && (
                        <EditConfigurationModal
                            botId={botId}
                            config={botDetail.default_version.config}
                            setBotDetail={setBotDetail}
                            setSuccessAlert={setSuccessAlert}
                            onClose={() => setActionModal({ action: null })}
                        />
                    )}

                    <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
                </>
            )}

            {!botDetail && (
                <div className="flex-1 flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default BotDetailPage;
