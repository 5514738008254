import React from 'react';
import { useNavigate } from 'react-router-dom';

import useUser from '../../../../hooks/useUser';

import { SOURCE_TYPE } from '../../../../constants/bot';

import SourceIcon from '../SourceIcon/SourceIcon';
import { ListOptionGroup } from '../../../../design-system';

const NewSourceOptionsPopup = ({ openNewSourcePanel, isGoogleCloudConnected, onClose }) => {
    const { user } = useUser();
    const organizationId = user?.organization?.id;

    const navigate = useNavigate();

    const handleGoogleFolderClick = () => {
        if (!isGoogleCloudConnected) {
            organizationId && navigate(`/settings/organization/${organizationId}`);
            return;
        }

        openNewSourcePanel({ type: SOURCE_TYPE.google_folder });
    };

    return (
        <div className="absolute top-full right-0 lg:right-unset lg:top-0 lg:left-full z-30 pb-5 pt-1 lg:pt-0">
            <ListOptionGroup
                fixedWidth={360}
                listOptions={
                    <>
                        <Option
                            type={SOURCE_TYPE.diviup_folder}
                            text="DiviUp Folder"
                            onClick={() => openNewSourcePanel({ type: SOURCE_TYPE.diviup_folder })}
                        />

                        <Option
                            type={SOURCE_TYPE.google_folder}
                            text="Google Folder"
                            isConnected={isGoogleCloudConnected}
                            onClick={handleGoogleFolderClick}
                        />
                    </>
                }
                handleClose={onClose}
            />
        </div>
    );
};

function Option({ type, text, isConnected = true, onClick }) {
    const optionClassName =
        'w-full flex items-center justify-between gap-2 p-2 rounded-2 bg-white hover:bg-neutral-50 transition-colors cursor-pointer';

    const buttonText = isConnected ? 'Add' : 'Connect';

    return (
        <button className={optionClassName} onClick={onClick}>
            <SourceIcon type={type} />
            <p className="text-start flex-1 font-body text-body-regular-m text-neutral-500">
                {text}
            </p>
            <p className="font-heading-bold text-[14px] text-neutral-400">{buttonText}</p>
        </button>
    );
}

export default NewSourceOptionsPopup;
