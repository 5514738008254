import React, { useMemo, useState } from 'react';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { HISTORY_TAB } from '../../../constants/assistant';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import HistoryGroupContainer from '../HistoryGroupContainer/HistoryGroupContainer';
import ChatThreadCard from '../ChatThreadCard/ChatThreadCard';
import HistoryTabsEmptyView from '../HistoryTabsEmptyView/HistoryTabsEmptyView';

const HistorySidePanelChatsTab = ({
    chatThreadsHistoryHookResponse,
    setIsPanelClosableByClickOutside,
    closePanel,
}) => {
    const {
        items: chatThreads,
        setItems: setChatThreads,
        isLoading: areChatThreadsLoading,
        canLoadMore,
        setPage,
        searchQuery,
        setSearchQuery,
        isFirstRequestCompleted,
        setIsFirstRequestCompleted,
    } = chatThreadsHistoryHookResponse;

    const [searchBarInputValue, setSearchBarInputValue] = useState(searchQuery);

    const responsiveBreakpointsHookResponse = useResponsiveBreakpoints();

    const handleSearchbarClear = () => {
        setSearchBarInputValue('');
        setSearchQuery('');
        setIsFirstRequestCompleted(false);
    };

    const chatThreadGroups = useMemo(
        () =>
            Object.entries(
                Object.groupBy(chatThreads || [], ({ created_at_group }) => created_at_group)
            ),
        [chatThreads]
    );

    const isEmptyStateDisplayed =
        isFirstRequestCompleted && !areChatThreadsLoading && !chatThreads?.length;

    return (
        <div className="flex flex-col gap-5 h-full">
            <SearchBar
                size="xs"
                value={searchBarInputValue}
                onChange={(e) => setSearchBarInputValue(e.target.value)}
                onSubmit={() => setSearchQuery(searchBarInputValue)}
                withClearIcon
                onClear={handleSearchbarClear}
            />

            {isEmptyStateDisplayed && (
                <HistoryTabsEmptyView tab={HISTORY_TAB.chats} isSearchQuery={!!searchQuery} />
            )}

            <div className="mx-[-20px] lg:mx-[-16px] xl:mx-[-20px] pl-5 pr-4 lg:pl-4 lg:pr-3 xl:pl-5 xl:pr-4 overflow-y-auto flex-grow pb-6">
                <InfiniteScrollList
                    handleFetch={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMore}
                    items={chatThreadGroups}
                    loading={areChatThreadsLoading}
                    gap={20}
                >
                    {chatThreadGroups.map(([group, chatThreads]) => {
                        return (
                            <HistoryGroupContainer key={'chat' + group} group={group}>
                                {chatThreads.map((chatThread) => (
                                    <ChatThreadCard
                                        key={chatThread.id}
                                        chatThead={chatThread}
                                        setChatThreads={setChatThreads}
                                        setIsPanelClosableByClickOutside={
                                            setIsPanelClosableByClickOutside
                                        }
                                        closePanel={closePanel}
                                        responsiveBreakpointsHookResponse={
                                            responsiveBreakpointsHookResponse
                                        }
                                    />
                                ))}
                            </HistoryGroupContainer>
                        );
                    })}
                </InfiniteScrollList>
            </div>
        </div>
    );
};

export default HistorySidePanelChatsTab;
