import React from 'react';
import classNames from 'classnames';

import FeedbackPanel from '../../../../components/FeedbackPanel/FeedbackPanel';
import BotPreviewPanel from '../BotPreviewPanel/BotPreviewPanel';

const RightPanel = ({
    botDetail,
    isInstructionEditing,
    isFeedbackPanelOpened,
    toggleFeedbackPanel,
}) => {
    const { name: botName, feedback } = botDetail;

    const botPreviewContainerClassName = classNames(
        'h-full flex flex-col',
        isFeedbackPanelOpened && 'lg:w-0 lg:max-w-0 lg:overflow-hidden'
    );

    return (
        <div className="h-[calc(100vh-60px)] sm:h-screen lg:h-full lg:flex-1 lg:overflow-y-auto bg-white flex flex-col">
            {isFeedbackPanelOpened && (
                <FeedbackPanel
                    feedback={feedback}
                    onClose={toggleFeedbackPanel}
                    desktopContainerClassName="w-full h-full flex-1"
                    emptyFeedbackMessage="No feedback submitted for chatbot"
                />
            )}

            <div className={botPreviewContainerClassName}>
                <BotPreviewPanel botName={botName} isInstructionEditing={isInstructionEditing} />
            </div>
        </div>
    );
};

export default RightPanel;
