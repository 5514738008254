import React from 'react';

import UserBadge from '../../../../design-system/UserBadge/UserBadge';
import TimestampDisplay from '../../../../design-system/TimestampDisplay/TimestampDisplay';

const BotLastUpdatedInfo = ({ updatedAt, lastUpdatedBy }) => {
    const userName = lastUpdatedBy?.name || lastUpdatedBy?.email;

    return (
        <div className="flex items-center flex-wrap gap-1 max-w-full">
            <TimestampDisplay
                label="Last Updated"
                timestamp={updatedAt}
                dateTimeFormat="MM/dd/yyyy"
                withClockIcon
            />

            <div className="flex items-center gap-1 max-w-full">
                <span className="font-body text-body-regular-xs text-neutral-300"> by </span>

                <UserBadge name={userName} />
            </div>
        </div>
    );
};

export default BotLastUpdatedInfo;
