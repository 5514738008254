import React from 'react';

import KnowledgeSourceCard from '../KnowledgeSourceCard/KnowledgeSourceCard';

const BotConnectedKnowledgeSection = ({ sources, ...cardProps }) => {
    const areSources = !!sources?.length;

    if (!areSources) {
        return (
            <p className="font-body-bold text-body-bold-s text-neutral-300">
                No Connected Knowledge
            </p>
        );
    }

    return (
        <div className="flex flex-col gap-3 rounded-2 bg-neutral-50 p-3">
            <p className="font-body-bold text-body-bold-s text-black">Connected Knowledge</p>

            <p className="font-body text-body-regular-xs text-black">
                Explore all of the context available to this chatbot
            </p>

            {areSources && (
                <ul className="flex flex-col gap-1">
                    {sources.map((source, index) => (
                        <KnowledgeSourceCard
                            key={source.id}
                            source={source}
                            isLastCard={index === sources.length - 1}
                            {...cardProps}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default BotConnectedKnowledgeSection;
