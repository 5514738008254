import React from 'react';
import classNames from 'classnames';

import { GOOGLE_IMAGE_PATH } from '../../../constants/bot';
import { BASE_ICON, BASE_TYPE } from '../../../constants/base';

import { SvgIcon } from '../../../design-system';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';
import HighlightedIcon from '../../../design-system/HighlightedIcon/HighlightedIcon';

const BaseIcon = ({ type, isHighlighted = false, variant: _variant = 'neutral' }) => {
    const variant = _variant || 'neutral';

    if (type === BASE_TYPE.google_folder) {
        const googleImage = (
            <img
                src={GOOGLE_IMAGE_PATH}
                width={16}
                height={17}
                alt="Google logo"
                className="block max-h-[17px] min-w-4"
            />
        );

        if (isHighlighted) {
            const className = classNames(
                'w-8 h-8 min-w-[32px] rounded-2 flex items-center justify-center bg-neutral-100'
            );

            return <div className={className}>{googleImage}</div>;
        }

        return googleImage;
    }

    const Icon = BASE_ICON[type] || ArchiveLineIcon;

    if (isHighlighted) {
        return (
            <HighlightedIcon
                icon={Icon}
                variant={variant}
                customIconColor={variant === 'neutral' ? '#754DCF' : null}
            />
        );
    }

    return <SvgIcon color="#754DCF" icon={Icon} size="medium" />;
};

export default BaseIcon;
