import { useCallback, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../services/assistant-api';

import { CHAT_ROLE } from '../constants/assistant';

import { useWebSocketMessageStream } from './useWebSocketMessageStream';

import { generateUUID } from '../helpers/generateUUID';

export const IS_STREAM_LOADING_KEY = 'isStreamLoading';

export const useAssistantMessageStream = ({
    setChatMessages,
    messageStreamState,
    setMessageStreamState,
    modelSettings,
}) => {
    const assistantMessageResponse = useRef(null);
    const assistantMessageIdToRefetchOnStreamEnd = useRef(null);

    const isStreamCanceled = useRef(false);
    const [emptyCanceledAssistantMessage, setEmptyCanceledAssistantMessage] = useState(null);

    const {
        setGenerationId,
        stopStreaming: stopWebSocketStream,
        tryReconnectIfWebsocketClosed,
    } = useWebSocketMessageStream({
        onStreamStart,
        onMessageStream,
        onStreamEnd,
        onStreamError,
    });

    function onStreamStart() {
        const assistantInitialMessage = assistantMessageResponse.current;
        if (assistantInitialMessage) {
            const updatedAssistantInitialMessage = {
                ...assistantInitialMessage,
                default_version: {
                    ...assistantInitialMessage.default_version,
                    [IS_STREAM_LOADING_KEY]: true,
                },
            };
            setChatMessages((prevMessages) => [...prevMessages, updatedAssistantInitialMessage]);
        }

        assistantMessageResponse.current = null;

        setMessageStreamState({
            isAssistantLoading: false,
            isStreamRunning: true,
        });
    }

    const updateStreamingMessage = (streamingMessageGenId, cb) => {
        setChatMessages((chatMessages) =>
            chatMessages.map((message) => {
                const isAssistantStreamingMessage =
                    message.default_version?.id === streamingMessageGenId;

                if (isAssistantStreamingMessage) {
                    return cb(message);
                }

                return message;
            })
        );
    };

    function onMessageStream({ message: streamingMessage }) {
        triggerStreamStartIfMissed();

        updateStreamingMessage(streamingMessage.gen_id, (message) => {
            const updatedMessageContent =
                (message.default_version.content || '') + streamingMessage.text;
            const updatedMessage = {
                ...message,
                default_version: {
                    ...message.default_version,
                    content: updatedMessageContent,
                },
            };

            return updatedMessage;
        });
    }

    function onStreamEnd({ message: streamingMessage, isStreamCanceled = false }) {
        triggerStreamStartIfMissed();

        const messageId = assistantMessageIdToRefetchOnStreamEnd.current;
        const showMessageAsCompleted = !messageId || isStreamCanceled;

        updateStreamingMessage(streamingMessage.gen_id, (message) => {
            // get the current content, and if it's empty, fill it; if not, just set the status
            if (!message.default_version.content) {
                const updatedMessageContent = streamingMessage.text;
                const updatedMessage = {
                    ...message,
                    default_version: {
                        ...message.default_version,
                        content: updatedMessageContent,
                        ...(showMessageAsCompleted && { status: 'success' }),
                        [IS_STREAM_LOADING_KEY]: false,
                    },
                };
                return updatedMessage;
            } else {
                const updatedMessage = {
                    ...message,
                    default_version: {
                        ...message.default_version,
                        ...(showMessageAsCompleted && { status: 'success' }),
                        [IS_STREAM_LOADING_KEY]: false,
                    },
                };
                return updatedMessage;
            }
        });

        if (!showMessageAsCompleted) {
            fetchUpdatedDefaultVersionOnStreamEnd(messageId);
        } else {
            clearMessageLoadingStreamState();
            assistantMessageIdToRefetchOnStreamEnd.current = null;
        }
    }

    async function fetchUpdatedDefaultVersionOnStreamEnd(messageId) {
        if (!messageId) return;

        try {
            const { data } = await client.get(API.ROUTES.assistant.chatMessage + messageId + '/');
            setChatMessages((chatMessages) =>
                chatMessages.map((message) => {
                    const isStreamingMessage = message.id === data.id;

                    if (isStreamingMessage) {
                        return {
                            ...message,
                            default_version: {
                                ...data.default_version,
                                content: message.default_version?.content || '',
                            },
                        };
                    }

                    return message;
                })
            );
        } catch (e) {
            setChatMessages((chatMessages) =>
                chatMessages.map((message) => {
                    const isStreamingMessage = message.id === messageId;

                    if (isStreamingMessage) {
                        return {
                            ...message,
                            default_version: { ...message.default_version, status: 'success' },
                        };
                    }

                    return message;
                })
            );
        } finally {
            clearMessageLoadingStreamState();
            assistantMessageIdToRefetchOnStreamEnd.current = null;
        }
    }

    function onStreamError({ message: streamingMessage }) {
        triggerStreamStartIfMissed();

        updateStreamingMessage(streamingMessage.gen_id, (message) => {
            const errorAssistantMessage = {
                ...message,
                default_version: {
                    ...message.default_version,
                    status: 'failure',
                    streamErrorMessage: streamingMessage.text,
                    [IS_STREAM_LOADING_KEY]: false,
                },
            };
            return errorAssistantMessage;
        });

        clearMessageLoadingStreamState();
        assistantMessageIdToRefetchOnStreamEnd.current = null;
    }

    function triggerStreamStartIfMissed() {
        const isStreamStartReceived = !assistantMessageResponse.current;
        if (!isStreamStartReceived) {
            onStreamStart();
        }
    }

    function clearMessageLoadingStreamState() {
        setMessageStreamState({
            isAssistantLoading: false,
            isStreamRunning: false,
        });
    }

    const startListeningWebSockets = useCallback(
        (data) => {
            const { assistant_message } = data;

            assistantMessageResponse.current = assistant_message;
            assistantMessageIdToRefetchOnStreamEnd.current = assistant_message.id;

            const generationId = assistant_message.default_version.id;
            setGenerationId(generationId);
        },
        [setGenerationId]
    );

    const cancelStream = () => {
        const abortedGenerationId = stopWebSocketStream();
        assistantMessageIdToRefetchOnStreamEnd.current = null;

        if (messageStreamState.isStreamRunning) {
            onStreamEnd({ message: { gen_id: abortedGenerationId }, isStreamCanceled: true });
        }

        if (messageStreamState.isAssistantLoading) {
            setMessageStreamState({
                isAssistantLoading: false,
                isStreamRunning: false,
            });

            isStreamCanceled.current = true;
            setEmptyCanceledAssistantMessage({
                id: `temp-${generateUUID()}`,
                role: CHAT_ROLE.assistant,
                default_version: {
                    content: '',
                    status: 'pending',
                    ...modelSettings,
                },
            });
        }
    };

    const resetAssistantMessageResponse = () => {
        assistantMessageResponse.current = null;
        assistantMessageIdToRefetchOnStreamEnd.current = null;
    };

    return {
        startListeningWebSockets,
        cancelStream,
        stopWebSocketStream,
        tryReconnectIfWebsocketClosed,
        clearMessageLoadingStreamState,
        resetAssistantMessageResponse,
        emptyCanceledAssistantMessage,
        setEmptyCanceledAssistantMessage,
        isStreamCanceled,
    };
};
