import React from 'react';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const EntryMoreOptionsPopup = ({ onClose, openActionModal, hasEditAccess }) => {
    const handleAction = (action) => {
        if (!hasEditAccess && action !== 'edit') return;

        openActionModal({ action });
        onClose();
    };

    return (
        <div
            className="absolute top-[120%] right-0 z-20 pb-5"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Edit"
                            onClick={() => handleAction('edit')}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete"
                            textColor="#E95B69"
                            iconColor="#E95B69"
                            isDisabled={!hasEditAccess}
                            onClick={() => handleAction('delete')}
                        />
                    </>
                }
                handleClose={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default EntryMoreOptionsPopup;
