import ArchiveLineIcon from '../design-system/Icons/ArchiveLineIcon';
import FolderFillIcon from '../design-system/Icons/FolderFillIcon';

export const BASE_TYPE = {
    playbook_section: 'playbook-section', // is outdated
    agent_knowledge: 'agent-knowledge',
    diviup_folder: 'diviup-folder',
    google_folder: 'google-folder',
};

export const BASE_ICON = {
    [BASE_TYPE.agent_knowledge]: ArchiveLineIcon,
    [BASE_TYPE.diviup_folder]: FolderFillIcon,
};

export const knowledgeBasePropertyFormatMapping = {
    'Single line text': 'text',
    'Multi line text': 'text',
    Checkbox: 'boolean',
    Integer: 'int',
    // Decimal: 'number',
};
