import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/template-api';

import useUser from '../../../hooks/useUser';
import {
    handleStepsFormErrors,
    getInitialSettingsFormData,
    getSetStepFormDataFunction,
    checkIfEveryStepFormDataValid,
    formatStepFormDataForRequestBody,
    replaceFileInStepsFormData,
} from '../../../helpers/settingsFormUtils';
import { addUniqueElementToArray } from '../../../helpers/addUniqueElementToArray';
import { useWebSocketListeningLoadingState } from '../../../hooks/useWebSocketListeningLoadingState';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { wsFailReconnectErrorMessage } from '../../../services/websocket';

import StepsPanel from '../StepsPanel/StepsPanel';
import SettingsStepForm from '../SettingsStepForm/SettingsStepForm';
import SubmissionSuccessState from '../SubmissionSuccessState/SubmissionSuccessState';
import SubmissionLoader from '../../../design-system/SubmissionLoader/SubmissionLoader';
import errorWarningLineIcon from '../../../design-system/Icons/ErrorWarningLineIcon';
import Alert from '../../../design-system/Alert/Alert';
import ErrorBanner from '../../../design-system/ErrorBanner/ErrorBanner';
import MobileBottomControls from '../MobileBottomControls/MobileBottomControls';
import NavigationGuard from '../../../components/NavigationGuard/NavigationGuard';

const ProcessSetupOrUpgradeIndex = ({ settings, pageAction, processTemplateName }) => {
    const { versionId, processId } = useParams();
    const { user } = useUser();

    const [submissionStatus, setSubmissionStatus] = useState('default'); // loading, success, default
    // newProcessId is got after success deploy_process and is used in SubmissionSuccessState
    const [newProcessId, setNewProcessId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [currentStepOrder, setCurrentStepOrder] = useState(0);
    // keeps form data in format {[step.order]: [stepFormData]}
    const [formData, setFormData] = useState(() =>
        settings
            .sort((a, b) => a.order - b.order)
            .reduce(
                (acc, { order, settings }) => ({
                    ...acc,
                    [order]: getInitialSettingsFormData({
                        settings,
                        shouldRemoveSharedValues: true,
                    }),
                }),
                {}
            )
    );

    const [stepsErrorData, setStepsErrorData] = useState({}); // keeps data in format {[step.order]: {isError: true, errorMessage: "error Message"}}
    const [errorBannerMessage, setErrorBannerMessage] = useState(null); // used for general (not step-specific) errors
    const [errorAlert, setErrorAlert] = useState(null);

    // array of all orders of completed steps
    const [completedStepOrders, setCompletedStepOrders] = useState([]);

    const currentStepData = useMemo(
        () => settings.find((item) => item.order === currentStepOrder),
        [currentStepOrder, settings]
    );

    const [isMobileStepsPanelOpened, setIsMobileStepsPanelOpened] = useState(false);

    const { addRequestUuid, clearRequestUuid, tryReconnectIfWebsocketClosed } =
        useWebSocketListeningLoadingState({
            messageType: 'deploy_process',
            onSuccess: onWebsocketSuccess,
            onError: onWebsocketError,
        });

    function onWebsocketSuccess(message) {
        setSubmissionStatus('success');
        setNewProcessId(message.data.id);
    }

    function onWebsocketError(message) {
        setErrorBannerMessage(message.error);
        setErrorAlert({ message: defaultErrorMessage });
        setSubmissionStatus('default');
    }

    const closeMobileStepsPanel = () => {
        setIsMobileStepsPanelOpened(false);
    };

    const getRequestBody = async () => {
        const updatedFormData = await replaceFileInStepsFormData(formData);
        const settings = Object.values(updatedFormData).reduce((acc, stepFormData, index) => {
            if (index === 0) {
                return acc;
            }
            return [...acc, ...formatStepFormDataForRequestBody(stepFormData)];
        }, []);

        const data = {
            name: formData['0'][0]?.value, // value from the "Process Name" step's input (first step)
            organization: user.organization.id,
            settings,
        };

        // if pageAction is upgrade, add process id to the request body
        if (pageAction === 'upgrade') {
            data.process = processId;
        }

        return data;
    };

    const submitProcessSetup = async () => {
        // remove all step errors before submitting
        setStepsErrorData({});
        setErrorBannerMessage(null);

        try {
            // check if all required fields are filled and if all inputted data is valid
            const { isValid, updatedStepsErrorData } = checkIfEveryStepFormDataValid({
                formData,
                setFormData,
                skipCheckingSharedSettings: true,
            });

            if (!isValid) {
                setStepsErrorData(updatedStepsErrorData);
                setErrorAlert({ message: 'All required fields should be filled.' });
                return;
            }

            setIsLoading(true);

            await tryReconnectIfWebsocketClosed();

            // mark the last step as a completed
            setCompletedStepOrders((prevData) =>
                addUniqueElementToArray(currentStepData.order, prevData)
            );

            const requestBody = await getRequestBody();

            const { data } = await client.post(
                `${API.ROUTES.template.processTemplateVersion}${versionId}/deploy/`,
                requestBody
            );
            setIsLoading(false);
            setSubmissionStatus('loading');
            if (data.status === 'started' && data.request_uuid) {
                addRequestUuid(data.request_uuid);
            }
        } catch (e) {
            setSubmissionStatus('default');
            setIsLoading(false);

            if (e.message === wsFailReconnectErrorMessage) {
                setErrorAlert({
                    message: 'Oops! Something went wrong. Please try submitting again.',
                });
                return;
            }

            clearRequestUuid();

            handleStepsFormErrors({
                e,
                setErrorBannerMessage,
                setStepsErrorData,
                setErrorAlert,
                formData,
                setFormData,
                settings,
            });
        }
    };

    const shouldRestrictNavigation = isLoading || submissionStatus === 'loading';

    return (
        <>
            {currentStepData && (
                <>
                    <StepsPanel
                        settings={settings}
                        stepsErrorData={stepsErrorData}
                        currentStepOrder={currentStepOrder}
                        setCurrentStepOrder={setCurrentStepOrder}
                        completedStepOrders={completedStepOrders}
                        submissionStatus={submissionStatus}
                        isMobileStepsPanelOpened={isMobileStepsPanelOpened}
                        onClose={closeMobileStepsPanel}
                        pageAction={pageAction}
                    />

                    <div className="w-full lg:w-[67%] l:w-[70%] xl:w-[75%] h-[calc(100%-73px)] lg:h-full overflow-y-auto px-4 pt-4 pb-[40px] sm:px-8 sm:pt-8 lg:px-6 lg-pt-6 xl:px-8 xl:pt-8">
                        {errorBannerMessage && (
                            <div className="mb-5">
                                <ErrorBanner errorMessage={errorBannerMessage} />
                            </div>
                        )}

                        {submissionStatus === 'default' && (
                            <SettingsStepForm
                                step={currentStepData}
                                stepFormData={formData[currentStepOrder]}
                                setStepFormData={getSetStepFormDataFunction(
                                    currentStepOrder,
                                    setFormData
                                )}
                                currentStepErrorData={stepsErrorData[currentStepData.order]}
                                setStepsErrorData={setStepsErrorData}
                                setCompletedStepOrders={setCompletedStepOrders}
                                isLastStep={currentStepOrder === settings.length - 1}
                                setCurrentStepOrder={setCurrentStepOrder}
                                submitProcessSetup={submitProcessSetup}
                                isLoading={isLoading}
                                pageAction={pageAction}
                            />
                        )}

                        {submissionStatus === 'success' && (
                            <SubmissionSuccessState
                                pageAction={pageAction}
                                processTemplateName={processTemplateName}
                                processId={newProcessId}
                            />
                        )}

                        {submissionStatus === 'loading' && (
                            <SubmissionLoader text="We’re working on it!" />
                        )}
                    </div>
                    {submissionStatus === 'default' && (
                        <MobileBottomControls
                            pageAction={pageAction}
                            instructions={currentStepData.instructions}
                            openMobileStepsPanel={() => setIsMobileStepsPanelOpened(true)}
                        />
                    )}

                    <NavigationGuard
                        when={shouldRestrictNavigation}
                        promptMessage="Are you sure you want to leave? We’ll keep loading your process in the background."
                        customNavigationBlockerMessageSegment="We’ll keep loading your process in the background."
                    />
                </>
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={errorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default ProcessSetupOrUpgradeIndex;
