import React, { useEffect } from 'react';
import classNames from 'classnames';

import { useLexicalNodeRemove } from '@mdxeditor/editor';
import { useRichTextWithDocsMappingContext } from '../../../hooks/useRichTextWithDocsMappingContext';

import { VARIABLE_NAME_PROP } from '../../../constants/richTextEditorWithDocsMapping';

import { CloseLineIcon } from '../../Icons';
import LoaderIcon from '../../Icons/LoaderIcon';
import FileTextLineIcon from '../../Icons/FileTextLineIcon';

const DocumentTag = (props) => {
    const remove = useLexicalNodeRemove();

    const variableName =
        props.mdastNode.attributes?.find((prop) => prop.name === VARIABLE_NAME_PROP)?.value || null;

    const {
        docsMapping = {},
        openSelectDocumentModal = () => {},
        triggerDocMappingCheck = () => {},
        isDisabled = false,
    } = useRichTextWithDocsMappingContext() || {};

    const isFilled = !!docsMapping?.[variableName];
    const isLabelLoading = isFilled && !docsMapping?.[variableName]?.label;

    const tagClassName = classNames(
        'inline-flex items-center align-middle gap-2 max-w-full px-2 py-1 rounded-2 bg-purple-100 my-0.5',
        {
            'text-purple-500': isFilled,
            'text-neutral-300': !isFilled,
            'cursor-pointer': !isDisabled && !isLabelLoading,
        }
    );

    const handleTagClick = (e) => {
        e?.stopPropagation();
        if (isDisabled || isLabelLoading) return;

        openSelectDocumentModal({ variableName });
    };

    const handleRemoveClick = (e) => {
        e?.stopPropagation();
        if (isDisabled) return;

        remove();
    };

    useEffect(() => {
        triggerDocMappingCheck();

        return () => {
            triggerDocMappingCheck();
        };
    }, []);

    const textToDisplay = isFilled ? docsMapping[variableName]?.label : variableName;

    const displayedContent = isLabelLoading ? (
        <span className="inline-flex animate-icon-spin">
            <LoaderIcon width={16} height={16} color="#000000" />
        </span>
    ) : (
        <span className="!font-body-medium !text-current !text-body-medium-xs truncate !m-0">
            {textToDisplay}
        </span>
    );

    return (
        <span className={tagClassName} onClick={handleTagClick}>
            <FileTextLineIcon color="currentColor" width={16} height={16} />

            {displayedContent}

            {!isDisabled && (
                <button onClick={handleRemoveClick}>
                    <CloseLineIcon color="#5E6470" width={16} height={16} />
                </button>
            )}
        </span>
    );
};

export default DocumentTag;

// The way to update props:

// const update = useMdastNodeUpdater();

// const updatedData = {
//         ...props.mdastNode,
//         attributes: props.mdastNode.attributes.map((prop) =>
//             prop.name === 'variableName'
//                 ? {
//                       ...prop,
//                       value: linkedDocs,
//                   }
//                 : prop
//         ),
//     };
//     update(updatedData);
