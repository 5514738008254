import React from 'react';

const FileTextLinkedIcon = ({ width, height, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 11.4995V8L14.9968 2H4.00221C3.4487 2 3 2.45531 3 2.9918V21.0082C3 21.556 3.44495 22 3.9934 22H10.4995C10.0467 21.3973 9.68272 20.7242 9.42676 20H5V4H14V9H19V10.4268C19.7242 10.6827 20.3973 11.0467 21 11.4995ZM12.7558 11H8V13H10.4995C11.1073 12.1907 11.8751 11.5084 12.7558 11ZM9.42676 15H8V17H9.0164C9.06228 16.303 9.20336 15.6321 9.42676 15ZM11 7H8V9H11V7Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.6412 14H13.7617V16.1329H17.0002L13 20.1331L14.5082 21.6412L18.5084 17.6411V20.8796H20.6412V14Z"
                fill={color}
            />
        </svg>
    );
};

export default FileTextLinkedIcon;
