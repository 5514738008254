import { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

import { DEFAULT_CONFIG } from './config';

import { generateUUID } from '../../helpers/generateUUID';
import { isDiagramSyntaxValid } from '../../helpers/diagramsUtils';

export const useInitMermaid = ({ code, setIsError, handlePanZoom }) => {
    const ref = useRef(null);
    const id = useRef(`mermaid-diagram-${generateUUID()}`);

    useEffect(() => {
        mermaid.initialize(DEFAULT_CONFIG);
        mermaid.contentLoaded();
    }, []);

    useEffect(() => {
        const drawDiagram = async () => {
            if (!ref.current) return;

            setIsError && setIsError(false);

            mermaid.initialize({ ...DEFAULT_CONFIG, startOnLoad: false });

            if (!code) return;

            try {
                const isCodeValid = await isDiagramSyntaxValid(code);
                if (!isCodeValid) {
                    throw new Error('Invalid Mermaid syntax');
                }

                const { svg, bindFunctions } = await mermaid.render(id.current, code);
                ref.current.innerHTML = svg;
                bindFunctions && bindFunctions();

                handlePanZoom && handlePanZoom(ref);
            } catch (error) {
                setIsError && setIsError(true);
            }
        };

        drawDiagram();
    }, [code]);

    return ref;
};
