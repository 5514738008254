import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import ScrollToBottom from 'react-scroll-to-bottom';

import { useUserChatMessage } from '../../../hooks/useUserChatMessage';
import { useAssistantMessageStream } from '../../../hooks/useAssistantMessageStream';
import { useAssistantChatThreadContext } from '../../../hooks/useAssistantChatThreadContext';

import { appendModelAndToolsToRequestBody } from '../../../helpers/assistantUtils';

import { CHAT_ROLE, CHAT_TYPE, HISTORY_TAB, TOOL_TYPE } from '../../../constants/assistant';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import ChatContent from '../ChatContent/ChatContent';
import ChatInputBox from '../ChatInputBox/ChatInputBox';
import ChatInvitingView from '../ChatInvitingView/ChatInvitingView';

const MainChatArea = ({ setChatThreadsHistoryList, preselectedDocsData }) => {
    const {
        chatThreadData,
        setChatThreadData,
        chatType,
        selectedAiModel,
        selectedCustomBot,
        setCustomBotAsModel,
    } = useAssistantChatThreadContext();

    const navigate = useNavigate();

    const [messageStreamState, setMessageStreamState] = useState({
        isAssistantLoading: false,
        isStreamRunning: false,
    });

    // keeps data in format {selected_tool_type: {details: {}}}
    // if there are a few selected tools so the object has a few properties
    const [selectedTools, setSelectedTools] = useState(() => {
        if (preselectedDocsData) {
            return { [TOOL_TYPE.add_documents]: { details: preselectedDocsData } };
        }
        return {};
    });

    const skipRefreshDataWhileIdIsChanged = useRef(true);

    useEffect(() => {
        if (!skipRefreshDataWhileIdIsChanged.current) {
            refreshData();
        }

        if (skipRefreshDataWhileIdIsChanged.current) {
            skipRefreshDataWhileIdIsChanged.current = false;
        }
    }, [chatThreadData?.id]);

    const setChatMessages = useCallback(
        (cb) => {
            setChatThreadData((prevData) =>
                !prevData
                    ? null
                    : {
                          ...prevData,
                          chat_messages: cb(prevData.chat_messages || []),
                      }
            );
        },
        [setChatThreadData]
    );

    const modelSettings = {
        type: chatType,
        model: selectedAiModel,
        custom_bot_version:
            chatType === CHAT_TYPE.custom ? { custom_bot: selectedCustomBot } : null,
    };
    const {
        startListeningWebSockets,
        stopWebSocketStream,
        tryReconnectIfWebsocketClosed,
        clearMessageLoadingStreamState,
        resetAssistantMessageResponse,
        cancelStream,
        isStreamCanceled,
        emptyCanceledAssistantMessage,
        setEmptyCanceledAssistantMessage,
    } = useAssistantMessageStream({
        setChatMessages,
        messageStreamState,
        setMessageStreamState,
        modelSettings,
    });

    const handleMessageSubmitResponse = useCallback(
        ({ data, messagesToAppend, isNewChat }) => {
            if (!isNewChat) {
                setChatThreadData((prevData) => ({
                    ...prevData,
                    chat_messages: [...prevData.chat_messages, ...messagesToAppend],
                }));
            }

            if (isNewChat) {
                const type = data.assistant_message.default_version.type || CHAT_TYPE.generic;
                const customBot =
                    data.assistant_message?.default_version?.custom_bot_version?.custom_bot;

                const chatThreadData = {
                    ...data.chat_thread,
                    chat_messages: [...messagesToAppend],
                    type,
                    custom_bot: customBot,
                };
                setChatThreadData(chatThreadData);

                skipRefreshDataWhileIdIsChanged.current = true;

                setChatThreadsHistoryList((chatThreads) => [data.chat_thread, ...chatThreads]);

                const chatThreadId = data.chat_thread.id;

                let url = `/assistant/chat/${chatThreadId}?tab=${HISTORY_TAB.chats}`;
                if (type === CHAT_TYPE.custom && customBot?.id) {
                    url += `&bot=${customBot?.id}`;
                    setCustomBotAsModel({ id: customBot.id, name: customBot.name });
                }

                navigate(url);
            }
        },
        [setChatThreadData, setChatThreadsHistoryList]
    );

    const getSubmittingMessageRequestBody = ({ message, isNewChat }) => {
        const requestBody = { content: message, origin: 'assistant' };
        if (!isNewChat) {
            requestBody.chat_thread = chatThreadData.id;
        }
        if (isNewChat && chatThreadData?.name) {
            requestBody.name = chatThreadData.name;
        }

        return appendModelAndToolsToRequestBody({
            requestBody,
            chatType,
            selectedAiModel,
            selectedCustomBot,
            selectedTools,
        });
    };

    const getRegenerateMessageRequestBody = useCallback(
        ({ role, message }) => {
            const requestBody = appendModelAndToolsToRequestBody({
                requestBody: { regenerate: true, origin: 'assistant' },
                chatType,
                selectedAiModel,
                selectedCustomBot,
                selectedTools,
            });
            if (role === CHAT_ROLE.user) {
                requestBody.content = message;
            }
            return requestBody;
        },
        [chatType, selectedAiModel, selectedCustomBot, selectedTools]
    );

    const {
        submitChatMessage,
        handleRegenerateChatMessage,
        displayedChatMessages,
        setSubmittedUserMessage,
        messageSubmissionErrorData,
        setMessageSubmissionErrorData,
        controller,
        errorAlert,
        setErrorAlert,
        scrollPosition,
        setScrollPosition,
        containerRef,
    } = useUserChatMessage({
        chatMessages: chatThreadData?.chat_messages,
        setChatMessages,
        setMessageStreamState,
        isNewChat: !chatThreadData?.id,
        handleMessageSubmitResponse,
        tryReconnectIfWebsocketClosed,
        startListeningWebSockets,
        getSubmittingMessageRequestBody,
        getRegenerateMessageRequestBody,
        emptyCanceledAssistantMessage,
        setEmptyCanceledAssistantMessage,
        isStreamCanceled,
    });

    const refreshData = () => {
        stopWebSocketStream();
        controller.current?.abort();

        isStreamCanceled.current = false;
        setEmptyCanceledAssistantMessage(null);

        setSubmittedUserMessage(null);
        resetAssistantMessageResponse();
        clearMessageLoadingStreamState();
        setSelectedTools({});
    };

    useEffect(() => {
        return () => {
            controller.current?.abort();
        };
    }, []);

    const chatMessagesExist = !!displayedChatMessages.length;

    const containerClassName = classNames(
        'w-full max-w-[1010px] px-4 xs:px-5 mx-auto min-h-full pb-6',
        !chatMessagesExist && 'h-full'
    );

    const isAssistantMessageLoadingOrStreaming =
        messageStreamState.isAssistantLoading || messageStreamState.isStreamRunning;

    const isRegenerateMessageDisabled =
        isAssistantMessageLoadingOrStreaming || !!isStreamCanceled.current;

    return (
        <div className="h-[calc(100%-122px)] md:h-[calc(100%-62px)] w-full flex flex-col">
            <div className="flex-1 overflow-hidden" ref={containerRef}>
                <ScrollToBottom
                    className="h-full"
                    followButtonClassName="hidden"
                    initialScrollBehavior="auto"
                    scrollViewClassName="overflow-x-hidden"
                >
                    <div className="h-full pt-4 xs:pt-5">
                        <div className={containerClassName}>
                            {chatMessagesExist && (
                                <ChatContent
                                    chatMessages={displayedChatMessages}
                                    setChatMessages={setChatMessages}
                                    chatType={chatType}
                                    isRegenerateMessageDisabled={isRegenerateMessageDisabled}
                                    handleRegenerateChatMessage={handleRegenerateChatMessage}
                                    messageStreamState={messageStreamState}
                                    scrollPosition={scrollPosition}
                                    setScrollPosition={setScrollPosition}
                                    containerRef={containerRef}
                                />
                            )}

                            {!chatMessagesExist && (
                                <ChatInvitingView
                                    chatType={chatType}
                                    selectedCustomBot={selectedCustomBot}
                                />
                            )}
                        </div>
                    </div>
                </ScrollToBottom>
            </div>

            <ChatInputBox
                submitChatMessage={submitChatMessage}
                selectedTools={selectedTools}
                setSelectedTools={setSelectedTools}
                isAssistantMessageLoadingOrStreaming={isAssistantMessageLoadingOrStreaming}
                handleCancelStream={cancelStream}
                isCancelStreamingLoading={!!isStreamCanceled.current}
                messageSubmissionErrorData={messageSubmissionErrorData}
                setMessageSubmissionErrorData={setMessageSubmissionErrorData}
            />

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default memo(MainChatArea);
