import React, { useState } from 'react';

import { API } from 'constants';
import operateClient from '../../../../services/operate-api';
import {
    checkIsFormDataValid,
    formatInputsToFormData,
    handleClientStartNewJobRequestErrors,
    replaceFilesWithCDNUrls,
} from '../../../../helpers/startNewJobFormUtils';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import Button from '../../../../design-system/Button/Button';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import NewJobForm from '../../../../components/NewJobForm/NewJobForm';
import PlayCircleFillIcon from '../../../../design-system/Icons/PlayCircleFillIcon';

const StartNewProjectFormBlock = ({
    scenarioData,
    formData,
    setFormData,
    startNewProjectState,
    setStartNewProjectState,
    setNewProjectId,
}) => {
    const [errorAlert, setErrorAlert] = useState(null);

    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 768 });

    const handleStartNewProject = async () => {
        try {
            const isFormDataValid = checkIsFormDataValid(formData, setFormData);
            if (!isFormDataValid) {
                return;
            }

            setNewProjectId(null);
            setStartNewProjectState('loading');

            const formDataWithCDNUrls = await replaceFilesWithCDNUrls(formData);
            const inputs = formatInputsToFormData(formDataWithCDNUrls);
            const scenario_version = scenarioData.default_version.id;
            const requestBody = { scenario_version, auto: true, inputs };

            const { data } = await operateClient.post(API.ROUTES.operate.execution, requestBody);
            setNewProjectId(data.id);

            setStartNewProjectState('success');
        } catch (error) {
            setStartNewProjectState('default');
            handleClientStartNewJobRequestErrors({ error, setErrorAlert, formData, setFormData });
        }
    };

    return (
        <div className="p-5 sm:p-8">
            <div className="mb-5">
                <h1 className="font-heading-bold text-heading-bold-l text-black mb-1">
                    {scenarioData.name}
                </h1>
                {/* <p className="font-body text-body-regular-s text-neutral-400">
                    {scenarioData.description}
                </p> */}
            </div>

            <div className="py-6 sm:py-8 px-5 sm:px-8 lg:px-[40px] w-full max-w-[800px] mx-auto rounded-2xl bg-white flex flex-col gap-6 md:gap-7">
                <div>
                    <p className="font-heading-bold text-heading-bold-m text-black mb-2">
                        Start New Project{' '}
                    </p>
                    <p className="font-body text-body-regular-s text-neutral-400">
                        Fill out the required inputs to start this project and automatically kick
                        off its connected agents.
                    </p>
                </div>

                <NewJobForm
                    formData={formData}
                    setFormData={setFormData}
                    containerGap={isMobile ? 24 : 28}
                />

                <div className="py-4">
                    <div className="pt-4 border-t-1 border-neutral-200 flex justify-end">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Start Auto-Run"
                            state={startNewProjectState === 'loading' ? 'loading' : 'default'}
                            trailingIcon={PlayCircleFillIcon}
                            onClick={handleStartNewProject}
                        />
                    </div>
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default StartNewProjectFormBlock;
