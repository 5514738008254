import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Badge, ButtonIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import ProcessTemplateCardMoreOptionsPopup from '../ProcessTemplateCardMoreOptionsPopup/ProcessTemplateCardMoreOptionsPopup';
import GitPullRequest from '../../../design-system/Icons/GitPullRequest';
import LayersIcon from '../../../design-system/Icons/LayersIcon';
import Tag from '../../../design-system/Tag/Tag';
import GlobeIcon from '../../../design-system/Icons/GlobeIcon';
import ProcessTemplateCardLabelsBlock from '../ProcessTemplateCardLabelsBlock/ProcessTemplateCardLabelsBlock';
import TimestampDisplay from '../../../design-system/TimestampDisplay/TimestampDisplay';
import HighlightedIcon from '../../../design-system/HighlightedIcon/HighlightedIcon';

const ProcessTemplateCard = ({ item, fetchAllLabelsHookResponse, requestKeysToMutate }) => {
    const {
        id,
        name,
        description,
        is_live,
        labels,
        current_version,
        updated_at,
        is_editing,
        is_published,
    } = item;
    const navigate = useNavigate();

    const [moreOptionsPopupOpened, setMoreOptionsPopupOpened] = useState(false);
    const [labelSelector, setLabelSelector] = useState({
        isOpened: false,
        width: 360,
        position: {},
    });

    const toggleMoreOptionsPopup = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setMoreOptionsPopupOpened(true);
    };

    const handleNavigateToProcessTemplateDetailPage = () => {
        if (moreOptionsPopupOpened || labelSelector.isOpened) {
            return;
        }
        navigate(`/templates/process/${id}`);
    };

    return (
        <div
            className="w-full h-full relative rounded-2 bg-white border-1 border-neutral-100 p-5 flex flex-col gap-4 justify-between self-stretch cursor-pointer"
            onClick={handleNavigateToProcessTemplateDetailPage}
        >
            <div className="absolute top-1.5 right-1">
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={More2FillIcon}
                    onClick={toggleMoreOptionsPopup}
                />
                {moreOptionsPopupOpened && (
                    <ProcessTemplateCardMoreOptionsPopup
                        processTemplateData={item}
                        onClose={() => setMoreOptionsPopupOpened(false)}
                    />
                )}
            </div>
            <div>
                <div className="flex items-center gap-2 mb-2 w-[calc(100%-6px)]">
                    <HighlightedIcon icon={LayersIcon} variant="neutral" />

                    <p className="font-body-bold text-body-bold-s text-black line-clamp-2">
                        {name}
                    </p>
                </div>
                <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2 ">
                    {description}
                </p>
            </div>

            <div className="flex flex-col gap-4">
                <div className="flex items-center gap-1 flex-wrap relative">
                    {is_live ? (
                        <Badge text={current_version} color="blue" />
                    ) : (
                        <Tag text="Draft" uppercase />
                    )}
                    <ProcessTemplateCardLabelsBlock
                        addedLabelsData={labels}
                        processTemplateId={id}
                        requestKeysToMutate={requestKeysToMutate}
                        labelSelector={labelSelector}
                        setLabelSelector={setLabelSelector}
                        fetchAllLabelsHookResponse={fetchAllLabelsHookResponse}
                    />
                </div>

                <div className="flex items-center justify-between gap-1">
                    <TimestampDisplay
                        label="Last Updated"
                        timestamp={updated_at}
                        dateTimeFormat="MM/dd/yyyy"
                        customLabelStyles="text-neutral-500"
                        withClockIcon
                    />

                    <div className="flex items-center gap-1">
                        <GlobeIcon
                            width={14}
                            height={14}
                            color={is_published ? '#754DCF' : '#CFD6E5'}
                        />
                        <GitPullRequest
                            width={14}
                            height={14}
                            color={is_editing ? '#754DCF' : '#CFD6E5'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcessTemplateCard;
