import React from 'react';
import { useNavigate } from 'react-router-dom';

import { formattedTabs } from '../HistorySidePanel/HistorySidePanel';
import { CHAT_TYPE } from '../../../constants/assistant';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { useAssistantChatThreadContext } from '../../../hooks/useAssistantChatThreadContext';

import ToolTip from '../../../design-system/ToolTip/ToolTip';
import EditBoxLineIcon from '../../../design-system/Icons/EditBoxLineIcon';
import ContractLeftLineIcon from '../../../design-system/Icons/ContractLeftLineIcon';
import { ButtonIcon, Tabs } from '../../../design-system';

const HistorySidePanelTopControls = ({
    collapsePanel,
    activeTab,
    activeTabIndex,
    handleTabChange,
}) => {
    const navigate = useNavigate();
    const { isDesktop } = useResponsiveBreakpoints();

    const { chatType, selectedCustomBot, setDefaultModel } = useAssistantChatThreadContext();

    const handleAction = (cb) => {
        cb();

        if (!isDesktop) {
            collapsePanel();
        }
    };

    const openNewChat = () => {
        const isCustomBot = chatType === CHAT_TYPE.custom && selectedCustomBot?.id;
        if (isCustomBot) {
            navigate(`/assistant?tab=${activeTab}&bot=${selectedCustomBot.id}`);
        } else {
            navigate(`/assistant?tab=${activeTab}`);
            setDefaultModel();
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
                <ToolTip
                    text="Collapse"
                    position="top-left"
                    useInternalHoverStateToShowToolTip
                    shift="4px"
                >
                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={ContractLeftLineIcon}
                        onClick={collapsePanel}
                    />
                </ToolTip>

                <ToolTip
                    text="New Chat"
                    position="top-right"
                    useInternalHoverStateToShowToolTip
                    shift="4px"
                >
                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={EditBoxLineIcon}
                        onClick={() => handleAction(openNewChat)}
                    />
                </ToolTip>
            </div>

            <Tabs tabs={formattedTabs} tabIndex={activeTabIndex} onTabChanged={handleTabChange} />
        </div>
    );
};

export default HistorySidePanelTopControls;
