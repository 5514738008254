import React from 'react';

import { SvgIcon } from '../../../design-system';
import { Robot2LineIcon } from '../../../design-system/Icons';
import EditBoxLineIcon from '../../../design-system/Icons/EditBoxLineIcon';

const ConnectedChatbotsSection = ({ chatbots, openNewChatWithBot }) => {
    if (!chatbots?.length) return <></>;

    return (
        <div className="w-full rounded-2 bg-neutral-100 flex flex-col gap-3 p-3">
            <p className="font-body-bold text-body-bold-s text-black">Connected Chatbots</p>
            <p className="font-body text-body-regular-s text-neutral-500">
                Explore all of the chatbots with access to this source
            </p>

            <ul className="flex flex-col gap-3">
                {chatbots.map((chatbot) => {
                    const { id, name, description } = chatbot;

                    return (
                        <li
                            key={id}
                            className="px-4 py-3 bg-white rounded-2 flex items-center gap-1 justify-between"
                        >
                            <div className="flex flex-col gap-0.5">
                                <div className="flex gap-2">
                                    <SvgIcon color="#1F2125" icon={Robot2LineIcon} size="medium" />
                                    <p className="font-body-bold text-body-bold-xs text-black">
                                        {name}
                                    </p>
                                </div>

                                <p className="font-body text-body-regular-xs text-neutral-500 line-clamp-1">
                                    {description}
                                </p>
                            </div>

                            <button
                                type="button"
                                className="block"
                                onClick={() => openNewChatWithBot({ id, name })}
                            >
                                <SvgIcon color="#1F2125" icon={EditBoxLineIcon} size="large" />
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ConnectedChatbotsSection;
