import React from 'react';

import Loading from '../../../components/Loading';
import ProcessCard from '../ProcessCard/ProcessCard';
import BlockWrapper from '../BlockWrapper/BlockWrapper';
import ContactUsBanner from '../../../design-system/ContactUsBanner/ContactUsBanner';
import { Button } from '../../../design-system';

const YourTopProcessesBlock = ({ processes, loading, loadNextProcessesPage, hasNextPage }) => {
    if (!processes?.length) {
        return (
            <div className="w-full flex justify-center bg-purple-100 rounded-2xl py-[40px] sm:py-[48px] px-6 sm:px-[40px]">
                <ContactUsBanner mainText="Set up your first Agent!" />
            </div>
        );
    }

    return (
        <BlockWrapper
            title="Your Top Agents"
            description="Explore the frequently utilized agents, click on ”view all” to access the complete list."
            viewAllNavPath="/agents"
        >
            <div className="grid grid-cols-1 min-[600px]:grid-cols-2 sm:grid-cols-1 gap-x-3 gap-y-4 min-[704px]:grid-cols-2 lg:grid-cols-1 l:grid-cols-2 min-[1870px]:grid-cols-3">
                {processes.map((process) => (
                    <ProcessCard key={process.id} process={process} />
                ))}
            </div>

            {hasNextPage && !loading && (
                <Button type="link" size="sm" text="View More" onClick={loadNextProcessesPage} />
            )}

            {loading && (
                <div className="min-h-[40px]">
                    <Loading withText={false} />
                </div>
            )}
        </BlockWrapper>
    );
};

export default YourTopProcessesBlock;
