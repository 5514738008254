import React, { useState } from 'react';

import LeftPanel from '../LeftPanel/LeftPanel';
import RightPanel from '../RightPanel/RightPanel';

const BotDetailMainContent = ({
    botDetail,
    setBotDetail,
    isFeedbackPanelOpened,
    toggleFeedbackPanel,
}) => {
    const [isInstructionEditing, setIsInstructionEditing] = useState(false);

    return (
        <div className="lg:flex-1 lg:overflow-hidden lg:min-h-[600px]">
            <div className="h-full max-h-full flex flex-col lg:flex-row">
                <LeftPanel
                    botDetail={botDetail}
                    setBotDetail={setBotDetail}
                    setIsInstructionEditing={setIsInstructionEditing}
                />

                <RightPanel
                    botDetail={botDetail}
                    isInstructionEditing={isInstructionEditing}
                    isFeedbackPanelOpened={isFeedbackPanelOpened}
                    toggleFeedbackPanel={toggleFeedbackPanel}
                />
            </div>
        </div>
    );
};

export default BotDetailMainContent;
