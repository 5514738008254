import React from 'react';

import { useManageSearchParams } from '../../../../../hooks/useManageSearchParams';
import { CHAT_SEARCH_PARAM_KEY } from '../BotPreviewPanel';

import { ButtonIcon } from '../../../../../design-system';
import EditBoxLineIcon from '../../../../../design-system/Icons/EditBoxLineIcon';

const ChatHeader = ({ isChatLoading = false }) => {
    const { deleteSearchParam } = useManageSearchParams();

    const openNewChat = () => deleteSearchParam(CHAT_SEARCH_PARAM_KEY);

    return (
        <div className="px-4 3xl:px-6 pb-4 pt-[18px] flex items-center justify-between gap-2">
            <ButtonIcon
                type="link"
                size="xs"
                icon={EditBoxLineIcon}
                state={isChatLoading ? 'disabled' : 'default'}
                onClick={openNewChat}
            />

            <p className="font-body-bold text-body-bold-m text-black">Bot Preview</p>

            <div className="w-[28px] min-w-[28px]"></div>
        </div>
    );
};

export default ChatHeader;
