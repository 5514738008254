import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Badge from 'design-system/Badge/Badge';
import SvgIcon from '../../design-system/SvgIcon/SvgIcon';
import LinkIcon from '../../design-system/Icons/LinkIcon';
import NewTextAreaBox from 'design-system/NewTextAreaBox/NewTextAreaBox';
import { ErrorWarningLineIcon } from '../../design-system/Icons';

function NewRunContainer(props, ref) {
    return (
        <div className="flex flex-col gap-4 rounded-2 flex-grow" ref={ref}>
            {props.label && <div className="font-bold font-inter uppercase">{props.label}</div>}
            {props.description && (
                <p className="font-body text-body-regular-s text-neutral-300">
                    {props.description}
                </p>
            )}
            {props.textAreaData &&
                props.textAreaData.map((item, index) => {
                    const label = props.useKeyAsALabel
                        ? `[${item.key}]`
                        : props.useDoubleBracketsKeyAsALabel
                        ? `[[${item.key}]]`
                        : item.label;
                    const info = {
                        text:
                            (item.mappedOutput && 'This is a linked variable in Chain settings.') ||
                            (item.isMissedInPrompt &&
                                props.showErrorInfo &&
                                `${label} not used in your prompt`),
                        state: item.isMissedInPrompt ? 'error' : 'default',
                    };
                    return (
                        <div className="flex flex-grow flex-col gap-[6px]" key={index}>
                            <NewTextAreaBox
                                id={item.id}
                                name={item.label}
                                value={item.value}
                                onChange={(e) => props.updateTextAreaData(e.target.value, index)}
                                label={label}
                                badge={
                                    item.mappedOutput && {
                                        text: `Step ${item.mappedOutput?.order} ${item.mappedOutput?.type}: ${item.mappedOutput?.label}`,
                                        color: 'blue',
                                        leadingIcon: LinkIcon,
                                        leadingIconColor: '#754DCF',
                                    }
                                }
                                info={info}
                                isRequired={item.isRequired || item.is_required || false}
                                placeholder={props.placeholder || 'Write here'}
                                state={item.state}
                                leadingIcon={props.leadingIcon}
                                errorMessage={item.errorMessage}
                                withCopyButton={props.withCopyButton}
                                withDeleteButton={props.withDeleteButton && !props.protectedMode}
                                withEditButton={
                                    (props.withEditButton || item.mappedOutput) &&
                                    !props.protectedMode
                                }
                                withLinkIcon={props.withLinkIcon}
                                onDeleteButtonClick={props.onDeleteButtonClick}
                                onEditButtonClick={props.onEditButtonClick}
                                onLinkIconClick={props.onLinkIconClick}
                                fullHeight={props.fullHeight}
                                simplified={props.simplified}
                                isLoadingId={props.isLoadingId}
                                scrollInsideTextArea={props.scrollInsideTextArea}
                                fixedMinHeight={props.fixedMinHeightOfTextArea}
                                outputRating={props.outputRating}
                                updateOutputRating={props.updateOutputRating}
                                resultId={props.resultId}
                                darkBg={props.darkBg}
                            />
                            {props.infoMessage && item.mappedOutput && (
                                <div className="flex items-center gap-1">
                                    <SvgIcon
                                        icon={ErrorWarningLineIcon}
                                        size="medium"
                                        color="#5E6470"
                                    />
                                    <p className="font-body text-body-regular-xs text-neutral-300">
                                        {props.infoMessage}
                                    </p>
                                </div>
                            )}
                        </div>
                    );
                })}
            {props.badgeAreaData && (
                <div className="flex flex-row space-x-2">
                    {props.badgeAreaData.map((item, index) => (
                        <div className="" key={index}>
                            <Badge text={item.text} color={item.color} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

const ForwardedRunContainer = forwardRef(NewRunContainer);

ForwardedRunContainer.propTypes = {
    state: PropTypes.oneOf(['closed', 'open']),
    label: PropTypes.string,
    description: PropTypes.string,
    textAreaData: PropTypes.array,
    badgeAreaData: PropTypes.array,
    updateTextAreaData: PropTypes.func,
    withCopyButton: PropTypes.bool,
    withDeleteButton: PropTypes.bool,
    withEditButton: PropTypes.bool,
    withLinkIcon: PropTypes.bool,
    onDeleteButtonClick: PropTypes.func,
    onEditButtonClick: PropTypes.func,
    onLinkIconClick: PropTypes.func,
    fullHeight: PropTypes.bool,
    simplified: PropTypes.bool,
    useKeyAsALabel: PropTypes.bool,
    infoMessage: PropTypes.string,
    isLoadingId: PropTypes.bool,
    showErrorInfo: PropTypes.bool,
    scrollInsideTextArea: PropTypes.bool,
    fixedMinHeightOfTextArea: PropTypes.number,
    leadingIcon: PropTypes.func,
    useDoubleBracketsKeyAsALabel: PropTypes.bool,
    protectedMode: PropTypes.bool, // delete and edit buttons are hidden in protected mode
    outputRating: PropTypes.number,
    updateOutputRating: PropTypes.func,
    resultId: PropTypes.string,
    darkBg: PropTypes.bool,
};

export default ForwardedRunContainer;
