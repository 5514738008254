import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function TabItem({
    name,
    shortcut,
    index,
    onClick,
    logAs,
    current,
    vertical,
    disabled,
}) {
    const containerClasses = classNames(
        'border rounded-lg border-neutral-500 inline-flex py-[7px] px-4 leading-[100%] justify-center items-center gap-2 flex-grow',
        current ? 'bg-white' : 'bg-neutral-500',
        vertical && 'self-stretch',
        disabled ? 'cursor-default' : 'cursor-pointer'
    );

    const nameClasses = classNames('font-body text-[14px] font-semibold transition-colors', {
        'text-neutral-500': current,
        'text-white': !current,
        'hover:text-neutral-300': !disabled,
    });
    const shortcutClasses = `text-neutral-300 font-body text-[14px] font-normal leading-5`;

    return (
        <div
            key={`${name}-${index}`}
            className={containerClasses}
            onClick={() => onClick && onClick(index)}
            {...(logAs ? { 'data-dd-action-name': logAs } : {})}
        >
            <p className={nameClasses}>{name}</p>
            {shortcut && <p className={shortcutClasses}>{shortcut}</p>}
        </div>
    );
}

TabItem.propTypes = {
    name: PropTypes.string.isRequired,
    shortcut: PropTypes.string,
    current: PropTypes.bool.isRequired,
    index: PropTypes.number,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    logAs: PropTypes.string,
};
