import React, { useCallback, useRef, useState } from 'react';
import AddDataSourcePopup from '../AddDataSourcePopup/AddDataSourcePopup';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';
import { getPopupTopPosition } from '../../../../helpers/getPopupTopPosition';
import { useOnScrollListener } from '../../../../hooks/useOnScrollListener';

const DataSourcesSectionHeader = ({
    sources,
    panelRef,
    scrollableAreaRef,
    setNewSourcePanel,
    googleCloudConnectionId,
    openAddExistingSourceModal,
}) => {
    const addButtonRef = useRef(null);

    const [popup, setPopup] = useState(null);
    const isPopupOpened = !!popup;

    const { isDesktop } = useResponsiveBreakpoints();

    const calculatePopupPosition = () => {
        const isPositionedDown = sources?.length > 1 || !isDesktop;

        const { top } = getPopupTopPosition({
            buttonRef: addButtonRef,
            panelRef,
        });

        if (isPositionedDown) {
            return { top };
        }

        if (!isPositionedDown) {
            return { top: Math.max(0, top - 150) };
        }
    };

    const openPopup = () => {
        const position = calculatePopupPosition();
        setPopup(position);
    };

    const onScroll = useCallback(() => {
        setPopup((prev) => {
            if (!prev) return prev;

            const position = calculatePopupPosition();
            return position;
        });
    }, [sources]);

    useOnScrollListener(onScroll, scrollableAreaRef.current);

    return (
        <div className="flex items-center justify-between gap-2.5">
            <div>
                <h2 className="font-heading-bold text-heading-bold-s text-black">Data Sources</h2>
                <p className="font-body text-body-regular-s text-neutral-300">
                    Connect this bot to available knowledge sources.
                </p>
            </div>

            <button
                onClick={openPopup}
                ref={addButtonRef}
                className="font-heading-bold text-[14px] text-neutral-400 underline focus:outline-none"
            >
                Add
            </button>

            {isPopupOpened && (
                <AddDataSourcePopup
                    panelRef={panelRef}
                    yPosition={{ top: popup.top }}
                    setNewSourcePanel={setNewSourcePanel}
                    isGoogleCloudConnected={!!googleCloudConnectionId}
                    openAddExistingSourceModal={openAddExistingSourceModal}
                    onClose={() => setPopup(null)}
                />
            )}
        </div>
    );
};

export default DataSourcesSectionHeader;
