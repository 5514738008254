import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import assistantClient from '../../../services/assistant-api';

import { HISTORY_TAB } from '../../../constants/assistant';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { useAssistantChatThreadContext } from '../../../hooks/useAssistantChatThreadContext';

import ConfirmDeletionModalPattern from '../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';

const DeleteHistoryItemConfirmationModal = ({ onClose, itemId, itemName, setItems }) => {
    const { chatThreadId: currentViewingChatThreadId } = useParams();

    const navigate = useNavigate();
    const { setDefaultModel } = useAssistantChatThreadContext();

    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('tab') || HISTORY_TAB.chats;

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleDelete = async () => {
        try {
            setIsLoading(true);

            await assistantClient.delete(`${API.ROUTES.assistant.chatThread}${itemId}/`);
            setItems((items) => items.filter((item) => item.id !== itemId));

            const isDeletedItemOpenedInPanel = currentViewingChatThreadId === itemId;
            if (isDeletedItemOpenedInPanel) {
                navigate(`/assistant?tab=${activeTab}`);
                setDefaultModel();
            }

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    return (
        <ConfirmDeletionModalPattern
            onClose={onClose}
            title="Confirm Archive Chat"
            confirmationTextStart="Are you sure you want to archive"
            confirmationTextEnd="chat?"
            entityName={itemName}
            handleDelete={handleDelete}
            isLoading={isLoading}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
            buttonsTheme="dark"
            customConfirmButtonText="Yes, Archive"
        />
    );
};

export default DeleteHistoryItemConfirmationModal;
