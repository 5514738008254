import React, { memo, useCallback } from 'react';

import { integerRegex } from '../../../constants/regex_patterns';
import { NEW_JOB_INPUT_ACTION_TYPE } from '../../../constants/startNewJobForm';

import { ButtonIcon, Input, Select, TextArea } from '../../../design-system';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import JsonTextareaBox from '../../../design-system/JsonTextareaBox/JsonTextareaBox';
import OptionsInput from '../../../design-system/OptionsInput/OptionsInput';
import Radio from '../../../design-system/Radio/Radio';
import Checkbox from '../../../design-system/Checkbox/Checkbox';
import MultiSelect from '../../../design-system/MultiSelect/MultiSelect';
import Toggle from '../../../design-system/Toggle/Toggle';
import CodeEditor from '../../../design-system/CodeEditor/CodeEditor';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import Trash2Icon from '../../../design-system/Icons/Trash2Icon';
import NewRichTextArea from '../../../design-system/NewRichTextArea/NewRichTextArea';
import AddToLibraryIcon from '../../../design-system/Icons/AddToLibraryIcon';
import MultiDatabaseInput from '../../../design-system/MultiDatabaseInput/MultiDatabaseInput';
import SingleDatabaseInput from '../../../design-system/SingleDatabaseInput/SingleDatabaseInput';
import SingleFileInputType from '../../SingleFileInputType/SingleFileInputType';
import RichTextAreaWithDocsMappingFormField from '../../RichTextAreaWithDocsMappingFormField/RichTextAreaWithDocsMappingFormField';

const NewJobFormField = ({
    input,
    setFormData,
    handleDuplicateInput,
    handleDeleteInput,
    handleEditInput,
    withActionButtons,
    allowDocsMapping = false,
    isFormDisabled = false,
}) => {
    const {
        key,
        label,
        description,
        type,
        action,
        is_required,
        options,
        value,
        state: _state,
        errorMessage,
        details,
        constraints,
    } = input;

    const state = isFormDisabled ? 'disabled' : _state;

    const formattedOptions =
        options &&
        Object.keys(options)?.map((item) => ({
            id: options[item],
            name: item,
        }));

    let infoTag = null;
    if (action === 'single-file' && (constraints?.max || constraints?.min)) {
        const min = constraints?.min ? `Min: ${constraints.min} MB` : '';
        const max = constraints?.max ? `Max: ${constraints.max} MB` : '';
        infoTag = (
            <p className="font-body text-body-regular-xs text-neutral-300">
                {min}
                {!!min && !!max && ','} {max}
            </p>
        );
    }

    const handleInputChange = (value) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.key === key ? { ...item, value, state: 'default', errorMessage: null } : item
            )
        );
    };

    const handleIntegerInputChange = (event) => {
        const inputValue = event.target.value;

        if (integerRegex.test(inputValue)) {
            setFormData((prevData) =>
                prevData.map((item) =>
                    item.key === key ? { ...item, value: +inputValue, errorMessage: null } : item
                )
            );
        }
    };

    const handleAddNewOption = (newOption) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.key === key
                    ? {
                          ...item,
                          value: [...(item.value || []), newOption],
                          state: 'default',
                          errorMessage: null,
                      }
                    : item
            )
        );
    };

    const handleRemoveOption = (optionToRemove) => {
        if (isFormDisabled) {
            return;
        }

        setFormData((prevData) =>
            prevData.map((item) =>
                item.key === key
                    ? {
                          ...item,
                          value: item.value?.filter((option) => option !== optionToRemove),
                          state: 'default',
                          errorMessage: null,
                      }
                    : item
            )
        );
    };

    const handleRadioButtonChange = (optionId) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.key === key
                    ? { ...item, value: optionId, state: 'default', errorMessage: null }
                    : item
            )
        );
    };

    const handleMultiSelectChange = (optionId) => {
        setFormData((prevData) =>
            prevData.map((item) => {
                if (item.key === key) {
                    const newValue = item.value?.includes(optionId)
                        ? item.value.filter((el) => el !== optionId)
                        : [...(item.value || []), optionId];
                    return { ...item, value: newValue, state: 'default', errorMessage: null };
                } else {
                    return item;
                }
            })
        );
    };

    const handleFormItemChange = useCallback(
        (cb) => {
            setFormData((prevData) =>
                prevData.map((item) => {
                    if (item.key === key) {
                        const updatedItem = typeof cb === 'function' ? cb(item) : cb;
                        return { ...updatedItem, state: 'default', errorMessage: null };
                    }

                    return item;
                })
            );
        },
        [key, setFormData]
    );

    const defaultPlaceholder = `Enter ${label}`;

    if (action === NEW_JOB_INPUT_ACTION_TYPE.richtext_input && allowDocsMapping) {
        return (
            <RichTextAreaWithDocsMappingFormField
                formItem={input}
                isFormDisabled={isFormDisabled}
                handleFormItemChange={handleFormItemChange}
            />
        );
    }

    return (
        <>
            {action !== NEW_JOB_INPUT_ACTION_TYPE.toggle && (
                <FormFieldWrapper
                    label={label}
                    isRequired={is_required}
                    state={state}
                    errorMessage={action !== 'multi-db' && errorMessage}
                    tipText={action !== 'multi-db' && description}
                    withDuplicateButton={withActionButtons}
                    withEditButton={withActionButtons}
                    withDeleteButton={withActionButtons}
                    onDuplicateButtonClick={() => handleDuplicateInput(input)}
                    onDeleteButtonClick={() => handleDeleteInput(input)}
                    onEditButtonClick={() => handleEditInput(input)}
                    gap={0}
                    bottomSpace={4}
                    labelMarginBottom={withActionButtons ? 0 : 7}
                    actionsBarLeftContent={infoTag}
                >
                    {action === NEW_JOB_INPUT_ACTION_TYPE.single_input && type === 'string' && (
                        <Input
                            size="sm"
                            name={key}
                            value={value || ''}
                            placeholder={defaultPlaceholder}
                            state={state}
                            type="text"
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.single_input && type === 'integer' && (
                        <Input
                            size="sm"
                            name={key}
                            value={value}
                            placeholder="0"
                            state={state}
                            onChange={(e) => handleIntegerInputChange(e)}
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.single_input && type === 'number' && (
                        <Input
                            size="sm"
                            name={key}
                            value={value}
                            placeholder="0.1"
                            type="number"
                            step="0.1"
                            state={state}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.json_input && (
                        <JsonTextareaBox
                            mode="code"
                            value={value}
                            state={state}
                            containerMinHeight={200}
                            setValue={handleInputChange}
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.textarea_input && (
                        <TextArea
                            name={key}
                            value={value || ''}
                            placeholder={defaultPlaceholder}
                            state={state}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.richtext_input && !allowDocsMapping && (
                        <NewRichTextArea
                            name={key}
                            value={value || ''}
                            placeholder={defaultPlaceholder}
                            state={state}
                            setValue={handleInputChange}
                            autoExpand
                            minHeight={170}
                            darkBorderColor
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.multi_input && (
                        <OptionsInput
                            name={key}
                            options={value || []}
                            placeholder="Add your input and click + to add them"
                            state={state}
                            handleAddNewOption={handleAddNewOption}
                            handleRemoveOption={handleRemoveOption}
                            containerGap={16}
                            optionsBadgeColor="neutral"
                            optionsBadgeFontStyle="text-body-regular-xs"
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.single_select && (
                        <ul className="flex flex-col gap-1 pt-0.5">
                            {formattedOptions.map((option) => (
                                <li key={option.id} className="py-1 px-2">
                                    <Radio
                                        isSelected={option.id === value}
                                        name={option.id}
                                        label={option.name}
                                        state={state}
                                        onChange={() => handleRadioButtonChange(option.id)}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.multi_select && (
                        <ul className="flex flex-col gap-1 pt-0.5">
                            {formattedOptions.map((option) => (
                                <li key={option.id} className="py-1 px-2">
                                    <Checkbox
                                        name={option.id}
                                        label={option.name}
                                        isChecked={!!value?.includes(option.id)}
                                        state={state}
                                        onChange={() => handleMultiSelectChange(option.id)}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.single_dropdown && (
                        <Select
                            size="sm"
                            name={key}
                            value={value}
                            options={formattedOptions || []}
                            placeholder="Select an option"
                            state={state}
                            onChange={(optionId) => handleInputChange(optionId)}
                            includeClientSideFiltering
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.multi_dropdown && (
                        <MultiSelect
                            name={key}
                            addedOptions={value || []}
                            optionsForSelect={formattedOptions || []}
                            placeholder="Select an option and click + to add them"
                            state={state}
                            handleAddNewOption={handleAddNewOption}
                            handleRemoveOption={handleRemoveOption}
                            includeClientSideFiltering
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.single_db && (
                        <SingleDatabaseInput
                            valueObj={value}
                            details={details}
                            state={state}
                            placeholder="Select an option"
                            handleChange={handleFormItemChange}
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.multi_db && (
                        <MultiDatabaseInput
                            addedOptions={value || []}
                            details={details}
                            state={state}
                            tipText={description}
                            errorMessage={errorMessage}
                            handleChange={handleFormItemChange}
                            placeholder="Select an option and click + to add them"
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.single_file && (
                        <SingleFileInputType
                            value={value}
                            constraints={constraints}
                            state={state}
                            handleChange={handleFormItemChange}
                        />
                    )}
                    {action === NEW_JOB_INPUT_ACTION_TYPE.code_input && (
                        <CodeEditor
                            value={value}
                            setValue={handleInputChange}
                            state={state}
                            placeholder={defaultPlaceholder}
                            autoExpand={false}
                            minHeight={200}
                        />
                    )}
                </FormFieldWrapper>
            )}
            {action === NEW_JOB_INPUT_ACTION_TYPE.toggle && (
                <FormFieldWrapper state={state} errorMessage={errorMessage} tipText={description}>
                    <div className="flex justify-between gap-x-7 gap-y-2 flex-wrap">
                        <div className="flex items-center gap-x-7 gap-y-2 flex-wrap">
                            <div className="font-body text-body-bold-s text-neutral-500">
                                {label}
                                {is_required && (
                                    <sup className="text-red-500 leading-1 ml-0.5">*</sup>
                                )}
                            </div>
                            <Toggle
                                name={key}
                                isDisabled={state === 'disabled'}
                                isSelected={value === true}
                                size="sm"
                                onChange={handleInputChange}
                            />
                        </div>
                        {withActionButtons && (
                            <div className="flex items-center gap-1 sm:gap-2">
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state="default"
                                    icon={AddToLibraryIcon}
                                    onClick={() => handleDuplicateInput(input)}
                                />

                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state="default"
                                    icon={EditLineIcon}
                                    onClick={() => handleEditInput(input)}
                                />

                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state="default"
                                    icon={Trash2Icon}
                                    onClick={() => handleDeleteInput(input)}
                                />
                            </div>
                        )}
                    </div>
                </FormFieldWrapper>
            )}
        </>
    );
};

export default memo(NewJobFormField);
