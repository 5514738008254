import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { API } from 'constants';
import client from '../../services/knowledge-api';

import { useSelectDocModalFetch } from '../../hooks/useSelectDocModalFetch';
import { useRichTextWithDocsMappingContext } from '../../hooks/useRichTextWithDocsMappingContext';

import { RichTextWithDocsMappingProvider } from '../../contexts/richTextWithDocsMappingContext';

import { extractVariableNamesFromTags } from '../../helpers/richTextEditorWithDocsMappingUtils';
import {
    collapseAllFolders,
    expandFoldersWithSelectedDocs,
} from '../../helpers/selectDocModalUtils';

import { VARIABLE_NAME_PROP } from '../../constants/richTextEditorWithDocsMapping';
import { ROOT_FOLDER_INITIAL_DATA } from '../../constants/selectDocModal';

import NewRichTextArea from '../NewRichTextArea/NewRichTextArea';
import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';
import AddVariableModal from './AddVariableModal/AddVariableModal';
import SelectDocumentModal from './SelectDocumentModal/SelectDocumentModal';

const RichTextAreaWithDocsMapping = ({
    name,
    value,
    docsMapping: _docsMapping,
    onDocsMappingChange,
    onValueChange,
    label,
    isRequired = false,
    state = 'default',
    placeholder: _placeholder,
    errorMessage,
    tipText,
    autoExpand = false,
    minHeight = 170,
    actionsBarRightContent = null,
}) => {
    const docsMapping = _docsMapping || {};
    const placeholder = _placeholder || `Enter ${label} or add document`;

    const ref = useRef(null);
    const [isDocsLabelsLoading, setIsDocsLabelsLoading] = useState(false);

    const {
        setDocsMapping: setDocsMappingInContext = () => {},
        selectDocumentModal = { variableName: null },
        closeSelectDocumentModal = () => {},
        setIsDisabled: setIsDisabledInContext = () => {},
        docMappingCheckSignal = 0,
    } = useRichTextWithDocsMappingContext() || {};

    const [foldersDocumentsData, setFoldersDocumentsData] = useState(ROOT_FOLDER_INITIAL_DATA); // keeps data for Select Doc Modal
    const [isAddVariableModalOpened, setIsAddVariableModalOpened] = useState(false);
    const variablesMappingKeys = Object.keys(docsMapping);

    const isSelectDocumentModalOpened = !!selectDocumentModal?.variableName;

    useEffect(() => {
        if (!docMappingCheckSignal) return;

        onDocsMappingChange((prevMapping) => {
            // add variable names to doc mapping if it missed in docs_mapping
            // it can be the case if the tag is removed and after added back by CMD + Z click
            const variablesNames = extractVariableNamesFromTags(value);

            const newMapping = { ...variablesNames, ...prevMapping };
            const variablesMappingKeys = Object.keys(newMapping);

            // delete variable names from docs mapping if they removed from value
            variablesMappingKeys.forEach((key) => {
                const regex = new RegExp(
                    `<DocumentTag\\s+${VARIABLE_NAME_PROP}="${key}"\\s*/>`,
                    'g'
                );
                const isKeyMissedInValue = !value?.match(regex);

                if (isKeyMissedInValue) {
                    delete newMapping[key];
                }
            });

            return newMapping;
        });
    }, [docMappingCheckSignal]);

    const { docsOptionsHookResponse, isLoading } = useSelectDocModalFetch({
        foldersDocumentsData,
        setFoldersDocumentsData,
    });

    const stringifiedDocsMapping = JSON.stringify(docsMapping);

    useLayoutEffect(() => {
        setDocsMappingInContext(docsMapping);

        const areLabelsMissedInDocsMapping = Object.values(docsMapping).some(
            (doc) => doc && !doc?.label
        );

        if (areLabelsMissedInDocsMapping && !isDocsLabelsLoading) {
            const fetchDocsLabels = async () => {
                try {
                    setIsDocsLabelsLoading(true);
                    const docsIdsToFetch = Object.values(docsMapping)
                        .filter((doc) => doc && !doc?.label)
                        .map(({ id }) => id);
                    const uniqueIds = [...new Set(docsIdsToFetch)];

                    const responses = await Promise.allSettled(
                        uniqueIds.map((id) => client.get(API.ROUTES.knowledge.document + id + '/'))
                    );
                    const extractDocumentIdFromUrl = (url) =>
                        url.match(/\/document\/([a-f0-9-]+)\/?$/)?.[1] || null;

                    onDocsMappingChange((prevMapping) => {
                        const updatedMapping = Object.entries(prevMapping).map(
                            ([variableName, doc]) => {
                                if (!doc || doc?.label) return [variableName, doc];

                                const currentDocResponse = responses.find(({ value, reason }) => {
                                    const fulfilledResDocId = value?.data?.id;
                                    const rejectedResDocId =
                                        reason?.config?.url &&
                                        extractDocumentIdFromUrl(reason?.config?.url);
                                    return (
                                        fulfilledResDocId === doc.id || rejectedResDocId === doc.id
                                    );
                                });

                                if (!currentDocResponse) return [variableName, null];

                                const { status, value } = currentDocResponse;
                                if (status === 'fulfilled')
                                    return [variableName, { ...doc, label: value.data.label }];
                                if (status === 'rejected') return [variableName, null];

                                return [variableName, null];
                            }
                        );

                        return Object.fromEntries(updatedMapping);
                    });
                } catch (e) {
                } finally {
                    setIsDocsLabelsLoading(false);
                }
            };

            fetchDocsLabels();
        }
    }, [stringifiedDocsMapping]);

    useEffect(() => {
        if (state === 'disabled') {
            setIsDisabledInContext(true);
        } else setIsDisabledInContext(false);
    }, [state]);

    useEffect(() => {
        if (isSelectDocumentModalOpened) {
            const variableName = selectDocumentModal.variableName;
            const selectedDocId = docsMapping[variableName]?.id;
            const selectedDocsArray = selectedDocId ? [selectedDocId] : [];

            expandFoldersWithSelectedDocs({
                selectedDocs: selectedDocsArray,
                setFoldersDocumentsData,
            });
        }
    }, [isSelectDocumentModalOpened]);

    const handleSelectDoc = (variableKey, { id, label }) => {
        onDocsMappingChange((prevMapping) => ({ ...prevMapping, [variableKey]: { id, label } }));
    };

    const handleAddVariable = (key) => {
        const button = ref.current?.querySelector('.markdown-add-doc-tag-button');
        if (button) {
            button.setAttribute('data-variablename', key);
            button.click();
        }

        onDocsMappingChange((prevMapping) => ({ ...prevMapping, [key]: null }));
    };

    const handleOpenAddVariableModal = () => setIsAddVariableModalOpened(true);

    const handleCloseAddVariableModal = () => setIsAddVariableModalOpened(false);

    const handleCloseSelectDocumentModal = () => {
        closeSelectDocumentModal();
        collapseAllFolders({ setFoldersDocumentsData });
    };

    const withAddFileButton = state !== 'disabled';
    const labelMarginBottom = withAddFileButton || actionsBarRightContent ? 4 : 8;

    return (
        <div className="flex flex-col gap-1">
            <div ref={ref}>
                <FormFieldWrapper
                    label={label}
                    isRequired={isRequired}
                    state={state}
                    errorMessage={errorMessage}
                    tipText={tipText}
                    withAddFileButton={withAddFileButton}
                    onAddFileButtonClick={handleOpenAddVariableModal}
                    gap={0}
                    bottomSpace={4}
                    labelMarginBottom={labelMarginBottom}
                    actionsBarRightContent={actionsBarRightContent}
                >
                    <NewRichTextArea
                        name={name}
                        value={value || ''}
                        placeholder={placeholder}
                        state={state}
                        setValue={onValueChange}
                        autoExpand={autoExpand}
                        minHeight={minHeight}
                        darkBorderColor
                        withDocumentTags
                    />
                </FormFieldWrapper>
            </div>

            {isSelectDocumentModalOpened && (
                <SelectDocumentModal
                    selectedDocId={docsMapping[selectDocumentModal.variableName]?.id}
                    handleSelectDoc={(data) =>
                        handleSelectDoc(selectDocumentModal.variableName, data)
                    }
                    foldersDocumentsData={foldersDocumentsData}
                    setFoldersDocumentsData={setFoldersDocumentsData}
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    isRootDataLoading={isLoading}
                    onClose={handleCloseSelectDocumentModal}
                />
            )}

            {isAddVariableModalOpened && (
                <AddVariableModal
                    addVariable={handleAddVariable}
                    existedVariablesKey={variablesMappingKeys}
                    onClose={handleCloseAddVariableModal}
                />
            )}
        </div>
    );
};

const WrappedRichTextAreaWithDocsMapping = (props) => (
    <RichTextWithDocsMappingProvider>
        <RichTextAreaWithDocsMapping {...props} />
    </RichTextWithDocsMappingProvider>
);

export default memo(WrappedRichTextAreaWithDocsMapping);

WrappedRichTextAreaWithDocsMapping.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    docsMapping: PropTypes.object.isRequired,
    onDocsMappingChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    autoExpand: PropTypes.bool,
    minHeight: PropTypes.number,
    actionsBarRightContent: PropTypes.node,
};
