import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';

import './codeEditor.css';
import FormFieldHeader from '../FormFieldHeader/FormFieldHeader';
import FormFieldFooter from '../FormFieldFooter/FormFieldFooter';

// wrap the <div className="flex flex-col"></div>
const CodeEditor = ({
    value: _value,
    setValue,
    label,
    isRequired = false,
    withCopyButton = false,
    state,
    errorMessage,
    placeholder,
    autoExpand = false,
    tipText,
    minHeight = 120,
}) => {
    const value = _value || '';

    const initValue = useRef(value);
    const valueRef = useRef(value);

    const editorRef = useRef(null);

    useLayoutEffect(() => {
        if (valueRef.current !== value) {
            if (editorRef.current?.view) {
                editorRef.current.view.dispatch({
                    changes: {
                        from: 0,
                        to: editorRef.current.view.state.doc.length,
                        insert: value,
                    },
                });
            }
            valueRef.current = value;
        }
    }, [value]);

    const containerClassName = classNames('flex flex-col gap-1', {
        'flex-grow': !autoExpand,
    });

    const codeClassName = classNames('code-editor', 'border-1 rounded-2 overflow-hidden', {
        'border-neutral-200': state !== 'error',
        'border-red-500': state === 'error',
        disabled: state === 'disabled',
        'flex-grow overflow-y-auto': !autoExpand,
    });

    const style = {
        minHeight: `${minHeight}px`,
        '--gutter-min-height': autoExpand ? `${minHeight}px` : 'auto',
    };
    if (!autoExpand) {
        style.height = `${minHeight}px`;
    }

    return (
        <div className={containerClassName}>
            <FormFieldHeader
                label={label}
                isRequired={isRequired}
                value={value}
                withCopyButton={withCopyButton}
            />

            <CodeMirror
                ref={editorRef}
                value={initValue.current}
                extensions={[python()]}
                theme="light"
                onChange={(value) => {
                    valueRef.current = value;
                    setValue(value);
                }}
                placeholder={placeholder}
                readOnly={state === 'disabled'}
                editable={state !== 'disabled'}
                className={codeClassName}
                height="100%"
                style={style}
            />

            <FormFieldFooter state={state} errorMessage={errorMessage} tipText={tipText} />
        </div>
    );
};

CodeEditor.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    withCopyButton: PropTypes.bool,
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    autoExpand: PropTypes.bool,
    tipText: PropTypes.string,
    minHeight: PropTypes.number,
};

export default CodeEditor;
