import { defaultErrorMessage } from '../constants/errorMessages';

import CheckLineIcon from '../design-system/Icons/CheckLineIcon';
import ErrorWarningLineIcon from '../design-system/Icons/ErrorWarningLineIcon';

export const handleCopyFormattedText = async ({
    node,
    setCopyAlert = () => {},
    successMessage = 'Copied to clipboard!',
}) => {
    if (!node) return;

    try {
        const clonedNode = node.cloneNode(true);

        // Remove Code Block gutters from New Rich Text Editor
        clonedNode.querySelectorAll('.mdxeditor-rich-text-editor .cm-gutters').forEach((el) => {
            el.remove();
        });

        // Remove background color from all child elements
        clonedNode.querySelectorAll('*').forEach((el) => {
            el.style.backgroundColor = 'transparent';
        });

        const htmlContent = clonedNode.innerHTML.replace(/\n\s*/g, '').trim();
        const textContent = clonedNode.textContent.replace(/\n{2,}/g, '\n').trim();

        await navigator.clipboard.write([
            new ClipboardItem({
                'text/html': new Blob([htmlContent], { type: 'text/html' }),
                'text/plain': new Blob([textContent], { type: 'text/plain' }),
            }),
        ]);

        setCopyAlert({
            status: 'positive',
            message: successMessage,
            icon: CheckLineIcon,
        });
    } catch (err) {
        setCopyAlert({
            status: 'critical',
            message: defaultErrorMessage,
            icon: ErrorWarningLineIcon,
        });
    }
};
