import { useEffect, useRef } from 'react';

export const useOnScrollListener = (callback, target = window) => {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const onScroll = () => {
        callbackRef.current();
    };

    useEffect(() => {
        if (!target) return;

        target.addEventListener('scroll', onScroll);

        return () => {
            target.removeEventListener('scroll', onScroll);
        };
    }, [target]);
};
