import React, { useState } from 'react';
import classNames from 'classnames';

import { CHAT_TYPE, SELECTED_TOOL_PLACEHOLDER, TOOL_TYPE } from '../../../constants/assistant';

import { Button, ButtonIcon } from '../../../design-system';
import SelectDocBlock from '../SelectDocBlock/SelectDocBlock';
import ChatInputHeader from '../ChatInputHeader/ChatInputHeader';
import ChatInputWrapper from '../ChatInputWrapper/ChatInputWrapper';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import SelectPromptBlock from '../SelectPromptBlock/SelectPromptBlock';

const ChatInputGenericView = ({
    chatType,
    selectedCustomBot,
    message,
    handleMessageChange,
    textareaRef,
    textareaContainerRef,
    commonButtonsGroup,
    handleFocusTextarea,
    handleSendMessageOnEnterPress,
    isMobile,
    isExpandedMode,
    setIsExpandedMode,
    handleAppendPromptToMessage,
    chatInputErrorMessage,
    selectedTools,
    setSelectedTools,
    clearErrorMessage,
}) => {
    const [isSelectDocModalOpened, setIsSelectDocModalOpened] = useState(false);
    const [closeExpandedModeOnOutsideClick, setCloseExpandedModeOnOutsideClick] = useState(true);

    const textareaHeight = textareaRef.current?.clientHeight;

    // for now user can select just one tool
    const handleToggleToolSelect = (toolType) => {
        setSelectedTools((prevState) => {
            if (prevState[toolType]) {
                return {};
            }

            return { [toolType]: { details: {} } };
        });

        if (toolType === TOOL_TYPE.add_documents && !selectedTools[TOOL_TYPE.add_documents]) {
            openSelectDocModal();
        }

        if (toolType !== TOOL_TYPE.add_documents) {
            clearErrorMessage();
        }
    };

    function openSelectDocModal(e) {
        e?.stopPropagation();
        e?.preventDefault();

        setIsSelectDocModalOpened(true);
        setIsExpandedMode(false);
    }

    const getToolButtonType = (toolType) => (selectedTools[toolType] ? 'neutral' : 'link');

    const selectPromptBlockProps = {
        textareaHeight,
        handleAppendPromptToMessage,
        setCloseExpandedModeOnOutsideClick,
        isMobile,
    };

    const bottomButtonsGroup = (
        <div className="px-3 pb-2 pt-1 flex items-center justify-between gap-4">
            {!isMobile && (
                <div className="flex items-center gap-4">
                    <Button
                        type={getToolButtonType(TOOL_TYPE.add_documents)}
                        size="xs"
                        text="Add Docs"
                        leadingIcon={FileTextLineIcon}
                        onClick={() => handleToggleToolSelect(TOOL_TYPE.add_documents)}
                    />
                    <SelectPromptBlock {...selectPromptBlockProps} />
                </div>
            )}

            {isMobile && (
                <div className="flex items-center gap-4">
                    <ButtonIcon
                        type={getToolButtonType(TOOL_TYPE.add_documents)}
                        size="sm"
                        icon={FileTextLineIcon}
                        onClick={() => handleToggleToolSelect(TOOL_TYPE.add_documents)}
                    />
                    <SelectPromptBlock {...selectPromptBlockProps} isMobile />
                </div>
            )}

            {commonButtonsGroup}
        </div>
    );

    const selectedDocumentsData = selectedTools[TOOL_TYPE.add_documents]?.details;
    const areSelectedDocs = !!Object.keys(selectedDocumentsData || {})?.length;

    const defaultPlaceholder =
        chatType === CHAT_TYPE.custom && selectedCustomBot?.name
            ? `Start chatting with ${selectedCustomBot.name}, select docs, or start with a Prompt`
            : 'Start chatting with AI, select docs, or start with a Prompt';
    const selectedToolType = Object.keys(selectedTools || {})[0];
    const placeholder = SELECTED_TOOL_PLACEHOLDER[selectedToolType] || defaultPlaceholder;

    const chatInputContainerClassName = classNames(
        'flex flex-col rounded-2 shadow-inset-1 relative z-20 bg-white transition-colors',
        {
            'shadow-neutral-200': !chatInputErrorMessage,
            'shadow-red-500': chatInputErrorMessage,
        }
    );

    return (
        <ChatInputWrapper
            message={message}
            handleMessageChange={handleMessageChange}
            placeholder={placeholder}
            chatInputErrorMessage={chatInputErrorMessage}
            bottomButtonsGroup={bottomButtonsGroup}
            isExpandedMode={isExpandedMode}
            setIsExpandedMode={setIsExpandedMode}
            isMobile={isMobile}
            closeExpandedModeOnOutsideClick={closeExpandedModeOnOutsideClick}
        >
            {areSelectedDocs && (
                <ChatInputHeader
                    selectedDocumentsData={selectedDocumentsData}
                    isMobile={isMobile}
                    setSelectedTools={setSelectedTools}
                    openSelectDocModal={openSelectDocModal}
                    clearErrorMessage={clearErrorMessage}
                />
            )}

            <div className={chatInputContainerClassName}>
                <div className="cursor-text px-3 py-2.5" onClick={handleFocusTextarea}>
                    <div ref={textareaContainerRef}>
                        <textarea
                            ref={textareaRef}
                            value={message}
                            placeholder={placeholder}
                            rows={1}
                            onChange={handleMessageChange}
                            onKeyDown={handleSendMessageOnEnterPress}
                            className="max-w-full w-full focus:outline-0 resize-none bg-transparent placeholder-neutral-300 font-body text-body-regular-s text-neutral-500"
                        />
                    </div>
                </div>

                {bottomButtonsGroup}

                <SelectDocBlock
                    selectedDocs={selectedDocumentsData || {}}
                    setSelectedTools={setSelectedTools}
                    clearErrorMessage={clearErrorMessage}
                    isModalOpened={isSelectDocModalOpened}
                    setIsModalOpened={setIsSelectDocModalOpened}
                    textareaHeight={textareaHeight}
                />
            </div>
        </ChatInputWrapper>
    );
};

export default ChatInputGenericView;
