import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../Alert/Alert';
import CheckLineIcon from '../Icons/CheckLineIcon';

const SuccessAlert = ({ successAlert, setSuccessAlert, autoCloseInMS = 3000 }) => {
    return (
        <>
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    autoCloseInMS={autoCloseInMS}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </>
    );
};

SuccessAlert.propTypes = {
    successAlert: PropTypes.oneOfType([
        PropTypes.shape({
            message: PropTypes.string,
        }),
        PropTypes.oneOf([null]),
    ]),
    setSuccessAlert: PropTypes.func.isRequired,
    autoCloseInMS: PropTypes.number,
};

export default SuccessAlert;
