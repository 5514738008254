import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useHandlePageDataLoadError } from '../../../hooks/useHandlePageDataLoadError';

import { baseGoalConfig } from '../../../constants/goal';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { addUniqueElementToArray } from '../../../helpers/addUniqueElementToArray';

import Button from '../../../design-system/Button/Button';
import Alert from '../../../design-system/Alert/Alert';
import Loading from '../../../components/Loading';
import JsonTextareaBox from '../../../design-system/JsonTextareaBox/JsonTextareaBox';
import Banner from '../../../design-system/Banner/Banner';
import { Input } from '../../../design-system';
import { ArrowGoBackLineIcon, ErrorWarningLineIcon } from '../../../design-system/Icons';

const GoalDetailPage = () => {
    const { processId, goalId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const processName = location.state?.processName || '';

    const mode = goalId ? 'edit' : 'create';

    const [inputsData, setInputsData] = useState({
        name: '',
        description: '',
        goal_config: baseGoalConfig,
    });
    const [errorInputMessages, setErrorInputMessages] = useState({
        name: null,
        description: null,
        goal_config: null,
    });
    const [editedFieldsArr, setEditedFieldsArr] = useState([]);

    const [goal, setGoal] = useState(null);
    const [isGoalLoading, setIsGoalLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const { handlePageDataLoadError } = useHandlePageDataLoadError();

    // if mode === "create" goal is always editable
    const isGoalEditable = mode === 'edit' ? !!goal?.is_editable : true;

    const title = {
        create: 'Create New Goal',
        edit: goal && `Edit ${goal.name} - ${goal.version}`,
    };

    useDocumentTitle(title[mode]);

    useEffect(() => {
        const fetchGoalData = async () => {
            try {
                setIsGoalLoading(true);
                const { data } = await client.get(`${API.ROUTES.library.workflow}${goalId}/`);
                setInputsData({
                    name: data.name,
                    description: data.description,
                    goal_config: data.goal_config.config,
                });
                setGoal(data);
                setIsGoalLoading(false);
            } catch (e) {
                setIsGoalLoading(false);
                handlePageDataLoadError({
                    e,
                    redirectPath: '/configure',
                    generalErrorHandler: () =>
                        navigate(`/configure/process/${processId}`, {
                            state: { ...(location.state || {}) },
                        }),
                });
            }
        };

        if (goalId) {
            fetchGoalData();
        }
    }, [goalId]);

    const handleInputChange = (e) => {
        setInputsData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
        if (errorInputMessages[e.target.name]) {
            setErrorInputMessages((prevState) => ({ ...prevState, [e.target.name]: null }));
        }
        if (!editedFieldsArr.includes(e.target.name)) {
            setEditedFieldsArr((prevArray) => [...prevArray, e.target.name]);
        }
    };

    const handleGoalConfigChange = (newValue) => {
        setInputsData((prevData) => ({ ...prevData, goal_config: newValue }));

        setErrorInputMessages((prevState) => ({ ...prevState, goal_config: null }));
        setEditedFieldsArr((prevArray) => addUniqueElementToArray('goal_config', prevArray));
    };

    const handleCreateGoal = async (parsedGoalConfig) => {
        const requestBody = {
            process: processId,
            ...inputsData,
            goal_config: parsedGoalConfig,
        };
        await client.post(API.ROUTES.library.workflow, requestBody);
    };

    const handleEditGoal = async (parsedGoalConfig) => {
        if (!editedFieldsArr.length) {
            return;
        }
        // in request body add just changed fields
        const requestBody = editedFieldsArr.reduce(
            (acc, field) => ({
                ...acc,
                [field]: field === 'goal_config' ? parsedGoalConfig : inputsData[field],
            }),
            {}
        );
        await client.patch(`${API.ROUTES.library.workflow}${goalId}/`, requestBody);

        setEditedFieldsArr([]);
    };

    const handleAction = {
        create: handleCreateGoal,
        edit: handleEditGoal,
    };

    const executeAction = async () => {
        const areEmptyFields =
            !inputsData.name || !inputsData.description || !inputsData.goal_config;
        if (areEmptyFields) {
            setErrorInputMessages({
                name: !inputsData.name ? 'Please fill in this field.' : null,
                description: !inputsData.description ? 'Please fill in this field.' : null,
                goal_config: !inputsData.goal_config ? 'Please fill in this field.' : null,
            });
            return;
        }

        let parsedGoalConfig = '';

        try {
            if (typeof inputsData.goal_config === 'string') {
                parsedGoalConfig = JSON.parse(inputsData.goal_config);
            } else {
                // if goal_config was not edited, its type is "object" so it should not be parsed
                parsedGoalConfig = inputsData.goal_config;
            }
        } catch (e) {
            setErrorInputMessages((prevState) => ({
                ...prevState,
                goal_config: 'Invalid JSON format. Please check your syntax.',
            }));
            return;
        }

        try {
            setIsLoading(true);

            await handleAction[mode](parsedGoalConfig);

            setIsLoading(false);
            navigate(`/configure/process/${processId}`, { state: { ...(location.state || {}) } });
        } catch (e) {
            const { data, status } = e.response;
            if (data?.error) {
                setErrorInputMessages((prevState) => ({
                    ...prevState,
                    goal_config: data.error,
                }));
            } else {
                setErrorAlert({ show: true, statusCode: status });
            }
            setIsLoading(false);
        }
    };

    const handleBackClick = () => {
        navigate(`/configure/process/${processId}`, {
            state: { ...(location.state || {}) },
        });
    };

    const uneditableBannerText = (
        <>
            This goal was deployed from the{' '}
            <span className="font-body-bold text-body-bold-s">{goal?.goal_template_name}</span>{' '}
            template and is uneditable.
        </>
    );

    return (
        <div className="bg-white w-full flex justify-center h-full px-5 pb-[40px] min-h-[calc(100vh-64px)] overflow-y-auto">
            {!isGoalLoading && (
                <>
                    {((mode === 'edit' && goal) || mode === 'create') && (
                        <div className="w-full max-w-[800px] py-5 flex flex-col flex-grow">
                            <div className="mb-5">
                                <Button
                                    type="link"
                                    size="sm"
                                    text={`Back ${processName && `to ${processName}`}`}
                                    leadingIcon={ArrowGoBackLineIcon}
                                    onClick={handleBackClick}
                                />
                            </div>
                            <div className="flex flex-col gap-8">
                                {!isGoalEditable && (
                                    <Banner
                                        title="Important Note"
                                        text={uneditableBannerText}
                                        color="blue"
                                    />
                                )}

                                <h1 className="font-heading-bold text-heading-bold-m text-neutral-500">
                                    {title[mode]}
                                </h1>
                                <div className="flex flex-col flex-grow gap-4">
                                    <Input
                                        size="md"
                                        name="name"
                                        value={inputsData.name}
                                        label="Goal Name"
                                        isRequired
                                        placeholder="Triage LinkedIn DM"
                                        onChange={handleInputChange}
                                        state={errorInputMessages.name ? 'error' : 'default'}
                                        errorMessage={errorInputMessages.name || ''}
                                    />
                                    <Input
                                        size="md"
                                        name="description"
                                        value={inputsData.description}
                                        label="Goal Description"
                                        isRequired
                                        placeholder="Take in a LinkedIn DM and triage it"
                                        onChange={handleInputChange}
                                        state={errorInputMessages.description ? 'error' : 'default'}
                                        errorMessage={errorInputMessages.description || ''}
                                    />
                                    <JsonTextareaBox
                                        value={inputsData.goal_config}
                                        setValue={handleGoalConfigChange}
                                        mode="code"
                                        label="Configuration"
                                        isRequired
                                        state={
                                            !isGoalEditable
                                                ? 'disabled'
                                                : errorInputMessages.goal_config
                                                ? 'error'
                                                : 'default'
                                        }
                                        errorMessage={errorInputMessages.goal_config || ''}
                                        withCopyButton
                                        withDownloadButton
                                        downloadFileName="configuration"
                                        parentGap={16}
                                        autoExpand
                                    />
                                </div>
                                <div>
                                    <Button
                                        type="primary"
                                        size="sm"
                                        text={`${mode === 'create' ? 'Create' : 'Update'} Goal`}
                                        state={isLoading ? 'loading' : 'default'}
                                        onClick={executeAction}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {isGoalLoading && (
                <div className="flex items-center justify-center">
                    <Loading />
                </div>
            )}
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </div>
    );
};

export default GoalDetailPage;
