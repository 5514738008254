import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import './mermaid.css';

import { exportDiagramAsPng } from '../../helpers/diagramsUtils';

import { useInitMermaid } from './useInitMermaid';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';

import { ErrorWarningLineIcon } from '../Icons';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import DownloadIcon from '../Icons/DownloadIcon';
import ExpandDiagonalLineIcon from '../Icons/ExpandDiagonalLineIcon';
import FullScreenDiagramModal from './FullScreenDiagramModal/FullScreenDiagramModal';

// should be wrapped with "flex flex-col"
const Diagram = ({ code, name }) => {
    const [isError, setIsError] = useState(false);

    const diagramRef = useInitMermaid({ code, setIsError });

    const [isHovered, setIsHovered] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [errorAlert, setErrorAlert] = useState(null);

    const { isMobile, isDesktop } = useResponsiveBreakpoints();

    if (!code)
        return (
            <p className="font-body-bold text-body-bold-m text-neutral-300 text-center py-3">
                No diagram to display
            </p>
        );

    if (isError) {
        const size = isMobile ? 32 : 40;

        return (
            <div className="max-w-full flex-grow border-1 border-neutral-200 rounded-2 p-4 md:p-5 flex items-center justify-center">
                <div className="flex gap-3 py-5 max-w-[260px]">
                    <div className="min-w-fit">
                        <ErrorWarningLineIcon width={size} height={size} color="#E95B69" />
                    </div>
                    <p className="font-body text-body-regular-m text-red-500">
                        There was an issue displaying your diagram
                    </p>
                </div>
            </div>
        );
    }

    const areActionsVisible = isHovered || !isDesktop;

    const actionsClassName = classNames(
        'absolute top-0 right-0 flex items-center gap-1 transition-all',
        {
            'opacity-100': areActionsVisible,
            'opacity-0': !areActionsVisible,
        },
    );

    return (
        <>
            <div
                className="relative max-w-full flex-grow border-1 border-neutral-200 rounded-2 p-4 md:p-5 flex items-center justify-center"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className={actionsClassName}>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={DownloadIcon}
                        onClick={() =>
                            exportDiagramAsPng({ diagramRef, diagramName: name, setErrorAlert })
                        }
                    />
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={ExpandDiagonalLineIcon}
                        onClick={() => setIsFullScreen(true)}
                    />
                </div>

                <pre
                    className="mermaid mermaid-regular-screen max-h-full flex-grow flex justify-center"
                    ref={diagramRef}
                >
                    {code}
                </pre>
            </div>

            {isFullScreen && (
                <FullScreenDiagramModal code={code} onClose={() => setIsFullScreen(false)} />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

Diagram.propTypes = {
    code: PropTypes.string,
    name: PropTypes.string,
};

export default Diagram;
