import React from 'react';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import Loading from '../../../components/Loading';
import RootFolderNavItem from '../RootFolderNavItem/RootFolderNavItem';
import DraggableFolderNavItem from '../DraggableFolderNavItem/DraggableFolderNavItem';

const FolderNavBar = () => {
    const { sidePanelFolders, expandedFolders } = useDocsPageContext();

    return (
        <div className="flex-1 flex flex-col gap-1 overflow-y-auto -mr-5 pr-5">
            <RootFolderNavItem />

            {sidePanelFolders && (
                <ul className="flex flex-col gap-1 max-w-full">
                    {sidePanelFolders?.map((folder) => {
                        const currentFolderExpandedState = expandedFolders[folder.id];

                        return (
                            <DraggableFolderNavItem
                                key={folder.id}
                                folder={folder}
                                isTopLevel
                                currentFolderExpandedState={currentFolderExpandedState}
                                prevFoldersLocation={[]}
                            />
                        );
                    })}
                </ul>
            )}

            {!sidePanelFolders && (
                <div>
                    <Loading withText={false} />
                </div>
            )}
        </div>
    );
};

export default FolderNavBar;
