import React from 'react';

import LogFeedbackTrigger from '../../../components/LogFeedbackTrigger/LogFeedbackTrigger';

const LogMessageFeedbackManager = ({ message, onSuccessFeedbackShare }) => {
    const {
        default_version: { id: currentVersionId, feedback },
    } = message;

    const feedbackCount = feedback?.length || 0;

    return (
        <LogFeedbackTrigger
            target="chat_message_version"
            targetId={currentVersionId}
            type="button_icon"
            customButtonType="neutral"
            customToolTipPosition="top-left"
            count={feedbackCount}
            successAlertMessage="Feedback successfully submitted!"
            onSuccess={onSuccessFeedbackShare}
        />
    );
};

export default LogMessageFeedbackManager;
