import React from 'react';

import { HISTORY_TAB } from '../../../constants/assistant';

const HistoryTabsEmptyView = ({ tab, isSearchQuery = false }) => {
    if (isSearchQuery) {
        return (
            <p className="font-body-bold text-body-bold-s text-[#25324B] text-center">
                No matching results found
            </p>
        );
    }

    const tabLabel = {
        [HISTORY_TAB.chats]: 'chats',
        [HISTORY_TAB.bots]: 'chatbots',
    }[tab];
    const mainMessage = `You don't have any ${tabLabel} yet.`;

    return (
        <div className="px-1 py-8 flex flex-col items-center gap-1">
            <p className="font-body-bold text-body-bold-m text-black text-center">{mainMessage}</p>
            <p className="font-body text-body-regular-s text-neutral-300 text-center">
                Start a new chat by clicking New Chat.
            </p>
        </div>
    );
};

export default HistoryTabsEmptyView;
