import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../design-system';
import { ArrowGoBackLineIcon } from '../../../../design-system/Icons';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';
import BotLastUpdatedInfo from '../BotLastUpdatedInfo/BotLastUpdatedInfo';
import BotMoreOptionPopup from '../BotMoreOptionPopup/BotMoreOptionPopup';
import MoreOptionsControls from '../../../../components/MoreOptionsControls/MoreOptionsControls';

const BotDetailHeader = ({ botDetail, backlinkHref, ...moreOptionsControlsProps }) => {
    const { name, description, default_version } = botDetail;
    const { updated_at, last_updated_by } = default_version;

    const navigate = useNavigate();
    const botsRoute = (() => {
        if (typeof backlinkHref === 'string' && backlinkHref.startsWith('/bots'))
            return backlinkHref;
        if (typeof backlinkHref === 'object' && backlinkHref.pathname?.startsWith('/bots'))
            return backlinkHref;
        return '/bots';
    })();

    return (
        <div className="px-5 py-4 xs:p-5 sm:p-6 lg:p-8 bg-purple-100 max-w-full flex flex-col gap-4 lg:gap-6 z-[45]">
            <div className="flex items-center gap-2 justify-between">
                <Button
                    type="link"
                    size="sm"
                    text="Back to Chatbots"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(botsRoute)}
                />

                <MoreOptionsControls
                    popupComponent={BotMoreOptionPopup}
                    position="right-0 top-[105%]"
                    {...moreOptionsControlsProps}
                />
            </div>

            <div className="w-full flex flex-col gap-2 max-w-[800px]">
                <h1 className="font-heading-bold text-heading-bold-l text-black max-w-[700px]">
                    {name}
                </h1>

                <BotLastUpdatedInfo updatedAt={updated_at} lastUpdatedBy={last_updated_by} />

                <RichTextArea
                    value={description}
                    previewOnly
                    autoExpand
                    isBorderHidden
                    customPreviewBgColor="transparent"
                    previewMaxLines={1}
                />
            </div>
        </div>
    );
};

export default BotDetailHeader;
