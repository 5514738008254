import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import { CHAT_TYPE } from '../../../constants/assistant';
import { getAssistantAllowedModelOptions } from '../../../helpers/getModelOptions';

import useUser from '../../../hooks/useUser';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { useAssistantChatThreadContext } from '../../../hooks/useAssistantChatThreadContext';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import SelectButton from '../../../design-system/SelectButton/SelectButton';
import AiModelsDropdown from '../AIModelsDropdown/AIModelsDropdown';

export const CHAT_GPT4o_MODEL = 'gpt-4o';
// const DIVI_BOT_OPTION = {
//     name: 'DiviBot Assistant',
//     model: CHAT_GPT4o_MODEL,
//     chatType: CHAT_TYPE.divibot,
// };

const placeholder = 'Select a model';

const AiModelSelector = () => {
    const { user } = useUser();
    const {
        chatType,
        setChatType,
        selectedAiModel,
        setSelectedAiModel,
        selectedCustomBot,
        setCustomBotAsModel,
        removeCustomBotFromModel,
    } = useAssistantChatThreadContext();

    const allowedModelOptions = useMemo(() => {
        if (!user) {
            return null;
        }

        const sortedAllowedModels = getAssistantAllowedModelOptions(user).sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        return (
            sortedAllowedModels?.map((model) => ({ ...model, chatType: CHAT_TYPE.generic })) || []
        );
    }, [user]);

    // const diviBotOption = useMemo(() => {
    //     const isChatGpt4oAvailable = !!allowedModelOptions?.find(
    //         ({ model }) => model === CHAT_GPT4o_MODEL
    //     );
    //     return isChatGpt4oAvailable ? DIVI_BOT_OPTION : null;
    // }, [allowedModelOptions]);

    const customBotOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.assistant.customBot,
        formatResponseToOptions: (results) =>
            results?.map(({ id, name }) => ({ id, name, chatType: CHAT_TYPE.custom })),
    });
    const { options: customBotOptions } = customBotOptionsHookResponse;

    const isErrorAlertDisplayed = useRef(false);

    let selectedOption;

    switch (chatType) {
        // case CHAT_TYPE.divibot:
        //     selectedOption = diviBotOption;
        //     break;
        case CHAT_TYPE.generic:
            selectedOption = allowedModelOptions?.find(({ model }) => model === selectedAiModel);
            break;
        case CHAT_TYPE.custom:
            if (selectedCustomBot?.id && selectedCustomBot?.name) {
                selectedOption = { ...selectedCustomBot, chatType: CHAT_TYPE.custom };
            } else {
                selectedOption = customBotOptions?.find(({ id }) => id === selectedCustomBot?.id);
            }
            break;
        default:
            selectedOption = null;
            break;
    }

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const { isMobile } = useResponsiveBreakpoints();

    useEffect(() => {
        if (!allowedModelOptions || selectedAiModel || selectedCustomBot) {
            return;
        }

        const isChatGpt4oAvailable = !!allowedModelOptions?.find(
            ({ model }) => model === CHAT_GPT4o_MODEL
        );

        if (isChatGpt4oAvailable) {
            setChatType(CHAT_TYPE.generic);
            setSelectedAiModel(CHAT_GPT4o_MODEL);
        }

        // const isDiviBotInAllowedOptions = !!allowedModelOptions?.find(
        //     ({ model }) => model === CHAT_GPT4o_MODEL
        // );
        //
        // if (isDiviBotInAllowedOptions) {
        //     setChatType(CHAT_TYPE.divibot);
        //     setSelectedAiModel(DIVI_BOT_OPTION.model);
        // }

        if (!isChatGpt4oAvailable && !isErrorAlertDisplayed.current) {
            setErrorAlert({ message: 'Please add an OpenAI API key to use this assistant' });
            isErrorAlertDisplayed.current = true;
        }
    }, [allowedModelOptions, selectedAiModel, selectedCustomBot]);

    const toggleDropdownOpened = () => {
        setIsDropdownOpened((prevState) => !prevState);
    };

    if (!allowedModelOptions) {
        return <div className="w-50 min-w-50 max-w-50"></div>;
    }

    const handleSelectOption = (option) => {
        const { chatType } = option;

        if (chatType === CHAT_TYPE.custom) {
            setCustomBotAsModel({ id: option.id, name: option.name });
        }

        if (chatType !== CHAT_TYPE.custom) {
            // setChatType(chatType); // idf type === divibot, set generic type
            setChatType(CHAT_TYPE.generic);
            setSelectedAiModel(option.model);
            removeCustomBotFromModel();
        }
    };

    return (
        <div className="w-50 min-w-50 max-w-[200px] relative">
            <SelectButton
                size="xs"
                selectedItem={selectedOption}
                isDropdownOpened={isDropdownOpened}
                toggleDropdownOpened={toggleDropdownOpened}
                placeholder={placeholder}
            />

            {isDropdownOpened && (
                <AiModelsDropdown
                    modelOptions={allowedModelOptions || []}
                    // diviBotOption={diviBotOption}
                    customBotOptionsHookResponse={customBotOptionsHookResponse}
                    selectedOption={selectedOption}
                    handleOptionSelect={handleSelectOption}
                    isMobile={isMobile}
                    onClose={(e) => {
                        e?.stopPropagation();
                        setIsDropdownOpened(false);
                    }}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default memo(AiModelSelector);
