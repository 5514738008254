import React, { useRef } from 'react';

import { insertJsx$, usePublisher } from '@mdxeditor/editor';
import { VARIABLE_NAME_PROP } from '../../constants/richTextEditorWithDocsMapping';

import DocumentTag from './DocumentTag/DocumentTag';

export const jsxComponentDescriptors = [
    {
        name: 'DocumentTag',
        kind: 'text',
        source: './external',
        props: [{ name: VARIABLE_NAME_PROP, type: 'string' }],
        hasChildren: true,
        Editor: DocumentTag,
    },
];

export const InsertDocumentTag = () => {
    const insertJsx = usePublisher(insertJsx$);
    const ref = useRef(null);

    const handleClick = () => {
        const button = ref.current;
        if (!button) return;

        const variableName = button.dataset?.variablename;
        if (variableName) {
            handleInsert(variableName);
            button.removeAttribute('data-variablename');
        }
    };

    const handleInsert = (variableName) => {
        if (!variableName) return;

        insertJsx({
            name: 'DocumentTag',
            kind: 'text',
            props: { [VARIABLE_NAME_PROP]: variableName },
        });
    };

    return (
        <button ref={ref} className="markdown-add-doc-tag-button hidden" onClick={handleClick}>
            Add Document Tag
        </button>
    );
};
