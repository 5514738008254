import React from 'react';
import classNames from 'classnames';

const ContentLoader = ({ additionalClassNames }) => {
    const loaderClassNames = classNames('flex items-center gap-1', additionalClassNames);
    const dotClassName = 'w-[6px] h-[6px] bg-purple-500 rounded-full animate-typingDots';

    return (
        <div className={loaderClassNames}>
            <span className={dotClassName} style={{ animationDelay: '0ms' }}></span>
            <span className={dotClassName} style={{ animationDelay: '200ms' }}></span>
            <span className={dotClassName} style={{ animationDelay: '400ms' }}></span>
        </div>
    );
};

export default ContentLoader;
