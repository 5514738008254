import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../services/assistant-api';

import useUser from '../hooks/useUser';
import { useManageSearchParams } from '../hooks/useManageSearchParams';
import { useHandlePageDataLoadError } from '../hooks/useHandlePageDataLoadError';

import { CHAT_TYPE } from '../constants/assistant';
import { CHAT_GPT4o_MODEL } from '../pages/AssistantPage/AIModelSelector/AIModelSelector';

export const AssistantChatThreadContext = createContext();

export const AssistantChatThreadProvider = ({ children }) => {
    const { chatThreadId } = useParams();

    const [searchParams] = useSearchParams();
    const botId = searchParams.get('bot');

    const { user } = useUser();
    const navigate = useNavigate();

    const { deleteSearchParam, setSearchParam } = useManageSearchParams();

    // const defaultChatType = useMemo(() => {
    // return CHAT_TYPE.generic;
    // if (!user) return CHAT_TYPE.generic;
    //
    // const isChatGpt4oAvailable = !!user.organization.available_models?.find(
    //     ({ model }) => model === CHAT_GPT4o_MODEL
    // );
    //
    // return isChatGpt4oAvailable ? CHAT_TYPE.divibot : CHAT_TYPE.generic;
    // }, [user]);

    const isChatGpt4oAvailable = useMemo(
        () => !!user.organization.available_models?.find(({ model }) => model === CHAT_GPT4o_MODEL),
        [user]
    );
    const defaultSelectedModel = isChatGpt4oAvailable ? CHAT_GPT4o_MODEL : null;
    const defaultChatType = CHAT_TYPE.generic;

    const [chatThreadData, setChatThreadData] = useState(null);
    const [controller, setController] = useState(new AbortController());

    const [chatType, setChatType] = useState(botId ? CHAT_TYPE.custom : defaultChatType);
    const [selectedAiModel, setSelectedAiModel] = useState(null);
    const [selectedCustomBot, setSelectedCustomBot] = useState(botId ? { id: botId } : null);

    const isFirstRender = useRef(true);

    const { handlePageDataLoadError } = useHandlePageDataLoadError();

    useEffect(() => {
        const fetchChatThreadData = async () => {
            try {
                const isCustomBotPreselected = !!botId;

                if (!isCustomBotPreselected) {
                    setSelectedAiModel(null);
                    setSelectedCustomBot(null);
                }

                if (isCustomBotPreselected) {
                    setSelectedAiModel(null);

                    const isWrongBotDataSaved =
                        selectedCustomBot?.id && selectedCustomBot?.id !== botId;
                    if (isWrongBotDataSaved) {
                        setSelectedCustomBot({ id: botId });
                    }
                }

                setChatThreadData(null);
                controller.abort();

                const newController = new AbortController();
                setController(newController);

                const { data } = await client.get(
                    `${API.ROUTES.assistant.chatThread}${chatThreadId}/`,
                    { signal: newController.signal }
                );

                setChatThreadData(data);

                if (isCustomBotPreselected) {
                    setChatType(CHAT_TYPE.custom);
                    return;
                }

                setCurrentChatThreadModel(data);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    redirectPath: '/assistant',
                    generalErrorHandler: () => navigate('/assistant'),
                });
            }
        };

        const resetChatThreadData = () => {
            controller.abort();
            setChatThreadData(null);

            if (!botId) {
                setDefaultModel();
            }
        };

        const shouldLoadNewChatThread = chatThreadId && chatThreadId !== chatThreadData?.id;
        if (shouldLoadNewChatThread) {
            fetchChatThreadData();
        }

        const isEmptyChatOpened = !chatThreadId && !isFirstRender.current;
        if (isEmptyChatOpened) {
            resetChatThreadData();
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, [chatThreadId]);

    useEffect(() => {
        const fetchBotData = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.assistant.customBot}${botId}/`);

                setSelectedCustomBot((currentBot) => {
                    if (currentBot?.id === data.id) {
                        setChatType(CHAT_TYPE.custom);
                        setSelectedAiModel(null);
                        return { id: data.id, name: data.name };
                    }
                    return currentBot;
                });
            } catch (e) {
                if (e.response?.status === 404) {
                    setDefaultModel();
                }
            }
        };

        const isCustomChatWithInvalidBotIdInState = botId && botId !== selectedCustomBot?.id;
        const isBotNameMissing = botId && !selectedCustomBot?.name;

        if (isCustomChatWithInvalidBotIdInState || isBotNameMissing) {
            setCustomBotAsModel({ id: botId });
            fetchBotData();
        }

        const isBotClearedButStillInState = !botId && selectedCustomBot?.id;

        if (isBotClearedButStillInState) {
            removeCustomBotFromModel();

            const isEmptyChatOpened = !chatThreadId;

            if (isEmptyChatOpened) {
                setDefaultModel();
            }

            if (!isEmptyChatOpened) {
                const isChatThreadDataLoaded = chatThreadData && chatThreadData.id === chatThreadId;
                if (isChatThreadDataLoaded) {
                    setCurrentChatThreadModel(chatThreadData);
                }

                if (!isChatThreadDataLoaded) {
                    setDefaultModel();
                }
            }
        }
    }, [botId]);

    const setCustomBotAsModel = ({ id, name }) => {
        setChatType(CHAT_TYPE.custom);

        setSearchParam('bot', id);
        setSelectedCustomBot({ id, name });

        setSelectedAiModel(null);
    };

    const removeCustomBotFromModel = () => {
        deleteSearchParam('bot');
        setSelectedCustomBot(null);
    };

    const setDefaultModel = () => {
        setChatType(defaultChatType);
        setSelectedAiModel(defaultSelectedModel);
        removeCustomBotFromModel();
    };

    const setCurrentChatThreadModel = (chatThreadData) => {
        const { type: _type, custom_bot, settings } = chatThreadData;
        const type = _type || CHAT_TYPE.generic;
        const model = settings.model || CHAT_GPT4o_MODEL;

        if (type === CHAT_TYPE.custom) {
            const isCustomBotDataReceived = custom_bot?.id && custom_bot?.name;

            if (isCustomBotDataReceived) {
                const { id, name } = custom_bot;
                setCustomBotAsModel({ id, name });
            }

            if (!isCustomBotDataReceived) {
                setChatType(CHAT_TYPE.generic);
                setSelectedAiModel(model);
            }
        }

        if (type !== CHAT_TYPE.custom) {
            // setChatType(type); // if type === divibot, set generic type
            setChatType(CHAT_TYPE.generic);
            setSelectedAiModel(model);
        }
    };

    return (
        <AssistantChatThreadContext.Provider
            value={{
                chatThreadData,
                setChatThreadData,
                chatType,
                setChatType,
                selectedAiModel,
                setSelectedAiModel,
                selectedCustomBot,
                setSelectedCustomBot,
                setCustomBotAsModel,
                removeCustomBotFromModel,
                setDefaultModel,
            }}
        >
            {children}
        </AssistantChatThreadContext.Provider>
    );
};
