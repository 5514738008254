import React, { useEffect, useRef, useState } from 'react';

import { useWindowSize } from '../../../hooks/useWindowSize';

import ToolTip from '../../../design-system/ToolTip/ToolTip';
import { SvgIcon } from '../../../design-system';
import { Robot2LineIcon } from '../../../design-system/Icons';

const FolderWithBotIndicator = ({ customBots }) => {
    const ref = useRef(null);
    const [distanceToLeft, setDistanceToLeft] = useState(0);

    const customBotsCount = customBots?.length;

    const mainBotName = customBots?.[0]?.name || '';
    const additionalBots = customBotsCount > 1 ? ` + ${customBotsCount - 1} more` : '';
    const tooltipText = `Connected to ${mainBotName}${additionalBots}`;

    const { width: screenWidth } = useWindowSize();

    const isMobile = screenWidth < 640;
    const isSmallTablet = screenWidth >= 640 && screenWidth < 768;
    const mobilePosition = distanceToLeft < 250 ? 'top-center' : 'top-right';

    const position = isMobile ? mobilePosition : isSmallTablet ? 'top-center' : 'top-left';
    const maxWidth = isMobile ? 250 : 276;

    useEffect(() => {
        if (!isMobile) return;

        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setDistanceToLeft(rect.left);
        }
    }, [screenWidth]);

    return (
        <div ref={ref} className="z-50">
            <ToolTip
                text={tooltipText}
                useInternalHoverStateToShowToolTip
                position={position}
                shift="-8px"
                maxWidth={maxWidth}
            >
                <SvgIcon color="#754DCF" icon={Robot2LineIcon} size="medium" />
            </ToolTip>
        </div>
    );
};

export default FolderWithBotIndicator;
