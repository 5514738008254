import React from 'react';

import Tag from '../../../design-system/Tag/Tag';
import LayersIcon from '../../../design-system/Icons/LayersIcon';
import { FlashlightFillIcon, FlowChartIcon } from '../../../design-system/Icons';

const getLabel = (count, singular, plural) => {
    return count === 0 ? `No ${plural}` : `${count} ${count === 1 ? singular : plural}`;
};

const PlaybookInfoBadges = ({ sections, processes, scenarios }) => {
    const sectionsCount = sections?.length || 0;
    const sectionsLabel = getLabel(sectionsCount, 'Section', 'Sections');

    const agentsCount = processes || 0;
    const agentsLabel = getLabel(agentsCount, 'Agent', 'Agents');

    const projectsCount = scenarios?.length || 0;
    const projectsLabel = getLabel(projectsCount, 'Project', 'Projects');

    return (
        <div className="flex items-center flex-wrap gap-1">
            <Tag text={sectionsLabel} type="ghost" color="neutral" leadingIcon={LayersIcon} />
            <Tag text={agentsLabel} type="ghost" color="neutral" leadingIcon={FlashlightFillIcon} />
            <Tag text={projectsLabel} type="ghost" color="neutral" leadingIcon={FlowChartIcon} />
        </div>
    );
};

export default PlaybookInfoBadges;
