import React from 'react';

import { ListOption, ListOptionGroup } from '../../../design-system';

const BaseMoreOptionsPopup = ({ onClose, openEditModal }) => {
    const handleEditClick = () => {
        openEditModal();
        onClose();
    };

    return (
        <div
            className="absolute top-9 right-3 z-10"
            onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
            }}
        >
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Edit"
                            onClick={handleEditClick}
                        />
                    </>
                }
                handleClose={onClose}
            />
        </div>
    );
};

export default BaseMoreOptionsPopup;
