import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/assistant-api';

import { useFormState } from '../../../../hooks/useFormState';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import { getFormattedJsonInputValue, isValidJsonInput } from '../../../../helpers/isValidJson';

import {
    defaultErrorMessage,
    emptyFieldErrorMessage,
    invalidJsonErrorMessage,
} from '../../../../constants/errorMessages';

import Modal from '../../../../design-system/Modal/Modal';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import MobilePopup from '../../../../design-system/MobilePopup/MobilePopup';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import JsonTextareaBox from '../../../../design-system/JsonTextareaBox/JsonTextareaBox';
import { Button } from '../../../../design-system';

const EditConfigurationModal = ({ botId, config, setBotDetail, setSuccessAlert, onClose }) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { config }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const { isMobile } = useResponsiveBreakpoints();

    const saveChanges = async () => {
        try {
            const { config } = formData;
            if (!config) {
                setFieldErrorMessages({ config: emptyFieldErrorMessage });
                return;
            }

            const isJsonValid = isValidJsonInput(config);
            if (!isJsonValid) {
                setFieldErrorMessages({ config: invalidJsonErrorMessage });
                return;
            }

            setIsLoading(true);

            const requestBody = { config: getFormattedJsonInputValue(config) };
            const { data } = await client.patch(
                `${API.ROUTES.assistant.customBot}${botId}/`,
                requestBody
            );
            const { sources, default_version } = data;
            const updatedConfig = default_version?.config;

            setBotDetail((prevBotDetail) => ({
                ...prevBotDetail,
                sources: sources || [],
                default_version: {
                    ...prevBotDetail.default_version,
                    config: updatedConfig,
                    last_updated_by: default_version.last_updated_by,
                    updated_at: default_version.updated_at,
                },
            }));

            setSuccessAlert({ message: 'Chatbot config successfully updated!' });

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const submitButtonState = isLoading ? 'loading' : 'default';

    const header = (
        <ModalHeader
            title="Edit Chatbot Configuration"
            onClose={onClose}
            outlined
            customPaddings="px-4 sm:px-5 py-3"
        />
    );

    const jsonInput = (
        <div className="flex-grow pt-4 pb-1 md:py-5 flex flex-col gap-4">
            <JsonTextareaBox
                mode="code"
                value={formData.config}
                label="Chatbot Configuration"
                isRequired
                state={fieldErrorMessages.config ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.config}
                tipText="Configure the engine settings for this chatbot."
                containerMinHeight={250}
                parentGap={16}
                setValue={(value) => handleInputChange('config', value)}
            />
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                minHeight="90vh"
                maxHeight="90vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="px-4 sm:px-5"
                bottomButtonsCustomPaddings="px-4 sm:px-5 pt-3"
                topFixedContent={header}
                customGap={8}
                withCloseButton={false}
                errorAlert={errorAlert}
                setErrorAlert={setErrorAlert}
                bottomButtonsGroup={
                    <div className="flex flex-col gap-2">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Save Changes"
                            state={submitButtonState}
                            onClick={saveChanges}
                        />
                        <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                    </div>
                }
            >
                {jsonInput}
            </MobilePopup>
        );
    }

    return (
        <Modal size="mediumPlus" onClose={onClose} resetPadding>
            {header}

            <div className="px-5 flex flex-col">
                {jsonInput}

                <div className="py-3 flex justify-end">
                    <Button
                        type="secondary"
                        size="sm"
                        text="Save Changes"
                        state={submitButtonState}
                        onClick={saveChanges}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default EditConfigurationModal;
