import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SvgIcon } from '../index';

const HighlightedIcon = ({
    icon: Icon,
    variant = 'light_purple',
    size: _size = 'medium',
    customIconColor = null,
}) => {
    const size = _size || 'medium';

    const wrapperClassName = classNames('rounded-2 flex items-center justify-center', {
        'bg-purple-100': variant === 'light_purple',
        'bg-purple-500': variant === 'dark_purple',
        'bg-neutral-100': variant === 'neutral',
        'w-8 h-8 min-w-[32px]': size === 'medium',
        'w-12 h-12 min-w-[48px]': size === 'large',
    });

    const defaultIconColor = {
        light_purple: '#754DCF',
        dark_purple: '#FFFFFF',
        neutral: '#000000',
    }[variant];

    const iconColor = customIconColor || defaultIconColor;

    return (
        <div className={wrapperClassName}>
            <SvgIcon color={iconColor} icon={Icon} size={size} />
        </div>
    );
};

HighlightedIcon.propTypes = {
    icon: PropTypes.elementType.isRequired,
    customIconColor: PropTypes.string,
    variant: PropTypes.oneOf(['light_purple', 'dark_purple', 'neutral']),
    size: PropTypes.oneOf(['medium', 'large']),
};

export default HighlightedIcon;
