import React from 'react';

import { knowledgeBaseSortOptions } from '../../../constants/sort';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import SortControls from '../../../components/SortControls/SortControls';
import SearchBarWithUrlParamsHandling from '../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';

const BasesPageHeader = () => {
    const { isMobile } = useResponsiveBreakpoints();

    return (
        <div className="w-full bg-white p-5 md:p-6 lg:p-8 flex flex-col gap-5 md:gap-6 lg:gap-10">
            <div className="max-w-[650px] flex flex-col gap-1 md:gap-0 items-center mx-auto">
                <h1 className="font-heading-bold text-heading-bold-l md:text-heading-bold-xl text-black text-center">
                    Welcome to <br className="hidden min-[400px]:block" />
                    your Knowledge Base
                </h1>
                <p className="font-body text-body-regular-m md:text-body-regular-l text-neutral-300 text-center max-w-[360px] sm:max-w-full">
                    Explore all of the context available to your chatbots and agents
                </p>
            </div>

            <div className="w-full flex flex-col gap-2.5 items-center">
                <div className="w-full max-w-[400px] md:max-w-[600px]">
                    <SearchBarWithUrlParamsHandling
                        size={isMobile ? 'sm' : 'md'}
                        placeholder="Search by keyword"
                    />
                </div>
                <SortControls sortOptions={knowledgeBaseSortOptions} variant="inline" />
            </div>
        </div>
    );
};

export default BasesPageHeader;
