import React, { useCallback, useRef, useState } from 'react';

import { getPopupTopPosition } from '../../../helpers/getPopupTopPosition';
import { useOnScrollListener } from '../../../hooks/useOnScrollListener';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { STATUS } from '../../../constants/statuses';

import SourceIcon from '../../BotsPage/BotDetailPage/SourceIcon/SourceIcon';
import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import SourceLastSyncBlock from '../SourceLastSyncBlock/SourceLastSyncBlock';
import KnowledgeSourceMoreOptionsPopup from '../KnowledgeSourceMoreOptionsPopup/KnowledgeSourceMoreOptionsPopup';
import { SvgIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';

const KnowledgeSourceCard = ({
    source,
    refetchBotDetail,
    openSourceDetailsPopup,
    modalRef,
    scrollableAreaRef,
    isLastCard = false,
}) => {
    const { id, name, type, ingestion_status, detail } = source;
    const { status } = ingestion_status;

    const moreOptionsButtonRef = useRef(null);

    const [popup, setPopup] = useState(null);
    const isPopupOpened = !!popup;

    const { isMobile } = useResponsiveBreakpoints();

    const [successAlert, setSuccessAlert] = useState(null);

    const isSyncInProgress = status !== STATUS.success;

    const calculatePopupPosition = () => {
        const { top } = getPopupTopPosition({
            buttonRef: moreOptionsButtonRef,
            panelRef: modalRef,
            shift: isLastCard ? -16 : 22,
        });

        return { top };
    };

    const openPopup = () => {
        if (isMobile) {
            setPopup({ top: 0 });
            return;
        }

        const position = calculatePopupPosition();
        setPopup(position);
    };

    const closePopup = (e) => {
        e?.stopPropagation();
        e?.toLocaleString();

        setPopup(null);
    };

    const onScroll = useCallback(() => {
        if (isMobile) return;

        setPopup((prev) => {
            if (!prev) return prev;

            return calculatePopupPosition();
        });
    }, []);

    useOnScrollListener(onScroll, scrollableAreaRef?.current);

    return (
        <>
            <li className="px-4 py-3 flex items-start justify-between gap-1 bg-white rounded-2">
                <div className="flex flex-col gap-y-0.5">
                    <div className="flex gap-2">
                        <SourceIcon type={type} />
                        <p className="font-body-bold text-body-bold-xs text-black">{name}</p>
                    </div>

                    <SourceLastSyncBlock ingestion_status={ingestion_status} />
                </div>

                <button className="block" onClick={openPopup} ref={moreOptionsButtonRef}>
                    <SvgIcon color="#1F2125" icon={More2FillIcon} size="large" />
                </button>

                {successAlert && (
                    <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
                )}
            </li>

            {isPopupOpened && (
                // On desktop, the popup is positioned relative to the modal (not the "More Options" button due to the scroll behavior of the left and right panels).
                // On mobile, it's a popup positioned relative to the viewport.
                <KnowledgeSourceMoreOptionsPopup
                    id={id}
                    type={type}
                    name={name}
                    detail={detail}
                    setSuccessAlert={setSuccessAlert}
                    refetchBotDetail={refetchBotDetail}
                    openSourceDetailsPopup={openSourceDetailsPopup}
                    isSyncInProgress={isSyncInProgress}
                    modalRef={modalRef}
                    yPosition={{ top: popup.top }}
                    onClose={closePopup}
                />
            )}
        </>
    );
};

export default KnowledgeSourceCard;
