import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../../../design-system';
import { FlashlightFillIcon, FlowChartIcon } from '../../../design-system/Icons';

const PlaybookEntitiesBlock = ({ type, items, playbookId }) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (!items?.length) {
        return <></>;
    }

    const config = {
        agents: {
            title: 'Connected Agents',
            secondaryButtonText: 'New Job',
            secondaryButtonIcon: FlashlightFillIcon,
        },
        projects: {
            title: 'Projects',
            secondaryButtonText: 'New Project',
            secondaryButtonIcon: FlowChartIcon,
        },
    };

    const { title, secondaryButtonText, secondaryButtonIcon: Icon } = config[type] || {};

    const handleNav = (route) => navigate(route, { state: { from: location } });

    return (
        <div>
            <h3 className="font-heading-regular font-semibold text-[16px] text-black mb-5">
                {title}
            </h3>

            <ul className="flex flex-col">
                {items.map(({ id, name }) => {
                    const detailsRoute = {
                        agents: `/agent/${id}`,
                        projects: `/scenario/${id}`,
                    }[type];
                    const startRoute = {
                        agents: `/agent/${id}/start`,
                        projects: `/playbook/${playbookId}/start/${id}`,
                    }[type];

                    return (
                        <li
                            key={id}
                            className="p-2 flex flex-col min-[550px]:flex-row min-[550px]:items-center min-[550px]:justify-between gap-x-4 gap-y-2 border-b-1 border-neutral-200"
                        >
                            <p className="font-body text-body-regular-s text-neutral-500">{name}</p>
                            <div className="min-w-fit flex items-center gap-2.5">
                                <Button
                                    type="neutral"
                                    size="xs"
                                    text="View Details"
                                    onClick={() => handleNav(detailsRoute)}
                                />
                                <Button
                                    type="link"
                                    size="xs"
                                    text={secondaryButtonText}
                                    leadingIcon={Icon}
                                    onClick={() => handleNav(startRoute)}
                                />
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default PlaybookEntitiesBlock;
