import React from 'react';

import { GOOGLE_IMAGE_PATH, SOURCE_ICON, SOURCE_TYPE } from '../../../../constants/bot';

import { SvgIcon } from '../../../../design-system';
import ArchiveLineIcon from '../../../../design-system/Icons/ArchiveLineIcon';
import HighlightedIcon from '../../../../design-system/HighlightedIcon/HighlightedIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SourceIcon = ({ type, size: _size = 'medium', isHighlighted = false }) => {
    const size = _size || 'medium';

    if (type === SOURCE_TYPE.google_folder) {
        const imgWidth =
            {
                medium: 16,
                large: 24,
            }[size] || 16;

        const googleImage = (
            <img
                src={GOOGLE_IMAGE_PATH}
                width={imgWidth}
                height={imgWidth + 1}
                alt="Google logo"
                className="block"
                style={{ maxHeight: `${imgWidth + 1}px`, minWidth: `${imgWidth}px` }}
            />
        );

        if (isHighlighted) {
            const wrapperClassName = classNames(
                'rounded-2 flex items-center justify-center bg-neutral-100',
                {
                    'w-8 h-8 min-w-[32px]': size === 'medium',
                    'w-12 h-12 min-w-[48px]': size === 'large',
                }
            );

            return <div className={wrapperClassName}>{googleImage}</div>;
        }

        return googleImage;
    }

    const Icon = SOURCE_ICON[type] || ArchiveLineIcon;

    if (isHighlighted) {
        return (
            <HighlightedIcon icon={Icon} variant="neutral" customIconColor="#754DCF" size={size} />
        );
    }

    return <SvgIcon color="#754DCF" icon={Icon} size={size} />;
};

SourceIcon.propTypes = {
    type: PropTypes.oneOf(Object.values(SOURCE_TYPE)).isRequired,
    size: PropTypes.oneOf(['medium', 'large']),
    isHighlighted: PropTypes.bool,
};

export default SourceIcon;
