import React, { useState } from 'react';

import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import { SvgIcon } from '../../../../design-system';
import { ArrowDownSLineIcon } from '../../../../design-system/Icons';
import FileTextLinkedIcon from '../../../../design-system/Icons/FileTextLinkedIcon';
import ArrowRightSLineIcon from '../../../../design-system/Icons/ArrowRightSLineIcon';
import NewSourceOptionsPopup from '../NewSourceOptionsPopup/NewSourceOptionsPopup';

const AddNewSourceOption = ({ openNewSourcePanel, isGoogleCloudConnected }) => {
    const [isHovered, setIsHovered] = useState(false);

    const { isDesktop } = useResponsiveBreakpoints();

    const ArrowIcon = isDesktop ? ArrowRightSLineIcon : ArrowDownSLineIcon;

    return (
        <div className="relative w-full -mr-4 pr-4 pb-4" onMouseLeave={() => setIsHovered(false)}>
            <div
                className="w-full p-2 flex items-center justify-between gap-2 overflow-hidden bg-white hover:bg-neutral-50 transition-colors cursor-pointer rounded-2"
                onMouseEnter={() => setIsHovered(true)}
                onClick={() => setIsHovered(true)}
            >
                <SvgIcon color="#000000" icon={FileTextLinkedIcon} size="large" />
                <p className="flex-1 font-body text-body-regular-m text-neutral-500 truncate">
                    Add new source
                </p>
                <ArrowIcon color="#5E6470" width={14} height={14} />
            </div>

            {isHovered && (
                <NewSourceOptionsPopup
                    openNewSourcePanel={openNewSourcePanel}
                    isGoogleCloudConnected={isGoogleCloudConnected}
                    onClose={() => setIsHovered(false)}
                />
            )}
        </div>
    );
};

export default AddNewSourceOption;
