import React from 'react';

const ParagraphIcon = ({ width, height, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
            <path
                d="M8.0013 4.00002V14H6.66797V10.6667C4.45883 10.6667 2.66797 8.87582 2.66797 6.66669C2.66797 4.45755 4.45883 2.66669 6.66797 2.66669H13.3346V4.00002H11.3346V14H10.0013V4.00002H8.0013ZM6.66797 4.00002C5.19521 4.00002 4.0013 5.19393 4.0013 6.66669C4.0013 8.13942 5.19521 9.33335 6.66797 9.33335V4.00002Z"
                fill={color}
            />
            <path
                d="M8.0013 4.00002V14H6.66797V10.6667C4.45883 10.6667 2.66797 8.87582 2.66797 6.66669C2.66797 4.45755 4.45883 2.66669 6.66797 2.66669H13.3346V4.00002H11.3346V14H10.0013V4.00002H8.0013ZM6.66797 4.00002C5.19521 4.00002 4.0013 5.19393 4.0013 6.66669C4.0013 8.13942 5.19521 9.33335 6.66797 9.33335V4.00002Z"
                fill={color}
                fillOpacity="0.2"
            />
        </svg>
    );
};

export default ParagraphIcon;
