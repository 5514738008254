import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// prop size can be added
UserBadge.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['black', 'purple']),
};

function UserBadge({ name, color = 'purple' }) {
    const badgeColorStyles = {
        purple: 'bg-purple-500',
        black: 'bg-neutral-500',
    };

    const badgeClassName = classNames(
        'w-5 h-5 min-w-[20px] rounded-full flex items-center justify-center uppercase font-body text-body-regular-xs text-white',
        badgeColorStyles[color]
    );

    return (
        <div className="flex items-center gap-1 overflow-hidden">
            <div className={badgeClassName}>{name?.[0]}</div>
            <p className="font-body-bold text-body-bold-xs text-neutral-500 truncate">{name}</p>
        </div>
    );
}

export default UserBadge;
