import React from 'react';
import { Link } from 'react-router-dom';

import ProcessCard from '../ProcessCard/ProcessCard';

const PlaybookContainer = ({ playbook, handleModalClose, isMobile }) => {
    return (
        <div>
            <p className="font-body-bold text-body-bold-s text-neutral-300 uppercase mb-4">
                {playbook.name}
            </p>
            <div className="flex flex-col gap-1">
                {playbook.processes?.map((process) => {
                    return (
                        <Link
                            key={process.id}
                            to={`/agent/${process.id}/start`}
                            onClick={handleModalClose}
                        >
                            <ProcessCard
                                process={process}
                                iconColor={playbook.icon_color}
                                isMobile={isMobile}
                            />
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default PlaybookContainer;
