import React, { useEffect, useRef, useState } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { handleMoveOnDrop } from '../../../helpers/docsDndUtils';

import { CONTENT_TYPE, DRAGGABLE_ITEM_TYPE } from '../../../constants/docs';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import FolderNavItem from '../FolderNavItem/FolderNavItem';

const DraggableFolderNavItem = ({ ...itemProps }) => {
    const { folder, prevFoldersLocation } = itemProps;
    const ref = useRef(null);

    const [errorAlert, setErrorAlert] = useState(null);

    const {
        setSidePanelFolders,
        setExpandedFolders,
        requestKeysToMutate,
        currentRequestKeyToMutate,
        isDragAndDropEnabled,
    } = useDocsPageContext() || {};

    const [{ isDragging }, drag, preview] = useDrag({
        type: DRAGGABLE_ITEM_TYPE.sidePanelFolder,
        item: () => {
            const activeDraggedItem = { ...folder, content_type: CONTENT_TYPE.folder };

            const draggedItems = [activeDraggedItem];

            const draggedItemsIds = draggedItems.map(({ id }) => id);

            return {
                draggedItems,
                draggedItemsIds,
                activeDraggedItem,
                draggedItemsParentLocation: prevFoldersLocation,
                isDraggingSelectedItems: false,
            };
        },
        canDrag: () => isDragAndDropEnabled,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: [DRAGGABLE_ITEM_TYPE.indexPageItem, DRAGGABLE_ITEM_TYPE.sidePanelFolder],
        canDrop: (item, monitor) => {
            const itemType = monitor.getItemType();
            const forbiddenDrop = itemType === DRAGGABLE_ITEM_TYPE.sidePanelFolder && isDragging;
            return !forbiddenDrop;
        },
        drop: async ({ draggedItems, draggedItemsIds, draggedItemsParentLocation }, monitor) => {
            const type = monitor.getItemType();

            const targetFolderLocation = [
                ...prevFoldersLocation,
                { id: folder.id, label: folder.label },
            ];

            const targetFolder = { ...folder, location: targetFolderLocation };

            await handleMoveOnDrop({
                targetFolder,
                draggedItems,
                draggedItemsIds,
                draggedItemsParentLocation,
                requestKeysToMutate,
                currentRequestKeyToMutate,
                setErrorAlert,
                setExpandedFolders,
                setSidePanelFolders,
                type,
            });
        },
        collect: (monitor) => {
            const canDrop = monitor.canDrop();
            return { isOver: canDrop && monitor.isOver() };
        },
    });

    useEffect(() => {
        preview(getEmptyImage(), {
            captureDraggingState: true,
        });
    }, []);

    drag(drop(ref));

    return (
        <>
            <FolderNavItem {...itemProps} isDndOver={isOver} isDragging={isDragging} ref={ref} />

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default DraggableFolderNavItem;
