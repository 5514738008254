import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { emptyFieldErrorMessage } from '../../constants/errorMessages';

import NewRichTextArea from '../NewRichTextArea/NewRichTextArea';

const RichTextAreaWithAutoSaveOnBlur = ({
    label,
    isRequired = false,
    isDisabled = false,
    value,
    originalValue,
    placeholder = 'Write here',
    handleChange: _handleChange,
    handleSave: _handleSave,
    minHeight = 160,
}) => {
    const wrapperRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSave = async () => {
        if (value === originalValue) return;

        try {
            if (isRequired && !value) {
                setErrorMessage(emptyFieldErrorMessage);

                const editorEl = wrapperRef.current.querySelector('._contentEditable_uazmk_379');
                editorEl && editorEl.focus();

                return;
            }

            await _handleSave(value);
        } catch (e) {
            console.log(e);
        }
    };

    const handleChange = (value) => {
        _handleChange(value);
        if (errorMessage) setErrorMessage(null);
    };

    const state = isDisabled ? 'disabled' : errorMessage ? 'error' : 'default';

    return (
        <div ref={wrapperRef} className="flex flex-col">
            <NewRichTextArea
                value={value}
                label={label}
                isRequired={isRequired}
                setValue={handleChange}
                state={state}
                errorMessage={errorMessage}
                minHeight={minHeight}
                darkBorderColor
                placeholder={placeholder}
                onBlur={handleSave}
            />
        </div>
    );
};

RichTextAreaWithAutoSaveOnBlur.propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    value: PropTypes.string,
    originalValue: PropTypes.string,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    minHeight: PropTypes.number,
};

export default RichTextAreaWithAutoSaveOnBlur;
