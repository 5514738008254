import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../design-system';
import Loading from '../../../components/Loading';
import BoxIcon from '../../../design-system/Icons/BoxIcon';
import ChatbotCard from '../ChatbotCard/ChatbotCard';
import BlockWrapper from '../BlockWrapper/BlockWrapper';

const YourChatbotsBlock = ({ chatbots, hasNextPage, loading, loadNextPage, isFirstPageLoaded }) => {
    const navigate = useNavigate();

    const goToAssistantButton = (
        <div className="min-w-fit">
            <Button
                type="ghost"
                size="xs"
                text="Go to Assistant"
                onClick={() => navigate('/assistant')}
            />
        </div>
    );

    return (
        <BlockWrapper
            title="Your Chatbots"
            description="Interact with your DiviUp instance through chatbots."
            size="sm"
            customButton={goToAssistantButton}
        >
            {!!chatbots?.length && (
                <>
                    <div className="flex flex-col gap-3">
                        {chatbots.map((chatbot) => (
                            <ChatbotCard key={chatbot.id} chatbot={chatbot} />
                        ))}
                    </div>

                    {hasNextPage && !loading && (
                        <Button type="link" size="sm" text="View More" onClick={loadNextPage} />
                    )}

                    {loading && (
                        <div className="min-h-[40px]">
                            <Loading withText={false} />
                        </div>
                    )}
                </>
            )}

            {!isFirstPageLoaded && (
                <div className="py-2">
                    <Loading withText={false} />
                </div>
            )}

            {isFirstPageLoaded && !chatbots?.length && (
                <div className="flex flex-col items-center justify-center gap-3 py-[80px]">
                    <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full mx-auto">
                        <BoxIcon width={32} height={32} color="#5E6470" />
                    </div>
                    <p className="font-heading-bold text-heading-bold-s text-neutral-500 text-center">
                        You don’t have any Chatbots yet.
                    </p>
                </div>
            )}
        </BlockWrapper>
    );
};

export default YourChatbotsBlock;
