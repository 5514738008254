import React, { memo, useCallback, useMemo, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';
import organizationClient from '../../../../services/organization-api';

import { useOverlayState } from '../../../../hooks/useOverlayState';
import { useFetchDataOnLoad } from '../../../../hooks/useFetchDataOnLoad';
import { useFetchOptionsForPaginatedSelect } from '../../../../hooks/useFetchOptionsForPaginatedSelect';

import SourceCard from '../SourceCard/SourceCard';
import CreateNewSourcePanel from '../CreateNewSourcePanel/CreateNewSourcePanel';
import AddExistingSourcesModal from '../AddExistingSourcesModal/AddExistingSourcesModal';
import DataSourcesSectionHeader from '../DataSourcesSectionHeader/DataSourcesSectionHeader';

const DataSourcesSection = ({ botId, sources, setBotDetail, panelRef, scrollableAreaRef }) => {
    const areSources = !!sources.length;

    const sourcesIds = useMemo(() => sources?.map((source) => source.id) || [], [sources]);

    const {
        isOpened: isAddExistingSourceModalOpened,
        open: openModal,
        close: closeModal,
    } = useOverlayState();

    const [newSourcePanel, setNewSourcePanel] = useState(null);

    const { data: connectionsData } = useFetchDataOnLoad({
        client: organizationClient,
        route: API.ROUTES.organization.connection,
        searchParams: { provider: 'google-cloud' },
    });
    const googleCloudConnectionId = connectionsData?.results?.[0]?.id;

    const sourcesOptionsHookResponse = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.knowledge.source,
        formatResponseToOptions: (results) =>
            results?.map(({ id, name, type }) => ({ id, name, type })) || [],
    });

    const onSuccessSourceCreate = (source) => {
        const { setOptions } = sourcesOptionsHookResponse;
        setOptions((prevOptions) => [source, ...(prevOptions || [])]);

        openModal();
    };

    const setUpdatedSources = useCallback(
        ({ data }) => {
            const { sources, default_version } = data;
            setBotDetail((prevState) => ({
                ...prevState,
                sources: sources || [],
                default_version: {
                    ...prevState.default_version,
                    config: default_version.config,
                    last_updated_by: default_version.last_updated_by,
                    updated_at: default_version.updated_at,
                },
            }));
        },
        [setBotDetail]
    );

    return (
        <div className="flex flex-col gap-2 pb-5 xl:pb-7">
            <DataSourcesSectionHeader
                sources={sources}
                panelRef={panelRef}
                scrollableAreaRef={scrollableAreaRef}
                googleCloudConnectionId={googleCloudConnectionId}
                setNewSourcePanel={setNewSourcePanel}
                openAddExistingSourceModal={openModal}
            />

            {areSources && (
                <ul className="flex flex-col gap-2">
                    {sources.map((source) => (
                        <SourceCard
                            key={source.id}
                            source={source}
                            currentSourcesIdsList={sourcesIds}
                            setUpdatedSources={setUpdatedSources}
                            setBotDetail={setBotDetail}
                        />
                    ))}
                </ul>
            )}

            {!areSources && (
                <p className="font-body text-body-regular-s text-neutral-500 pt-1">
                    No connected Data Sources yet.
                </p>
            )}

            {newSourcePanel && (
                <CreateNewSourcePanel
                    type={newSourcePanel.type}
                    googleCloudConnectionId={googleCloudConnectionId}
                    onSuccessSourceCreate={onSuccessSourceCreate}
                    onClose={() => setNewSourcePanel(null)}
                />
            )}

            {isAddExistingSourceModalOpened && (
                <AddExistingSourcesModal
                    botId={botId}
                    setUpdatedSources={setUpdatedSources}
                    savedSelectedSources={sourcesIds}
                    sourcesOptionsHookResponse={sourcesOptionsHookResponse}
                    onClose={closeModal}
                />
            )}
        </div>
    );
};

export default memo(DataSourcesSection);
