import React from 'react';

import { usePublisher } from '@mdxeditor/gurx';
import { insertTable$ } from '@mdxeditor/editor';

import { SvgIcon } from '../../../index';
import FiLayout from '../../../Icons/FiLayout';

const ToolbarTable = () => {
    const insertTable = usePublisher(insertTable$);

    return (
        <button
            className="p-[6px] hover:bg-neutral-50 rounded-2"
            onClick={() => insertTable({ columns: 3, rows: 3 })}
        >
            <SvgIcon color="#1F2125" icon={FiLayout} size="medium" />
        </button>
    );
};

export default ToolbarTable;
