import React, { useState } from 'react';

import { HISTORY_TAB } from '../../../constants/assistant';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import BotCard from '../BotCard/BotCard';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import HistoryTabsEmptyView from '../HistoryTabsEmptyView/HistoryTabsEmptyView';
import HistoryGroupContainer from '../HistoryGroupContainer/HistoryGroupContainer';

const HistorySidePanelBotsTab = ({
    isActiveTab = false,
    botsHistoryHookResponse,
    setIsPanelClosableByClickOutside,
    closePanel,
}) => {
    const {
        items: bots,
        isLoading,
        canLoadMore,
        setPage,
        searchQuery,
        setSearchQuery,
        isFirstRequestCompleted,
        setIsFirstRequestCompleted,
    } = botsHistoryHookResponse;
    const [searchBarInputValue, setSearchBarInputValue] = useState(searchQuery);

    const handleClearSearchBar = () => {
        setSearchBarInputValue('');
        setSearchQuery('');
        setIsFirstRequestCompleted(false);
    };

    const isEmptyState = isFirstRequestCompleted && !isLoading && !bots?.length;

    return (
        <div className={isActiveTab ? 'flex flex-col gap-5 h-full' : 'hidden'}>
            <SearchBar
                size="xs"
                value={searchBarInputValue}
                onChange={(e) => setSearchBarInputValue(e.target.value)}
                onSubmit={() => setSearchQuery(searchBarInputValue)}
                withClearIcon
                onClear={handleClearSearchBar}
            />

            {isEmptyState && (
                <HistoryTabsEmptyView tab={HISTORY_TAB.bots} isSearchQuery={!!searchQuery} />
            )}

            <div className="mx-[-20px] lg:mx-[-16px] xl:mx-[-20px] pl-5 pr-4 lg:pl-4 lg:pr-3 xl:pl-5 xl:pr-4 overflow-y-auto flex-grow pb-6">
                <InfiniteScrollList
                    handleFetch={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMore}
                    items={bots}
                    loading={isLoading}
                    gap={20}
                >
                    {!!bots.length && (
                        <HistoryGroupContainer customLabel="ALL CHATBOTS">
                            {bots.map((bot) => (
                                <BotCard
                                    key={bot.id}
                                    bot={bot}
                                    closePanel={closePanel}
                                    setIsPanelClosableByClickOutside={
                                        setIsPanelClosableByClickOutside
                                    }
                                />
                            ))}
                        </HistoryGroupContainer>
                    )}
                </InfiniteScrollList>
            </div>
        </div>
    );
};

export default HistorySidePanelBotsTab;
