import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useEscapeKeyListener } from '../../hooks/useEscapeKeyListener';
import './modal.css';

Modal.propTypes = {
    size: PropTypes.oneOf([
        'extraSmall',
        'small',
        'semiMedium',
        'midSize',
        'medium',
        'mediumPlus',
        'large',
        'extraLarge',
        'fullScreen',
    ]),
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    resetPadding: PropTypes.bool,
    higherZIndex: PropTypes.bool,
    closeByClickOutside: PropTypes.bool,
    customStyles: PropTypes.string,
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const modalRoot = document.querySelector('#modal-root');

function Modal(props) {
    const {
        size = 'large',
        children,
        onClose,
        higherZIndex = false,
        closeByClickOutside = true,
        resetPadding = false,
        customStyles = null,
        maxHeight: _maxHeight = '90%',
    } = props;
    const modalRef = useRef(null);

    useEscapeKeyListener(onClose);

    const sizes = {
        extraSmall: 'w-[90%] xs:w-[400px]',
        small: 'w-[90%] xs:w-110',
        semiMedium: 'w-[90%] sm:w-[542px]',
        midSize: 'w-[90%] sm:w-[600px]',
        medium: 'w-[90%] min-[700px]:w-[632px]',
        mediumPlus: 'w-[90%] ql:w-[768px]',
        large: 'w-[90%] ql:w-200',
        extraLarge: 'w-[90%] min-[1140px]:w-[971px]',
        fullScreen: 'max-w-[95%] 3xl:max-w-[90%] min-w-[100px]', // assuming that the max width of the modal is 95% (90% from 1650px) of the screen and max height is 95% of the screen
    };

    let modalName = classNames(
        `hide-scrollbar absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] bg-white rounded-2 shadow-l1 overflow-y-auto ${
            !resetPadding && 'p-5 sm:p-6'
        }`,
        {
            'z-52': !higherZIndex,
            'z-55': higherZIndex,
        },
        sizes[size],
        customStyles
    );

    const maxHeight = typeof _maxHeight === 'string' ? _maxHeight : `${_maxHeight}px`;

    const onBackDropClick = (e) => {
        if (e.target === e.currentTarget && closeByClickOutside) {
            onClose();
        }
    };

    return createPortal(
        <div className={`fixed top-0 left-0 right-0 bottom-0 ${higherZIndex ? 'z-53' : 'z-50'}`}>
            <div
                className={`absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-black/50 ${
                    higherZIndex ? 'z-54' : 'z-51'
                }`}
                onMouseDown={onBackDropClick}
            ></div>
            <div className={modalName} ref={modalRef} style={{ maxHeight }}>
                {children}
            </div>
        </div>,
        modalRoot
    );
}

export default Modal;
