import { Link } from 'react-router-dom';
import { mutate } from 'swr';

import { API } from 'constants';
import client from '../services/knowledge-api';
import { defaultErrorMessage } from '../constants/errorMessages';

import {
    ROUTE,
    CONTENT_TYPE,
    DOCUMENT_TYPE,
    EMPTY_DIAGRAM_DATA,
    EMPTY_DOCUMENT_DATA,
    DETAIL_PAGE_BASE_ROUTE,
} from '../constants/docs';
import { mutateRequestKeysData } from './swrMutateUtils';
import { updateSidePanelStateAfterFoldersInsert } from './docsSidePanelUtils';

export const mutateDataAfterRenamingDoc = ({ requestKeysToMutate, docId, updatedLabel }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: false,
        cb: (currentData) => {
            const updatedResults = currentData.results.map((doc) =>
                doc.id === docId ? { ...doc, label: updatedLabel } : doc
            );

            return { ...currentData, results: updatedResults };
        },
    });
};

export const handleDuplicateItem = async ({
    id,
    contentType = CONTENT_TYPE.document,
    setSuccessAlert,
    setErrorAlert,
    location,
    requestKeysToMutate = [],
    setIsLoading = () => {},
    closePopup = () => {},
    onNavigateToFolderView = () => {},
    parentLocation = [],
    setSidePanelFolders,
    skipUpdatingSidePanel = false,
    currentRequestKeyToMutate,
}) => {
    try {
        setIsLoading(true);

        const route = ROUTE[contentType];
        const { data } = await client.post(`${route}${id}/duplicate/`);

        await mutateCashedDataAfterAction({
            requestKeysToMutate,
            currentRequestKeyToMutate,
            parentLocation,
        });

        if (contentType === CONTENT_TYPE.folder && setSidePanelFolders && !skipUpdatingSidePanel) {
            const duplicatedItem = { id: data.id, label: data.label, folders: data.folders };

            updateSidePanelStateAfterFoldersInsert({
                insertedFolders: [duplicatedItem],
                parentLocation,
                setSidePanelFolders,
            });
        }

        const linkUrl = (DETAIL_PAGE_BASE_ROUTE[contentType] || '/doc/') + data.id;

        const handleLinkClick = () => {
            if (contentType === CONTENT_TYPE.folder) {
                const location = [...(parentLocation || []), { id: data.id, label: data.label }];
                onNavigateToFolderView({ location });
            }
        };

        setSuccessAlert({
            message: `Created ”${data.label}”`,
            description: (
                <Link
                    to={linkUrl}
                    state={{ from: location }}
                    onClick={handleLinkClick}
                    className="font-[inherit] text-[inherit] underline"
                >
                    Visit
                </Link>
            ),
        });

        closePopup();
    } catch (e) {
        setIsLoading(false);
        setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
    }
};

export const handleDuplicateSelectedItems = async ({
    selectedItems,
    setSuccessAlert,
    setErrorAlert,
    requestKeysToMutate = [],
    currentRequestKeyToMutate,
    setIsLoading = () => {},
    parentLocation = [],
    setSidePanelFolders,
    skipUpdatingSidePanel = false,
    clearAllSelectedItems = () => {},
}) => {
    try {
        setIsLoading(true);

        const selectedItemsArray = Object.values(selectedItems) || [];

        const data = await Promise.allSettled(
            selectedItemsArray?.map(({ id, content_type }) => {
                const route = ROUTE[content_type];
                return client.post(`${route}${id}/duplicate/`);
            })
        );

        showErrorAlertIfRequestError({ data, setErrorAlert });

        await mutateCashedDataAfterAction({
            requestKeysToMutate,
            currentRequestKeyToMutate,
            parentLocation,
        });

        const duplicatedFolders = getFoldersDataFromPromiseAllSettledResponse({
            data,
            selectedItemsArray,
        });

        if (!!duplicatedFolders?.length && setSidePanelFolders && !skipUpdatingSidePanel) {
            updateSidePanelStateAfterFoldersInsert({
                insertedFolders: duplicatedFolders,
                parentLocation,
                setSidePanelFolders,
            });
        }

        const duplicatedItemsCount =
            data.filter(({ status }) => status === 'fulfilled')?.length || 0;
        const successAlertMessage = `Duplicated ${duplicatedItemsCount} item${
            duplicatedItemsCount > 1 ? 's' : ''
        }`;
        setSuccessAlert({ message: successAlertMessage });
        clearAllSelectedItems();

        setIsLoading(false);
    } catch (e) {
        setIsLoading(false);
        setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
    }
};

export const handleCreateNewDoc = async ({
    folder,
    fileType = DOCUMENT_TYPE.document,
    setFileTypeLoading,
    setErrorAlert,
    navigate,
    location,
}) => {
    try {
        setFileTypeLoading(fileType);
        const requestBody =
            fileType === DOCUMENT_TYPE.diagram
                ? { ...EMPTY_DIAGRAM_DATA }
                : { ...EMPTY_DOCUMENT_DATA };
        if (folder) {
            requestBody.folder = folder;
        }

        const { data: doc } = await client.post(API.ROUTES.knowledge.document, requestBody);

        navigate(`/doc/${doc.id}`, { state: { from: location } });
    } catch (e) {
        setFileTypeLoading(null);
        setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
    }
};

export const mutateDocsDataAfterDocEditing = ({ requestKeysToMutate, docId, updatedValue }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: false,
        cb: (currentData) => {
            const updatedResults = currentData.results.map((doc) =>
                doc.id === docId ? { ...doc, value: updatedValue } : doc
            );
            return { ...currentData, results: updatedResults };
        },
    });
};

export const revalidateRequestKeysForParentFolder = ({ requestKeysToMutate, parentId }) => {
    const isRootFolder = !parentId;
    requestKeysToMutate?.map((key) => {
        if (isRootFolder && !key.includes(`parent=`)) {
            return mutate(key, null);
        }
        if (
            !isRootFolder &&
            (key.includes(`parent=${parentId}`) ||
                key.includes('query=') ||
                key.includes('search='))
        ) {
            return mutate(key, null);
        }
    });
};

export async function mutateCashedDataAfterAction({
    requestKeysToMutate,
    currentRequestKeyToMutate,
    parentLocation,
}) {
    if (currentRequestKeyToMutate) {
        await mutate(currentRequestKeyToMutate);
    }

    if (!parentLocation) return;

    const restRequestKeysToMutate = requestKeysToMutate?.filter(
        (key) => key !== currentRequestKeyToMutate
    );
    let parentId = null;
    if (parentLocation?.length > 0) {
        parentId = parentLocation[parentLocation.length - 1]?.id;
    }
    revalidateRequestKeysForParentFolder({
        requestKeysToMutate: restRequestKeysToMutate,
        parentId,
    });
}

export async function mutateCashedDataAfterMoveAction({
    requestKeysToMutate,
    currentRequestKeyToMutate,
    movedItemsParentLocation,
    targetFolderLocation,
    waitForMutate = true,
}) {
    if (currentRequestKeyToMutate) {
        if (waitForMutate) {
            await mutate(currentRequestKeyToMutate);
        } else {
            mutate(currentRequestKeyToMutate);
        }
    }

    const restRequestKeysToMutate = requestKeysToMutate?.filter(
        (key) => key !== currentRequestKeyToMutate
    );

    [movedItemsParentLocation, targetFolderLocation].map((location) => {
        if (!location) return;

        let parentId = null;
        if (location?.length > 0) {
            parentId = location[location.length - 1]?.id;
        }
        revalidateRequestKeysForParentFolder({
            requestKeysToMutate: restRequestKeysToMutate,
            parentId,
        });
    });
}

export const getFoldersDataFromPromiseAllSettledResponse = ({ data, selectedItemsArray }) => {
    return data.reduce((acc, { status, value }, index) => {
        const itemContentType = selectedItemsArray[index]?.content_type;
        if (status === 'fulfilled' && itemContentType === CONTENT_TYPE.folder) {
            const { id, label, folders } = value.data;
            acc.push({ id, label, folders });
        }
        return acc;
    }, []);
};

export const showErrorAlertIfRequestError = ({ data, setErrorAlert }) => {
    const rejectedRequests = data.filter(({ status }) => status === 'rejected');
    const allRequestsAreRejected = rejectedRequests.length === data.length;
    if (allRequestsAreRejected) {
        throw new Error('request error');
    }

    const areSomeRejectedRequests = !!rejectedRequests.length;
    if (areSomeRejectedRequests) {
        setErrorAlert({ message: defaultErrorMessage });
    }
};

export const moveItemsAPIRequest = async ({ targetFolder, itemsToMove, setErrorAlert }) => {
    const data = await Promise.allSettled(
        itemsToMove?.map(({ id, content_type }) => {
            const route = ROUTE[content_type];
            const targetFolderId = targetFolder.isRootFolder ? null : targetFolder.id;
            const requestBody =
                content_type === CONTENT_TYPE.folder
                    ? { parent: targetFolderId }
                    : { folder: targetFolderId };
            return client.patch(`${route}${id}/`, requestBody);
        })
    );

    showErrorAlertIfRequestError({ data, setErrorAlert });

    return data;
};
