import React from 'react';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';
import Panel from '../../../components/Panel';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import PlaybookMainInfo from '../PlaybookMainInfo/PlaybookMainInfo';
import CreationAndUpdateLog from '../CreationAndUpdateLog/CreationAndUpdateLog';
import PlaybookEntitiesBlock from '../PlaybookEntitiesBlock/PlaybookEntitiesBlock';
import PlaybookBuilderNavigation from '../PlaybookBuilderNavigation/PlaybookBuilderNavigation';

export const sectionContainerStyles = 'pb-8 lg:pb-4 border-b-1 border-neutral-200';

const PlaybookDetailsPanel = ({ playbook, onClose }) => {
    const { isDesktop } = useResponsiveBreakpoints();
    const WrapperComponent = isDesktop ? Panel : MobilePopup;

    const title = playbook.is_shared ? 'Shared Playbook Details' : 'Playbook Details';

    const { id, scenarios, processes, is_shared } = playbook;

    return (
        <WrapperComponent onClose={onClose} closeByClickOutside showControls={false} resetPadding>
            <div className="min-h-full flex flex-col justify-between gap-3 lg:pt-4 lg:px-8 lg:overflow-hidden">
                <div className="flex items-start justify-between gap-5 mb-3 lg:mb-0">
                    <p className="font-heading-bold text-heading-bold-s lg:text-heading-bold-m text-neutral-500">
                        {title}
                    </p>
                    {isDesktop && (
                        <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                    )}
                </div>

                <div className="flex flex-col gap-8 lg:gap-4 lg:flex-grow lg:overflow-y-auto lg:-mr-6 lg:pr-6 pb-3 lg:pb-5">
                    <PlaybookMainInfo playbook={playbook} />
                    <CreationAndUpdateLog playbook={playbook} />

                    <PlaybookEntitiesBlock type="projects" items={scenarios} playbookId={id} />
                    <PlaybookEntitiesBlock type="agents" items={processes} playbookId={id} />
                </div>

                <div className="pb-1 lg:pb-8">
                    <PlaybookBuilderNavigation playbookId={id} isPlaybookShared={is_shared} />
                </div>
            </div>
        </WrapperComponent>
    );
};

export default PlaybookDetailsPanel;
