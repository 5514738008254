import React from 'react';

import { SELECTION_TYPE } from '../../../../constants/selection';
import { CONTENT_TYPE_ICON } from '../../../../constants/docs';

import CheckboxListItem from '../../../../design-system/CheckboxListItem/CheckboxListItem';
import FileTextLineIcon from '../../../../design-system/Icons/FileTextLineIcon';
import InfiniteScrollList from '../../../../components/InfiniteScrollList/InfiniteScrollList';
import SelectableListItem from '../../../../design-system/SelectableListItem/SelectableListItem';

const DocsListWithSearch = ({
    filteredOptions,
    selectedDocs = {},
    docsOptionsHookResponse,
    handleChange,
    selectionType = SELECTION_TYPE.multiple,
}) => {
    const { optionsLoading, setPage, canLoadMoreOptions } = docsOptionsHookResponse;

    return (
        <InfiniteScrollList
            handleFetch={() => setPage((page) => page + 1)}
            canLoadMore={canLoadMoreOptions}
            items={filteredOptions}
            loading={optionsLoading}
            gap={0}
        >
            {filteredOptions.map((doc) => {
                const { id, content_type } = doc;
                const icon = CONTENT_TYPE_ICON[content_type] || FileTextLineIcon;

                if (selectionType === SELECTION_TYPE.multiple) {
                    const isChecked = !!selectedDocs[id];

                    return (
                        <CheckboxListItem
                            key={id}
                            item={doc}
                            isChecked={isChecked}
                            handleChange={handleChange}
                            icon={icon}
                        />
                    );
                }

                const isSelected = selectedDocs?.[id];

                return (
                    <SelectableListItem
                        key={id}
                        item={doc}
                        handleSelect={handleChange}
                        isSelected={isSelected}
                        icon={icon}
                    />
                );
            })}
        </InfiniteScrollList>
    );
};

export default DocsListWithSearch;
