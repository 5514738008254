import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormState } from '../../hooks/useFormState';
import { prepareMarkdownOnLoad } from '../../helpers/normalizeMarkdown';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../constants/errorMessages';

import { Button } from '../../design-system';
import ErrorAlert from '../../design-system/ErrorAlert/ErrorAlert';
import NewRichTextArea from '../../design-system/NewRichTextArea/NewRichTextArea';

const RichTextFieldEditingState = ({ value, handleSave, stopEditing }) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        () => ({ value: prepareMarkdownOnLoad(value) })
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSubmit = async () => {
        if (!formData.value) {
            setFieldErrorMessages({ value: emptyFieldErrorMessage });
            return;
        }

        const isValueDifferent = formData.value !== value;
        if (!isValueDifferent) {
            stopEditing();
            return;
        }

        try {
            setIsLoading(true);

            await handleSave(formData.value);

            stopEditing();
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        } finally {
            setIsLoading(false);
        }
    };

    const submitButtonState = isLoading ? 'loading' : 'default';

    return (
        <div className="flex flex-col gap-2">
            <NewRichTextArea
                value={formData.value || ''}
                state={fieldErrorMessages.value ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.value}
                setValue={(value) => handleInputChange('value', value)}
                autoExpand
                minHeight={200}
            />

            <div className="flex items-center justify-between gap-2">
                <Button type="neutral" size="xs" text="Cancel" onClick={stopEditing} />
                <Button
                    type="secondary"
                    size="xs"
                    text="Save"
                    state={submitButtonState}
                    onClick={handleSubmit}
                />
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

RichTextFieldEditingState.propTypes = {
    value: PropTypes.string,
    handleSave: PropTypes.func.isRequired,
    stopEditing: PropTypes.func.isRequired,
};

export default RichTextFieldEditingState;
