export const handleUpdateFoldersState = ({
    setFolders,
    location,
    cb,
    callbackForRootFolders,
    shouldSort = false,
}) => {
    if (!location) return;

    if (location.length === 0 && callbackForRootFolders) {
        setFolders((prevFolders) => callbackForRootFolders(prevFolders));
        return;
    }

    setFolders((prevFolders) => {
        const recursiveUpdateState = (state, location, index) => {
            const currentFolderId = location[index];

            if (!state) {
                return null;
            }

            if (index === location.length - 1) {
                const updatedState = state?.map((folder) => {
                    if (folder.id === currentFolderId) {
                        return cb(folder);
                    }

                    return folder;
                });

                if (shouldSort) {
                    return updatedState?.sort((a, b) => a.label.localeCompare(b.label));
                }
                return updatedState;
            }

            return state?.map((folder) => {
                if (folder.id === currentFolderId) {
                    return {
                        ...folder,
                        folders: recursiveUpdateState(folder.folders, location, index + 1),
                    };
                }

                return folder;
            });
        };

        return recursiveUpdateState(prevFolders, location, 0);
    });
};

export const handleExpandSidePanelFolder = ({ setExpandedFolders, location }) => {
    if (!location) return;

    if (location?.length === 0) return;

    setExpandedFolders((prevExpandedFolders) => {
        const recursiveUpdateState = (state, location, index) => {
            const currentFolderId = location[index];

            if (index === location.length - 1) {
                const isExpanded = state[currentFolderId];
                if (!isExpanded) {
                    return { ...state, [currentFolderId]: true };
                }
                return state;
            }

            return {
                ...state,
                [currentFolderId]: {
                    ...recursiveUpdateState(state[currentFolderId] ?? {}, location, index + 1),
                },
            };
        };

        return recursiveUpdateState(prevExpandedFolders, location, 0);
    });
};

export const toggleSidePanelFolderExpand = ({ setExpandedFolders, location }) => {
    if (!location) return;

    setExpandedFolders((prevExpandedFolders) => {
        const recursiveUpdateState = (state, location, index) => {
            const currentFolderId = location[index];

            if (index === location.length - 1) {
                const isExpanded = state[currentFolderId];
                if (isExpanded) {
                    return { ...state, [currentFolderId]: false };
                } else {
                    return { ...state, [currentFolderId]: true };
                }
            }

            return {
                ...state,
                [currentFolderId]: {
                    ...recursiveUpdateState(state[currentFolderId] ?? {}, location, index + 1),
                },
            };
        };

        return recursiveUpdateState(prevExpandedFolders, location, 0);
    });
};

export const updateSidePanelStateAfterRemovingFolders = ({
    removedFoldersIds,
    parentLocation,
    setSidePanelFolders,
}) => {
    if (!removedFoldersIds?.length) return;

    const removeFolderFromState = (folder) => ({
        ...folder,
        folders: folder.folders?.filter((f) => !removedFoldersIds?.includes(f.id)),
    });

    const removeRootFolder = (folders) =>
        folders?.filter((f) => !removedFoldersIds?.includes(f.id));

    handleUpdateFoldersState({
        setFolders: setSidePanelFolders,
        location: parentLocation?.map(({ id }) => id),
        cb: removeFolderFromState,
        callbackForRootFolders: removeRootFolder,
    });
};

export const updateSidePanelStateAfterFoldersInsert = ({
    insertedFolders = [],
    parentLocation,
    setSidePanelFolders,
}) => {
    if (!insertedFolders?.length) return;

    const insertFolders = (folder) => {
        if (!folder.folders) return folder;

        const folders = [...(folder.folders || []), ...insertedFolders];
        const uniqueFolders = Array.from(
            new Map(folders.map((folder) => [folder.id, folder])).values()
        );

        return {
            ...folder,
            folders: uniqueFolders.sort((a, b) => a.label.localeCompare(b.label)),
        };
    };

    const insertRootFolders = (folders) => {
        const updatedFolders = [...(folders || []), ...insertedFolders];
        const uniqueFolders = Array.from(
            new Map(updatedFolders.map((folder) => [folder.id, folder])).values()
        );

        return uniqueFolders.sort((a, b) => a.label.localeCompare(b.label));
    };

    handleUpdateFoldersState({
        setFolders: setSidePanelFolders,
        location: parentLocation?.map(({ id }) => id),
        cb: insertFolders,
        callbackForRootFolders: insertRootFolders,
    });
};

export const updateSidePanelStateAfterMovingItems = ({
    targetFolder,
    movedFolders,
    isSearchApplied = false,
    movedItemsParentLocation,
    setSidePanelFolders,
    setExpandedFolders,
}) => {
    if (!setSidePanelFolders) return;

    if (movedFolders?.length) {
        const movedFoldersIds = movedFolders.map(({ id }) => id);

        if (!isSearchApplied) {
            updateSidePanelStateAfterRemovingFolders({
                removedFoldersIds: movedFoldersIds,
                parentLocation: movedItemsParentLocation,
                setSidePanelFolders,
            });
        }

        if (isSearchApplied) {
            removeMultipleFoldersFromSidePanel({
                setFolders: setSidePanelFolders,
                folderIdsArray: movedFoldersIds,
            });
        }

        updateSidePanelStateAfterFoldersInsert({
            insertedFolders: movedFolders,
            parentLocation: targetFolder.location,
            setSidePanelFolders,
        });
    }

    if (setExpandedFolders) {
        handleExpandSidePanelFolder({
            setExpandedFolders,
            location: targetFolder.location?.map(({ id }) => id),
        });
    }
};

export const findFolderLocation = ({ folders, folderId }) => {
    if (!folders) return undefined;

    const recursion = (folders, location) => {
        for (let i = 0; i < folders.length; ++i) {
            const folder = folders[i];
            const newLocation = [...location, { id: folders[i].id, label: folders[i].label }];

            if (folder.id === folderId) {
                return newLocation;
            }

            if (folder.folders) {
                const result = recursion(folder.folders, newLocation);
                if (!!result?.length) {
                    return result;
                }
            }
        }
    };

    return recursion(folders, []);
};

export const removeFolderFromSidePanel = ({ setFolders, folderId }) => {
    setFolders((prev) => {
        if (!prev) return;

        const recursion = (folders) => {
            for (let i = 0; i < folders.length; ++i) {
                if (folders[i].id === folderId) {
                    return [...folders.slice(0, i), ...folders.slice(i + 1)];
                }

                if (!!folders[i].folders?.length) {
                    const result = recursion(folders[i].folders);
                    if (result) {
                        return [
                            ...folders.slice(0, i),
                            { ...folders[i], folders: result },
                            ...folders.slice(i + 1),
                        ];
                    }
                }
            }
        };

        return recursion(prev);
    });
};

export const removeMultipleFoldersFromSidePanel = ({ setFolders, folderIdsArray }) => {
    if (!folderIdsArray?.length) return;

    setFolders((prev) => {
        if (!prev) return;

        let restIdsToRemove = [...folderIdsArray];

        const recursion = (folders) => {
            if (!restIdsToRemove?.length) return;

            return folders.filter((folder) => {
                if (folderIdsArray.includes(folder.id)) {
                    restIdsToRemove = restIdsToRemove.filter((id) => id !== folder.id);
                    return false;
                }

                if (!!folder.folders?.length && restIdsToRemove?.length) {
                    folder.folders = recursion(folder.folders);
                }

                return true;
            });
        };

        return recursion(prev);
    });
};
