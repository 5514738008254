import React from 'react';
import { DateTime } from 'luxon';

import { STATUS } from '../../../constants/statuses';

import Loader2Icon from '../../../design-system/Icons/Loader2Icon';
import TimestampDisplay from '../../../design-system/TimestampDisplay/TimestampDisplay';

const SourceLastSyncBlock = ({ ingestion_status, isHighlighted = false }) => {
    const { status, updated_at } = ingestion_status || {};

    const isSyncInProgress = status !== STATUS.success;

    const formattedTime = DateTime.fromISO(updated_at).toFormat("MM/dd/yyyy '@' h:mm a");

    return (
        <div>
            {status === STATUS.success && (
                <>
                    {!isHighlighted && (
                        <p className="font-body text-body-regular-xs text-neutral-500 truncate">
                            Last sync: {formattedTime}
                        </p>
                    )}

                    {isHighlighted && (
                        <TimestampDisplay
                            timestamp={updated_at}
                            label="Last sync:"
                            dateTimeFormat="MM/dd/yyyy '@' h:mm a"
                            withClockIcon
                        />
                    )}
                </>
            )}

            {isSyncInProgress && (
                <div className="flex items-center gap-1 min-h-[18px] overflow-hidden">
                    <div className="min-w-[14px] animate-spin-slow">
                        <Loader2Icon width={14} height={14} color="#C6B8E7" />
                    </div>

                    <p className="font-body text-body-regular-xs text-neutral-500 truncate">
                        Sync in progress
                    </p>
                </div>
            )}
        </div>
    );
};

export default SourceLastSyncBlock;
