import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import { openSourceBySourceType } from '../../../helpers/assistantSourcesUtils';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import { ListOption, ListOptionGroup } from '../../../design-system';

const KnowledgeSourceMoreOptionsPopup = ({
    id,
    type,
    name,
    detail,
    refetchBotDetail,
    openSourceDetailsPopup,
    setSuccessAlert,
    isSyncInProgress,
    modalRef,
    yPosition,
    onClose,
}) => {
    const { isMobile } = useResponsiveBreakpoints();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    if (!modalRef?.current && !isMobile) return null;

    const handleViewSource = () => {
        openSourceBySourceType({ source_type: type, detail });
        onClose();
    };

    const handleSyncSource = async () => {
        try {
            setIsLoading(true);

            await client.post(`${API.ROUTES.knowledge.source}${id}/sync/`);
            await refetchBotDetail();

            setSuccessAlert({
                message: 'Source sync request submitted! This may take a few moments.',
            });
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const options = (
        <>
            <ListOption
                leadingIconName="informationLineIcon"
                text="About source"
                onClick={() => openSourceDetailsPopup({ id, name, type })}
            />
            <ListOption
                leadingIconName="shareBoxLineIcon"
                text="View source"
                onClick={handleViewSource}
            />
            {!isSyncInProgress && (
                <ListOption
                    leadingIconName="loopRightFillIcon"
                    text="Sync source"
                    isLoading={isLoading}
                    onClick={handleSyncSource}
                />
            )}

            {errorAlert && <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />}
        </>
    );

    if (isMobile) {
        return (
            <MobilePopup onClose={onClose} closeButtonText="Cancel">
                <div className="flex flex-col gap-1">{options}</div>
            </MobilePopup>
        );
    }

    return createPortal(
        <div className="absolute right-10 z-100 pb-1" style={{ ...yPosition }}>
            <ListOptionGroup fixedWidth={300} listOptions={options} handleClose={onClose} />
        </div>,
        modalRef.current
    );
};

export default KnowledgeSourceMoreOptionsPopup;
