import React, { useState } from 'react';

import client from '../../../../services/template-api';
import { TEMPLATE_TYPES, templateAPIRoutes } from '../../../../constants/template';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import { getIdArray } from '../../../../helpers/getIdArray';
import { normalizeMarkdown, prepareMarkdownOnLoad } from '../../../../helpers/normalizeMarkdown';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useFormState } from '../../../../hooks/useFormState';
import { useFetchOptionsForPaginatedSelect } from '../../../../hooks/useFetchOptionsForPaginatedSelect';

import { Button, ButtonIcon, Tabs } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import OverviewTabForm from '../OverviewTabForm/OverviewTabForm';
import HowItWorksTabForm from '../HowItWorksTabForm/HowItWorksTabForm';
import ConnectionsTabForm from '../ConnectionsTabForm/ConnectionsTabForm';
import ProcessTemplateDetailsModal from '../../ProcessTemplateDetailsModal/ProcessTemplateDetailsModal';
import EyeIcon from '../../../../design-system/Icons/EyeIcon';
import Alert from '../../../../design-system/Alert/Alert';

const tabs = [{ name: 'Overview' }, { name: 'How it works' }, { name: 'Connections' }];

const ProcessDetailsBlock = ({ processTemplateData, setProcessTemplateData }) => {
    const { id, name, description, labels, instructions, details } = processTemplateData;

    const [tabIndex, setTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const [isPreviewModalOpened, setIsPreviewModalOpened] = useState(false);
    const { width: screenWidth } = useWindowSize();

    const formStateHookResponse = useFormState(() => {
        const initialFormState = {
            name,
            description,
            labels,
            instructions: prepareMarkdownOnLoad(instructions),
            connected_providers: details.connected_providers,
            connected_processes: getIdArray(details.connected_processes),
        };

        return initialFormState;
    }, true);
    const {
        formData,
        changedFields,
        handleInputChange,
        resetChangedFields,
        setFieldErrorMessages,
    } = formStateHookResponse;

    const formatPublishedProcessTemplates = (results) => {
        return (
            results
                ?.filter(({ id }) => id !== processTemplateData.id)
                ?.map(({ id, name }) => ({ id, name })) || []
        );
    };

    const fetchProcessTemplatesOptionsHookResponse = useFetchOptionsForPaginatedSelect({
        client,
        route: templateAPIRoutes[TEMPLATE_TYPES.process],
        searchParams: { is_published: true },
        formatResponseToOptions: formatPublishedProcessTemplates,
    });

    const onTabChanged = (tab, index) => {
        setTabIndex(index);
    };

    const getSaveChangesRequestBody = () =>
        changedFields.reduce((acc, field) => {
            if (field === 'labels') {
                // get array of labels id
                return { ...acc, labels: getIdArray(formData.labels) };
            }
            if (field === 'instructions') {
                return { ...acc, instructions: normalizeMarkdown(formData.instructions) };
            }
            if (field === 'connected_processes' || field === 'connected_providers') {
                // for fields "connected_processes" and "connected_providers" send them together in {details: {connected_providers: [], connected_processes: []}} object
                if (!acc.details) {
                    return {
                        ...acc,
                        details: {
                            connected_providers: formData.connected_providers,
                            connected_processes: getIdArray(formData.connected_processes),
                        },
                    };
                }
                return acc;
            }
            return { ...acc, [field]: formData[field] };
        }, {});

    const handleSaveChanges = async () => {
        // if there are no data to save
        if (!changedFields.length) {
            return;
        }

        // The fields name, description, and instructions are required, while labels, connected_providers, and connected_processes are optional.
        // We can use the expression some((field) => !formData[field]) for areEmptyRequiredFields because labels, connected_providers, and connected_processes are arrays, so even if arrays are empty, !formData[field] evaluates to false.
        const areEmptyRequiredFields = !!changedFields.some((field) => !formData[field]);
        if (areEmptyRequiredFields) {
            const errorMessages = changedFields.reduce(
                (acc, field) =>
                    !formData[field] ? { ...acc, [field]: emptyFieldErrorMessage } : acc,
                {}
            );
            setFieldErrorMessages(errorMessages);
            setErrorAlert({ errorMessages: 'All required fields should be filled.' });
            return;
        }

        try {
            setIsLoading(true);
            const requestBody = getSaveChangesRequestBody();
            const { data } = await client.patch(
                `${templateAPIRoutes[TEMPLATE_TYPES.process]}${id}/`,
                requestBody
            );
            setProcessTemplateData((prevData) => ({ ...prevData, ...data }));
            handleInputChange('instructions', data.instructions || '');
            resetChangedFields();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ errorMessages: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const saveChangesButtonState = isLoading
        ? 'loading'
        : !!changedFields.length
        ? 'default'
        : 'disabled';

    return (
        <div className="p-4 md:px-8 w-full bg-white flex flex-col gap-5">
            <p className="font-body-bold text-body-bold-l text-neutral-500">Process Details</p>
            <div className="w-full max-w-[700px] flex flex-col gap-5">
                <div className="flex items-start min-[455px]:items-center gap-2">
                    <Tabs
                        tabs={tabs}
                        tabIndex={tabIndex}
                        onTabChanged={onTabChanged}
                        vertical={screenWidth < 455}
                    />

                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={EyeIcon}
                        onClick={() => setIsPreviewModalOpened(true)}
                    />
                </div>

                {tabIndex === 0 && (
                    <OverviewTabForm formStateHookResponse={formStateHookResponse} />
                )}

                {tabIndex === 1 && (
                    <HowItWorksTabForm formStateHookResponse={formStateHookResponse} />
                )}

                <div className={`${tabIndex !== 2 && 'hidden'}`}>
                    <ConnectionsTabForm
                        formStateHookResponse={formStateHookResponse}
                        fetchProcessTemplatesOptionsHookResponse={
                            fetchProcessTemplatesOptionsHookResponse
                        }
                        connectedProcesses={processTemplateData.details.connected_processes}
                    />
                </div>
                <div>
                    <Button
                        type="primary"
                        size="sm"
                        text="Save Changes"
                        state={saveChangesButtonState}
                        onClick={handleSaveChanges}
                    />
                </div>
            </div>
            {isPreviewModalOpened && (
                <ProcessTemplateDetailsModal
                    processTemplateData={processTemplateData}
                    onClose={() => setIsPreviewModalOpened(false)}
                />
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default ProcessDetailsBlock;
