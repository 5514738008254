import React from 'react';
import { createPortal } from 'react-dom';

import { ListOption, ListOptionGroup } from '../../../../design-system';
import AddNewSourceOption from '../AddNewSourceOption/AddNewSourceOption';

const AddDataSourcePopup = ({
    panelRef,
    yPosition,
    setNewSourcePanel,
    isGoogleCloudConnected,
    openAddExistingSourceModal,
    onClose,
}) => {
    if (!panelRef.current) return null;

    const handleAction = (action) => {
        action();
        onClose();
    };

    const openNewSourcePanel = ({ type }) => {
        handleAction(() => setNewSourcePanel({ type }));
    };

    return createPortal(
        <div
            className="absolute right-4 md:right-5 xl:right-unset xl:left-[calc(100%-80px)] z-60 pb-5"
            style={{ ...yPosition }}
        >
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <div className="w-full flex flex-col pr-4">
                            <ListOption
                                leadingIconName="fileHistoryLineIcon"
                                text="Connect existing source"
                                onClick={() => handleAction(openAddExistingSourceModal)}
                            />
                        </div>

                        <AddNewSourceOption
                            openNewSourcePanel={openNewSourcePanel}
                            isGoogleCloudConnected={isGoogleCloudConnected}
                        />
                    </>
                }
                additionalStyles="!pr-0 !pb-0"
                withOverflowProperty={false}
                handleClose={onClose}
            />
        </div>,
        panelRef.current
    );
};

export default AddDataSourcePopup;
