import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/inbox-api';

import useUser from '../../../../hooks/useUser';
import { prepareMarkdownOnLoad } from '../../../../helpers/normalizeMarkdown';
import { compareObjectsByActionType } from '../../../../helpers/compareObjectsByActionType';

import JobTaskPageModalContainer from '../../JobTaskPageModalContainer/JobTaskPageModalContainer';
import MessageContentForm from '../../../InboxPage/MessageContentForm/MessageContentForm';
import InboxIndicatorIcon from '../../../../design-system/Icons/InboxIndicatorIcon';
import MessageDetailHeader from '../../../InboxPage/MessageDetailHeader/MessageDetailHeader';
import { Button } from '../../../../design-system';

const ViewInboxMessageModal = ({ messageId, setErrorAlert, onClose }) => {
    const { user } = useUser();
    const modalContentRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();

    const [messageDetail, setMessageDetail] = useState(null);
    const [messageContentFormData, setMessageContentFormData] = useState(null);

    const bodyTypeFormFieldsCount = useMemo(
        () => messageContentFormData?.filter((item) => item.action_type === 'body')?.length || 0,
        [messageContentFormData]
    );

    useEffect(() => {
        const fetchMessageDetail = async () => {
            try {
                const { data: message } = await client.get(
                    `${API.ROUTES.inbox.message}${messageId}/`
                );
                setMessageDetail(message);
                const content =
                    message.status === 'completed' ? message.final_output : message.message_content;

                const formattedContent =
                    content?.map((item) => {
                        if (item.action_type === 'freeform') {
                            return { ...item, value: prepareMarkdownOnLoad(item.value) };
                        }
                        return item;
                    }) || [];
                setMessageContentFormData([...formattedContent].sort(compareObjectsByActionType)); // set objects with action_type === 'body' first

                if (message.status === 'archived' || message.status === 'deleted') {
                    setErrorAlert({
                        message: 'This message has been deleted. Please select another to view.',
                    });
                    onClose();
                }
            } catch (e) {
                setErrorAlert({
                    message: 'Oops! Something went wrong. Please select another message to view.',
                });
                onClose();
            }
        };

        fetchMessageDetail();
    }, []);

    const handleNavigateToInbox = () => {
        const status = messageDetail.status === 'completed' ? 'completed' : 'pending';
        navigate(`/inbox?message_id=${messageId}&status=${status}`, { state: { from: location } });
    };

    const goToInboxButtonState = messageDetail?.assigned_to === user?.id ? 'default' : 'disabled';

    return (
        <JobTaskPageModalContainer
            onClose={onClose}
            title="Viewing Inbox Task"
            isDataLoaded={!!messageDetail}
        >
            <div ref={modalContentRef} className="flex flex-col gap-6">
                <MessageDetailHeader
                    message={messageDetail}
                    containerRef={modalContentRef}
                    isSimplifiedView
                />
                <div className="flex flex-col">
                    <MessageContentForm
                        formData={messageContentFormData}
                        editable={false}
                        bodyTypeFormFieldsCount={bodyTypeFormFieldsCount}
                    />
                </div>
            </div>

            <div className="p-2 fixed bottom-1 right-1">
                <Button
                    type="secondary"
                    size="xs"
                    text="Go to Inbox"
                    state={goToInboxButtonState}
                    leadingIcon={InboxIndicatorIcon}
                    onClick={handleNavigateToInbox}
                />
            </div>
        </JobTaskPageModalContainer>
    );
};

export default ViewInboxMessageModal;
