import mermaid from 'mermaid';
import { jsPDF } from 'jspdf';

import { defaultErrorMessage } from '../constants/errorMessages';

const EMPTY_DIAGRAM_ERROR_MESSAGE = 'Diagram is empty';

export const isDiagramSyntaxValid = async (code) => {
    const result = await mermaid.parse(code, { suppressErrors: true });
    return !!result;
};

export const createDiagramImage = async ({ svg, maxDimension = 860 }) => {
    const box = svg.getBoundingClientRect();
    const padding = 20;

    const scale = maxDimension / Math.max(box.width, box.height);
    const scaledWidth = Math.round(box.width * scale);
    const scaledHeight = Math.round(box.height * scale);

    const canvasWidth = scaledWidth + padding * 2;
    const canvasHeight = scaledHeight + padding * 2;

    const canvas = document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const context = canvas.getContext('2d');
    if (!context) {
        console.log('Context not found');
        return;
    }

    context.fillStyle = '#FFFFFF';
    context.fillRect(0, 0, canvas.width, canvas.height);

    const fontUrl = 'https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap';
    const fontCss = await fetch(fontUrl).then((res) => res.text());
    const fontBase64 = btoa(unescape(encodeURIComponent(fontCss)));

    const clonedSvg = svg.cloneNode(true);
    const style = document.createElementNS('http://www.w3.org/2000/svg', 'style');
    style.textContent = `
        @font-face {
            font-family: 'Inter';
            src: url('data:text/css;base64,${fontBase64}');
        }
        text, tspan, p, div {
            font-family: 'Inter', sans-serif !important;
        }
    `;
    clonedSvg.insertBefore(style, clonedSvg.firstChild);

    const svgData = new XMLSerializer().serializeToString(clonedSvg);
    const encodedData = window.btoa(unescape(encodeURIComponent(svgData)));

    const image = new Image();
    image.src = `data:image/svg+xml;base64,${encodedData}`;

    const drawImage = async () => {
        await context.drawImage(image, padding, padding, scaledWidth, scaledHeight);
    };

    return {
        image,
        canvas,
        drawImage,
        dimensions: {
            width: canvasWidth,
            height: canvasHeight,
            scaledWidth,
            scaledHeight,
        },
    };
};

export const exportDiagramAsPng = async ({ diagramRef, diagramName, setErrorAlert }) => {
    if (!diagramRef.current) return;

    try {
        const svg = diagramRef.current.querySelector('svg');
        if (!svg) {
            console.log('No diagram found to export');
            return;
        }

        const { image, canvas, drawImage } = await createDiagramImage({ svg });
        image.onload = async () => {
            try {
                await drawImage();
                const link = document.createElement('a');

                const downloadName = diagramName || 'diagram';
                link.download = `${downloadName}.png`;

                link.href = canvas.toDataURL('image/png');
                link.click();
            } catch (e) {
                setErrorAlert && setErrorAlert({ message: defaultErrorMessage });
            }
        };
    } catch (error) {
        setErrorAlert && setErrorAlert({ message: defaultErrorMessage });
    }
};

export const exportDiagramAsPdf = async ({ diagramRef, diagramName, setErrorAlert }) => {
    try {
        if (!diagramRef.current) {
            throw new Error(EMPTY_DIAGRAM_ERROR_MESSAGE);
        }
        const svg = diagramRef.current?.querySelector('pre > svg');

        if (!svg) {
            throw new Error(EMPTY_DIAGRAM_ERROR_MESSAGE);
        }
        const { image, canvas, drawImage, dimensions } = await createDiagramImage({
            svg,
            maxDimension: 1500,
        });

        image.onload = async () => {
            try {
                await drawImage();
                const imgData = canvas.toDataURL('image/png');

                const isLandscape = dimensions.width > dimensions.height;
                const orientation = isLandscape ? 'landscape' : 'portrait';

                const pdf = new jsPDF(orientation, 'pt', 'a4');

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                const margin = 40; // Margin on all sides
                const maxWidth = pdfWidth - margin * 2;
                const maxHeight = pdfHeight - margin * 2;

                const scaleX = maxWidth / dimensions.width;
                const scaleY = maxHeight / dimensions.height;
                const scale = Math.min(scaleX, scaleY);

                const scaledWidth = dimensions.width * scale;
                const scaledHeight = dimensions.height * scale;

                const x = (pdfWidth - scaledWidth) / 2;
                const y = (pdfHeight - scaledHeight) / 2;

                await pdf.addImage(imgData, 'PNG', x, y, scaledWidth, scaledHeight);

                const name = diagramName || 'Diagram';

                pdf.save(`${name}.pdf`);
            } catch (e) {
                setErrorAlert && setErrorAlert({ message: defaultErrorMessage });
            }
        };
    } catch (error) {
        let message = defaultErrorMessage;
        if (error.message === EMPTY_DIAGRAM_ERROR_MESSAGE) {
            message = 'No diagram to export';
        }
        setErrorAlert && setErrorAlert({ message });
    }
};
