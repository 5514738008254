import { useEffect } from 'react';

const useRemoveBgOnCopy = () => {
    useEffect(() => {
        const handleRemoveBgColorWhileCopying = async (event) => {
            const selection = window.getSelection();
            const activeElement = document.activeElement;

            if (!selection.rangeCount) return;

            if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA') {
                return;
            }

            if (!navigator.clipboard || !window.isSecureContext) {
                console.warn('Clipboard API is not available');
                return;
            }

            if (typeof ClipboardItem === 'undefined') {
                console.warn('ClipboardItem is not available');
                return;
            }

            event.preventDefault();

            const tempDiv = document.createElement('div');
            tempDiv.appendChild(selection.getRangeAt(0).cloneContents());

            tempDiv.querySelectorAll('*').forEach((el) => {
                el.style.backgroundColor = 'transparent';
            });

            const htmlContent = tempDiv.innerHTML.replace(/\n\s*/g, '').trim();
            const textContent = selection.toString();

            await navigator.clipboard.write([
                new ClipboardItem({
                    'text/html': new Blob([htmlContent], { type: 'text/html' }),
                    'text/plain': new Blob([textContent], { type: 'text/plain' }),
                }),
            ]);
        };

        document.addEventListener('copy', handleRemoveBgColorWhileCopying);
        return () => document.removeEventListener('copy', handleRemoveBgColorWhileCopying);
    }, []);
};

export default useRemoveBgOnCopy;
