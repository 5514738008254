import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import { useFormState } from '../../../hooks/useFormState';
import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import {
    handleUpdateFoldersState,
    handleExpandSidePanelFolder,
} from '../../../helpers/docsSidePanelUtils';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import { Button, Input } from '../../../design-system';

const NewFolderModal = ({ onClose }) => {
    const { folderId } = useParams();

    const { formData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } = useFormState(
        { label: '' }
    );

    const { setSidePanelFolders, currentRequestKeyToMutate, setExpandedFolders } =
        useDocsPageContext() || {};

    const [loadingState, setLoadingState] = useState('default');
    const [errorAlert, setErrorAlert] = useState(null);

    const { isMobile } = useResponsiveBreakpoints();

    const createFolder = async () => {
        const { label } = formData;
        if (!label) {
            setFieldErrorMessages({ label: emptyFieldErrorMessage });
            return;
        }

        try {
            setLoadingState('loading');

            const requestBody = { label };
            if (folderId) {
                requestBody.parent = folderId;
            }
            const { data } = await client.post(API.ROUTES.knowledge.folder, requestBody);

            const parentLocation = data.location?.slice(0, -1) || [];

            const insertNewFolder = (folder) => {
                if (!folder.folders) return { ...folder, folders: [data] };

                const updatedFolders = [...folder.folders, data].sort((a, b) =>
                    a.label.localeCompare(b.label)
                );

                return { ...folder, folders: updatedFolders };
            };

            const insertRootFolder = (folders) => {
                const updatedFolders = [...(folders || []), data].sort((a, b) =>
                    a.label.localeCompare(b.label)
                );
                return updatedFolders;
            };

            if (currentRequestKeyToMutate) {
                await mutate(currentRequestKeyToMutate);
            }

            handleUpdateFoldersState({
                setFolders: setSidePanelFolders,
                location: parentLocation.map(({ id }) => id),
                cb: insertNewFolder,
                callbackForRootFolders: insertRootFolder,
            });

            handleExpandSidePanelFolder({
                setExpandedFolders,
                location: parentLocation.map(({ id }) => id),
            });

            onClose();
        } catch (e) {
            setLoadingState('default');
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response.status });
        }
    };

    const input = (
        <Input
            size="md"
            name="folder_name"
            value={formData.label}
            placeholder="Folder name"
            state={fieldErrorMessages.label ? 'error' : 'default'}
            errorMessage={fieldErrorMessages.label}
            onChange={(e) => handleInputChange('label', e.target.value)}
        />
    );

    const buttonSize = isMobile ? 'sm' : 'md';
    const controls = (
        <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between gap-2">
            <Button size={buttonSize} type="neutral" text="Cancel" onClick={onClose} />
            <Button
                size={buttonSize}
                type="secondary"
                text="Add Folder"
                state={loadingState}
                onClick={createFolder}
            />
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                bottomButtonsGroup={controls}
                containerCustomPaddings="!pt-0 pb-5"
                contentCustomPaddings="!py-0"
                bottomButtonsCustomPaddings="px-5"
                withCloseButton={false}
                customGap={32}
            >
                <div className="flex flex-col gap-5">
                    <ModalHeader
                        title="New Folder"
                        outlined
                        customPaddings="px-5 py-3"
                        withEscapeButton={false}
                        onClose={onClose}
                    />
                    <div className="px-5">{input}</div>
                </div>
            </MobilePopup>
        );
    }

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title="New Folder" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                {input}

                {controls}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default NewFolderModal;
