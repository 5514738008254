import React from 'react';
import { HISTORY_GROUP_LABEL } from '../../../constants/assistant';

const HistoryGroupContainer = ({ group, customLabel, children }) => {
    const groupLabel = customLabel || HISTORY_GROUP_LABEL[group];

    return (
        <div className="flex flex-col gap-1">
            <p className="font-body-bold text-body-bold-xs text-neutral-300">{groupLabel}</p>

            {children}
        </div>
    );
};

export default HistoryGroupContainer;
