import React from 'react';

import { DateTime } from 'luxon';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import ClockIcon from '../Icons/ClockIcon';

const TimestampDisplay = ({
    label,
    dateTimeFormat: _dateTimeFormat,
    timestamp,
    variant = 'primary',
    customLabelStyles = '',
    withClockIcon = false,
}) => {
    const dateTimeFormat = _dateTimeFormat || 'MM/dd/yyyy, hh:mm a';
    const formattedTime = DateTime.fromISO(timestamp).toFormat(dateTimeFormat);

    const labelClassName = classNames(
        'whitespace-nowrap truncate',
        {
            'font-body text-body-regular-xs text-neutral-300': variant === 'primary',
            'font-body-bold text-body-bold-xs text-neutral-300': variant === 'secondary',
        },
        customLabelStyles
    );

    const timestampClassName = classNames({
        'font-body-bold text-body-bold-xs text-neutral-500': variant === 'primary',
        'font-body text-body-regular-xs text-neutral-300': variant === 'secondary',
    });

    return (
        <div className="flex items-center gap-1 overflow-hidden">
            {withClockIcon && (
                <div className="min-w-[14px] min-h-[14px]">
                    <ClockIcon width={14} height={14} color="#754DCF" />
                </div>
            )}

            <p className={labelClassName}>
                {label} <span className={timestampClassName}>{formattedTime}</span>
            </p>
        </div>
    );
};

TimestampDisplay.propTypes = {
    label: PropTypes.string.isRequired,
    dateTimeFormat: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['primary', 'secondary']),
    customLabelStyles: PropTypes.string,
    withClockIcon: PropTypes.bool,
};

export default TimestampDisplay;
