import React, { memo } from 'react';
import classNames from 'classnames';

import { containerXPaddings } from '../../ProjectDetailPage/ProjectDetailMainPanel/ProjectDetailMainPanel';

import { useOverlayState } from '../../../../hooks/useOverlayState';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import Switcher from '../../../../design-system/Switcher/Switcher';
// import ViewSwitcher from '../ViewSwitcher/ViewSwitcher';
import FeedbackBadge from '../../../../components/FeedbackBadge/FeedbackBadge';
import MilestonesBoard from '../MilestonesBoard/MilestonesBoard';
import FeedbackPanel from '../../../../components/FeedbackPanel/FeedbackPanel';

const MilestonesSection = ({ milestones, feedbackCount, feedback, view, setView }) => {
    const { isOpened, toggle: toggleFeedbackPanel } = useOverlayState();

    const { isDesktop } = useResponsiveBreakpoints();

    const isSeparateScroll = (!isOpened && view === 'column') || (isOpened && isDesktop);

    const containerClassName = classNames(
        'pt-2.5 flex flex-col flex-1 bg-neutral-50/70',
        isSeparateScroll && 'overflow-hidden'
    );

    const contentContainerClassName = classNames('flex', {
        'absolute inset-0 overflow-y-auto': isSeparateScroll,
        'h-full': isOpened,
    });

    const milestonesContainerClassNames = classNames(
        'h-full min-h-full flex-grow pt-5 pb-4 lg:pb-7 flex flex-col',
        containerXPaddings,
        {
            'overflow-y-auto w-full lg:w-[calc(100%-350px)] xl:w-[calc(100%-400px)] 2xl:w-[calc(100%-430px)]':
                isOpened,
            flex: !isOpened,
        }
    );

    return (
        <div className={containerClassName}>
            <div className="flex flex-col h-full max-h-full flex-grow">
                <div className={containerXPaddings}>
                    <div className="px-4 md:px-5 border-b-1 border-neutral-200 flex flex-col">
                        <div className="flex items-center justify-between">
                            <Switcher
                                tabs={[{ name: 'Job History' }]}
                                tabIndex={0}
                                onTabChanged={() => {}}
                            />

                            <FeedbackBadge
                                count={feedbackCount}
                                type="button"
                                highlighted={isOpened}
                                withHover
                                onClick={toggleFeedbackPanel}
                            />
                            {/*<ViewSwitcher view={view} setView={setView} />*/}
                        </div>
                    </div>
                </div>

                <div className="w-full flex-grow relative">
                    <div className={contentContainerClassName}>
                        <div className={milestonesContainerClassNames}>
                            <h3 className="font-heading-regular text-heading-regular-s text-black mb-5">
                                History of work completed:
                            </h3>

                            <MilestonesBoard
                                milestones={milestones}
                                view={view}
                                pageType="job"
                                entityName="Job Tasks"
                            />
                        </div>

                        {isOpened && (
                            <FeedbackPanel
                                feedback={feedback}
                                onClose={toggleFeedbackPanel}
                                desktopContainerClassName="w-full lg:w-[350px] xl:w-[400px] 2xl:w-[430px] lg:overflow-y-auto flex-grow h-full"
                                emptyFeedbackMessage="No feedback submitted for job"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(MilestonesSection);
