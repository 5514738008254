import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { openSourceBySourceType } from '../../../helpers/assistantSourcesUtils';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { Button } from '../../../design-system';
import { LoopRightFillIcon, ShareBoxLineIcon } from '../../../design-system/Icons';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import SourceIcon from '../../BotsPage/BotDetailPage/SourceIcon/SourceIcon';
import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import DetailsPopupLayout from '../DetailsPopupLayout/DetailsPopupLayout';
import SourceDetailsMainContent from '../SourceDetailsMainContent/SourceDetailsMainContent';

const SourceDetailsPopup = ({ id, name, type, openNewChatWithBot, onClose }) => {
    const [sourceDetails, setSourceDetails] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    useEffect(() => {
        if (!sourceDetails) {
            refetchSourceDetail();
        }
    }, []);

    const refetchSourceDetail = useCallback(async () => {
        try {
            const { data } = await client.get(`${API.ROUTES.knowledge.source}${id}/`);
            setSourceDetails(data);
        } catch (e) {
            onClose();
        }
    }, []);

    const handleViewSource = () => {
        if (!sourceDetails) return;

        const { type, detail } = sourceDetails;
        openSourceBySourceType({ source_type: type, detail });
    };

    const handleSyncSource = async () => {
        try {
            setIsLoading(true);

            await client.post(`${API.ROUTES.knowledge.source}${id}/sync/`);
            await refetchSourceDetail();

            setSuccessAlert({
                message: 'Source sync request submitted! This may take a few moments.',
            });
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const { isMobile } = useResponsiveBreakpoints();

    const isDataLoaded = !!sourceDetails;

    const controlsSize = isMobile ? 'sm' : 'xs';

    const controls = (
        <div className="flex flex-col gap-y-2 md:flex-row md:items-center gap-x-5">
            <div className="md:flex-1 flex flex-col">
                <Button
                    size={controlsSize}
                    type="neutral"
                    text="View Source"
                    leadingIcon={ShareBoxLineIcon}
                    onClick={handleViewSource}
                />
            </div>
            <div className="md:flex-1 flex flex-col">
                <Button
                    size={controlsSize}
                    type="neutral"
                    text="Sync Source"
                    state={isLoading ? 'loading' : 'default'}
                    leadingIcon={LoopRightFillIcon}
                    onClick={handleSyncSource}
                />
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </div>
    );

    const headerClassName = classNames(
        'flex gap-2',
        isDataLoaded && 'pb-4 lg:pb-5 border-b-1 border-neutral-200'
    );

    const header = (
        <div className={headerClassName}>
            <SourceIcon type={type} isHighlighted size="large" />
            <p className="font-heading-bold text-heading-bold-m text-black my-auto">{name}</p>
        </div>
    );

    return (
        <DetailsPopupLayout
            onClose={onClose}
            isLoaded={isDataLoaded}
            header={header}
            bottomControls={controls}
            loaderHeight={56}
        >
            <SourceDetailsMainContent
                sourceDetails={sourceDetails}
                openNewChatWithBot={openNewChatWithBot}
            />
        </DetailsPopupLayout>
    );
};

export default SourceDetailsPopup;
