import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import knowledgeClient from '../services/knowledge-api';

import { useManageSearchParams } from './useManageSearchParams';

import { CONTENT_TYPE } from '../constants/docs';
import { DOCUMENTS_QUERY_PARAM } from '../constants/assistant';

export const useFetchAssistantPreselectedDocs = () => {
    const [searchParams] = useSearchParams();

    const { deleteSearchParam } = useManageSearchParams();

    const documentsIds = searchParams.get(DOCUMENTS_QUERY_PARAM);
    const parsedDocumentsIds = documentsIds ? documentsIds.split(',') : null;

    const [isLoading, setIsLoading] = useState(!!documentsIds);
    const [preselectedDocsData, setPreselectedDocsData] = useState(null);

    useEffect(() => {
        const fetchDocsData = async () => {
            try {
                setIsLoading(true);
                const data = await Promise.allSettled(
                    parsedDocumentsIds.map((id) =>
                        knowledgeClient.get(`${API.ROUTES.knowledge.document}${id}/`)
                    )
                );

                const result = data.reduce((acc, { status, value }) => {
                    if (status === 'fulfilled') {
                        const { id, label, file_type } = value.data;
                        const content_type = file_type || CONTENT_TYPE.document;
                        return { ...acc, [id]: { id, label, content_type } };
                    }
                    return acc;
                }, {});

                const allRequestsAreRejected = Object.keys(result)?.length === 0;
                if (allRequestsAreRejected) {
                    throw new Error('request error');
                }

                setPreselectedDocsData(result);
            } catch (e) {
                deleteSearchParam(DOCUMENTS_QUERY_PARAM);
            } finally {
                setIsLoading(false);
            }
        };

        if (parsedDocumentsIds && !preselectedDocsData) {
            fetchDocsData();
        }
        if (!parsedDocumentsIds && preselectedDocsData) {
            setPreselectedDocsData(null);
        }
    }, [documentsIds]);

    const isPreselectedDocsLoading = !!documentsIds && isLoading;

    return { preselectedDocsData, isPreselectedDocsLoading };
};
