import React from 'react';

import StopFillIcon from '../../../design-system/Icons/StopFillIcon';
import CollapseDiagonalLine from '../../../design-system/Icons/CollapseDiagonalLine';
import ExpandDiagonalLineIcon from '../../../design-system/Icons/ExpandDiagonalLineIcon';

import { ButtonIcon } from '../../../design-system';
import { SendPlane2FillIcon } from '../../../design-system/Icons';

const ChatInputBoxActionButtons = ({
    size = 'sm',
    handleSendMessage,
    handleCancelStream,
    isLoading,
    sendButtonState,
    isSwitchExpandedModeButton,
    isExpandedMode,
    toggleExpandedMode,
}) => {
    return (
        <div className="flex items-center gap-4" onClick={(e) => e.stopPropagation()}>
            {isSwitchExpandedModeButton && (
                <ButtonIcon
                    type="link"
                    size={size}
                    icon={isExpandedMode ? CollapseDiagonalLine : ExpandDiagonalLineIcon}
                    onClick={toggleExpandedMode}
                />
            )}

            {!isLoading && (
                <ButtonIcon
                    type="primary"
                    size={size}
                    icon={SendPlane2FillIcon}
                    state={sendButtonState}
                    onClick={handleSendMessage}
                />
            )}

            {isLoading && (
                <ButtonIcon
                    type="primary"
                    size={size}
                    icon={StopFillIcon}
                    state="default"
                    onClick={handleCancelStream}
                />
            )}
        </div>
    );
};

export default ChatInputBoxActionButtons;
