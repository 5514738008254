import React, { useCallback, useMemo, useRef } from 'react';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import { CHAT_ROLE, CHAT_TYPE } from '../../../constants/assistant';
import { IS_STREAM_LOADING_KEY } from '../../../hooks/useAssistantMessageStream';

import { SvgIcon } from '../../../design-system';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import ContentLoader from '../ContentLoader/ContentLoader';
import RefreshCwIcon from '../../../design-system/Icons/RefreshCwIcon';
import MessageWrapper from '../MessageWrapper/MessageWrapper';
import DiviBotMessageHeader from '../DiviBotMessageHeader/DiviBotMessageHeader';
import CustomBotMessageHeader from '../CustomBotMessageHeader/CustomBotMessageHeader';
import AssistantMessageSourcesBlock from '../AssistantMessageSourcesBlock/AssistantMessageSourcesBlock';
import AssistantMessageActionButtons from '../AssistantMessageActionButtons/AssistantMessageActionButtons';

// possible statuses: "failure", "pending", "success"
const AssistantMessage = ({
    message,
    setChatMessages,
    isRegenerateDisabled,
    handleRegenerateChatMessage,
    isSimplifiedVersion = false,
}) => {
    const {
        id,
        default_version: {
            id: currentVersionId,
            content,
            status,
            tools,
            type: chatType,
            custom_bot_version,
            streamErrorMessage,
        },
    } = message;
    const ref = useRef(null);

    const isCompletedMessage = status === 'success';
    const isClickableErrorBanner = status === 'failure';

    // sometimes pending status is shown in old messages by mistake
    const isStreamLoading = message.default_version[IS_STREAM_LOADING_KEY];
    const isLoaderShown = status === 'pending' && !content && isStreamLoading;

    const toolsArray = useMemo(() => Object.entries(tools || {}), [tools]);

    const onRegenerateMessageClick = () => {
        if (handleRegenerateChatMessage) {
            handleRegenerateChatMessage({ id, role: CHAT_ROLE.assistant });
        }
    };

    const onSuccessFeedbackShare = useCallback(async () => {
        try {
            const { data } = await client.get(`${API.ROUTES.assistant.chatMessage}${id}/`);
            setChatMessages((prevMessages) =>
                prevMessages.map((message) => (message.id === data.id ? data : message))
            );
        } catch (e) {}
    }, [id, setChatMessages]);

    const withHeader = chatType === CHAT_TYPE.divibot || chatType === CHAT_TYPE.custom;

    return (
        <MessageWrapper
            role={CHAT_ROLE.assistant}
            blackRobotIcon={chatType !== CHAT_TYPE.divibot}
            isSimplifiedVersion={isSimplifiedVersion}
        >
            {chatType === CHAT_TYPE.divibot && <DiviBotMessageHeader toolsArray={toolsArray} />}
            {chatType === CHAT_TYPE.custom && (
                <CustomBotMessageHeader customBotVersion={custom_bot_version} />
            )}
            <div className="flex flex-col gap-4" ref={ref}>
                {isLoaderShown && (
                    <ContentLoader additionalClassNames={withHeader ? '' : 'min-h-[24px]'} />
                )}

                {!isLoaderShown && (
                    <RichTextArea
                        value={content}
                        previewOnly
                        autoExpand
                        isBorderHidden
                        customPreviewBgColor="transparent"
                    />
                )}

                {isCompletedMessage && (
                    <AssistantMessageSourcesBlock
                        toolsArray={toolsArray}
                        currentVersionId={currentVersionId}
                        isSimplifiedVersion={isSimplifiedVersion}
                        onSuccessFeedbackShare={onSuccessFeedbackShare}
                        isCustomChatType={chatType === CHAT_TYPE.custom}
                    />
                )}

                {isCompletedMessage && (
                    <AssistantMessageActionButtons
                        message={message}
                        setChatMessages={setChatMessages}
                        isRegenerateDisabled={isRegenerateDisabled}
                        onRegenerateMessageClick={onRegenerateMessageClick}
                        onSuccessFeedbackShare={onSuccessFeedbackShare}
                        containerRef={ref}
                        isSimplifiedVersion={isSimplifiedVersion}
                    />
                )}

                {isClickableErrorBanner && (
                    <div className="flex gap-2 cursor-pointer" onClick={onRegenerateMessageClick}>
                        <SvgIcon color="#E95B69" icon={RefreshCwIcon} size="medium" />
                        <p className="font-body text-body-regular-s text-red-500 break-words">
                            {streamErrorMessage || defaultErrorMessage}
                        </p>
                    </div>
                )}
            </div>
        </MessageWrapper>
    );
};

export default AssistantMessage;
