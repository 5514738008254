import { API } from 'constants';

import FolderFillIcon from '../design-system/Icons/FolderFillIcon';
import FileTextLineIcon from '../design-system/Icons/FileTextLineIcon';
import FileIcon from '../design-system/Icons/FileIcon';
import SharedWithIcon from '../design-system/Icons/SharedWithIcon';

export const EMPTY_DOCUMENT_DATA = {
    label: 'Untitled Document',
    key: 'untitled_document',
};

export const EMPTY_DIAGRAM_DATA = {
    label: 'Untitled Diagram',
    key: 'untitled_diagram',
    file_type: 'diagram',
};

export const ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY = 'archived-folder-or-doc-data';
export const SENT_FOLDER_OR_DOC_DATA_LS_KEY = 'sent-folder-or-doc-data';

export const CONTENT_TYPE = {
    folder: 'folder',
    document: 'document',
    file: 'file',
    diagram: 'diagram',
};

export const DOCUMENT_TYPE = {
    document: 'document',
    file: 'file',
    diagram: 'diagram',
};

export const ROUTE = {
    [CONTENT_TYPE.folder]: API.ROUTES.knowledge.folder,
    [CONTENT_TYPE.document]: API.ROUTES.knowledge.document,
    [CONTENT_TYPE.file]: API.ROUTES.knowledge.document,
    [CONTENT_TYPE.diagram]: API.ROUTES.knowledge.document,
};

export const CONTENT_TYPE_ICON = {
    [CONTENT_TYPE.folder]: FolderFillIcon,
    [CONTENT_TYPE.document]: FileTextLineIcon,
    [CONTENT_TYPE.file]: FileIcon,
    [CONTENT_TYPE.diagram]: SharedWithIcon,
};

export const CONTENT_TYPE_LABEL = {
    [CONTENT_TYPE.folder]: 'Folder',
    [CONTENT_TYPE.document]: 'Doc',
    [CONTENT_TYPE.file]: 'File',
    [CONTENT_TYPE.diagram]: 'Diagram',
};

export const DETAIL_PAGE_BASE_ROUTE = {
    [CONTENT_TYPE.folder]: `/docs/folder/`,
    [CONTENT_TYPE.document]: `/doc/`,
    [CONTENT_TYPE.file]: `/doc/`,
    [CONTENT_TYPE.diagram]: `/doc/`,
};

export const DRAGGABLE_ITEM_TYPE = {
    sidePanelFolder: 'sidePanelFolder',
    indexPageItem: 'indexPageItem',
};
