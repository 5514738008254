import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/library-api';

import { useFetchCollections } from '../../hooks/useFetchCollections';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';
import { useHandlePageDataLoadError } from '../../hooks/useHandlePageDataLoadError';

import Loading from '../../components/Loading';
import CompassIcon from '../../design-system/Icons/CompassIcon';
import NewTextAreaBox from '../../design-system/NewTextAreaBox/NewTextAreaBox';
import HistoryLineIcon from '../../design-system/Icons/HistoryLineIcon';
import PromptSettingsBox from '../../components/PromptSettingsBox/PromptSettingsBox';
import CollapsableContainer from '../../components/CollapsableContainer/CollapsableContainer';
import PromptViewPageHeader from '../../components/PromptViewPageHeader/PromptViewPageHeader';
import PromptRunRatingsTable from './PromptRunRatingsTable/PromptRunRatingsTable';
import { Button } from '../../design-system';
import { getPromptSettings } from '../../helpers/getPromptSettings';
import { ArrowGoBackLineIcon, TerminalBoxLineIcon } from '../../design-system/Icons';

const VersionHistoryDetailPage = () => {
    const { promptId, versionId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '/library';
    const historyVersionPageNumber = location.state?.historyVersionPageNumber || null;

    const [versionData, setVersionData] = useState(null);
    const [taskData, setTaskData] = useState(null);
    const [collections, setCollections] = useState([]);
    const [areCollectionsLoading, setAreCollectionsLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isEditVersionLoading, setIsEditVersionLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState({ promptSettings: true });
    const { isDesktop } = useResponsiveBreakpoints();

    const accessLevel = versionData && versionData.access_level;
    const promptText = versionData && versionData.messages[0]?.content;
    const promptSettings = useMemo(() => {
        return versionData ? getPromptSettings(versionData.settings) : {};
    }, [versionData]);
    const ratings = versionData ? versionData.ratings : {};

    const { handlePageDataLoadError } = useHandlePageDataLoadError();

    useFetchCollections(setCollections, setAreCollectionsLoading);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const [{ data: versionData }, { data: promptData }] = await Promise.all([
                    client.get(`${API.ROUTES.library.promptVersion}${versionId}/`),
                    client.get(`${API.ROUTES.library.prompt}${promptId}/`),
                ]);

                setVersionData(versionData);
                setTaskData(promptData.task);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    redirectPath: '/library',
                    generalErrorHandler: handleBackClick,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const setAsCurrentVersion = async () => {
        try {
            setIsEditVersionLoading(true);
            const { data } = await client.patch(
                `${API.ROUTES.library.promptVersion}${versionId}/`,
                { is_default: true }
            );
            setVersionData(data);
            setIsEditVersionLoading(false);
        } catch (e) {
            setIsEditVersionLoading(false);
            console.log('error', e);
        }
    };

    const toggleCollapsed = (field) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    function handleBackClick() {
        navigate(`/library/prompt/${promptId}/history?page=versions`, {
            state: { historyVersionPageNumber, libraryLocation },
        });
    }

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white overflow-auto lg:overflow-hidden flex flex-col lg:flex-row">
            {!isLoading && taskData && versionData && (
                <>
                    <div className="h-auto lg:h-full bg-white relative w-full lg:w-1/2 py-5 px-6">
                        <div className="h-full flex flex-col sm:px-4 sm:py-5 overflow-y-auto gap-5">
                            <div>
                                <Button
                                    type="link"
                                    size="sm"
                                    text="Back to Version History"
                                    onClick={handleBackClick}
                                    leadingIcon={ArrowGoBackLineIcon}
                                    trailingIcon={HistoryLineIcon}
                                />
                            </div>
                            <PromptViewPageHeader
                                headerText={taskData.name}
                                emojiCode={taskData.icon_text}
                                subHeaderText={taskData.description}
                                editable={false}
                                currentTaskCollectionsIds={taskData?.collections}
                                allCollections={collections}
                                setAllCollections={setCollections}
                                areCollectionsLoading={areCollectionsLoading}
                                collectionsEditable={false}
                            />
                            <div className="flex flex-col flex-grow">
                                <NewTextAreaBox
                                    name={'prompt'}
                                    value={promptText}
                                    label={'Prompt'}
                                    placeholder="Write your prompt here"
                                    state={'disabled'}
                                    withCopyButton
                                    fullHeight
                                    scrollInsideTextArea={isDesktop}
                                    darkBg
                                    badge={{
                                        text: `Version ${versionData.name}`,
                                        color: 'peach',
                                        leadingIcon: CompassIcon,
                                        leadingIconColor: '#000000',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="h-auto lg:h-full bg-neutral-50 max-lg:flex-grow relative w-full lg:w-1/2">
                        <div className="h-full w-full flex flex-col gap-3 py-5 px-5 sm:px-8 overflow-y-auto max-lg:pb-[70px]">
                            <CollapsableContainer
                                title="Prompt Settings"
                                isExpanded={isExpanded.promptSettings}
                                toggleExpand={() => toggleCollapsed('promptSettings')}
                            >
                                <PromptSettingsBox
                                    editable={false}
                                    promptSettings={promptSettings}
                                />
                            </CollapsableContainer>
                            <div>
                                <p className="font-body text-body-bold-m text-neutral-500 uppercase mb-3">
                                    Prompt run ratings
                                </p>
                                <PromptRunRatingsTable ratings={ratings} />
                            </div>
                            <div>
                                <Button
                                    type="link"
                                    size="sm"
                                    text="Go to Run History"
                                    onClick={() =>
                                        navigate(`/library/prompt/${promptId}/history?page=runs`, {
                                            state: { libraryLocation },
                                        })
                                    }
                                    trailingIcon={HistoryLineIcon}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="justify-end fixed right-[10px] min-[380px]:right-[20px] bottom-[5px] lg:bottom-[16px] z-28 flex gap-[4px] xs:gap-[10px] items-center p-[7px]">
                        <Button
                            type="secondary"
                            size="sm"
                            text={
                                versionData.is_default
                                    ? 'Current Version'
                                    : 'Set as Current Version'
                            }
                            state={
                                accessLevel === 'viewer' || versionData.is_default
                                    ? 'disabled'
                                    : isEditVersionLoading
                                    ? 'loading'
                                    : 'default'
                            }
                            onClick={setAsCurrentVersion}
                        />
                        <Button
                            type="primary"
                            size="sm"
                            text="Back to Prompt"
                            trailingIcon={TerminalBoxLineIcon}
                            onClick={() =>
                                navigate(`/library/prompt/${promptId}`, {
                                    state: { libraryLocation },
                                })
                            }
                        />
                    </div>
                </>
            )}
            {isLoading && (
                <div className="w-full h-full flex-grow flex justify-center items-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default VersionHistoryDetailPage;
