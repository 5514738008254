import React from 'react';

const AlertTriangleIcon = ({ width, height, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
            <path
                d="M6.00245 2.25167L1.06162 10.5C0.959749 10.6764 0.905848 10.8764 0.905278 11.0801C0.904708 11.2838 0.957487 11.4842 1.05837 11.6611C1.15925 11.8381 1.30471 11.9856 1.48028 12.0889C1.65585 12.1922 1.85542 12.2478 2.05912 12.25H11.9408C12.1445 12.2478 12.344 12.1922 12.5196 12.0889C12.6952 11.9856 12.8407 11.8381 12.9415 11.6611C13.0424 11.4842 13.0952 11.2838 13.0946 11.0801C13.0941 10.8764 13.0402 10.6764 12.9383 10.5L7.99745 2.25167C7.89346 2.08023 7.74704 1.93849 7.57232 1.84012C7.39759 1.74174 7.20046 1.69007 6.99995 1.69007C6.79944 1.69007 6.60231 1.74174 6.42759 1.84012C6.25286 1.93849 6.10644 2.08023 6.00245 2.25167V2.25167Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 5.25V7.58333"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 9.91667H7.00583"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AlertTriangleIcon;
