import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { Button, SvgIcon } from '../../../design-system';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import DetailsPopupLayout from '../DetailsPopupLayout/DetailsPopupLayout';
import BotLastUpdatedInfo from '../../BotsPage/BotDetailPage/BotLastUpdatedInfo/BotLastUpdatedInfo';
import BotDetailMainContent from '../BotDetailMainContent/BotDetailMainContent';

const BotDetailPopup = ({ id, name, openNewChatWithBot, openSourceDetailsPopup, onClose }) => {
    const [botDetail, setBotDetail] = useState(null);

    const modalRef = useRef(null);

    useEffect(() => {
        if (!botDetail) {
            refetchBotDetail();
        }
    }, []);

    const refetchBotDetail = useCallback(async () => {
        try {
            const { data } = await client.get(`${API.ROUTES.assistant.customBot}${id}/`);
            setBotDetail(data);
        } catch (e) {}
    }, []);

    const { updated_at, last_updated_by } = botDetail?.default_version || {};

    const { isMobile } = useResponsiveBreakpoints();

    const isDataLoaded = !!botDetail;

    const headerClassName = classNames(
        'flex flex-col gap-2',
        isDataLoaded && 'pb-4 lg:pb-5 border-b-1 border-neutral-200'
    );

    const modalHeader = (
        <div className={headerClassName}>
            <h2 className="font-heading-bold text-heading-bold-l text-black">{name}</h2>

            {isDataLoaded && (
                <BotLastUpdatedInfo updatedAt={updated_at} lastUpdatedBy={last_updated_by} />
            )}
        </div>
    );

    const openChatButton = (
        <>
            <div className="flex flex-col">
                <button
                    onClick={openNewChatWithBot}
                    className="flex items-center justify-center gap-2 p-2 rounded-2 bg-purple-100 min-h-[40px] md:min-h-[36px]"
                >
                    <SvgIcon color="#1F2125" icon={EditLineIcon} size="medium" />
                    <p className="font-body-bold text-body-bold-s md:text-body-bold-m text-neutral-500">
                        Start Chat
                    </p>
                </button>
            </div>

            {isMobile && <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />}
        </>
    );

    return (
        <DetailsPopupLayout
            isLoaded={isDataLoaded}
            onClose={onClose}
            bottomControls={openChatButton}
            header={modalHeader}
            ref={modalRef}
        >
            <BotDetailMainContent
                botDetail={botDetail}
                refetchBotDetail={refetchBotDetail}
                openSourceDetailsPopup={openSourceDetailsPopup}
                modalRef={modalRef}
            />
        </DetailsPopupLayout>
    );
};

export default BotDetailPopup;
