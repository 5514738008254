import React from 'react';

import { SOURCE_CARD_STATUS, SOURCE_CARD_STATUS_MAPPING } from '../../../../constants/bot';

import Loader2Icon from '../../../../design-system/Icons/Loader2Icon';
import TimestampDisplay from '../../../../design-system/TimestampDisplay/TimestampDisplay';
import AlertTriangleIcon from '../../../../design-system/Icons/AlertTriangleIcon';
import { CheckboxCircleLineIcon } from '../../../../design-system/Icons';

const SourceCardStatusBlock = ({ status, updatedAt }) => {
    const sourceStatus = SOURCE_CARD_STATUS_MAPPING[status];

    if (sourceStatus === SOURCE_CARD_STATUS.success) {
        return (
            <div className="flex items-center gap-1">
                <div className="min-w-[14px]">
                    <CheckboxCircleLineIcon width={14} height={14} color="#754DCF" />
                </div>

                <TimestampDisplay
                    label="Last Updated at"
                    timestamp={updatedAt}
                    dateTimeFormat="MM/dd/yyyy"
                />
            </div>
        );
    }

    if (sourceStatus === SOURCE_CARD_STATUS.error) {
        return (
            <div className="flex items-center gap-1 overflow-hidden">
                <div className="min-w-[14px]">
                    <AlertTriangleIcon width={14} height={14} color="#E95B69" />
                </div>

                <p className="font-body text-body-regular-xs text-neutral-500 truncate">
                    Oops! An error occurred
                </p>
            </div>
        );
    }

    if (sourceStatus === SOURCE_CARD_STATUS.in_progress) {
        return (
            <div className="flex items-center gap-1">
                <div className="min-w-[14px] animate-spin-slow">
                    <Loader2Icon width={14} height={14} color="#C6B8E7" />
                </div>

                <p className="font-body text-body-regular-xs text-neutral-500">In Progress</p>
            </div>
        );
    }
};

export default SourceCardStatusBlock;
