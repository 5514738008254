import React from 'react';

import { CONTENT_TYPE, CONTENT_TYPE_ICON } from '../../../../constants/docs';
import { SELECTION_TYPE } from '../../../../constants/selection';

import FolderListItem from '../FolderListItem/FolderListItem';
import CheckboxListItem from '../../../../design-system/CheckboxListItem/CheckboxListItem';
import FileTextLineIcon from '../../../../design-system/Icons/FileTextLineIcon';
import SelectableListItem from '../../../../design-system/SelectableListItem/SelectableListItem';
import InfiniteScrollList from '../../../../components/InfiniteScrollList/InfiniteScrollList';

const DocsAndFoldersList = ({
    data,
    setFoldersDocumentsData,
    selectedDocs,
    handleChange,
    isLoading,
    setPage,
    isRoot = false,
    parentFolderLocation,
    selectionType = SELECTION_TYPE.multiple,
}) => {
    const { pagination, results } = data;

    const areResults = results?.length;

    return (
        <InfiniteScrollList
            handleFetch={() => setPage((page) => page + 1)}
            canLoadMore={pagination.canLoadMore}
            items={results}
            loading={isLoading}
            gap={0}
            loaderSize={isRoot ? 'medium' : 'small'}
        >
            {areResults && (
                <div className="flex flex-col">
                    {results.map((item) => {
                        const { content_type } = item;

                        if (content_type === CONTENT_TYPE.folder) {
                            return (
                                <FolderListItem
                                    key={item.id}
                                    folder={item}
                                    selectedDocs={selectedDocs}
                                    handleChange={handleChange}
                                    setFoldersDocumentsData={setFoldersDocumentsData}
                                    isTopLevel={isRoot}
                                    parentFolderLocation={parentFolderLocation}
                                    selectionType={selectionType}
                                />
                            );
                        }

                        const { id } = item;
                        const icon = CONTENT_TYPE_ICON[content_type] || FileTextLineIcon;

                        if (selectionType === SELECTION_TYPE.multiple) {
                            const isChecked = !!selectedDocs[id];

                            return (
                                <CheckboxListItem
                                    key={id}
                                    item={item}
                                    isChecked={isChecked}
                                    handleChange={handleChange}
                                    icon={icon}
                                />
                            );
                        }

                        const isSelected = selectedDocs?.[id];

                        return (
                            <SelectableListItem
                                key={id}
                                item={item}
                                handleSelect={handleChange}
                                isSelected={isSelected}
                                icon={icon}
                            />
                        );
                    })}
                </div>
            )}
        </InfiniteScrollList>
    );
};

export default DocsAndFoldersList;
