import FileTextLineIcon from '../design-system/Icons/FileTextLineIcon';
import PlayProcessIcon from '../design-system/Icons/PlayProcessIcon';
import ArchiveLineIcon from '../design-system/Icons/ArchiveLineIcon';
import SharedWithIcon from '../design-system/Icons/SharedWithIcon';
import FileIcon from '../design-system/Icons/FileIcon';

export const CHAT_ROLE = {
    user: 'user',
    assistant: 'assistant',
};

export const HISTORY_GROUP_LABEL = {
    today: 'Today',
    yesterday: 'Yesterday',
    week: 'Previous 7 days',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
};

export const HISTORY_TAB = {
    chats: 'chats',
    bots: 'chatbots',
};

export const CHAT_TYPE = {
    generic: 'generic',
    divibot: 'divibot', // is outdated, but some ord chat threads still have it
    custom: 'custom',
};

export const TOOL_TYPE = {
    query_playbooks: 'query_playbooks', // is not used for now
    add_documents: 'add_documents', // in use
    query_knowledge: 'query_knowledge', // is not used for now
};

export const TOOL_ICON = {
    [TOOL_TYPE.query_playbooks]: PlayProcessIcon,
    [TOOL_TYPE.add_documents]: FileTextLineIcon,
    [TOOL_TYPE.query_knowledge]: ArchiveLineIcon,
};

export const SELECTED_TOOL_PLACEHOLDER = {
    [TOOL_TYPE.query_playbooks]: 'Start chatting with your Playbooks',
    [TOOL_TYPE.query_knowledge]: 'Start chatting with your Knowledge Bases',
    [TOOL_TYPE.add_documents]: 'Start chatting or select a different doc',
};

// If a color is not specified here, `icon_color` will be used as the default
export const TOOL_ICON_COLOR = {
    [TOOL_TYPE.query_playbooks]: '#000000',
    [TOOL_TYPE.query_knowledge]: '#1F2125',
    [TOOL_TYPE.add_documents]: '#1F2125',
};

export const BASE_TYPE = {
    agent_knowledge: 'agent-knowledge',
    diviup_folder: 'diviup-folder',
    google_folder: 'google-folder',
    document: 'document',
    playbook_section: 'playbook-section', // is outdated type, but some ord chat threads still have it
};

export const SOURCE_ICON = {
    [BASE_TYPE.agent_knowledge]: ArchiveLineIcon,
    [BASE_TYPE.diviup_folder]: FileTextLineIcon,
    [BASE_TYPE.document]: FileTextLineIcon,
    [BASE_TYPE.playbook_section]: PlayProcessIcon, // is outdated, but some ord chat threads still have it
};

export const SOURCE_LINK_PREFIX = {
    [BASE_TYPE.agent_knowledge]: '/base/',
    [BASE_TYPE.diviup_folder]: '/doc/',
    [BASE_TYPE.google_folder]: 'https://docs.google.com/document/d/',
    [BASE_TYPE.document]: '/doc/',
    [BASE_TYPE.playbook_section]: '/playbook/', // is outdated, but some ord chat threads still have it
};

export const SOURCE_LINK_TYPE_ICON = {
    document: FileTextLineIcon,
    file: FileIcon,
    diagram: SharedWithIcon,
};

export const DOCUMENTS_QUERY_PARAM = 'documents';
