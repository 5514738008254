import React, { useRef } from 'react';

import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import BotConnectedKnowledgeSection from '../BotConnectedKnowledgeSection/BotConnectedKnowledgeSection';

const BotDetailMainContent = ({
    botDetail,
    refetchBotDetail,
    openSourceDetailsPopup,
    modalRef,
}) => {
    const { description, sources } = botDetail;

    const scrollableAreaRef = useRef(null);

    return (
        <div className="flex flex-col gap-y-4 gap-x-0 md:flex-row md:flex-1 md:overflow-hidden">
            <div className="md:flex-1 md:overflow-y-auto pr-0 md:pr-2 lg:pr-4 md:min-h-[56px]">
                <RichTextArea
                    value={description}
                    previewOnly
                    autoExpand
                    isBorderHidden
                    previewMinHeight={40}
                />
            </div>

            <div
                className="md:flex-1 md:overflow-y-auto pl-0 md:pl-2 lg:pl-4"
                ref={scrollableAreaRef}
            >
                <p className="font-body-bold text-body-bold-m text-black mb-3">Chatbot Details</p>

                <BotConnectedKnowledgeSection
                    sources={sources}
                    refetchBotDetail={refetchBotDetail}
                    openSourceDetailsPopup={openSourceDetailsPopup}
                    modalRef={modalRef}
                    scrollableAreaRef={scrollableAreaRef}
                />
            </div>
        </div>
    );
};

export default BotDetailMainContent;
