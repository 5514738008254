import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import useUser from '../../../hooks/useUser';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useViewType } from '../../../hooks/useViewType';
import { useDefaultSearchParams } from '../../../hooks/useDefaultSearchParams';
import { useHandlePageDataLoadError } from '../../../hooks/useHandlePageDataLoadError';

import { VIEW_TYPES } from '../../../constants/viewTypes';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../../constants/organization';
import { baseEntriesSortOptions, SORT_TYPES } from '../../../constants/sort';

import Loading from '../../../components/Loading';
import BaseEntriesIndex from './BaseEntriesIndex/BaseEntriesIndex';
import BaseDetailPageHeader from './BaseDetailPageHeader/BaseDetailPageHeader';

const BaseDetailPage = () => {
    const { baseId } = useParams();

    const { orgPlan, userRole } = useUser();

    const hasOrgEditAccess =
        orgPlan === ORGANIZATION_PLAN.agency &&
        (userRole === USER_ROLE.admin || userRole === USER_ROLE.owner);

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from || '/bases';

    const { handlePageDataLoadError } = useHandlePageDataLoadError();

    const [baseDetail, setBaseDetail] = useState(null);

    const { viewType } = useViewType();
    const hasEntriesEditAccess = baseDetail?.is_editable || viewType === VIEW_TYPES.admin;

    useEffect(() => {
        const fetchBaseDetail = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.knowledge.base}${baseId}/`);
                setBaseDetail(data);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    redirectPath: '/bases',
                    generalErrorHandler: () => navigate(backLinkHref),
                });
            }
        };

        if (baseDetail) {
            setBaseDetail(null);
        }
        fetchBaseDetail();
    }, [baseId]);

    useDocumentTitle(baseDetail?.name);

    useDefaultSearchParams({
        checkPage: true,
        checkSort: true,
        defaultSortType: SORT_TYPES.created,
        availableSortTypes: baseEntriesSortOptions.map((item) => item.value),
    });

    return (
        <div className="page-position flex flex-col bg-neutral-50 overflow-y-auto">
            {baseDetail && (
                <BaseDetailPageHeader
                    baseDetail={baseDetail}
                    setBaseDetail={setBaseDetail}
                    backLinkHref={backLinkHref}
                    hasEditAccess={hasOrgEditAccess}
                />
            )}

            <BaseEntriesIndex
                isBaseDetailLoaded={!!baseDetail}
                baseId={baseId}
                hasEditAccess={hasOrgEditAccess && hasEntriesEditAccess}
                schema={baseDetail?.detail?.schema || []}
            />

            {!baseDetail && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default BaseDetailPage;
