import React from 'react';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useDefaultSearchParams } from '../../hooks/useDefaultSearchParams';

import BasesIndex from './BasesIndex/BasesIndex';
import BasesPageHeader from './BasesPageHeader/BasesPageHeader';

const BasesPage = () => {
    useDefaultSearchParams({ checkPage: true, checkSort: true });

    useDocumentTitle('Knowledge Bases');

    return (
        <div className="page-position flex flex-col bg-neutral-50 overflow-y-auto hide-scrollbar">
            <BasesPageHeader />

            <div className="w-full px-5 xs:px-6 lg:px-8 pt-5 lg:pt-6 pb-6 lg:pb-9">
                <BasesIndex />
            </div>
        </div>
    );
};

export default BasesPage;
