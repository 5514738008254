import React from 'react';
import { createPortal } from 'react-dom';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../index';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

const popupRoot = document.querySelector('#popup-root');

const MobilePopup = ({
    onClose,
    children,
    closeButtonText = 'Close',
    withCloseButton = true,
    withSaveButton = false,
    onSave = () => {},
    title = '',
    minHeight: _minHeight = 0, // can be a number (in pixels) or a string (percentage, etc.)
    maxHeight: _maxHeight = '92vh', // can be a number (in pixels) or a string (percentage, etc.)
    bottomButtonsGroup = null,
    containerCustomPaddings = '',
    contentCustomPaddings = '',
    bottomButtonsCustomPaddings = '',
    customGap = 24,
    topFixedContent = null,
    closeOnClickOutside = true,
    errorAlert = null,
    setErrorAlert = () => {},
}) => {
    const minHeight = typeof _minHeight === 'string' ? _minHeight : `${_minHeight}px`;
    const maxHeight = typeof _maxHeight === 'string' ? _maxHeight : `${_maxHeight}px`;

    const withBottomButtons = withSaveButton || withCloseButton || bottomButtonsGroup;

    const containerClassName = classNames(
        'fixed bottom-0 inset-x-0 pt-1 flex flex-col justify-between bg-white rounded-t-[8px] shadow-l1 border-1 border-neutral-200 z-[65]',
        containerCustomPaddings || 'px-5 pb-6 sm:px-6 md:px-8 md:pb-8'
    );

    const contentContainerClassName = classNames(
        'overflow-y-auto hide-scrollbar pb-2 flex flex-col gap-6 flex-1',
        contentCustomPaddings || 'pt-5 md:pt-7'
    );

    const bottomButtonsContainerClassName = classNames(
        'flex flex-col gap-2',
        bottomButtonsCustomPaddings || ''
    );

    const onBackDropClick = (e) => {
        e.stopPropagation();

        if (e.target === e.currentTarget && closeOnClickOutside) {
            onClose();
        }
    };

    return createPortal(
        <div className="fixed inset-0 bg-black/50 z-[64]" onClick={onBackDropClick}>
            <div
                className={containerClassName}
                style={{ minHeight, maxHeight, gap: customGap - 8 }}
            >
                {topFixedContent}

                <div className={contentContainerClassName}>
                    {title && (
                        <p className="font-body-bold text-body-bold-l text-neutral-500">{title}</p>
                    )}

                    <div className="flex flex-col gap-3 flex-1">{children}</div>
                </div>

                {withBottomButtons && (
                    <div className={bottomButtonsContainerClassName}>
                        {withSaveButton && (
                            <Button type="secondary" size="sm" text="Save" onClick={onSave} />
                        )}
                        {withCloseButton && (
                            <Button
                                type="neutral"
                                size="sm"
                                text={closeButtonText}
                                onClick={onClose}
                            />
                        )}
                        {bottomButtonsGroup}
                    </div>
                )}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>,
        popupRoot
    );
};

MobilePopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    closeButtonText: PropTypes.string,
    withCloseButton: PropTypes.bool,
    withSaveButton: PropTypes.bool,
    onSave: PropTypes.func,
    title: PropTypes.string,
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottomButtonsGroup: PropTypes.node,
    containerCustomPaddings: PropTypes.string,
    contentCustomPaddings: PropTypes.string,
    bottomButtonsCustomPaddings: PropTypes.string,
    customGap: PropTypes.number,
    topFixedContent: PropTypes.node,
    closeOnClickOutside: PropTypes.bool,
    errorAlert: PropTypes.shape({ message: PropTypes.string, statusCode: PropTypes.number }),
    setErrorAlert: PropTypes.func,
};

export default MobilePopup;
