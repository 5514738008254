import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { useViewType } from '../../hooks/useViewType';
import useCustomPagination from '../../hooks/useCustomPagination';

import { API } from 'constants';
import client from '../../services/inbox-api';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { SORT_TYPES } from '../../constants/sort';
import { logoIconColor, SIDEBAR_ITEMS, sideBarBg } from '../../constants/sideBar';

import Logo from 'components/Logo/Logo';
import SideBarMobVersion from './SideBarMobVersion/SideBarMobVersion';
import UniversalSearchModal from 'components/UniversalSearchModal/UniversalSearchModal';
import SideBarNavItem from './SideBarNavItem/SideBarNavItem';
import ViewTypeSwitcher from './ViewTypeSwitcher/ViewTypeSwitcher';
import SideBarNotNavLinkItem from './SideBarNotNavLinkItem/SideBarNotNavLinkItem';
import OrganizationIconBlock from './OrganizationIconBlock/OrganizationIconBlock';
import StartNewJobModal from '../StartNewJobModal/StartNewJobModal';

export default function SideBar() {
    const [isUniversalSearchModalOpened, setUniversalSearchModalOpened] = useState(false);
    const [isStartNewJobModalOpened, setIsStartNewJobModalOpened] = useState(false);

    const { pathname } = useLocation();

    const { viewType } = useViewType();

    const handleKeyPress = (e) => {
        const openUniversalSearchModalShortcut = e.key === 'k' && e.metaKey;
        const openStartNewJobModalShortcut = e.key === 'j' && e.metaKey;

        if (openUniversalSearchModalShortcut && viewType === VIEW_TYPES.admin) {
            setUniversalSearchModalOpened(true);
        }

        if (openStartNewJobModalShortcut && viewType === VIEW_TYPES.client) {
            setIsStartNewJobModalOpened(true);
        }
    };

    const { total: totalInboxMessages } = useCustomPagination({
        pageIndex: 0,
        searchParams: { ordering: SORT_TYPES.created, status: 'pending' },
        client,
        route: API.ROUTES.inbox.message,
    });

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [viewType]);

    const desktopVersionContainerClassName = classNames(
        'max-sm:hidden w-17 py-4 px-2 fixed top-0 bottom-0 left-0 z-30',
        sideBarBg[viewType],
        viewType === VIEW_TYPES.client && 'border-r-1 border-neutral-200'
    );

    return (
        <>
            <SideBarMobVersion inboxMessagesTotal={totalInboxMessages} />
            <div className={desktopVersionContainerClassName}>
                <div className="mb-2.5">
                    <Logo color={logoIconColor[viewType]} />
                </div>
                <nav
                    className="flex flex-col items-center justify-between"
                    style={{ height: 'calc(100% - 62px)' }}
                >
                    <ul>
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.dashboard} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.assistant} />
                        <SideBarNotNavLinkItem
                            itemData={SIDEBAR_ITEMS.adminSearch}
                            selected={!!pathname.includes('search')}
                            handleClick={() => {
                                setUniversalSearchModalOpened(true);
                            }}
                        />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.library} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.configure} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.threads} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.playbooks} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.inbox} label={totalInboxMessages} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.jobs} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.bases} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.docs} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.bots} />
                    </ul>
                    <ul>
                        <OrganizationIconBlock />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.templates} />
                        <ViewTypeSwitcher />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.settings} />
                        <SideBarNavItem itemData={SIDEBAR_ITEMS.logout} />
                    </ul>
                </nav>
            </div>
            {isUniversalSearchModalOpened && (
                <UniversalSearchModal
                    handleClose={() => {
                        setUniversalSearchModalOpened(false);
                    }}
                />
            )}
            {isStartNewJobModalOpened && (
                <StartNewJobModal
                    handleClose={() => {
                        setIsStartNewJobModalOpened(false);
                    }}
                />
            )}
        </>
    );
}
