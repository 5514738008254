import { useState } from 'react';

import { useTextareaAutoResize } from './useTextareaAutoResize';
import { useResponsiveBreakpoints } from './useResponsiveBreakpoints';

export const useChatInputBox = ({
    submitChatMessage,
    isLoading,
    isSendButtonDisabled,
    messageErrorData,
    setMessageErrorData,
    height,
}) => {
    const [message, setMessage] = useState('');
    const [isExpandedMode, setIsExpandedMode] = useState(false);

    const sendButtonState = message && !isSendButtonDisabled ? 'default' : 'disabled';

    const { isMobile } = useResponsiveBreakpoints();

    const { textareaRef, textareaContainerRef, isScrollable } = useTextareaAutoResize({
        text: message,
        minHeight: 10,
        maxHeight: Math.floor(height * 0.27),
    });

    const handleSendMessage = async () => {
        try {
            setIsExpandedMode(false);
            setMessage('');
            await submitChatMessage(message);
        } catch (e) {}
    };

    const handleSendMessageOnEnterPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            if (!isLoading && !!message && !isSendButtonDisabled) {
                handleSendMessage();
            }
        }
    };

    const handleFocusTextarea = (e) => {
        const isTextareaClicked = e.target?.nodeName === 'TEXTAREA';
        if (isTextareaClicked) {
            return;
        }

        const textAreaEl = textareaRef.current;
        if (textAreaEl) {
            textAreaEl.focus();
        }
    };

    const toggleExpandedMode = () => setIsExpandedMode((prevState) => !prevState);

    const isSwitchExpandedModeButton = isMobile && (isExpandedMode || isScrollable);

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        clearErrorMessage();
    };

    function clearErrorMessage() {
        if (messageErrorData) {
            setMessageErrorData(null);
        }
    }

    return {
        message,
        setMessage,
        handleMessageChange,
        handleSendMessage,
        handleSendMessageOnEnterPress,
        clearErrorMessage,
        sendButtonState,
        isExpandedMode,
        toggleExpandedMode,
        setIsExpandedMode,
        textareaRef,
        textareaContainerRef,
        handleFocusTextarea,
        isSwitchExpandedModeButton,
        isMobile,
    };
};
