import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CONTENT_TYPE } from '../../../constants/docs';
import { handleCreateNewDoc as _handleCreateNewDoc } from '../../../helpers/docsUtils';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import NewFolderModal from '../NewFolderModal/NewFolderModal';
import UploadFileModal from '../UploadFileModal/UploadFileModal';
import AddNewMultiActionPopup from '../AddNewMultiActionPopup/AddNewMultiActionPopup';
import { AddCircleLineIcon, ArrowDownSLineIcon } from '../../../design-system/Icons';
import { Button, ButtonIcon } from '../../../design-system';

const AddNewMultiActionButton = () => {
    const { folderId: folder } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [addNewEntityModal, setAddNewEntityModal] = useState({ contentType: null });

    const [fileTypeLoading, setFileTypeLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleCreateNewDoc = async ({ fileType }) => {
        await _handleCreateNewDoc({
            folder,
            fileType,
            setFileTypeLoading,
            setErrorAlert,
            navigate,
            location,
        });
    };

    const openPopup = () => setIsPopupOpened(true);

    const closePopup = (e) => {
        e?.stopPropagation();
        setIsPopupOpened(false);
    };

    const openNewEntityModal = ({ contentType }) => {
        setIsPopupOpened(false);
        setAddNewEntityModal({ contentType });
    };

    const closeNewEntityModal = () => setAddNewEntityModal({ contentType: null });

    return (
        <>
            <div className="w-full flex gap-[1px] bg-neutral-300 rounded-2 relative">
                <div className="flex-1 flex flex-col">
                    <Button
                        type="primary"
                        size="sm"
                        text="Add new"
                        noRoundedRight
                        leadingIcon={AddCircleLineIcon}
                        onClick={openPopup}
                    />
                </div>

                <ButtonIcon
                    type="primary"
                    size="sm"
                    icon={ArrowDownSLineIcon}
                    noRoundedLeft
                    onClick={openPopup}
                />

                {isPopupOpened && (
                    <AddNewMultiActionPopup
                        handleCreateNewDoc={handleCreateNewDoc}
                        openNewEntityModal={openNewEntityModal}
                        fileTypeLoading={fileTypeLoading}
                        onClose={closePopup}
                    />
                )}

                {addNewEntityModal.contentType === CONTENT_TYPE.folder && (
                    <NewFolderModal onClose={closeNewEntityModal} />
                )}

                {addNewEntityModal.contentType === CONTENT_TYPE.file && (
                    <UploadFileModal onClose={closeNewEntityModal} />
                )}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default AddNewMultiActionButton;
