import {
    IMPORT_REGEX,
    IMPORT_STATEMENT,
    // MAPPED_DOC_OUTDATED_SYNTAX_REGEX,
    MAPPED_DOC_REGEX,
    TAG_REGEX,
    VARIABLE_KEY_REGEX,
    VARIABLE_NAME_PROP,
} from '../constants/richTextEditorWithDocsMapping';
import { normalizeMarkdown, prepareMarkdownOnLoad } from './normalizeMarkdown';
import { generateUUID } from './generateUUID';

export const getInitialDocsMapping = (value) => {
    return extractKeysFromSquareBrackets(value);
};

export const getRichTextWithDocsMappingFormattedInitialValue = ({
    initialValue,
    replaceOnlyFilledTags = true, // if true, [variable] tags will not be replaced with doc tags, only filled ${document.id} tags will be replaced
}) => {
    let result = prepareMarkdownOnLoad(initialValue);

    if (!replaceOnlyFilledTags) {
        result = replaceVariableNamesWithDocTags(result);
    }

    const initialDocsMapping = replaceOnlyFilledTags ? {} : getInitialDocsMapping(initialValue);

    const { value: newValue, docsMapping } = replaceFilledRawTagsWithDocTagsAndUpdateMapping(
        result,
        initialDocsMapping
    );

    return { value: newValue, docsMapping };
};

export const getRichTextWithLinkedDocsValueForSubmit = ({ value, docsMapping }) => {
    return normalizeMarkdown(replaceDocsTagsWithIds(value, docsMapping));
};

// this function helps update the form state by leveraging the previous docs mapping
// it can be used to prefill labels for new docs mapping based on previously stored labels
export const getRichTextWithDocsMappingInitialStateUsingPrevDocsMapping = ({
    initialValue,
    prevDocsMapping,
    replaceOnlyFilledTags = true,
}) => {
    const prevDocs = Object.values(prevDocsMapping || {}).filter((doc) => doc?.id && doc?.label);

    const { value, docsMapping } = getRichTextWithDocsMappingFormattedInitialValue({
        initialValue,
        replaceOnlyFilledTags,
    });

    const updatedDocMappingEntries = Object.entries(docsMapping).map(([variableName, doc]) => {
        if (doc?.id && !doc?.label) {
            const label = prevDocs.find((prevMappedDoc) => prevMappedDoc?.id === doc.id)?.label;
            if (label) {
                return [variableName, { ...doc, label }];
            }
        }
        return [variableName, doc];
    });

    return {
        value,
        docsMapping: Object.fromEntries(updatedDocMappingEntries),
    };
};

// export function addMissingBackslashToDocsMapping(value) {
//     if (!value || typeof value !== 'string') return '';
//
//     return value.replace(MAPPED_DOC_OUTDATED_SYNTAX_REGEX, '$\\{document.$1}');
// }

export function replaceVariableNamesWithDocTags(value) {
    if (!value || typeof value !== 'string') return '';

    if (!VARIABLE_KEY_REGEX.test(value)) {
        return value;
    }

    const newMarkdown = value.replace(VARIABLE_KEY_REGEX, (match, p1) => {
        return `<DocumentTag ${VARIABLE_NAME_PROP}="${p1}" />`;
    });

    if (newMarkdown.startsWith(IMPORT_STATEMENT)) {
        return newMarkdown;
    }

    return IMPORT_STATEMENT + newMarkdown;
}

export function replaceFilledRawTagsWithDocTagsAndUpdateMapping(value, docsMapping) {
    if (!value || typeof value !== 'string') return { value: '', docsMapping };

    if (!MAPPED_DOC_REGEX.test(value)) {
        return { value, docsMapping };
    }

    const updatedMapping = { ...docsMapping };

    const updatedMarkdown = value.replace(MAPPED_DOC_REGEX, (match, p1) => {
        const variableId = 'variable-' + generateUUID();
        updatedMapping[variableId] = { id: p1 };

        return `<DocumentTag ${VARIABLE_NAME_PROP}="${variableId}" />`;
    });

    if (updatedMarkdown.startsWith(IMPORT_STATEMENT)) {
        return { value: updatedMarkdown, docsMapping: updatedMapping };
    }

    return { value: IMPORT_STATEMENT + updatedMarkdown, docsMapping: updatedMapping };
}

export function extractKeysFromSquareBrackets(value) {
    const keys = {};
    let match;

    while ((match = VARIABLE_KEY_REGEX.exec(value)) !== null) {
        keys[match[1]] = null;
    }

    return keys;
}

export const replaceDocsTagsWithIds = (_value, docsMapping) => {
    if (typeof _value !== 'string') return _value;

    let value = _value;

    if (IMPORT_REGEX.test(value)) {
        value = value.replace(IMPORT_REGEX, '');
    }

    return value.replace(TAG_REGEX, (match, p1) => {
        const id = docsMapping?.[p1]?.id;
        if (!id) return `\\[${p1}]`;
        return '$\\{document.' + id + '}';
    });
};

export function extractVariableNamesFromTags(value) {
    const keys = {};
    let match;

    while ((match = TAG_REGEX.exec(value)) !== null) {
        keys[match[1]] = null;
    }

    return keys;
}

// outdated
// export const checkIfDocsMappingFilled = (docsMapping) => {
//     const areUnfilledTags = Object.values(docsMapping || {}).some((doc) => !doc?.id);
//     const isFilled = !areUnfilledTags;
//
//     return isFilled;
// };

// export function extractDocIds(inputs) {
//     return inputs.reduce((acc, input) => {
//         const keys = {};
//         let match;
//
//         while ((match = MAPPED_DOC_OUTDATED_SYNTAX_REGEX.exec(input.value || '')) !== null) {
//             keys[match[1]] = null;
//         }
//
//         return { ...acc, ...keys };
//     }, {});
// }
