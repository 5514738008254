import React, { memo, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { applyOpacityToHex } from '../../../helpers/applyOpacityToHex';

import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import UsersIcon from '../../../design-system/Icons/UsersIcon';
import { Button, SvgIcon } from '../../../design-system';

const PlaybookCard = ({ playbook }) => {
    const { id, name, description, is_shared, icon_color } = playbook;

    const navigate = useNavigate();
    const location = useLocation();

    const titleRef = useRef(null);

    const backgroundColor = applyOpacityToHex(icon_color, 20);

    const [titleLines, setTitleLines] = useState(0);

    useEffect(() => {
        if (titleRef.current) {
            const isSingleLine = titleRef.current.clientHeight < 24;
            setTitleLines(isSingleLine ? 1 : 2);
        }
    }, []);

    const handleViewPlaybook = () =>
        navigate(`/playbook/${id}?mode=${PLAYBOOK_BUILDER_MODE.view}`, {
            state: { from: location },
        });

    const nameClassName = classNames(
        'font-body-bold text-body-bold-m text-black line-clamp-2 my-auto'
    );

    const descriptionClassName = classNames('font-body text-body-regular-xs text-neutral-500', {
        'line-clamp-2': titleLines === 1,
        'line-clamp-1': titleLines !== 1,
    });

    return (
        <li
            className="self-stretch p-4 min-h-[160px] rounded-2 flex flex-col justify-between"
            style={{ backgroundColor }}
        >
            <div className={`flex flex-col gap-2 ${!titleLines ? 'opacity-0' : 'opacity-100'}`}>
                <div className="flex gap-3 min-h-[24px] justify-between">
                    <p className={nameClassName} ref={titleRef}>
                        {name}
                    </p>

                    {is_shared && <SvgIcon color="#000000" icon={UsersIcon} size="large" />}
                </div>

                <p className={descriptionClassName}>{description}</p>
            </div>

            <div>
                <Button
                    type="primary"
                    size="xs"
                    text="View Playbook"
                    onClick={handleViewPlaybook}
                />
            </div>
        </li>
    );
};

export default memo(PlaybookCard);
