export const VARIABLE_NAME_PROP = 'variableName';
export const UNFILLED_TAG_ERROR_MESSAGE =
    'Please complete all document tags or remove unnecessary ones.';

// [variable-name]
export const VARIABLE_KEY_REGEX = /\\?\[([^\]]+)\](?!\()/g;

// <DocumentTag variableName="variable-name" />
export const TAG_REGEX = new RegExp(`<DocumentTag\\s+${VARIABLE_NAME_PROP}="([^"]+)"\\s*\\/>`, 'g');

// ${document.uuid} (current syntax includes backslash: $\{document.uuid})
export const MAPPED_DOC_OUTDATED_SYNTAX_REGEX = /\${document\.([a-f0-9\-]+)}/g;

// new and outdated syntax together: $\{document.uuid} (current syntax includes backslash: $\{document.uuid}) and ${document.uuid} (old syntax)
export const MAPPED_DOC_REGEX = /\\*\$+\\*\{document\.([a-f0-9\-]+)\}/g;

// import { DocumentTag } from './external'
export const IMPORT_REGEX = /import\s*\{\s*DocumentTag\s*\}\s*from\s*['"]\.\/external['"]\s*/;

export const IMPORT_STATEMENT = "import { DocumentTag } from './external'\n\n";
