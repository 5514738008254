import React, { memo, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import {
    ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY,
    SENT_FOLDER_OR_DOC_DATA_LS_KEY,
} from '../../../constants/docs';

import DocsColumnHeader from '../DocsColumnHeader/DocsColumnHeader';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import MoveToPanel from '../MoveToPanel/MoveToPanel';
import SendToOrgModal from '../SendToOrgModal/SendToOrgModal';
import RenameItemModal from '../RenameItemModal/RenameItemModal';
import ArchiveItemModal from '../ArchiveItemModal/ArchiveItemModal';
import SuccessSentToAlert from '../SuccessSentToAlert/SuccessSentToAlert';
import SuccessArchiveAlert from '../SuccessArchiveAlert/SuccessArchiveAlert';
import SelectedItemsActionBar from '../SelectedItemsActionBar/SelectedItemsActionBar';
import DraggableFolderOrDocCard from '../DraggableFolderOrDocCard/DraggableFolderOrDocCard';
import ArchiveMultipleItemsModal from '../ArchiveMultipleItemsModal/ArchiveMultipleItemsModal';

const actionModalEmptyState = {
    action: null,
    contentType: null,
    data: null,
};
const successArchiveAlertEmptyState = {
    archivedItems: null, // [] of archived items
    parentFolderLocation: null,
};

const FoldersAndDocsList = ({ data }) => {
    const { folderId } = useParams();
    const [searchParams] = useSearchParams();
    const searchQuery = searchParams.get('search');

    const [selectedItems, setSelectedItems] = useState({}); // keeps data in format { id: itemData }

    const [actionModal, setActionModal] = useState(actionModalEmptyState);
    const [selectedItemsActionModal, setSelectedItemsActionModal] = useState({ action: null });
    const [errorAlert, setErrorAlert] = useState(null);

    const [successArchiveAlert, setSuccessArchiveAlert] = useState(successArchiveAlertEmptyState);
    const [successSentToAlert, setSuccessSentToAlert] = useState(null);

    const { requestKeysToMutate, currentRequestKeyToMutate } = useDocsPageContext();

    useEffect(() => {
        clearAllSelectedItems();
    }, [folderId, searchQuery]);

    useEffect(() => {
        // if the doc was archived from Doc Detail page show success archive alert
        const savedSuccessArchiveDocData = localStorage.getItem(ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY);
        if (savedSuccessArchiveDocData) {
            try {
                const parsedData = JSON.parse(savedSuccessArchiveDocData);
                setSuccessArchiveAlert(parsedData);
            } catch (e) {
            } finally {
                localStorage.removeItem(ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY);
            }
        }
    }, []);

    useEffect(() => {
        // if the doc was sent to another organization from Doc Detail page show success sent to org alert
        const sentToOrgItemData = localStorage.getItem(SENT_FOLDER_OR_DOC_DATA_LS_KEY);
        if (sentToOrgItemData) {
            try {
                const parsedData = JSON.parse(sentToOrgItemData);
                setSuccessSentToAlert(parsedData);
            } catch (e) {
            } finally {
                localStorage.removeItem(SENT_FOLDER_OR_DOC_DATA_LS_KEY);
            }
        }
    }, []);

    const clearAllSelectedItems = () => setSelectedItems({});

    const removeArchivedItemFromSelectedList = (archivedItemId) => {
        setSelectedItems((prevSelectedDocIds) => {
            const newSelectedDocIds = { ...prevSelectedDocIds };
            delete newSelectedDocIds[archivedItemId];
            return newSelectedDocIds;
        });
    };

    const renameSelectedItem = ({ id, updatedLabel }) => {
        setSelectedItems((prevSelectedItems) => {
            const isCurrentItemSelected = !!prevSelectedItems[id];

            if (!isCurrentItemSelected) return prevSelectedItems;

            return {
                ...prevSelectedItems,
                [id]: { ...prevSelectedItems[id], label: updatedLabel },
            };
        });
    };

    const selectAllItems = () => {
        const currentPageItems = data.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

        setSelectedItems((prev) => ({ ...prev, ...currentPageItems }));
    };

    const selectedItemsCount = Object.keys(selectedItems)?.length || 0;

    return (
        <>
            <div className="relative">
                {!!data?.length && (
                    <>
                        <div className="md:mb-1">
                            {selectedItemsCount > 0 && (
                                <SelectedItemsActionBar
                                    selectedItems={selectedItems}
                                    selectedItemsCount={selectedItemsCount}
                                    currentRequestKeyToMutate={currentRequestKeyToMutate}
                                    requestKeysToMutate={requestKeysToMutate}
                                    setSelectedItemsActionModal={setSelectedItemsActionModal}
                                    clearAllSelectedItems={clearAllSelectedItems}
                                />
                            )}
                            <div className="hidden md:block">
                                {selectedItemsCount === 0 && (
                                    <DocsColumnHeader selectAllItems={selectAllItems} />
                                )}
                            </div>
                        </div>

                        <ul className="flex flex-col gap-1">
                            {data.map((item) => (
                                <DraggableFolderOrDocCard
                                    key={item.id}
                                    // card props
                                    item={item}
                                    isSelected={!!selectedItems[item.id]}
                                    setSelectedItems={setSelectedItems}
                                    setActionModal={setActionModal}
                                    // props for dragging
                                    selectedItems={selectedItems}
                                />
                            ))}
                        </ul>
                    </>
                )}

                {selectedItemsActionModal.action === 'move' && (
                    <MoveToPanel
                        itemsToMove={selectedItems}
                        clearAllSelectedItems={clearAllSelectedItems}
                        currentRequestKeyToMutate={currentRequestKeyToMutate}
                        requestKeysToMutate={requestKeysToMutate}
                        viewType="popup"
                        onClose={() => setSelectedItemsActionModal({ action: null })}
                    />
                )}

                {actionModal.action === 'move' && (
                    <MoveToPanel
                        itemsToMove={[actionModal.data]}
                        currentRequestKeyToMutate={currentRequestKeyToMutate}
                        requestKeysToMutate={requestKeysToMutate}
                        viewType="modal"
                        onClose={() => setActionModal(actionModalEmptyState)}
                    />
                )}
            </div>

            {actionModal.action === 'send' && (
                <SendToOrgModal
                    contentType={actionModal.contentType}
                    data={actionModal.data}
                    requestKeysToMutate={requestKeysToMutate}
                    setSuccessAlert={setSuccessSentToAlert}
                    onClose={() => setActionModal(actionModalEmptyState)}
                />
            )}

            {actionModal.action === 'rename' && (
                <RenameItemModal
                    contentType={actionModal.contentType}
                    data={actionModal.data}
                    requestKeysToMutate={requestKeysToMutate}
                    onRenameSuccess={renameSelectedItem}
                    onClose={() => setActionModal(actionModalEmptyState)}
                />
            )}

            {actionModal.action === 'archive' && (
                <ArchiveItemModal
                    contentType={actionModal.contentType}
                    data={actionModal.data}
                    requestKeysToMutate={requestKeysToMutate}
                    setSuccessArchiveAlert={setSuccessArchiveAlert}
                    onArchiveSuccess={removeArchivedItemFromSelectedList}
                    onClose={() => setActionModal(actionModalEmptyState)}
                />
            )}

            {selectedItemsActionModal.action === 'multiple-archive' && (
                <ArchiveMultipleItemsModal
                    items={selectedItems}
                    requestKeysToMutate={requestKeysToMutate}
                    currentRequestKeyToMutate={currentRequestKeyToMutate}
                    setSuccessArchiveAlert={setSuccessArchiveAlert}
                    clearAllSelectedItems={clearAllSelectedItems}
                    onClose={() => setSelectedItemsActionModal({ action: null })}
                />
            )}

            {successArchiveAlert.archivedItems && (
                <SuccessArchiveAlert
                    archivedItems={successArchiveAlert.archivedItems}
                    parentFolderLocation={successArchiveAlert.parentFolderLocation}
                    requestKeysToMutate={requestKeysToMutate}
                    currentRequestKeyToMutate={currentRequestKeyToMutate}
                    setErrorAlert={setErrorAlert}
                    handleClose={() => setSuccessArchiveAlert(successArchiveAlertEmptyState)}
                />
            )}

            {successSentToAlert && (
                <SuccessSentToAlert
                    data={successSentToAlert.data}
                    onClose={() => setSuccessSentToAlert(null)}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default memo(FoldersAndDocsList);
