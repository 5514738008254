import React, { useCallback } from 'react';

import RichTextAreaWithDocsMapping from '../../design-system/RichTextAreaWithDocsMapping/RichTextAreaWithDocsMapping';

const RichTextAreaWithDocsMappingFormField = ({
    formItem,
    handleFormItemChange,
    isFormDisabled,
    actionsBarRightContent = null,
    placeholder, // by default -> Edit {label} or or add document
}) => {
    const {
        label,
        description,
        is_required,
        value,
        state: _state,
        errorMessage,
        docs_mapping,
    } = formItem;
    const state = isFormDisabled ? 'disabled' : _state;

    const handleRichTextChange = useCallback(
        (value) => {
            handleFormItemChange((item) => ({ ...item, value }));
        },
        [handleFormItemChange]
    );

    const handleMappingChange = useCallback(
        (cb) => {
            handleFormItemChange((item) => ({
                ...item,
                docs_mapping: typeof cb === 'function' ? cb(item.docs_mapping || {}) : cb,
            }));
        },
        [handleFormItemChange]
    );

    const formattedActionsBarRightContent = actionsBarRightContent ? (
        <div className="flex items-center gap-1 sm:gap-2 min-h-[28px]">
            {actionsBarRightContent}
        </div>
    ) : null;

    return (
        <RichTextAreaWithDocsMapping
            value={value}
            isRequired={is_required}
            state={state}
            label={label}
            errorMessage={errorMessage}
            tipText={description}
            placeholder={placeholder}
            docsMapping={docs_mapping}
            onDocsMappingChange={handleMappingChange}
            onValueChange={handleRichTextChange}
            autoExpand
            minHeight={170}
            actionsBarRightContent={formattedActionsBarRightContent}
        />
    );
};

export default RichTextAreaWithDocsMappingFormField;
