import React from 'react';

import useUser from '../../../hooks/useUser';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { CONTENT_TYPE, DOCUMENT_TYPE } from '../../../constants/docs';

import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import { ListOption, ListOptionGroup } from '../../../design-system';

const AddNewMultiActionPopup = ({
    fileTypeLoading,
    handleCreateNewDoc,
    openNewEntityModal,
    onClose,
}) => {
    const { user } = useUser();
    const isStaff = user?.is_staff;

    const { isMobile } = useResponsiveBreakpoints();

    const listOptions = (
        <>
            <ListOption
                leadingIconName="fileTextLineIcon"
                text="Doc"
                isLoading={fileTypeLoading === DOCUMENT_TYPE.document}
                onClick={() => handleCreateNewDoc({ fileType: DOCUMENT_TYPE.document })}
            />
            <ListOption
                leadingIconName="fileIcon"
                text="File"
                onClick={() => openNewEntityModal({ contentType: CONTENT_TYPE.file })}
            />
            {isStaff && (
                <ListOption
                    leadingIconName="sharedWithIcon"
                    text="Diagram"
                    isLoading={fileTypeLoading === DOCUMENT_TYPE.diagram}
                    onClick={() => handleCreateNewDoc({ fileType: DOCUMENT_TYPE.diagram })}
                />
            )}
            <ListOption
                leadingIconName="folderFillIcon"
                text="Folder"
                onClick={() => openNewEntityModal({ contentType: CONTENT_TYPE.folder })}
            />
        </>
    );

    if (isMobile) {
        return (
            <MobilePopup onClose={onClose} closeButtonText="Cancel">
                {listOptions}
            </MobilePopup>
        );
    }

    return (
        <div
            className="absolute top-[calc(100%+6px)] right-0 lg:right-[unset] lg:left-1/2 lg:transform lg:translate-x-[-50%] z-20 pb-5"
            onClick={(e) => e.preventDefault()}
        >
            <ListOptionGroup fixedWidth={280} listOptions={listOptions} handleClose={onClose} />
        </div>
    );
};

export default AddNewMultiActionPopup;
