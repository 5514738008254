import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { TASK_TYPES } from '../../constants/library';
import { integerRegex } from '../../constants/regex_patterns';
import { formatKeyToLabel } from '../../helpers/formatKeyToLabel';

import NewTextAreaBox from '../../design-system/NewTextAreaBox/NewTextAreaBox';
import Checkbox from '../../design-system/Checkbox/Checkbox';
import TaskInputHeader from '../TaskInputHeader/TaskInputHeader';
import Radio from '../../design-system/Radio/Radio';
import JsonTextareaBox from '../../design-system/JsonTextareaBox/JsonTextareaBox';
import { Input, Select } from '../../design-system';

const booleanTypeOptions = [
    { name: 'True', value: true },
    { name: 'False', value: false },
];

function TaskInputsContainer(
    {
        inputsData,
        setInputsData,
        taskType,
        placeholder,
        withCopyButton,
        withDeleteButton,
        onDeleteButtonClick,
        protectedMode,
        hasEditAccess,
    },
    ref
) {
    const handleInputsDataChange = (newValue, id) => {
        setInputsData((prevState) => {
            return prevState.map((input) =>
                input.id === id
                    ? { ...input, value: newValue, state: 'default', errorMessage: null }
                    : input
            );
        });
    };

    const handleMultiSelectChange = (id, option) => {
        setInputsData((prevData) =>
            prevData.map((item) => {
                if (item.id === id) {
                    const newValue = item.value?.includes(option)
                        ? item.value.filter((el) => el !== option)
                        : [...(item.value || []), option];
                    return { ...item, value: newValue };
                } else {
                    return item;
                }
            })
        );
    };

    const handleRadioButtonChange = (id, option) => {
        setInputsData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, value: option } : item))
        );
    };

    const handleIntegerInputChange = (e, id) => {
        const inputValue = e.target.value;

        if (integerRegex.test(inputValue)) {
            setInputsData((prevData) =>
                prevData.map((item) => (item.id === id ? { ...item, value: +inputValue } : item))
            );
        }
    };

    const handleSelectChange = (id, option) => {
        setInputsData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, value: option } : item))
        );
    };

    return (
        <div className="flex flex-col gap-4 flex-grow" ref={ref}>
            {!!inputsData?.length &&
                inputsData.map((item) => {
                    const {
                        id,
                        type,
                        action_type,
                        key,
                        value,
                        is_required,
                        state: _state,
                        options,
                        errorMessage,
                        isUnused = false,
                    } = item;

                    const tags = [{ text: formatKeyToLabel(type || ''), color: 'neutral' }];
                    const label =
                        taskType === TASK_TYPES.api || taskType === TASK_TYPES.plot
                            ? `{{${key}}}`
                            : taskType === TASK_TYPES.code
                            ? key
                            : `[${key}]`;
                    if (action_type) {
                        tags.push({
                            text: formatKeyToLabel(action_type || ''),
                            color: 'peach',
                        });
                    }

                    const warningText = {
                        [TASK_TYPES.assistant]: `${label} not used in your user message`,
                        [TASK_TYPES.function]: `${label} not used in your messages`,
                        [TASK_TYPES.api]: `${label} not used in your api request`,
                        [TASK_TYPES.code]: `${label} not used in your code`,
                        [TASK_TYPES.plot]: `${label} not used in your plot definition`,
                    };

                    const warningInfo = {
                        text: isUnused && warningText[taskType],
                        state: 'error',
                    };

                    const selectOptions = options
                        ? Object.values(options)?.map((option) => ({ id: option, name: option }))
                        : [];

                    const state = hasEditAccess ? _state || 'default' : 'disabled';

                    return (
                        <div className="flex flex-grow flex-col gap-[6px]" key={id}>
                            {action_type === 'multi-select' ||
                            action_type === 'single-select' ||
                            (!action_type &&
                                (type === 'boolean' ||
                                    type === 'integer' ||
                                    type === 'number' ||
                                    type === 'object' ||
                                    type === 'array' ||
                                    options)) ? (
                                <TaskInputHeader
                                    id={id}
                                    label={action_type ? item.label : label}
                                    isRequired={is_required}
                                    state={state}
                                    errorMessage={errorMessage}
                                    value={value}
                                    withCopyButton={
                                        type === 'integer' ||
                                        type === 'number' ||
                                        type === 'object' ||
                                        type === 'array'
                                    }
                                    withDeleteButton={
                                        withDeleteButton && !protectedMode && hasEditAccess
                                    }
                                    onDeleteButtonClick={onDeleteButtonClick}
                                    protectedMode={protectedMode}
                                    trailingTags={tags}
                                    info={warningInfo}
                                >
                                    {action_type === 'multi-select' && (
                                        <ul className="flex flex-col gap-1">
                                            {Object.values(options)?.map((option, index) => (
                                                <li key={index} className="py-1 px-2">
                                                    <Checkbox
                                                        name={option}
                                                        label={option}
                                                        isChecked={value?.includes(option)}
                                                        state={state}
                                                        onChange={() =>
                                                            handleMultiSelectChange(id, option)
                                                        }
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {action_type === 'single-select' && (
                                        <ul className="flex flex-col gap-1">
                                            {Object.values(options)?.map((option, index) => (
                                                <li key={index} className="py-1 px-2">
                                                    <Radio
                                                        isSelected={option === value}
                                                        name={option}
                                                        state={state}
                                                        onChange={() =>
                                                            handleRadioButtonChange(id, option)
                                                        }
                                                        label={option}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {!action_type &&
                                        (options ? (
                                            <Select
                                                size="sm"
                                                name={key}
                                                value={value}
                                                options={selectOptions}
                                                state={state}
                                                onChange={(option) =>
                                                    handleSelectChange(id, option)
                                                }
                                            />
                                        ) : (
                                            <>
                                                {type === 'boolean' && (
                                                    <ul className="flex flex-col gap-1">
                                                        {booleanTypeOptions.map((option) => (
                                                            <li
                                                                key={option.value}
                                                                className="py-1 px-2"
                                                            >
                                                                <Radio
                                                                    isSelected={
                                                                        option.value === value
                                                                    }
                                                                    name={option.name}
                                                                    state={state}
                                                                    onChange={() =>
                                                                        handleRadioButtonChange(
                                                                            id,
                                                                            option.value
                                                                        )
                                                                    }
                                                                    label={option.name}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                {type === 'integer' && (
                                                    <Input
                                                        size="xs"
                                                        value={value}
                                                        name={key}
                                                        isRequired
                                                        type="text"
                                                        placeholder="0"
                                                        state={state}
                                                        onChange={(e) =>
                                                            handleIntegerInputChange(e, id)
                                                        }
                                                    />
                                                )}
                                                {type === 'number' && (
                                                    <Input
                                                        size="xs"
                                                        value={value}
                                                        name={key}
                                                        isRequired
                                                        placeholder="0.1"
                                                        type="number"
                                                        step="0.1"
                                                        state={state}
                                                        onChange={(e) =>
                                                            handleInputsDataChange(
                                                                e.target.value,
                                                                id
                                                            )
                                                        }
                                                    />
                                                )}
                                            </>
                                        ))}
                                    {(type === 'array' || type === 'object') && (
                                        <JsonTextareaBox
                                            mode="code"
                                            value={value}
                                            state={state}
                                            containerMinHeight={212}
                                            setValue={(value) => handleInputsDataChange(value, id)}
                                        />
                                    )}
                                </TaskInputHeader>
                            ) : (
                                <NewTextAreaBox
                                    id={id}
                                    name={key}
                                    value={value}
                                    label={label}
                                    isRequired={is_required || false}
                                    placeholder={placeholder || 'Write here'}
                                    state={state}
                                    errorMessage={errorMessage}
                                    onChange={(e) => handleInputsDataChange(e.target.value, id)}
                                    withCopyButton={withCopyButton}
                                    withDeleteButton={
                                        withDeleteButton && !protectedMode && hasEditAccess
                                    }
                                    onDeleteButtonClick={onDeleteButtonClick}
                                    protectedMode={protectedMode}
                                    trailingTags={tags}
                                    info={warningInfo}
                                />
                            )}
                        </div>
                    );
                })}
        </div>
    );
}

const ForwardedTaskInputsContainer = forwardRef(TaskInputsContainer);

ForwardedTaskInputsContainer.propTypes = {
    inputsData: PropTypes.array.isRequired,
    setInputsData: PropTypes.func,
    placeholder: PropTypes.string,
    withCopyButton: PropTypes.bool,
    withDeleteButton: PropTypes.bool,
    onDeleteButtonClick: PropTypes.func,
    protectedMode: PropTypes.bool,
    hasEditAccess: PropTypes.bool,
    taskType: PropTypes.oneOf([
        'prompt',
        'inbox',
        'retrieval',
        'trigger',
        'webhook',
        'assistant',
        'function',
        'record',
        'api',
        'code',
        'plot',
    ]),
};

export default ForwardedTaskInputsContainer;
