import { STATUS } from './statuses';

import FolderFillIcon from '../design-system/Icons/FolderFillIcon';
import ArchiveLineIcon from '../design-system/Icons/ArchiveLineIcon';

export const SOURCE_TYPE = {
    diviup_base: 'diviup-base',
    diviup_folder: 'diviup-folder',
    google_folder: 'google-folder',
};

export const SOURCE_LINK_PREFIX = {
    [SOURCE_TYPE.diviup_base]: '/base/',
    [SOURCE_TYPE.diviup_folder]: '/docs/folder/',
    [SOURCE_TYPE.google_folder]: 'https://drive.google.com/drive/u/3/folders/',
};

export const SOURCE_TYPE_LABEL = {
    [SOURCE_TYPE.diviup_base]: 'DiviUp Base', // can be updated (is not used yet)
    [SOURCE_TYPE.diviup_folder]: 'DiviUp Folder',
    [SOURCE_TYPE.google_folder]: 'Google Folder',
};

export const SOURCE_ICON = {
    [SOURCE_TYPE.diviup_base]: ArchiveLineIcon,
    [SOURCE_TYPE.diviup_folder]: FolderFillIcon,
};

export const SOURCE_CARD_STATUS = {
    in_progress: 'in_progress',
    success: 'success',
    error: 'error',
};

export const SOURCE_CARD_STATUS_MAPPING = {
    [STATUS.pending]: SOURCE_CARD_STATUS.in_progress,
    [STATUS.started]: SOURCE_CARD_STATUS.in_progress,
    [STATUS.success]: SOURCE_CARD_STATUS.success,
    [STATUS.failure]: SOURCE_CARD_STATUS.error,
    [STATUS.canceled]: SOURCE_CARD_STATUS.error,
};

export const GOOGLE_IMAGE_PATH = '/images/providers-logo/google-logo.png';
