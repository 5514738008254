import React from 'react';

import { ListOption, ListOptionGroup } from '../../../design-system';

// is used for both Bot Card and Bot Detail page
const BotMoreOptionPopup = ({ onClose, setActionModal, position = 'top-[110%] right-3' }) => {
    const handleOpenModal = (action) => {
        return () => {
            setActionModal({ action });
            onClose();
        };
    };

    return (
        <div
            className={`absolute z-40 ${position}`}
            onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
            }}
        >
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete Bot"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={handleOpenModal('delete')}
                        />
                    </>
                }
                handleClose={onClose}
            />
        </div>
    );
};

export default BotMoreOptionPopup;
