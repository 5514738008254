import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { HISTORY_TAB } from '../../../constants/assistant';

import { useOverlayState } from '../../../hooks/useOverlayState';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { useAssistantChatThreadContext } from '../../../hooks/useAssistantChatThreadContext';

import { SvgIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import BotDetailPopup from '../BotDetailPopup/BotDetailPopup';
import BotCardMoreOptionsPopup from '../BotCardMoreOptionsPopup/BotCardMoreOptionsPopup';
import SourceDetailsPopup from '../SourceDetailsPopup/SourceDetailsPopup';

const BotCard = ({ bot, closePanel, setIsPanelClosableByClickOutside }) => {
    const navigate = useNavigate();
    const { id, name } = bot;

    const { isDesktop } = useResponsiveBreakpoints();

    const [isHovered, setIsHovered] = useState(false);
    const { isOpened: isPopupOpened, open: openPopup, close: closePopup } = useOverlayState();
    const {
        isOpened: isBotDetailPopupOpened,
        open: openBotDetailPopup,
        close: _closeBotDetailPopup,
    } = useOverlayState();

    const [sourceDetailsPopup, setSourceDetailsPopup] = useState(null);
    const isSourceDetailsPopupOpened = !!sourceDetailsPopup;

    const { selectedCustomBot, setCustomBotAsModel } = useAssistantChatThreadContext();
    const isCurrent = selectedCustomBot?.id === id;

    const isMoreOptionsButtonDisplayed = !isDesktop || isHovered || isPopupOpened;

    const closeBotDetailPopup = (e) => {
        setIsHovered(false);
        _closeBotDetailPopup(e);

        setIsPanelClosableByClickOutside(true);
    };

    const openMoreOptionsPopup = (e) => {
        openPopup(e);
        setIsPanelClosableByClickOutside(false);
    };

    const closeMoreOptionsPopup = (e, panelClosableByClickOutside = true) => {
        closePopup(e);

        if (panelClosableByClickOutside) {
            setIsPanelClosableByClickOutside(true);
        }
    };

    const openSourceDetailsPopup = (data) => {
        setIsHovered(false);
        setSourceDetailsPopup(data);

        _closeBotDetailPopup();
        setIsPanelClosableByClickOutside(false);
    };

    const closeSourceDetailsPopup = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setSourceDetailsPopup(null);
        setIsPanelClosableByClickOutside(true);
    };

    const openNewChatWithBot = ({ id, name }) => {
        navigate(`/assistant?tab=${HISTORY_TAB.bots}&bot=${id}`);
        setCustomBotAsModel({ id, name });
        setIsHovered(false);
        if (!isDesktop) {
            closePanel();
        }
        _closeBotDetailPopup();
        closeSourceDetailsPopup();
    };

    const openNewChatWithCurrentBot = () => {
        openNewChatWithBot({ id, name });
    };

    const handleCardClick = () => {
        if (isBotDetailPopupOpened || isSourceDetailsPopupOpened) return;

        setCustomBotAsModel({ id, name });
        if (!isDesktop) {
            closePanel();
        }
    };

    const isHighlighted = isCurrent || isPopupOpened;

    const cardClassName = classNames(
        'rounded-2 py-1 pl-1 flex items-center justify-between gap-1 transition-colors cursor-pointer hover:bg-neutral-50 min-h-[38px]',
        {
            'bg-white': !isHighlighted,
            'bg-neutral-50': isHighlighted,
        }
    );

    const nameClassName = classNames(
        'py-1 pl-1 font-body text-body-regular-m text-neutral-500 truncate',
        !isMoreOptionsButtonDisplayed && 'pr-1'
    );

    return (
        <>
            <div
                className={cardClassName}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleCardClick}
            >
                <p className={nameClassName}>{name}</p>

                <div className="relative">
                    {isMoreOptionsButtonDisplayed && (
                        <button
                            className="block pr-2 focus:outline-0"
                            onClick={openMoreOptionsPopup}
                        >
                            <SvgIcon color="#000000" icon={More2FillIcon} size="large" />
                        </button>
                    )}

                    {isPopupOpened && (
                        <BotCardMoreOptionsPopup
                            onClose={closeMoreOptionsPopup}
                            openNewChatWithBot={openNewChatWithCurrentBot}
                            openBotDetailPopup={openBotDetailPopup}
                        />
                    )}
                </div>
            </div>

            {isBotDetailPopupOpened && (
                <BotDetailPopup
                    id={id}
                    name={name}
                    openNewChatWithBot={openNewChatWithCurrentBot}
                    openSourceDetailsPopup={openSourceDetailsPopup}
                    onClose={closeBotDetailPopup}
                />
            )}

            {isSourceDetailsPopupOpened && (
                <SourceDetailsPopup
                    id={sourceDetailsPopup.id}
                    name={sourceDetailsPopup.name}
                    type={sourceDetailsPopup.type}
                    openNewChatWithBot={openNewChatWithBot}
                    onClose={closeSourceDetailsPopup}
                />
            )}
        </>
    );
};

export default BotCard;
