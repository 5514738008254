import React from 'react';

const SourceInfoBlockWrapper = ({ title, description, children }) => {
    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-2">
                <h3 className="font-body-bold text-body-bold-m text-black">{title}</h3>
                <p className="font-body text-body-regular-s text-neutral-400">{description}</p>
            </div>

            {children}
        </div>
    );
};

export default SourceInfoBlockWrapper;
