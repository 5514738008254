import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import classNames from 'classnames';
import ScrollToBottom from 'react-scroll-to-bottom';

import { API } from 'constants';
import client from '../../../../services/assistant-api';

import { useUserChatMessage } from '../../../../hooks/useUserChatMessage';
import { useManageSearchParams } from '../../../../hooks/useManageSearchParams';
import { useAssistantMessageStream } from '../../../../hooks/useAssistantMessageStream';
import useScrollbarGutterAuto from '../../../../hooks/useScrollbarGutterAuto';

import { CHAT_GPT4o_MODEL } from '../../../AssistantPage/AIModelSelector/AIModelSelector';
import { CHAT_ROLE, CHAT_TYPE } from '../../../../constants/assistant';

import ChatHeader from './ChatHeader/ChatHeader';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import ChatContent from '../../../AssistantPage/ChatContent/ChatContent';
import ChatInputBox from './ChatInputBox/ChatInputBox';
import ChatInvitingView from '../../../AssistantPage/ChatInvitingView/ChatInvitingView';
import Loading from '../../../../components/Loading';

const TEMP_CHAT_THREAD_ID = 'temporary_chat_thread_id'; // this ID is used when the user has already submitted the message, but the request is still loading.
export const CHAT_SEARCH_PARAM_KEY = 'chat';

const BotPreviewPanel = ({ botName, isInstructionEditing }) => {
    const { botId } = useParams();

    const [searchParams] = useSearchParams();
    const chatThreadId = searchParams.get(CHAT_SEARCH_PARAM_KEY) || null;

    const panelContainerRef = useRef(null);

    const [chatMessages, setChatMessages] = useState([]);
    const [messageStreamState, setMessageStreamState] = useState({
        isAssistantLoading: false,
        isStreamRunning: false,
    });

    const [isPrevChatLoading, setIsPrevChatLoading] = useState(false);

    const { setSearchParam, deleteSearchParam } = useManageSearchParams();

    // just on page load
    useEffect(() => {
        const fetchChatThreadData = async () => {
            try {
                setIsPrevChatLoading(true);
                const { data } = await client.get(
                    `${API.ROUTES.assistant.chatThread}${chatThreadId}/`
                );
                setChatMessages(data.chat_messages || []);
            } catch (e) {
                deleteSearchParam(CHAT_SEARCH_PARAM_KEY);
            } finally {
                setIsPrevChatLoading(false);
            }
        };

        if (chatThreadId) {
            fetchChatThreadData();
        }
    }, []);

    const modelSettings = {
        type: CHAT_TYPE.custom,
        model: CHAT_GPT4o_MODEL,
        custom_bot_version: { custom_bot: { id: botId, name: botName } },
    };
    const {
        startListeningWebSockets,
        stopWebSocketStream,
        tryReconnectIfWebsocketClosed,
        clearMessageLoadingStreamState,
        resetAssistantMessageResponse,
        cancelStream,
        isStreamCanceled,
        emptyCanceledAssistantMessage,
        setEmptyCanceledAssistantMessage,
    } = useAssistantMessageStream({
        setChatMessages,
        messageStreamState,
        setMessageStreamState,
        modelSettings,
    });

    const getSubmittingMessageRequestBody = ({ message, isNewChat }) => {
        const requestBody = {
            content: message,
            origin: 'test',
            type: CHAT_TYPE.custom,
            custom_bot: botId,
        };
        if (!isNewChat) {
            requestBody.chat_thread = chatThreadId;
        }

        return requestBody;
    };

    const getRegenerateMessageRequestBody = useCallback(
        ({ role, message }) => {
            const requestBody = {
                regenerate: true,
                origin: 'test',
                type: CHAT_TYPE.custom,
                custom_bot: botId,
            };
            if (role === CHAT_ROLE.user) {
                requestBody.content = message;
            }

            return requestBody;
        },
        [botId]
    );

    const handleMessageSubmitResponse = useCallback(
        ({ data, messagesToAppend, isNewChat }) => {
            if (!isNewChat) {
                setChatMessages((prevMessages) => [...prevMessages, ...messagesToAppend]);
            }

            if (isNewChat) {
                const id = data.chat_thread.id;
                setSearchParam(CHAT_SEARCH_PARAM_KEY, id);
                setChatMessages([...messagesToAppend]);
            }
        },
        [setChatMessages, setSearchParam]
    );

    const {
        submitChatMessage,
        handleRegenerateChatMessage,
        displayedChatMessages,
        setSubmittedUserMessage,
        messageSubmissionErrorData,
        setMessageSubmissionErrorData,
        controller,
        errorAlert,
        setErrorAlert,
        scrollPosition,
        setScrollPosition,
        containerRef,
    } = useUserChatMessage({
        chatMessages,
        setChatMessages,
        setMessageStreamState,
        isNewChat: !chatThreadId || chatThreadId === TEMP_CHAT_THREAD_ID,
        handleMessageSubmitResponse,
        tryReconnectIfWebsocketClosed,
        startListeningWebSockets,
        getSubmittingMessageRequestBody,
        getRegenerateMessageRequestBody,
        emptyCanceledAssistantMessage,
        setEmptyCanceledAssistantMessage,
        isStreamCanceled,
    });

    useEffect(() => {
        if (chatThreadId) return;

        refreshChat();
    }, [chatThreadId]);

    const refreshChat = () => {
        stopWebSocketStream();
        controller.current?.abort();

        isStreamCanceled.current = false;
        setEmptyCanceledAssistantMessage(null);

        setChatMessages([]);
        setSubmittedUserMessage(null);
        resetAssistantMessageResponse();
        clearMessageLoadingStreamState();
    };

    useScrollbarGutterAuto();

    const chatMessagesExist = !!displayedChatMessages?.length;

    const containerClassName = classNames(
        'w-full max-w-[1010px] mx-auto min-h-full',
        !chatMessagesExist && 'h-full',
        chatMessagesExist && 'pb-6'
    );

    const isAssistantMessageLoadingOrStreaming =
        messageStreamState.isAssistantLoading || messageStreamState.isStreamRunning;

    const isRegenerateMessageDisabled =
        isAssistantMessageLoadingOrStreaming || !!isStreamCanceled.current;

    return (
        <div className="h-full flex flex-col" ref={panelContainerRef}>
            <ChatHeader isChatLoading={isPrevChatLoading} />

            <div
                className={`flex-1 overflow-hidden ${isPrevChatLoading && 'hidden'}`}
                ref={containerRef}
            >
                <ScrollToBottom
                    className="h-full"
                    followButtonClassName="hidden"
                    initialScrollBehavior="auto"
                >
                    <div className="px-5 3xl:px-8 pt-5 pb-6 h-full">
                        <div className={containerClassName}>
                            {chatMessagesExist && (
                                <ChatContent
                                    chatMessages={displayedChatMessages}
                                    setChatMessages={setChatMessages}
                                    chatType={CHAT_TYPE.custom}
                                    isRegenerateMessageDisabled={isRegenerateMessageDisabled}
                                    handleRegenerateChatMessage={handleRegenerateChatMessage}
                                    messageStreamState={messageStreamState}
                                    scrollPosition={scrollPosition}
                                    setScrollPosition={setScrollPosition}
                                    containerRef={containerRef}
                                    isSimplifiedVersion
                                />
                            )}

                            {!chatMessagesExist && (
                                <ChatInvitingView
                                    chatType={CHAT_TYPE.custom}
                                    selectedCustomBot={{ name: botName }}
                                    isSimplifiedVersion
                                />
                            )}
                        </div>
                    </div>
                </ScrollToBottom>
            </div>

            {isPrevChatLoading && (
                <div className="flex-1 flex items-center justify-center pb-9">
                    <Loading withText={false} />
                </div>
            )}

            <ChatInputBox
                submitChatMessage={submitChatMessage}
                handleCancelStream={cancelStream}
                isAssistantMessageLoadingOrStreaming={isAssistantMessageLoadingOrStreaming}
                isCancelStreamingLoading={!!isStreamCanceled.current}
                isPrevChatLoading={isPrevChatLoading}
                messageErrorData={messageSubmissionErrorData}
                setMessageErrorData={setMessageSubmissionErrorData}
                botName={botName}
                chatThreadId={chatThreadId}
                containerRef={panelContainerRef}
                isInstructionEditing={isInstructionEditing}
            />

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default memo(BotPreviewPanel);
