import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { useOverlayState } from '../../../hooks/useOverlayState';

import { ButtonIcon } from '../../../design-system';
import { More2FillIcon, Robot2LineIcon } from '../../../design-system/Icons';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import BaseIcon from '../BaseIcon/BaseIcon';
import ClockIcon from '../../../design-system/Icons/ClockIcon';
import EditBaseModal from '../EditBaseModal/EditBaseModal';
import TimestampDisplay from '../../../design-system/TimestampDisplay/TimestampDisplay';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import BaseMoreOptionsPopup from '../BaseMoreOptionsPopup/BaseMoreOptionsPopup';

const BaseCard = ({ base, hasEditAccess, requestKeysToMutate }) => {
    const { id, name, description, records, updated_at, source, type } = base;
    const { is_live = false, bot_count = 0 } = source || {};

    const location = useLocation();

    const { isOpened: isPopupOpened, open: openPopup, close: closePopup } = useOverlayState();
    const { isOpened: isEditModalOpened, open: openModal, close: closeModal } = useOverlayState();

    const formattedDescription = description || `This is a knowledge base for ${name}`;

    const statsInfo = [
        { icon: Robot2LineIcon, count: bot_count },
        { icon: FileTextLineIcon, count: records?.toLocaleString() },
    ];

    const cardClassName = classNames(
        'h-full flex flex-col gap-4 justify-between p-5 rounded-[6px] border-1 border-neutral-100 bg-white relative max-w-full',
        !is_live && 'opacity-50'
    );

    const nameContainerClassName = classNames(
        'max-w-[calc(100%-40px)] max-w-[calc(100%-40px)]',
        hasEditAccess && 'pr-1'
    );

    return (
        <li className="content-stretch relative">
            <Link to={`/base/${id}`} state={{ from: location }} className={cardClassName}>
                {hasEditAccess && (
                    <div className="absolute top-[6px] right-1 z-10">
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={More2FillIcon}
                            onClick={openPopup}
                        />
                    </div>
                )}

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                        <BaseIcon type={type} isHighlighted />

                        <div className={nameContainerClassName}>
                            <ToolTip
                                text={name}
                                position="top-left"
                                shift="1px"
                                useInternalHoverStateToShowToolTip
                            >
                                <p className="max-w-full font-body-bold text-body-bold-s text-black truncate">
                                    {name}
                                </p>
                            </ToolTip>
                        </div>
                    </div>

                    <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-1">
                        {formattedDescription}
                    </p>
                </div>

                <div className="flex items-center justify-between gap-2 overflow-hidden">
                    {is_live && (
                        <TimestampDisplay
                            label="Last Updated"
                            timestamp={updated_at}
                            dateTimeFormat="MM/dd/yyyy"
                            customLabelStyles="text-neutral-500"
                            withClockIcon
                        />
                    )}
                    {!is_live && (
                        <div className="flex items-center gap-1 overflow-hidden">
                            <div className="min-w-[14px]">
                                <ClockIcon width={14} height={14} color="#754DCF" />
                            </div>
                            <p className="font-body text-body-regular-xs text-neutral-500 truncate">
                                In progress
                            </p>
                        </div>
                    )}

                    <div className="flex items-center gap-2">
                        {statsInfo.map(({ icon: Icon, count }, index) => (
                            <div
                                key={index}
                                className="flex items-center gap-1 font-body text-body-regular-xs text-neutral-500"
                            >
                                <Icon width={14} height={14} color="#754DCF" />
                                {count}
                            </div>
                        ))}
                    </div>
                </div>
            </Link>

            {isPopupOpened && (
                <BaseMoreOptionsPopup baseId={id} openEditModal={openModal} onClose={closePopup} />
            )}
            {isEditModalOpened && (
                <EditBaseModal
                    id={id}
                    name={name}
                    description={description || ''}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={closeModal}
                />
            )}
        </li>
    );
};

export default memo(BaseCard);
