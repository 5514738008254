import React, { memo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { DateTime } from 'luxon';
import classNames from 'classnames';

import {
    docItemGridColsStyles,
    nestedGridContainerStyles,
    updatedByColStyles,
} from '../DocsColumnHeader/DocsColumnHeader';
import { CONTENT_TYPE, CONTENT_TYPE_ICON } from '../../../constants/docs';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import { openLinkInNewTab } from '../../../helpers/openLinkInNewTab';
import { handleExpandSidePanelFolder } from '../../../helpers/docsSidePanelUtils';

import { ButtonIcon, SvgIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import Alert from '../../../design-system/Alert/Alert';
import UserBadge from '../../../design-system/UserBadge/UserBadge';
import Checkbox from '../../../design-system/Checkbox/Checkbox';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import DocAndDiagramExporter from '../DocAndDiagramExporter/DocAndDiagramExporter';
import FolderWithBotIndicator from '../FolderWithBotIndicator/FolderWithBotIndicator';
import DocCardMoreOptionsPopup from '../DocCardMoreOptionsPopup/DocCardMoreOptionsPopup';

const FolderOrDocCard = ({
    item,
    isSelected = false,
    setSelectedItems,
    setActionModal,
    isDndOver,
}) => {
    const { id, label, content_type, last_updated_by, file, custom_bots } = item;
    const { name, updated_at } = last_updated_by || {};

    const location = useLocation();

    const isFolder = content_type === CONTENT_TYPE.folder;
    const isFile = content_type === CONTENT_TYPE.file;
    const isDiagram = content_type === CONTENT_TYPE.diagram;
    const isDocument = content_type === CONTENT_TYPE.document;

    const { location: parentLocation, setLocation, setExpandedFolders } = useDocsPageContext();

    const withRobotIcon = isFolder && !!custom_bots?.length;

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    const [successAlert, setSuccessAlert] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy, HH:mm:ss');

    const exporterRef = useRef(null);

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setIsMoreOptionsPopupOpened(true);
    };

    const handleCheckboxChange = () => {
        setSelectedItems((prevSelectedIds) => {
            const alreadySelected = prevSelectedIds[id];
            const newState = { ...prevSelectedIds };

            if (alreadySelected) {
                delete newState[id];
            } else {
                newState[id] = item;
            }

            return newState;
        });
    };

    const Icon = CONTENT_TYPE_ICON[content_type] || FileTextLineIcon;

    const route = {
        [CONTENT_TYPE.folder]: `/docs/folder/${id}`,
        [CONTENT_TYPE.document]: `/doc/${id}`,
        [CONTENT_TYPE.file]: '',
        [CONTENT_TYPE.diagram]: `/doc/${id}`,
    }[content_type];

    const currentLocation = [...(parentLocation || []), { id, label }];

    const handleCardClick = ({ e, location }) => {
        if (isFile) {
            e?.preventDefault();
            openLinkInNewTab(file);
            return;
        }

        if (!isFolder) {
            return;
        }

        setLocation(location);

        handleExpandSidePanelFolder({
            location: location.map((loc) => loc.id),
            setExpandedFolders,
        });
    };

    const cardClassName = classNames(
        'py-3 bg-white rounded-2 shadow-inset-2 transition-shadow',
        docItemGridColsStyles,
        {
            'shadow-purple-500': isDndOver,
            'shadow-transparent': !isDndOver,
        },
    );

    return (
        <li className="w-full relative">
            <div className="absolute top-1/2 transform translate-y-[-50%] left-4 z-40">
                <Checkbox isChecked={isSelected} name={label} onChange={handleCheckboxChange} />
            </div>

            <Link
                to={route}
                state={{ from: location }}
                onClick={(e) => handleCardClick({ e, location: currentLocation })}
                className={cardClassName}
            >
                <div className="opacity-0">Checkbox</div>

                <div className={nestedGridContainerStyles}>
                    <div className="max-w-full flex items-center gap-2 sm:gap-3 lg:gap-4">
                        <div className="w-[26px] h-[26px] min-w-[26px] sm:w-[32px] sm:h-[32px] sm:min-w-[32px] rounded-2 flex items-center justify-center bg-purple-100">
                            <SvgIcon icon={Icon} color="#754DCF" size="medium" />
                        </div>
                        <div className="flex items-center gap-2 w-[calc(100%-32px)] sm:w-[calc(100%-44px)] lg:w-[calc(100%-48px)]">
                            <p className="font-body text-body-regular-s text-black truncate">
                                {label}
                            </p>

                            {withRobotIcon && <FolderWithBotIndicator customBots={custom_bots} />}
                        </div>
                    </div>

                    <div className="max-w-full font-body text-body-regular-xs text-neutral-500 truncate">
                        <span className="sm:hidden">Last Update </span>
                        {formattedTime}
                    </div>
                </div>

                <div className={updatedByColStyles}>
                    <UserBadge name={name} color="purple" />
                </div>

                <div>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={openMoreOptionsPopup}
                    />
                </div>
            </Link>

            {(isDiagram || isDocument) && <DocAndDiagramExporter id={id} ref={exporterRef} />}

            {isMoreOptionsPopupOpened && (
                <DocCardMoreOptionsPopup
                    contentType={content_type}
                    data={{ id, label, location: currentLocation }}
                    setActionModal={setActionModal}
                    setSuccessAlert={setSuccessAlert}
                    setErrorAlert={setErrorAlert}
                    onNavigateToFolderView={handleCardClick}
                    parentLocation={parentLocation || []}
                    exporterRef={exporterRef}
                    onClose={() => setIsMoreOptionsPopupOpened(false)}
                />
            )}

            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    description={successAlert.description}
                    position="top-center"
                    autoCloseInMS={5000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </li>
    );
};

export default memo(FolderOrDocCard);
