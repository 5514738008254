import React from 'react';

import EmptyView from '../../../design-system/EmptyView/EmptyView';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';

const BasesEmptyView = ({ isSearchQuery }) => {
    if (isSearchQuery) {
        return (
            <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]">
                No bases found. Please adjust your search and try again!
            </p>
        );
    }

    return (
        <EmptyView
            icon={ArchiveLineIcon}
            mainText="You don't have any Knowledge Bases yet."
            subtext="Contact your account manager to set up your Knowledge Bases."
            mainTextAdditionalStyles="max-w-[490px]"
            withButton={false}
        />
    );
};

export default BasesEmptyView;
