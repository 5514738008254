export const isValidJson = (json) => {
    try {
        JSON.parse(json);
        return true;
    } catch (error) {
        return false;
    }
};

export const isValidJsonInput = (value) => {
    if (typeof value === 'object') return true;

    return isValidJson(value);
};

export const getFormattedJsonInputValue = (value) => {
    if (typeof value === 'object') return value;

    return JSON.parse(value);
};
