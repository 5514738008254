import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { HISTORY_TAB } from '../../../constants/assistant';

import HistorySidePanelTopControls from '../HistorySidePanelTopControls/HistorySidePanelTopControls';
import HistorySidePanelChatsTab from '../HistorySidePanelChatsTab/HistorySidePanelChatsTab';
import HistorySidePanelWrapper from '../HistorySidePanelWrapper/HistorySidePanelWrapper';
import HistorySidePanelBotsTab from '../HistorySidePanelBotsTab/HistorySidePanelBotsTab';

const tabs = [HISTORY_TAB.chats, HISTORY_TAB.bots];
export const formattedTabs = tabs.map((tab) => ({ name: tab.toUpperCase(), key: tab }));

const HistorySidePanel = ({
    historyPanelExpandedState,
    chatThreadsHistoryHookResponse,
    botsHistoryHookResponse,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get('tab');
    const activeTabIndex = Math.max(tabs.indexOf(activeTab), 0);

    const [_, setIsExpanded] = historyPanelExpandedState;
    const [isPanelClosableByClickOutside, setIsPanelClosableByClickOutside] = useState(true);

    useEffect(() => {
        const isParsedWrongTabName = !tabs.includes(activeTab);

        if (isParsedWrongTabName) {
            changeTab(HISTORY_TAB.chats);
        }
    }, [activeTab]);

    const changeTab = (newTab) => {
        const urlSearchParams = new URLSearchParams(searchParams);
        urlSearchParams.set('tab', newTab);
        setSearchParams(urlSearchParams);
    };

    const handleTabChange = (tab) => changeTab(tab.key);

    const closePanel = useCallback(() => setIsExpanded(false), [setIsExpanded]);

    const tabsCommonProps = {
        setIsPanelClosableByClickOutside,
        closePanel,
    };

    return (
        <HistorySidePanelWrapper
            historyPanelExpandedState={historyPanelExpandedState}
            isPanelClosableByClickOutside={isPanelClosableByClickOutside}
        >
            <div className="px-5 lg:px-4 xl:px-5 pt-4 w-full min-w-full border-r-1 border-neutral-200 h-full max-h-full flex flex-col gap-2 bg-white">
                <HistorySidePanelTopControls
                    collapsePanel={closePanel}
                    activeTab={activeTab}
                    activeTabIndex={activeTabIndex}
                    handleTabChange={handleTabChange}
                />

                <div className="flex-grow h-[calc(100%-134px)]">
                    {activeTabIndex === 0 && (
                        <HistorySidePanelChatsTab
                            chatThreadsHistoryHookResponse={chatThreadsHistoryHookResponse}
                            {...tabsCommonProps}
                        />
                    )}

                    <HistorySidePanelBotsTab
                        isActiveTab={activeTabIndex === 1}
                        botsHistoryHookResponse={botsHistoryHookResponse}
                        {...tabsCommonProps}
                    />
                </div>
            </div>
        </HistorySidePanelWrapper>
    );
};

export default HistorySidePanel;
