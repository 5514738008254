import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { API } from 'constants';
import client from '../../services/inbox-api';

import { useFormState } from '../../hooks/useFormState';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../constants/errorMessages';

import Modal from '../../design-system/Modal/Modal';
import ErrorAlert from '../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../design-system/ModalHeader/ModalHeader';
import RatingControls from '../../design-system/RatingControls/RatingControls';
import NewRichTextArea from '../../design-system/NewRichTextArea/NewRichTextArea';
import { Button, TextArea } from '../../design-system';

ShareFeedbackModal.propTypes = {
    target: PropTypes.oneOf(['inbox_message', 'thread', 'chat_message_version']).isRequired,
    targetId: PropTypes.string.isRequired, // message id or thread id
    state: PropTypes.oneOf(['empty', 'filled']),
    variant: PropTypes.oneOf(['primary', 'secondary']),
    initialRating: PropTypes.number, // is used if state === "filled"
    setSuccessAlert: PropTypes.func.isRequired,
    onSuccessFeedbackShare: PropTypes.func,
    successAlertMessage: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

function ShareFeedbackModal({
    target,
    targetId,
    state = 'empty',
    variant = 'primary',
    initialRating = 0,
    setSuccessAlert,
    onSuccessFeedbackShare,
    successAlertMessage = 'Thanks for submitting feedback!',
    onClose,
}) {
    const formInitialState =
        state === 'filled' ? { rating: initialRating, comment: '' } : { rating: 0, comment: '' };

    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } =
        useFormState(formInitialState);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const submitFeedback = async () => {
        try {
            const { rating, comment } = formData;

            const requiredFields = {
                primary: ['rating', 'comment'],
                secondary: ['rating'],
            }[variant];

            const areEmptyFields = requiredFields.some((field) => !formData[field]);
            if (areEmptyFields) {
                setFieldErrorMessages({
                    rating: !rating ? 'Please rate this run.' : null,
                    comment:
                        !comment && requiredFields.includes('comment')
                            ? emptyFieldErrorMessage
                            : null,
                });
                return;
            }

            const requestBody = { rating: formData.rating, [target]: targetId };
            if (comment) {
                requestBody.value = comment;
            }

            setIsLoading(true);
            const { data } = await client.post(API.ROUTES.inbox.feedback, requestBody);

            if (onSuccessFeedbackShare) {
                await onSuccessFeedbackShare(data);
            }

            setSuccessAlert({ message: successAlertMessage });
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    if (variant === 'secondary') {
        const modalTitle = {
            empty: 'Share Feedback',
            filled: target === 'thread' ? 'Add Feedback to Thread?' : 'Add Feedback to Message?',
        }[state];

        const ratingLabel = {
            empty: 'How would you rate this run?',
            filled: 'Adjust your rating?',
        }[state];

        const commentLabel = {
            empty: 'Comment',
            filled: 'Add a comment?',
        }[state];

        const submitButtonText = {
            empty: 'Share Feedback',
            filled: target === 'thread' ? 'Add to Thread' : 'Add to Message',
        }[state];

        return (
            <Modal onClose={onClose} size="extraSmall">
                <div className="flex flex-col gap-4 mb-8">
                    <p className="font-body-bold text-body-bold-m text-black py-2">{modalTitle}</p>

                    <RatingControls
                        rating={formData.rating}
                        label={ratingLabel}
                        isRequired
                        state={fieldErrorMessages.rating ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.rating}
                        onChange={(rating) => handleInputChange('rating', rating)}
                    />

                    <TextArea
                        name="comment"
                        value={formData.comment}
                        label={commentLabel}
                        placeholder="Add your feedback here (optional)"
                        onChange={(e) => handleInputChange('comment', e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-between">
                    <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="sm"
                        text={submitButtonText}
                        state={isLoading ? 'loading' : 'default'}
                        onClick={submitFeedback}
                    />
                </div>

                <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            </Modal>
        );
    }

    return (
        <Modal onClose={onClose} size="mediumPlus" resetPadding>
            <ModalHeader
                onClose={onClose}
                title="Log Feedback"
                outlined
                customPaddings="px-5 py-3"
            />

            <div className="px-5 py-5 flex flex-col gap-5 md:gap-6">
                <RatingControls
                    rating={formData.rating}
                    label="How would you rate this output?"
                    isRequired
                    state={fieldErrorMessages.rating ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.rating}
                    onChange={(rating) => handleInputChange('rating', rating)}
                />

                <NewRichTextArea
                    value={formData.comment}
                    setValue={(value) => handleInputChange('comment', value)}
                    label="Feedback"
                    isRequired
                    state={fieldErrorMessages.comment ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.comment}
                    placeholder="Add your feedback or comments here!"
                    tipText="Please make your feedback as specific and actionable as possible, including adding quotes, examples, etc."
                    minHeight={200}
                />
            </div>
            <div className="px-5 py-3 flex justify-end">
                <Button
                    type="secondary"
                    size="sm"
                    text="Log Feedback"
                    state={isLoading ? 'loading' : 'default'}
                    onClick={submitFeedback}
                />
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
}

export default ShareFeedbackModal;
