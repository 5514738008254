import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { bytesToMegabytes, isFileFormatValid, isFileSizeValid } from '../../helpers/fileUtils';

import ErrorAlert from '../ErrorAlert/ErrorAlert';

FileInput.propTypes = {
    file: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]),
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    handleFile: PropTypes.func.isRequired,
    maxSizeInBytes: PropTypes.number,
    minSizeInBytes: PropTypes.number,
    formatsArray: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    limitationsLabel: PropTypes.string,
    view: PropTypes.oneOf(['simple', 'extended']),
    shouldShowPreview: PropTypes.bool,
    errorNotificationMode: PropTypes.oneOf(['alert', 'message']),
    setErrorMessage: PropTypes.func,
};

function FileInput({
    file,
    handleFile,
    maxSizeInBytes,
    minSizeInBytes,
    formatsArray = null,
    state = 'default',
    placeholder = 'Drag & drop or click here to upload photo',
    limitationsLabel = '',
    view = 'simple',
    shouldShowPreview = false, // just for image preview
    errorNotificationMode = 'alert',
    // If 'message' – files with the wrong size or format will be set along with an error message,
    // so an additional check for file validity is needed in the submit form function.
    // If 'alert' – files with the wrong size or format will not be set; only an error alert will be displayed.
    setErrorMessage, // is required if errorNotificationMode is 'message'
}) {
    const [previewUrl, setPreviewUrl] = useState(() =>
        shouldShowPreview ? getFilePreviewUrl() : null
    );
    const [errorAlert, setErrorAlert] = useState(null);

    const isDisabled = state === 'disabled';
    const isError = state === 'error';

    const [{ isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: handleDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    useEffect(() => {
        if (!shouldShowPreview) return;

        const previewUrl = getFilePreviewUrl();
        setPreviewUrl(previewUrl);
    }, [file, shouldShowPreview]);

    function getFilePreviewUrl() {
        if (!file) {
            return null;
        }

        return typeof file === 'string' ? file : URL.createObjectURL(file);
    }

    const setErrorNotification = (message) => {
        if (errorNotificationMode === 'message') {
            setErrorMessage(message);
        }

        if (errorNotificationMode === 'alert') {
            if (message) {
                setErrorAlert({ message });
            }
            if (!message) {
                setErrorAlert(null);
            }
        }
    };

    const handleFileWrongSize = (param) => {
        const maxSizeLabel = bytesToMegabytes(maxSizeInBytes) + 'MB';
        const minSizeLabel = bytesToMegabytes(minSizeInBytes) + 'MB';

        const message = {
            min: `File size is smaller than ${minSizeLabel} and cannot be uploaded.`,
            max: `File size exceeds ${maxSizeLabel} and cannot be uploaded.`,
            range: `File size should be between ${minSizeLabel} and ${maxSizeLabel} and cannot be uploaded.`,
        };

        setErrorNotification(message[param]);
    };

    function handleDrop(item, monitor) {
        if (isDisabled) {
            return;
        }

        const droppedFiles = monitor.getItem().files;
        if (droppedFiles && droppedFiles[0]) {
            const file = droppedFiles[0];

            if (errorNotificationMode === 'message') {
                handleFile(file);
            }

            const shouldCheckFormats = !!formatsArray && !!formatsArray?.length;
            if (shouldCheckFormats) {
                const isWrongFormat = !isFileFormatValid({ file, availableFormats: formatsArray });

                if (isWrongFormat) {
                    const formatsString = formatsArray
                        ?.map((format) => format.slice(1)?.toUpperCase())
                        ?.join(', ');

                    const message = `Allowed file types: ${formatsString}`;
                    setErrorNotification(message);
                    return;
                }
            }

            const isWrongSize = !isFileSizeValid({
                file,
                maxSizeInBytes,
                minSizeInBytes,
                handleFileWrongSize,
            });
            if (isWrongSize) {
                return;
            }

            if (errorNotificationMode === 'alert') {
                handleFile(file);
            }
        }
    }

    const handleChange = (e) => {
        e?.preventDefault();
        setErrorNotification(null);
        const file = e.target.files && e.target.files[0];

        if (file) {
            if (errorNotificationMode === 'message') {
                handleFile(file);
            }

            const isWrongSize = !isFileSizeValid({
                file,
                maxSizeInBytes,
                minSizeInBytes,
                handleFileWrongSize,
            });
            if (isWrongSize) {
                return;
            }

            if (errorNotificationMode === 'alert') {
                handleFile(file);
            }
        }
    };

    const labelClassName = classNames(
        'block p-4 bg-purple-100 border-1 border-dashed transition-all',
        {
            'rounded-2': view === 'simple',
            'rounded-[10px]': view === 'extended',
            'border-purple-300': view === 'simple' && state === 'default',
            'border-purple-500': view === 'extended' && state === 'default',
            'border-neutral-300 cursor-not-allowed': isDisabled,
            'border-red-500': isError,
            'cursor-pointer': !isDisabled,
            'bg-opacity-80': isOver && !isDisabled,
            'bg-opacity-30': !isOver || isDisabled,
        }
    );

    const isPreviewDisplayed = shouldShowPreview && previewUrl;

    const fileIsUrl = typeof file === 'string';
    const displayedFilePath = !file ? placeholder : fileIsUrl ? file : file.name;
    const pathClassName = classNames('font-body text-body-regular-m break-words md:break-normal', {
        'text-neutral-300 opacity-60': !file,
        'text-neutral-400 opacity-70': !!file,
    });

    return (
        <>
            {view === 'simple' && (
                <label ref={drop} className={labelClassName}>
                    {isPreviewDisplayed && (
                        <div className="h-[268px] flex items-center justify-center">
                            <img
                                src={previewUrl}
                                className="block max-w-full max-h-full object-contain"
                                alt="Image Preview"
                            />
                        </div>
                    )}
                    {!isPreviewDisplayed && <p className={pathClassName}>{displayedFilePath}</p>}
                    <input
                        type="file"
                        className="hidden"
                        accept={formatsArray?.join(', ')}
                        onChange={handleChange}
                        disabled={isDisabled}
                    />
                </label>
            )}

            {view === 'extended' && (
                <label ref={drop} className={labelClassName}>
                    <input
                        type="file"
                        className="hidden"
                        accept={formatsArray?.join(', ')}
                        onChange={handleChange}
                        disabled={isDisabled}
                    />
                    <div className="flex flex-col gap-2.5 items-center p-2.5">
                        <p
                            className={`font-body text-body-regular-m text-center ${
                                isDisabled ? 'text-neutral-300' : 'text-purple-500'
                            }`}
                        >
                            <span className="font-body-bold text-body-bold-m underline">
                                Click to upload
                            </span>{' '}
                            or drag and drop here
                        </p>
                        <p className="font-body text-body-regular-s text-neutral-300 text-center">
                            {limitationsLabel}
                        </p>
                    </div>
                </label>
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
}

export default FileInput;
