export const NEW_JOB_INPUT_ACTION_TYPE = {
    single_input: 'single-input',
    json_input: 'json-input',
    textarea_input: 'textarea-input',
    richtext_input: 'richtext-input',
    multi_input: 'multi-input',
    single_select: 'single-select',
    multi_select: 'multi-select',
    single_dropdown: 'single-dropdown',
    multi_dropdown: 'multi-dropdown',
    single_db: 'single-db',
    multi_db: 'multi-db',
    single_file: 'single-file',
    code_input: 'code-input',
    toggle: 'toggle',
};

export const ACTION_TYPE_INITIAL_VALUE = {
    [NEW_JOB_INPUT_ACTION_TYPE.single_input]: '',
    [NEW_JOB_INPUT_ACTION_TYPE.json_input]: {},
    [NEW_JOB_INPUT_ACTION_TYPE.textarea_input]: '',
    [NEW_JOB_INPUT_ACTION_TYPE.richtext_input]: '',
    [NEW_JOB_INPUT_ACTION_TYPE.multi_input]: [],
    [NEW_JOB_INPUT_ACTION_TYPE.single_select]: null,
    [NEW_JOB_INPUT_ACTION_TYPE.multi_select]: [],
    [NEW_JOB_INPUT_ACTION_TYPE.single_dropdown]: null,
    [NEW_JOB_INPUT_ACTION_TYPE.multi_dropdown]: [],
    [NEW_JOB_INPUT_ACTION_TYPE.single_db]: null,
    [NEW_JOB_INPUT_ACTION_TYPE.multi_db]: [],
    [NEW_JOB_INPUT_ACTION_TYPE.single_file]: null,
    [NEW_JOB_INPUT_ACTION_TYPE.code_input]: '',
    [NEW_JOB_INPUT_ACTION_TYPE.toggle]: false,
};
