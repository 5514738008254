import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API } from 'constants';
import operateClient from '../../../services/operate-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useHandlePageDataLoadError } from '../../../hooks/useHandlePageDataLoadError';

import Loading from '../../../components/Loading';
import ThreadsList from './ThreadsList/ThreadsList';
import RestartSidePanel from '../../ThreadDetailPage/RestartSidePanel/RestartSidePanel';
import ExecutionPageHeader from './ExecutionPageHeader/ExecutionPageHeader';
import RenameExecutionModal from './RenameExecutionModal/RenameExecutionModal';
import CancelExecutionModal from './CancelExecutionModal/CancelExecutionModal';

const fetcher = (url) => operateClient.get(url).then((res) => res.data);

const ExecutionDetailPage = () => {
    const { executionId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const backlinkHref = location.state?.from ?? '/threads?tab=executions';

    const [actionModal, setActionModal] = useState(null);
    const [shouldRefresh, setShouldRefresh] = useState(true); // execution.status === 'started'

    const endpoint = `${API.ROUTES.operate.execution}${executionId}/`;
    const {
        data: execution,
        error,
        mutate,
    } = useSWR(endpoint, fetcher, {
        refreshInterval: shouldRefresh ? 5000 : 0,
    });

    const { handlePageDataLoadError } = useHandlePageDataLoadError();

    useDocumentTitle(execution?.name);

    useEffect(() => {
        if (execution) {
            setShouldRefresh(execution.status === 'started');
        }
    }, [execution]);

    if (error) {
        handlePageDataLoadError({
            e: error,
            redirectPath: '/threads?tab=executions',
            generalErrorHandler: () => navigate(backlinkHref),
        });
    }

    const handleCloseActionModal = () => {
        setActionModal(null);
    };

    return (
        <div className="page-position bg-neutral-50 flex flex-col overflow-y-auto">
            {execution && (
                <>
                    <ExecutionPageHeader
                        execution={execution}
                        backlinkHref={backlinkHref}
                        setActionModal={setActionModal}
                    />
                    <ThreadsList threads={execution.threads || []} mutate={mutate} />

                    {actionModal === 'restart' && (
                        <RestartSidePanel
                            mutate={mutate}
                            restartTarget="execution"
                            executionId={executionId}
                            onClose={handleCloseActionModal}
                        />
                    )}

                    {actionModal === 'rename' && (
                        <RenameExecutionModal
                            mutate={mutate}
                            executionId={executionId}
                            executionName={execution.name}
                            onClose={handleCloseActionModal}
                        />
                    )}

                    {actionModal === 'cancel' && (
                        <CancelExecutionModal
                            mutate={mutate}
                            executionId={executionId}
                            executionName={execution.name}
                            onClose={handleCloseActionModal}
                        />
                    )}
                </>
            )}

            {!execution && (
                <div className="flex-grow h-full flex justify-center items-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ExecutionDetailPage;
