import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
    checkIfDisplayedRequiredFieldsFilled,
    getSetStepFormDataFunction,
} from '../../../../helpers/settingsFormUtils';
import { SETTING_TYPE, SHARING_STATE } from '../../../../constants/settingsForm';

import SettingsForm from '../../../../pages/CustomizeGoalPage/SettingsForm/SettingsForm';
import InstructionsModal from '../../../InstructionsModal/InstructionsModal';
import ErrorBanner from '../../../../design-system/ErrorBanner/ErrorBanner';
import { Button } from '../../../../design-system';

const SettingStepForm = ({
    stepFormData,
    setFormData,
    stepOrder,
    setAreStepsRequiredFieldsFilled,
    currentStepErrorData,
    instructions,
    tabContainerRef,
    shouldShowHiddenSettings = true,
    variant,
    isFormDisabled,
    allowSharing,
    newSuggestionAccessState,
    processId,
}) => {
    const viewInstructionButtonRef = useRef(null);
    const [isInstructionModalOpened, setIsInstructionModalOpened] = useState(false);

    useEffect(() => {
        // when a field of the current step is changed, it checks the stepFormData to determine if any field was removed.
        setAreStepsRequiredFieldsFilled((prevData) => {
            const updatedFieldState = checkIfDisplayedRequiredFieldsFilled({
                formData: stepFormData,
                areHiddenSettingsDisplayed: shouldShowHiddenSettings,
                skipSharedFields: allowSharing,
            });

            // ff the updatedFieldState hasn't changed, return the previous state (prevData) to avoid unnecessary changes to the areStepsRequiredFieldsFilled state.
            if (prevData[stepOrder] === updatedFieldState) {
                return prevData;
            }

            return {
                ...prevData,
                [stepOrder]: updatedFieldState,
            };
        });
    }, [stepFormData]);

    const setStepFormData = useCallback(getSetStepFormDataFunction(stepOrder, setFormData), [
        stepOrder,
        setFormData,
    ]);

    return (
        <div className="flex flex-col gap-5">
            {currentStepErrorData?.errorMessage && (
                <ErrorBanner errorMessage={currentStepErrorData.errorMessage} />
            )}

            <SettingsForm
                formData={stepFormData}
                setFormData={setStepFormData}
                containerGap={20}
                shouldShowHiddenSettings={shouldShowHiddenSettings}
                isFormDisabled={isFormDisabled}
                sharingState={allowSharing ? SHARING_STATE.enabled : SHARING_STATE.unavailable}
                newSuggestionAccessState={newSuggestionAccessState}
                processId={processId}
                settingType={SETTING_TYPE.agent}
                allowDocsMapping
            />

            {instructions && variant === 'primary' && (
                <div className="relative flex justify-end" ref={viewInstructionButtonRef}>
                    <Button
                        type="ghost"
                        size="xs"
                        text="View Instructions"
                        onClick={() => setIsInstructionModalOpened(true)}
                    />
                    {isInstructionModalOpened && (
                        <InstructionsModal
                            instructions={instructions}
                            description="Here are the instructions for this step."
                            pageContainerRef={tabContainerRef}
                            viewInstructionButtonRef={viewInstructionButtonRef}
                            topValue={42}
                            onClose={(e) => {
                                e?.stopPropagation();
                                setIsInstructionModalOpened(false);
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default SettingStepForm;
