import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useHandlePageDataLoadError } from '../../../hooks/useHandlePageDataLoadError';

import { getAdminProcessOverviewTabs } from '../../../helpers/processOverviewUtils';

import { CONFIGURE_TAB } from '../../../constants/configure';

import Banner from '../../../design-system/Banner/Banner';
import Loading from '../../../components/Loading';
import TitleBlock from '../../../components/TitleBlock/TitleBlock';
import FormTabSection from './FormTabSection/FormTabSection';
import AdminTabSection from './AdminTabSection/AdminTabSection';
import ConfigureTabSection from './ConfigureTabSection/ConfigureTabSection';
import ProcessDetailTopNavBar from './ProcessDetailTopNavBar/ProcessDetailTopNavBar';
import AgentSettingsTabSection from '../../../components/AgentSettingsTabSection/AgentSettingsTabSection';
import ProcessDetailTopControls from './ProcessDetailTopControls/ProcessDetailTopControls';

const ProcessDetailPage = () => {
    const { processId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const backLinkHref = location.state?.from ?? `/configure?tab=${CONFIGURE_TAB.processes}`;

    const [processDetail, setProcessDetail] = useState(null);

    const isProcessEditable = !!processDetail?.is_editable; // isProcessEditable === false if the process was deployed from a Process Template and should not be allowed to be edited
    const isUpgradeStatus = processDetail?.default_version?.upgrade_status?.is_upgrade;

    const tabs = getAdminProcessOverviewTabs(isProcessEditable);
    const activeTabIndexState = useState(0);
    const [activeTabIndex] = activeTabIndexState;
    const activeTab = tabs[activeTabIndex]?.key;

    const { handlePageDataLoadError } = useHandlePageDataLoadError();

    useDocumentTitle(processDetail?.name);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.library.process}${processId}/?shared=true`
                );
                setProcessDetail(data);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    redirectPath: `/configure?tab=${CONFIGURE_TAB.processes}`,
                    generalErrorHandler: () => navigate(backLinkHref),
                });
            }
        };

        fetchData();
    }, []);

    const formTabContainerClassName = activeTab === 'form' ? '' : 'h-0 max-h-0 overflow-hidden';
    const adminTabContainerClassName = activeTab === 'admin' ? '' : 'h-0 max-h-0 overflow-hidden';
    const settingsTabContainerClassName =
        activeTab === 'settings' ? 'flex flex-col gap-5' : 'h-0 max-h-0 overflow-hidden';

    const upgradeNowBannerText = (
        <>
            You are on outdated version ({processDetail?.default_version?.name}).{' '}
            <span className="underline">
                Click here to view the improvements and migrate to{' '}
                {processDetail?.default_version?.upgrade_status?.latest_version}.
            </span>
        </>
    );

    const onUpgradeNowBannerClick = () => {
        const latestVersionId = processDetail.default_version?.upgrade_status?.latest_version_id;
        navigate(`/agent/${processId}/upgrade/${latestVersionId}`, { state: { from: location } });
    };

    return (
        <div className="page-position overflow-y-auto bg-neutral-50">
            {processDetail ? (
                <>
                    <ProcessDetailTopNavBar
                        processDetail={processDetail}
                        backLinkHref={backLinkHref}
                    />

                    <div className="w-full flex flex-col gap-5 p-5 sm:p-8">
                        <div className="max-w-[800px]">
                            <TitleBlock
                                title={processDetail.name}
                                withSharedIcon={processDetail.is_shared}
                            />

                            <p className="font-body text-body-regular-s text-neutral-400">
                                {processDetail.description}
                            </p>
                        </div>

                        <ProcessDetailTopControls
                            tabs={tabs}
                            activeTabIndexState={activeTabIndexState}
                            isProcessEditable={isProcessEditable}
                        />

                        {isUpgradeStatus && (
                            <Banner
                                title="Upgrade Today!"
                                text={upgradeNowBannerText}
                                color="peach"
                                onBannerClick={onUpgradeNowBannerClick}
                            />
                        )}

                        <div>
                            {activeTab === 'configure' && (
                                <ConfigureTabSection
                                    processDetail={processDetail}
                                    setProcessDetail={setProcessDetail}
                                    isEditable={isProcessEditable}
                                />
                            )}
                            <div className={formTabContainerClassName}>
                                <FormTabSection
                                    processDetail={processDetail}
                                    isEditable={isProcessEditable}
                                />
                            </div>
                            <div className={settingsTabContainerClassName}>
                                <AgentSettingsTabSection
                                    processDetail={processDetail}
                                    setProcessDetail={setProcessDetail}
                                    shouldShowHiddenSettings
                                />
                            </div>
                            <div className={adminTabContainerClassName}>
                                <AdminTabSection
                                    processDetail={processDetail}
                                    setProcessDetail={setProcessDetail}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ProcessDetailPage;
