import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useOverlayState } from '../../hooks/useOverlayState';

import ToolTip from '../../design-system/ToolTip/ToolTip';
import SuccessAlert from '../../design-system/SuccessAlert/SuccessAlert';
import DiscussLineIcon from '../../design-system/Icons/DiscussLineIcon';
import ShareFeedbackModal from '../ShareFeedbackModal/ShareFeedbackModal';
import { Button, ButtonIcon } from '../../design-system';

const LogFeedbackTrigger = ({
    target,
    targetId,
    onSuccess,
    type = 'button',
    count = 0,
    customSize = null,
    customButtonType = null,
    customToolTipPosition = null,
    successAlertMessage = 'Thanks for submitting feedback!',
}) => {
    const { isOpened, open: openModal, close: closeModal } = useOverlayState();
    const [successAlert, setSuccessAlert] = useState(null);

    return (
        <>
            {type === 'button' && (
                <Button
                    type={customButtonType || 'link'}
                    size={customSize || 'sm'}
                    text="Log Feedback"
                    leadingIcon={DiscussLineIcon}
                    onClick={openModal}
                />
            )}

            {type === 'button_icon' && (
                <div className="relative">
                    <ToolTip
                        text="Log Feedback"
                        position={customToolTipPosition || 'top-right'}
                        shift="-3px"
                        useInternalHoverStateToShowToolTip
                    >
                        <ButtonIcon
                            type={customButtonType || 'link'}
                            size={customSize || 'xs'}
                            icon={DiscussLineIcon}
                            onClick={openModal}
                        />
                    </ToolTip>

                    {!!count && (
                        <span className="absolute top-[1px] right-[-3px] min-w-[10px] w-fit h-[10px] p-0.5 rounded-full bg-red-500 text-[8px] text-white font-medium font-body inline-flex justify-center items-center">
                            {count}
                        </span>
                    )}
                </div>
            )}

            {isOpened && (
                <ShareFeedbackModal
                    target={target}
                    targetId={targetId}
                    state="empty"
                    variant="primary"
                    setSuccessAlert={setSuccessAlert}
                    onSuccessFeedbackShare={onSuccess}
                    successAlertMessage={successAlertMessage}
                    onClose={closeModal}
                />
            )}

            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </>
    );
};

LogFeedbackTrigger.propTypes = {
    target: PropTypes.oneOf(['inbox_message', 'thread', 'chat_message_version']).isRequired,
    targetId: PropTypes.string.isRequired, // message id or thread id or chat_message_version id
    type: PropTypes.oneOf(['button', 'button_icon']),
    onSuccess: PropTypes.func,
    count: PropTypes.number,
    customSize: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
    customButtonType: PropTypes.oneOf(['primary', 'secondary', 'neutral', 'ghost', 'link', 'grey']),
    customToolTipPosition: PropTypes.oneOf([
        'top-left',
        'top-center',
        'top-right',
        'center-right',
        'center-left',
        'bottom-left',
        'bottom-center',
        'bottom-right',
    ]),
    successAlertMessage: PropTypes.string,
};

export default LogFeedbackTrigger;
