import React from 'react';
import classNames from 'classnames';

import { TOOL_TYPE } from '../../../constants/assistant';
import { CONTENT_TYPE_ICON } from '../../../constants/docs';

import { Badge } from '../../../design-system';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';

const ChatInputHeader = ({
    selectedDocumentsData,
    setSelectedTools,
    isMobile,
    openSelectDocModal,
    clearErrorMessage,
}) => {
    const removeSelectedDoc = (id) => {
        openSelectDocModal();
        setSelectedTools((prevTools) => {
            const selectedDocs = { ...prevTools[TOOL_TYPE.add_documents].details };
            delete selectedDocs[id];
            return {
                [TOOL_TYPE.add_documents]: {
                    details: selectedDocs || {},
                },
            };
        });

        clearErrorMessage();
    };

    const containerClassName = classNames(
        'bg-neutral-50 relative z-10 -mb-3 pt-2.5 pb-[22px] px-4 rounded-2 border-1 border-neutral-200 flex gap-x-4 gap-y-1 max-w-full cursor-pointer',
        { 'flex-row items-start': !isMobile, 'flex-col': isMobile }
    );

    return (
        <div className={containerClassName} onClick={openSelectDocModal}>
            <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap min-h-[24px] flex items-center">
                Chatting with knowledge from
            </p>

            <div className="flex-1 overflow-hidden flex items-center gap-2 flex-wrap">
                {Object.values(selectedDocumentsData)?.map(({ id, label, content_type }) => {
                    const icon = CONTENT_TYPE_ICON[content_type] || FileTextLineIcon;

                    return (
                        <Badge
                            key={id}
                            id={id}
                            text={label}
                            leadingIcon={icon}
                            leadingIconColor="#754DCF"
                            color="purple"
                            removeable
                            handleRemove={removeSelectedDoc}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ChatInputHeader;
