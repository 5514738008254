export const DEFAULT_CONFIG = {
    startOnLoad: true,
    theme: 'default',
    darkMode: false,
    securityLevel: 'strict',
    fontFamily: '"Inter", "sans-serif"',
    layout: 'dagre',
    look: 'classic',
    logLevel: 'fatal',
    arrowMarkerAbsolute: false,
    wrap: true,
    markdownAutoWrap: true,
    htmlLabels: true,

    flowchart: {
        htmlLabels: true,
        curve: 'linear',
        padding: 12,
    },
    sequence: {
        showSequenceNumbers: false,
    },
    gantt: {
        barHeight: 28,
        rightPadding: 0,
        leftPadding: 0,
        sectionFontSize: 14,
        fontSize: 16,
        axisFormat: '%Y-%m-%d',
    },
    timeline: { padding: 12 },
    state: { padding: 8 },
    er: { entityPadding: 12 },
    journey: {
        diagramMarginX: 50,
        diagramMarginY: 0,
        leftMargin: 0,
    },
};
