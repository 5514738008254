import React from 'react';

import { SOURCE_TYPE_LABEL } from '../../../../constants/bot';

import { Input, TextArea } from '../../../../design-system';
import JsonTextareaBox from '../../../../design-system/JsonTextareaBox/JsonTextareaBox';

const CreateNewSourceForm = ({ type, formData, fieldErrorMessages, handleInputChange }) => {
    const typeLabel = SOURCE_TYPE_LABEL[type];

    const folderIdLabel = `${typeLabel} ID`;
    const folderIdPlaceholder = `Enter the ${typeLabel} ID`;

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5 pb-5 border-b-1 border-neutral-200">
                <Input
                    size="sm"
                    value={formData.name}
                    name="source_name"
                    label="Source Name"
                    isRequired
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    placeholder="Give your source a descriptive, unique name"
                />

                <TextArea
                    name="description"
                    value={formData.description}
                    label="Source Description"
                    isRequired
                    state={fieldErrorMessages.description ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.description}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    placeholder="Explain the type of information this source holds"
                />

                <Input
                    size="sm"
                    value={formData.folderId}
                    name="folder_id"
                    label={folderIdLabel}
                    isRequired
                    state={fieldErrorMessages.folderId ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.folderId}
                    onChange={(e) => handleInputChange('folderId', e.target.value)}
                    placeholder={folderIdPlaceholder}
                />
            </div>

            <div className="flex flex-col gap-5 justify-start">
                <h3 className="font-body-bold text-body-bold-m text-black">Ingestion Settings</h3>

                <div className="flex flex-col gap-3">
                    <JsonTextareaBox
                        mode="code"
                        label="Pipeline Configuration"
                        value={formData.config}
                        state={fieldErrorMessages.config ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.config}
                        setValue={(value) => handleInputChange('config', value)}
                        placeholder="Enter the pipeline configuration in JSON format"
                        parentGap={12}
                        containerMinHeight={215}
                        tipText="Configure the ingestion pipeline settings for this source. Leave blank to use the defaults."
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateNewSourceForm;
