import React from 'react';

import { ListOption, ListOptionGroup } from '../../../../design-system';

const BotMoreOptionPopup = ({
    onClose,
    setActionModal,
    isFeedbackPanelOpened,
    toggleFeedbackPanel: _toggleFeedbackPanel,
}) => {
    const handleOpenModal = (action) => {
        setActionModal({ action });
        onClose();
    };

    const toggleFeedbackPanel = () => {
        _toggleFeedbackPanel();
        onClose();
    };

    return (
        <div
            className="absolute z-40 right-0 top-[105%]"
            onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
            }}
        >
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Edit config"
                            onClick={() => handleOpenModal('editConfig')}
                        />
                        <ListOption
                            leadingIconName="discussLineIcon"
                            text={isFeedbackPanelOpened ? 'Hide Feedback' : 'View Feedback'}
                            onClick={toggleFeedbackPanel}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete Bot"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={() => handleOpenModal('delete')}
                        />
                    </>
                }
                handleClose={onClose}
            />
        </div>
    );
};

export default BotMoreOptionPopup;
