import React, { useState } from 'react';
import classNames from 'classnames';

import { SELECTION_TYPE } from '../../../constants/selection';

import { useModalAutoMinHeight } from '../../../hooks/useModalAutoMinHeight';
import { useClientSideFilterBar } from '../../../hooks/useClientSideFilterBar';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import Modal from '../../Modal/Modal';
import MobilePopup from '../../MobilePopup/MobilePopup';
import DocsListWithSearch from '../../../pages/AssistantPage/SelectDocBlock/DocsListWithSearch/DocsListWithSearch';
import ModalHeaderWithSearch from '../../ModalHeaderWithSearch/ModalHeaderWithSearch';
import DocsListWithoutSearch from '../../../pages/AssistantPage/SelectDocBlock/DocsListWithoutSearch/DocsListWithoutSearch';
import { Button } from '../../index';

const SelectDocumentModal = ({
    selectedDocId,
    handleSelectDoc,
    foldersDocumentsData,
    setFoldersDocumentsData,
    docsOptionsHookResponse,
    isRootDataLoading,
    onClose,
}) => {
    const [searchQuery, setSearchQuery] = useState('');

    const { options, optionsLoading } = docsOptionsHookResponse;

    const { filteredOptions, setFilteredOptions } = useClientSideFilterBar({
        options,
        showClientSideFilterBar: true,
    });

    const { isMobile } = useResponsiveBreakpoints();

    const maxHeight = 400;
    const { modalRef, modalMinHeight } = useModalAutoMinHeight({
        maxHeight,
        shouldFixHeight: !isMobile,
        dependenciesArray: [filteredOptions],
    });

    const handleSelect = ({ id, label }) => {
        handleSelectDoc({ id, label });
        onClose();
    };

    const docsListClassName = classNames('flex-1 pt-2 mt-1 md:pt-3 md:mt-2 px-5 overflow-y-auto', {
        'md:pb-3': !!filteredOptions?.length,
        'min-h-[80px]': optionsLoading,
    });

    const topFixedContent = (
        <ModalHeaderWithSearch
            onClose={onClose}
            onCancelClick={onClose}
            title="Select Document"
            listLabel="All Docs"
            options={options}
            setFilteredOptions={setFilteredOptions}
            useExternalQuery
            query={searchQuery}
            setQuery={setSearchQuery}
        />
    );

    const content = (
        <div className={docsListClassName}>
            <div className={searchQuery ? 'hidden' : 'block'}>
                <DocsListWithoutSearch
                    handleChange={handleSelect}
                    foldersDocumentsData={foldersDocumentsData}
                    setFoldersDocumentsData={setFoldersDocumentsData}
                    isRootDataLoading={isRootDataLoading}
                    selectionType={SELECTION_TYPE.single}
                    selectedDocs={selectedDocId ? { [selectedDocId]: true } : {}}
                />
            </div>

            {searchQuery && (
                <DocsListWithSearch
                    filteredOptions={filteredOptions}
                    handleChange={handleSelect}
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    selectionType={SELECTION_TYPE.single}
                    selectedDocs={selectedDocId ? { [selectedDocId]: true } : {}}
                />
            )}
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                closeButtonText="Cancel"
                minHeight="92vh"
                maxHeight="92vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={topFixedContent}
                customGap={8}
                withCloseButton={false}
                bottomButtonsGroup={
                    <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                }
            >
                <div className="flex flex-col bg-white">{content}</div>
            </MobilePopup>
        );
    }

    return (
        <Modal size="extraLarge" onClose={onClose} resetPadding customStyles="flex flex-col">
            <div
                className="h-full max-h-full flex flex-col bg-white"
                ref={modalRef}
                style={{ maxHeight: `${maxHeight}px`, minHeight: `${modalMinHeight}px` }}
            >
                {topFixedContent}

                {content}
            </div>
        </Modal>
    );
};

export default SelectDocumentModal;
