import React, { useState } from 'react';

import * as API from '../../constants/api';
import client from '../../services/knowledge-api';

import { useFormState } from '../../hooks/useFormState';
import { normalizeMarkdown, prepareMarkdownOnLoad } from '../../helpers/normalizeMarkdown';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { SORT_TYPES } from '../../constants/sort';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../constants/errorMessages';
import { formatStringToSnakeCase } from '../../helpers/formatStringToSnakeCase';
import { mutateDocsDataAfterDocEditing } from '../../helpers/docsUtils';

import ErrorAlert from '../../design-system/ErrorAlert/ErrorAlert';
import QuickAddToDocMobileView from './QuickAddToDocMobileView/QuickAddToDocMobileView';
import QuickAddToDocDesktopView from './QuickAddToDocDesktopView/QuickAddToDocDesktopView';

const requestRequiredFields = {
    add: ['doc', 'value'],
    create: ['label', 'value'],
};

export const submitButtonText = {
    add: 'Add to Doc',
    create: 'Create Doc',
};

const QuickAddToDocModal = ({
    content,
    setSuccessAlert,
    useCustomIsMobileState = false,
    isMobile: customIsMobileState = false,
    onClose,
}) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        () => ({ doc: null, value: prepareMarkdownOnLoad(content), label: '' })
    );
    const [mode, setMode] = useState('add');

    const { isMobile: defaultIsMobileState } = useResponsiveBreakpoints();
    const isMobile = useCustomIsMobileState ? customIsMobileState : defaultIsMobileState;

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const formatResponseToOptions = (results) =>
        results?.map(({ id, label, value }) => ({
            id,
            name: label,
            label,
            currentValue: value || '',
        }));

    const docsOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.knowledge.document,
        searchParams: { ordering: SORT_TYPES.alphabeticalByLabel },
        formatResponseToOptions,
    });
    const { requestKeysToMutate, revalidateAllRequestKeys } = docsOptionsHookResponse;

    const handleAddToDoc = async () => {
        const formattedValue = normalizeMarkdown(formData.value);
        const requestBody = {
            value: `${formData.doc.currentValue}\n\n${formattedValue}`,
        };
        setIsLoading(true);

        const { data } = await client.patch(
            `${API.ROUTES.knowledge.document}${formData.doc.id}/`,
            requestBody
        );
        mutateDocsDataAfterDocEditing({
            requestKeysToMutate,
            docId: formData.doc.id,
            updatedValue: data.default_version?.value || requestBody.value,
        });
        setSuccessAlert({ message: 'Successfully added to doc!' });
    };

    const handleCreateDoc = async () => {
        const formattedValue = normalizeMarkdown(formData.value);
        const requestBody = {
            label: formData.label,
            key: formatStringToSnakeCase(formData.label),
            value: formattedValue,
        };
        setIsLoading(true);

        const { data: doc } = await client.post(API.ROUTES.knowledge.document, requestBody);
        revalidateAllRequestKeys();
        setSuccessAlert({ message: `Document ${doc.label} successfully created!` });
    };

    const action = {
        add: handleAddToDoc,
        create: handleCreateDoc,
    };

    const handleSubmit = async () => {
        const requiredFields = requestRequiredFields[mode];
        const areEmptyFields = requiredFields?.some((field) => !formData[field]);

        if (areEmptyFields) {
            setFieldErrorMessages(
                requiredFields.reduce((acc, field) => {
                    if (!formData[field]) {
                        const emptyMessage =
                            field === 'doc' ? 'Please select a Document' : emptyFieldErrorMessage;
                        acc[field] = emptyMessage;
                    }
                    return acc;
                }, {})
            );
            return;
        }

        try {
            setIsLoading(true);

            await action[mode]();

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const tipText = {
        add: (
            <>
                This will add the text to the end of the document selected. Or{' '}
                <span className="underline cursor-pointer" onClick={() => setMode('create')}>
                    click here to create new doc.
                </span>
            </>
        ),
        create: (
            <>
                This will create a new document and add the text.{' '}
                <span className="underline cursor-pointer" onClick={() => setMode('add')}>
                    Or click here to select a doc.
                </span>
            </>
        ),
    }[mode];

    return (
        <>
            {isMobile && (
                <QuickAddToDocMobileView
                    formData={formData}
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    handleSubmit={handleSubmit}
                    handleInputChange={handleInputChange}
                    fieldErrorMessages={fieldErrorMessages}
                    mode={mode}
                    tipText={tipText}
                    isLoading={isLoading}
                    onClose={onClose}
                />
            )}

            {!isMobile && (
                <QuickAddToDocDesktopView
                    formData={formData}
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    fieldErrorMessages={fieldErrorMessages}
                    onClose={onClose}
                    handleSubmit={handleSubmit}
                    handleInputChange={handleInputChange}
                    mode={mode}
                    tipText={tipText}
                    isLoading={isLoading}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default QuickAddToDocModal;
