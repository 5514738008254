import React from 'react';

import SourceLastSyncBlock from '../SourceLastSyncBlock/SourceLastSyncBlock';
import ConnectedChatbotsSection from '../ConnectedChatbotsSection/ConnectedChatbotsSection';

const SourceDetailsMainContent = ({ sourceDetails, openNewChatWithBot }) => {
    const { description, ingestion_status, custom_bots } = sourceDetails;

    return (
        <div className="flex flex-col gap-y-4 lg:gap-5 md:flex-1 md:overflow-y-auto">
            <SourceLastSyncBlock ingestion_status={ingestion_status} isHighlighted />

            <p className="font-body text-body-regular-s text-neutral-500">{description}</p>

            {!!custom_bots?.length && (
                <ConnectedChatbotsSection
                    chatbots={custom_bots}
                    openNewChatWithBot={openNewChatWithBot}
                />
            )}
        </div>
    );
};

export default SourceDetailsMainContent;
