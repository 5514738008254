import { SOURCE_LINK_PREFIX, SOURCE_TYPE } from '../constants/bot';
import {
    BASE_TYPE,
    SOURCE_LINK_PREFIX as ASSISTANT_SOURCE_LINK_PREFIX,
} from '../constants/assistant';

export const openSourceBySourceType = ({ source_type, detail }) => {
    const { base_id, folder_id } = detail || {};

    let id = source_type === SOURCE_TYPE.diviup_base ? base_id : folder_id;
    let url = SOURCE_LINK_PREFIX[source_type] + id;

    if (id) {
        window.open(url, '_blank');
    }
};

export const openSourceByBaseType = ({ link: { id }, object_id, base_type }) => {
    let url = ASSISTANT_SOURCE_LINK_PREFIX[base_type] + id;

    if (base_type === BASE_TYPE.agent_knowledge && object_id) {
        url = url + `?entry=${object_id}`;
    }

    window.open(url, '_blank');
};
