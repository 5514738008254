import { createContext, useEffect, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../services/knowledge-api';

import { SORT_TYPES } from '../constants/sort';
import { useResponsiveBreakpoints } from '../hooks/useResponsiveBreakpoints';
import { addUniqueElementToArray } from '../helpers/addUniqueElementToArray';
import { useSwrRequestKeysToMutateFromContext } from '../hooks/useSwrRequestKeysToMutateFromContext';

export const DocsPageContext = createContext();

export const DocsPageProvider = ({ children }) => {
    const [location, setLocation] = useState(null);

    const [sidePanelFolders, setSidePanelFolders] = useState(null);
    const [expandedFolders, setExpandedFolders] = useState({});

    const {
        requestKeysToMutate: { folderDocs: requestKeysToMutateFromContext },
        setRequestKeysToMutate: setRequestKeysToMutateInContext,
    } = useSwrRequestKeysToMutateFromContext();

    const requestKeysToMutate = useRef(requestKeysToMutateFromContext);
    const [currentRequestKeyToMutate, setCurrentRequestKeyToMutate] = useState(null);

    const { isDesktop } = useResponsiveBreakpoints();

    const isDragAndDropEnabled = isDesktop;

    const addNewRequestKeysToMutate = (key) => {
        requestKeysToMutate.current = addUniqueElementToArray(key, requestKeysToMutate.current);
    };

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const { data } = await client.get(API.ROUTES.knowledge.folder, {
                    params: {
                        parent_is_null: true,
                        pagination: false,
                        ordering: SORT_TYPES.alphabeticalByLabel,
                    },
                });

                setSidePanelFolders(data);
            } catch (e) {}
        };

        if (isDesktop) {
            fetchFolders();
        }
    }, [isDesktop]);

    useEffect(() => {
        return () => {
            setRequestKeysToMutateInContext((prev) => ({
                ...prev,
                folderDocs: requestKeysToMutate.current,
            }));
        };
    }, []);

    return (
        <DocsPageContext.Provider
            value={{
                location,
                setLocation,
                expandedFolders,
                setExpandedFolders,
                sidePanelFolders,
                setSidePanelFolders,
                requestKeysToMutate: requestKeysToMutate.current,
                addNewRequestKeysToMutate,
                currentRequestKeyToMutate,
                setCurrentRequestKeyToMutate,
                isDragAndDropEnabled,
            }}
        >
            {children}
        </DocsPageContext.Provider>
    );
};
