import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CONTENT_TYPE } from '../../../../constants/docs';

import Alert from '../../../../design-system/Alert/Alert';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import MoveToPanel from '../../MoveToPanel/MoveToPanel';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import SendToOrgModal from '../../SendToOrgModal/SendToOrgModal';
import ArchiveItemModal from '../../ArchiveItemModal/ArchiveItemModal';
import DocDetailMoreOptionsPopup from '../DocDetailMoreOptionsPopup/DocDetailMoreOptionsPopup';
import { Button, ButtonIcon } from '../../../../design-system';
import { ArrowGoBackLineIcon, More2FillIcon } from '../../../../design-system/Icons';

const DocDetailTopControls = ({
    id,
    label,
    backLinkHref,
    docsRequestKeysToMutate,
    documentType,
    isDiagramEditing,
    setIsDiagramEditing,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isMoreOptionsPopupOpened, setIsMorePopupOpened] = useState(false);
    const [actionModal, setActionModal] = useState({ action: null });

    const [successAlert, setSuccessAlert] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const navigateToDocsIndex = () => {
        const backLinkHref = location.state?.from || '/docs';

        const backLinkPath =
            typeof backLinkHref === 'string' ? backLinkHref : backLinkHref?.pathname;

        if (backLinkPath?.startsWith('/docs')) {
            navigate(backLinkHref);
        } else {
            navigate('/docs');
        }
    };

    return (
        <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-5">
                <Button
                    type="link"
                    size="xs"
                    text="Back to Docs"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backLinkHref, { state: { from: location } })}
                />
                <p className="font-body text-body-regular-s text-neutral-300">Docs /</p>
            </div>

            <div className="relative">
                <ButtonIcon
                    type="neutral"
                    size="sm"
                    icon={More2FillIcon}
                    onClick={() => setIsMorePopupOpened(true)}
                />

                {isMoreOptionsPopupOpened && (
                    <DocDetailMoreOptionsPopup
                        id={id}
                        setErrorAlert={setErrorAlert}
                        setSuccessAlert={setSuccessAlert}
                        requestKeysToMutate={docsRequestKeysToMutate}
                        setActionModal={setActionModal}
                        documentType={documentType}
                        isDiagramEditing={isDiagramEditing}
                        setIsDiagramEditing={setIsDiagramEditing}
                        onClose={() => setIsMorePopupOpened(false)}
                    />
                )}
            </div>

            {actionModal.action === 'archive' && (
                <ArchiveItemModal
                    data={{ id, label }}
                    contentType={CONTENT_TYPE.document}
                    requestKeysToMutate={docsRequestKeysToMutate}
                    navigateToDocsIndex={navigateToDocsIndex}
                    onClose={() => setActionModal({ action: null })}
                />
            )}

            {actionModal.action === 'move' && (
                <MoveToPanel
                    itemsToMove={[{ id, label, content_type: CONTENT_TYPE.document }]}
                    viewType="modal"
                    requestKeysToMutate={docsRequestKeysToMutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}

            {actionModal.action === 'send' && (
                <SendToOrgModal
                    data={{ id, label }}
                    contentType={CONTENT_TYPE.document}
                    requestKeysToMutate={docsRequestKeysToMutate}
                    navigateToDocsIndex={navigateToDocsIndex}
                    onClose={() => setActionModal({ action: null })}
                />
            )}

            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    description={successAlert.description}
                    position="top-center"
                    autoCloseInMS={5000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default DocDetailTopControls;
