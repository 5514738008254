import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { startChatWithDocs } from '../../../helpers/assistantUtils';
import { handleDuplicateItem } from '../../../helpers/docsUtils';

import { CONTENT_TYPE } from '../../../constants/docs';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import { ListOption, ListOptionGroup } from '../../../design-system';

const DocCardMoreOptionsPopup = ({
    contentType,
    data,
    setActionModal,
    setErrorAlert,
    setSuccessAlert,
    onNavigateToFolderView,
    parentLocation,
    exporterRef,
    onClose,
}) => {
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isExportLoading, setIsExportLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const { setSidePanelFolders, requestKeysToMutate, currentRequestKeyToMutate } =
        useDocsPageContext();

    const { isMobile } = useResponsiveBreakpoints();

    const openModal = (action) => {
        let updatedData = { ...data };
        if (action === 'move') {
            updatedData.content_type = contentType;
        }

        setActionModal({ action, data: updatedData, contentType });
        onClose();
    };

    const handleClose = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose();
    };

    const handleDuplicate = async () => {
        await handleDuplicateItem({
            id: data.id,
            location,
            setSuccessAlert,
            setErrorAlert,
            setIsLoading,
            closePopup: handleClose,
            requestKeysToMutate,
            contentType,
            onNavigateToFolderView,
            parentLocation,
            setSidePanelFolders,
            currentRequestKeyToMutate,
            skipUpdatingSidePanel: searchParams.get('search'),
        });
    };

    const isDocument = contentType !== CONTENT_TYPE.folder;

    const downloadAsPdf = async () => {
        try {
            if (!exporterRef.current) return;

            setIsExportLoading(true);

            await exporterRef.current.exportAsPdf();

            onClose();
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage });
        } finally {
            setIsExportLoading(false);
        }
    };

    const isDocumentOrDiagram =
        contentType === CONTENT_TYPE.document || contentType === CONTENT_TYPE.diagram;

    const listOptions = (
        <>
            <ListOption
                leadingIconName="editLineIcon"
                text="Rename"
                onClick={() => openModal('rename')}
            />
            <ListOption
                leadingIconName="copyIcon"
                text="Duplicate"
                isLoading={isLoading}
                onClick={handleDuplicate}
            />
            <ListOption
                leadingIconName="folderTransferFillIcon"
                text="Move to"
                onClick={() => openModal('move')}
            />
            <ListOption
                leadingIconName="inboxArchiveLineIcon"
                text="Archive"
                onClick={() => openModal('archive')}
            />
            <ListOption
                leadingIconName="userSharedLineIcon"
                text="Send to"
                onClick={() => openModal('send')}
            />

            {isDocument && (
                <ListOption
                    leadingIconName="robot2LineIcon"
                    text="Chat with doc"
                    onClick={() => startChatWithDocs({ navigate, idsArray: [data.id] })}
                />
            )}

            {isDocumentOrDiagram && (
                <ListOption
                    leadingIconName="downloadIcon"
                    text="Download as PDF"
                    isLoading={isExportLoading}
                    onClick={downloadAsPdf}
                />
            )}
        </>
    );

    if (isMobile) {
        return (
            <MobilePopup onClose={handleClose} closeButtonText="Cancel">
                {listOptions}
            </MobilePopup>
        );
    }

    return (
        <div className="absolute right-2 top-[48px] z-20 pb-5" onClick={(e) => e.preventDefault()}>
            <ListOptionGroup fixedWidth={300} listOptions={listOptions} handleClose={handleClose} />
        </div>
    );
};
export default DocCardMoreOptionsPopup;
