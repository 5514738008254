import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useHandlePageDataLoadError } from '../../../hooks/useHandlePageDataLoadError';
import { useSwrRequestKeysToMutateFromContext } from '../../../hooks/useSwrRequestKeysToMutateFromContext';

import Loading from '../../../components/Loading';
import DocDetailHeader from './DocDetailHeader/DocDetailHeader';
import DocDetailTopControls from './DocDetailTopControls/DocDetailTopControls';
import DocDetailMainContent from './DocDetailMainContent/DocDetailMainContent';
import { DOCUMENT_TYPE } from '../../../constants/docs';
import DiagramDetailMainContent from './DiagramDetailMainContent/DiagramDetailMainContent';
import useScrollbarGutterAuto from '../../../hooks/useScrollbarGutterAuto';

const DocDetailPage = () => {
    const { docId } = useParams();

    const navigate = useNavigate();

    const backLinkHref = '/docs';

    const [docDetail, setDocDetail] = useState(null);
    const [isDiagramEditing, setIsDiagramEditing] = useState(false);

    const {
        requestKeysToMutate: { folderDocs: docsRequestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const { handlePageDataLoadError } = useHandlePageDataLoadError();

    useEffect(() => {
        const fetchDocDetail = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.knowledge.document}${docId}/`);
                const isFile = data.file_type === 'file';
                if (isFile) {
                    window.location.replace(data.file);
                    return;
                }

                setDocDetail(data);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    redirectPath: '/docs',
                    generalErrorHandler: () => navigate(backLinkHref),
                });
            }
        };

        if (docDetail) {
            setDocDetail(null);
        }
        fetchDocDetail();
    }, [docId]);

    useDocumentTitle(docDetail?.label);
    useScrollbarGutterAuto();

    const documentType = docDetail?.file_type;

    return (
        <div className="page-position bg-white overflow-y-auto py-5 px-4 xs:px-5 sm:p-8 lg:py-9 lg:px-[40px] xl:py-[40px] xl:px-[60px] flex flex-col gap-3 xs:gap-5">
            {docDetail && (
                <>
                    <DocDetailTopControls
                        id={docDetail.id}
                        label={docDetail.label}
                        backLinkHref={backLinkHref}
                        docsRequestKeysToMutate={docsRequestKeysToMutate}
                        documentType={documentType}
                        isDiagramEditing={isDiagramEditing}
                        setIsDiagramEditing={setIsDiagramEditing}
                    />

                    <div className="w-full flex-grow max-w-[800px] mx-auto flex flex-col gap-3 xs:gap-6">
                        <DocDetailHeader
                            docDetail={docDetail}
                            setDocDetail={setDocDetail}
                            docsRequestKeysToMutate={docsRequestKeysToMutate}
                        />

                        {documentType === DOCUMENT_TYPE.document && (
                            <DocDetailMainContent
                                docDetail={docDetail}
                                setDocDetail={setDocDetail}
                            />
                        )}

                        {documentType === DOCUMENT_TYPE.diagram && (
                            <DiagramDetailMainContent
                                docDetail={docDetail}
                                setDocDetail={setDocDetail}
                                isDiagramEditing={isDiagramEditing}
                                setIsDiagramEditing={setIsDiagramEditing}
                            />
                        )}
                    </div>
                </>
            )}

            {!docDetail && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default DocDetailPage;
