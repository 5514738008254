import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { defaultErrorMessage } from '../../constants/errorMessages';

import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import CloseIcon from 'design-system/Icons/CloseIcon';
import CopyIcon from '../Icons/CopyIcon';
import Alert from '../Alert/Alert';
import CheckLineIcon from '../Icons/CheckLineIcon';
import { ErrorWarningLineIcon } from '../Icons';
import { Emoji, EmojiStyle } from 'emoji-picker-react';

Badge.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]).isRequired,
    leadingIcon: PropTypes.func,
    leadingIconColor: PropTypes.string,
    leadingItem: PropTypes.node,
    trailingIcon: PropTypes.func,
    trailingIconColor: PropTypes.string,
    trailingItem: PropTypes.node,
    leadingEmoji: PropTypes.string,
    removeable: PropTypes.bool,
    handleRemove: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // is used for handleRemove
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red']),
        PropTypes.string,
    ]), // can be passed as "neutral", "blue" or just as HEX color or RGB
    withBorder: PropTypes.bool,
    textToCopy: PropTypes.string, // if Badge includes a copy button, and you want to specify a different text to copy than the text prop value, provide the desired value for 'textToCopy'. If 'textToCopy' is empty, the text prop value will be used for copying.
    withCopyButton: PropTypes.bool,
    onBadgeClick: PropTypes.func,
    customTextStyles: PropTypes.string,
};

function Badge(props) {
    const {
        text,
        leadingIcon,
        leadingEmoji,
        trailingIcon,
        removeable,
        color,
        handleRemove,
        id,
        leadingIconColor,
        trailingIconColor,
        withBorder = false,
        textToCopy,
        withCopyButton = false,
        onBadgeClick,
        leadingItem: _leadingItem,
        trailingItem: _trailingItem,
        customTextStyles = null,
    } = props;
    const [copyAlert, setCopyAlert] = useState(null);

    const badgeClass = classNames(
        'max-w-full inline-flex items-center gap-x-2 rounded-lg px-2 py-1 text-gray-600 ',
        {
            'border-1 border-neutral-200': withBorder,
            'cursor-pointer': !!onBadgeClick,
        }
    );

    // TODO: Fix hard coded font and match design system

    let leadingItem = null;
    if (leadingIcon) {
        leadingItem = (
            <SvgIcon icon={leadingIcon} size="medium" color={leadingIconColor || '#5E6470'} />
        );
    } else if (leadingEmoji) {
        leadingItem = (
            <Emoji unified={leadingEmoji} emojiStyle={EmojiStyle.APPLE} size={16} /> // TODO: 16 is same as medium for icon but how can we avoid hardcoding?
        );
    } else if (_leadingItem) {
        leadingItem = _leadingItem;
    }

    let trailingItem = null;
    if (trailingIcon) {
        trailingItem = (
            <SvgIcon icon={trailingIcon} size="medium" color={trailingIconColor || '#5E6470'} />
        );
    } else if (_trailingItem) {
        trailingItem = _trailingItem;
    }

    const handleCopy = () => {
        navigator.clipboard
            .writeText(textToCopy || text)
            .then(() => {
                setCopyAlert({ status: 'positive', message: 'Copied to Clipboard!' });
            })
            .catch(() => {
                setCopyAlert({ status: 'critical', message: defaultErrorMessage });
            });
    };

    const badgeColors = {
        neutral: '#F0F5FE',
        blue: '#D3F1FD',
        purple: '#EEEAF9',
        peach: '#FCEEE3',
        lime: '#E6EDE6',
        red: '#F8CACE',
        white: '#FFFFFF',
    };

    const badgeStyles = { backgroundColor: badgeColors[color] || color || '#F0F5FE' };

    const handleBadgeClick = (e) => {
        if (onBadgeClick) {
            onBadgeClick(e);
        }
    };

    const textClassName = classNames(
        'font-body-medium text-neutral-500 text-body-medium-xs truncate',
        customTextStyles
    );

    return (
        <>
            <div className={badgeClass} style={badgeStyles} onClick={handleBadgeClick}>
                {leadingItem}

                <span className={textClassName}>{text}</span>

                {trailingItem}

                {withCopyButton && (
                    <button
                        type="button"
                        onClick={(e) => {
                            e?.stopPropagation();
                            e?.preventDefault();
                            handleCopy();
                        }}
                    >
                        <span className="sr-only">Copy</span>
                        <SvgIcon icon={CopyIcon} size="medium" color="#1F2125" />
                    </button>
                )}

                {removeable && (
                    <button
                        type="button"
                        className="group h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20 flex justify-center items-center"
                        onClick={(e) => {
                            e?.stopPropagation();
                            e?.preventDefault();
                            handleRemove(id);
                        }}
                    >
                        <span className="sr-only">Remove</span>
                        <SvgIcon icon={CloseIcon} size="medium" color="#5E6470" />
                    </button>
                )}
            </div>
            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.status === 'positive' ? CheckLineIcon : ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </>
    );
}

export default Badge;
