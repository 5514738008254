import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useManageSearchParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamsRef = useRef(searchParams);

    useEffect(() => {
        searchParamsRef.current = searchParams;
    }, [searchParams]);

    const setSearchParam = useCallback(
        (key, value) => {
            if (!key || value == null) return;
            const urlSearchParams = new URLSearchParams(searchParamsRef.current);
            urlSearchParams.set(key, value);
            setSearchParams(urlSearchParams);
        },
        [setSearchParams]
    );

    const deleteSearchParam = useCallback(
        (key) => {
            if (!key) return;
            const urlSearchParams = new URLSearchParams(searchParamsRef.current);
            urlSearchParams.delete(key);
            setSearchParams(urlSearchParams);
        },
        [setSearchParams]
    );

    const clearSearchParams = useCallback(() => {
        setSearchParams(new URLSearchParams());
    }, [setSearchParams]);

    return { deleteSearchParam, setSearchParam, clearSearchParams };
};
