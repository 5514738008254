import React, { useEffect, useRef } from 'react';
import {
    markdownShortcutPlugin,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    MDXEditor,
    codeBlockPlugin,
    frontmatterPlugin,
    linkPlugin,
    linkDialogPlugin,
    tablePlugin,
    diffSourcePlugin,
    jsxPlugin,
} from '@mdxeditor/editor';

import '@mdxeditor/editor/style.css';
import '../newRichTextArea.css';

import classNames from 'classnames';

import { catchAllPlugin } from '../plugins/catchAllPlugin';
import { jsxComponentDescriptors } from '../jsxComponentDescriptors';

import {
    escapeUnescapedCurlyBraces,
    escapeInvalidAngleBrackets,
    escapeInvalidImportsExports,
} from '../../../helpers/normalizeMarkdown';

import { LinkDialog } from '../LinkDialog/LinkDialog';
import NewRichTextAreaToolbar from '../NewRichTextAreaToolbar/NewRichTextAreaToolbar';
import { PlainTextCodeEditorDescriptor } from '../plugins/codeEditorPlugin';

const NewRichTextEditor = ({
    value,
    setValue,
    state,
    placeholder,
    minHeight,
    autoExpand,
    withDeleteButton,
    handleDelete,
    darkBorderColor,
    onBlur,
    isMobileViewActive,
    withDocumentTags = false,
}) => {
    const ref = useRef(null);
    const containerRef = useRef(null);

    const attemptedFix = useRef(false);
    const correctedValue = useRef(value);

    useEffect(() => {
        if (!ref.current) return;

        if (attemptedFix.current && value !== correctedValue.current) {
            attemptedFix.current = false;
        }

        const displayedValue = ref.current.getMarkdown();
        if (displayedValue === value) return;

        ref.current.setMarkdown(value);
    }, [value]);

    const containerClassName = classNames(
        'border-1 rounded-2',
        {
            'border-neutral-200': state !== 'error' && !darkBorderColor,
            'border-neutral-300': state !== 'error' && darkBorderColor,
            'border-red-500 error-state': state === 'error',
            'bg-white': state !== 'disabled',
            'bg-neutral-50': state === 'disabled',
            'flex-grow': !autoExpand,
        },
        autoExpand ? 'auto-expand' : 'no-auto-expand',
        darkBorderColor && 'dark-border'
    );

    const style = { minHeight: `${minHeight}px` };
    if (!autoExpand) {
        style.height = `${minHeight}px`;
    }

    const handleBlur = (e) => {
        const { relatedTarget } = e;

        const isToolbarButtonClicked = relatedTarget && relatedTarget?.matches('button, input');
        if (isToolbarButtonClicked) return;

        onBlur && onBlur(e);
    };

    return (
        <div className={containerClassName} style={style} ref={containerRef}>
            <MDXEditor
                ref={ref}
                markdown={value ?? ''}
                toMarkdownOptions={{ tightDefinitions: false }}
                plugins={[
                    headingsPlugin({ allowedHeadingLevels: [1, 2, 3, 4, 5, 6] }),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    codeBlockPlugin({
                        defaultCodeBlockLanguage: '',
                        codeBlockEditorDescriptors: [PlainTextCodeEditorDescriptor],
                    }),
                    frontmatterPlugin(),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <NewRichTextAreaToolbar
                                handleDelete={handleDelete}
                                withDeleteButton={withDeleteButton}
                                isMobileViewActive={isMobileViewActive}
                                containerRef={containerRef}
                            />
                        ),
                    }),
                    linkPlugin({ disableAutoLink: true }),
                    linkDialogPlugin({ disableAutoLink: true, LinkDialog: LinkDialog }),
                    tablePlugin(),
                    jsxPlugin({ jsxComponentDescriptors }),
                    // ...(withDocumentTags ? [jsxPlugin({ jsxComponentDescriptors })] : []),
                    diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: 'boo' }),
                    catchAllPlugin(),
                    markdownShortcutPlugin(),
                ]}
                onChange={state === 'disabled' ? () => {} : setValue}
                readOnly={state === 'disabled'}
                placeholder={placeholder}
                onBlur={handleBlur}
                suppressHtmlProcessing={true}
                onError={() => {
                    if (attemptedFix.current) return;
                    const normalizedValue = escapeInvalidImportsExports(
                        escapeInvalidAngleBrackets(escapeUnescapedCurlyBraces(value))
                    );
                    setValue(normalizedValue);
                    correctedValue.current = normalizedValue;
                    attemptedFix.current = true;
                }}
            />
        </div>
    );
};

export default NewRichTextEditor;
