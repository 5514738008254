import React, { useEffect } from 'react';
import classNames from 'classnames';

import { useChatInputBox } from '../../../../../hooks/useChatInputBox';

import ChatInputWrapper from '../../../../AssistantPage/ChatInputWrapper/ChatInputWrapper';
import ChatInputBoxActionButtons from '../../../../AssistantPage/ChatInputBoxActionButtons/ChatInputBoxActionButtons';

const ChatInputBox = ({
    botName,
    chatThreadId = null,
    submitChatMessage,
    messageErrorData = null,
    setMessageErrorData,
    handleCancelStream,
    isCancelStreamingLoading,
    isAssistantMessageLoadingOrStreaming,
    isPrevChatLoading,
    containerRef,
    isInstructionEditing,
}) => {
    const isSendButtonDisabled =
        !!isCancelStreamingLoading || !!isInstructionEditing || isPrevChatLoading;

    const {
        message,
        setMessage,
        handleMessageChange,
        handleSendMessage,
        handleSendMessageOnEnterPress,
        clearErrorMessage,
        sendButtonState,
        isExpandedMode,
        toggleExpandedMode,
        textareaRef,
        textareaContainerRef,
        handleFocusTextarea,
        isSwitchExpandedModeButton,
        isMobile,
    } = useChatInputBox({
        submitChatMessage,
        isLoading: isAssistantMessageLoadingOrStreaming,
        isSendButtonDisabled,
        messageErrorData,
        setMessageErrorData,
        height: containerRef?.current?.clientHeight,
    });

    useEffect(() => {
        if (!chatThreadId) {
            setMessage('');
            clearErrorMessage();
        }
    }, [chatThreadId]);

    const placeholder = `Send a message to ${botName} to test it’s capabilities`;

    const buttonsGroup = (
        <ChatInputBoxActionButtons
            size="xs"
            handleSendMessage={handleSendMessage}
            handleCancelStream={handleCancelStream}
            isLoading={isAssistantMessageLoadingOrStreaming}
            isExpandedMode={isExpandedMode}
            toggleExpandedMode={toggleExpandedMode}
            isSwitchExpandedModeButton={isSwitchExpandedModeButton}
            sendButtonState={sendButtonState}
        />
    );

    const expandedModeButtonsGroup = (
        <div className="flex px-3 pb-2 pt-1 items-center justify-end">{buttonsGroup}</div>
    );

    const chatInputErrorMessage = messageErrorData?.errorMessage;

    const chatInputContainerClassName = classNames(
        'pr-2 py-2 pl-3 cursor-text w-full min-w-full flex gap-2 items-start justify-between rounded-2 shadow-inset-1 relative z-20 bg-white transition-colors',
        {
            'shadow-neutral-200': !chatInputErrorMessage,
            'shadow-red-500': chatInputErrorMessage,
        }
    );

    return (
        <ChatInputWrapper
            message={message}
            handleMessageChange={handleMessageChange}
            isExpandedMode={isExpandedMode}
            isMobile={isMobile}
            placeholder={placeholder}
            bottomButtonsGroup={expandedModeButtonsGroup}
            closeExpandedModeOnOutsideClick
            setIsExpandedMode={toggleExpandedMode}
            chatInputErrorMessage={messageErrorData?.errorMessage}
        >
            <div className={chatInputContainerClassName} onClick={handleFocusTextarea}>
                <div className="flex-1 my-auto">
                    <div ref={textareaContainerRef}>
                        <textarea
                            ref={textareaRef}
                            value={message}
                            placeholder={placeholder}
                            rows={1}
                            onChange={handleMessageChange}
                            onKeyDown={handleSendMessageOnEnterPress}
                            className="max-w-full w-full focus:outline-0 resize-none bg-transparent placeholder-neutral-300 font-body text-body-regular-s text-neutral-500"
                        />
                    </div>
                </div>

                {buttonsGroup}
            </div>
        </ChatInputWrapper>
    );
};

export default ChatInputBox;
