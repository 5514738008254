import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../services/assistant-api';

import { AssistantChatThreadProvider } from '../../contexts/assistantChatThreadContext';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import useRemoveBgOnCopy from '../../hooks/useRemoveBgOnCopy';
import useScrollbarGutterAuto from '../../hooks/useScrollbarGutterAuto';
import { useFetchInfinityScrollableSearchAndEditData } from '../../hooks/useFetchInfinityScrollableSearchAndEditData';

import { SORT_TYPES } from '../../constants/sort';

import ChatThreadPanel from './ChatThreadPanel/ChatThreadPanel';
import HistorySidePanel from './HistorySidePanel/HistorySidePanel';

const AssistantPage = () => {
    const isDesktop = window.innerWidth >= 1024;
    const historyPanelExpandedState = useState(isDesktop);

    const chatThreadsHistoryHookResponse = useFetchInfinityScrollableSearchAndEditData({
        client,
        route: API.ROUTES.assistant.chatThread,
    });

    const botsHistoryHookResponse = useFetchInfinityScrollableSearchAndEditData({
        client,
        route: API.ROUTES.assistant.customBot,
        searchParams: { ordering: SORT_TYPES.alphabetical },
    });

    useDocumentTitle('Assistant');

    useScrollbarGutterAuto();

    useRemoveBgOnCopy();

    return (
        <div className="page-position bg-white flex overflow-hidden">
            <HistorySidePanel
                historyPanelExpandedState={historyPanelExpandedState}
                chatThreadsHistoryHookResponse={chatThreadsHistoryHookResponse}
                botsHistoryHookResponse={botsHistoryHookResponse}
            />

            <ChatThreadPanel
                historyPanelExpandedState={historyPanelExpandedState}
                setChatThreadsHistoryList={chatThreadsHistoryHookResponse.setItems}
            />
        </div>
    );
};

const AssistantPageWrappedWithContext = () => (
    <AssistantChatThreadProvider>
        <AssistantPage />
    </AssistantChatThreadProvider>
);

export default AssistantPageWrappedWithContext;
