import { emptyFieldErrorMessage } from './errorMessages';

export const SETTING_ACTION_TYPE = {
    single_input: 'single-input',
    json_input: 'json-input',
    textarea_input: 'textarea-input',
    richtext_input: 'richtext-input',
    multi_input: 'multi-input',
    single_select: 'single-select',
    multi_select: 'multi-select',
    single_dropdown: 'single-dropdown',
    multi_dropdown: 'multi-dropdown',
    single_db: 'single-db',
    multi_db: 'multi-db',
    toggle: 'toggle',
    nested_input: 'nested-input',
    single_file: 'single-file',
    code_input: 'code-input',
};

export const ALLOWED_SETTING_ACTION_TYPES = Object.values(SETTING_ACTION_TYPE);

export const DEFAULT_HANDLING_ACTION_TYPES = [
    SETTING_ACTION_TYPE.single_input,
    SETTING_ACTION_TYPE.json_input,
    SETTING_ACTION_TYPE.textarea_input,
    SETTING_ACTION_TYPE.richtext_input,
    SETTING_ACTION_TYPE.multi_input,
    SETTING_ACTION_TYPE.single_select,
    SETTING_ACTION_TYPE.multi_select,
    SETTING_ACTION_TYPE.single_dropdown,
    SETTING_ACTION_TYPE.multi_dropdown,
    SETTING_ACTION_TYPE.single_db,
    SETTING_ACTION_TYPE.multi_db,
    SETTING_ACTION_TYPE.single_file,
    SETTING_ACTION_TYPE.code_input,
];

export const EMPTY_FIELD_ERROR_MESSAGE = {
    [SETTING_ACTION_TYPE.single_input]: emptyFieldErrorMessage,
    [SETTING_ACTION_TYPE.json_input]: emptyFieldErrorMessage,
    [SETTING_ACTION_TYPE.textarea_input]: emptyFieldErrorMessage,
    [SETTING_ACTION_TYPE.richtext_input]: emptyFieldErrorMessage,
    [SETTING_ACTION_TYPE.multi_input]: 'Please add at least one input.',
    [SETTING_ACTION_TYPE.single_select]: 'Please select an option.',
    [SETTING_ACTION_TYPE.multi_select]: 'Please select an option.',
    [SETTING_ACTION_TYPE.single_dropdown]: 'Please select an option.',
    [SETTING_ACTION_TYPE.multi_dropdown]: 'Please select at least one option.',
    [SETTING_ACTION_TYPE.single_db]: 'Please select an option.',
    [SETTING_ACTION_TYPE.multi_db]: 'Please select at least one option.',
    [SETTING_ACTION_TYPE.nested_input]: 'Please add at least one Schema Property.',
    [SETTING_ACTION_TYPE.single_file]: 'Please upload a file.',
    [SETTING_ACTION_TYPE.code_input]: emptyFieldErrorMessage,
};

export const ACTION_TYPE_INITIAL_VALUE = {
    [SETTING_ACTION_TYPE.single_input]: '',
    [SETTING_ACTION_TYPE.json_input]: {},
    [SETTING_ACTION_TYPE.textarea_input]: '',
    [SETTING_ACTION_TYPE.richtext_input]: '',
    [SETTING_ACTION_TYPE.multi_input]: [],
    [SETTING_ACTION_TYPE.single_select]: null,
    [SETTING_ACTION_TYPE.multi_select]: [],
    [SETTING_ACTION_TYPE.single_dropdown]: null,
    [SETTING_ACTION_TYPE.multi_dropdown]: [],
    [SETTING_ACTION_TYPE.single_db]: null,
    [SETTING_ACTION_TYPE.multi_db]: [],
    [SETTING_ACTION_TYPE.toggle]: false,
    [SETTING_ACTION_TYPE.nested_input]: [],
    [SETTING_ACTION_TYPE.single_file]: null,
    [SETTING_ACTION_TYPE.code_input]: '',
};

export const ACTION_TYPE_PLACEHOLDER = {
    [SETTING_ACTION_TYPE.single_input]: 'Fill this input',
    [SETTING_ACTION_TYPE.json_input]: 'Enter a JSON',
    [SETTING_ACTION_TYPE.textarea_input]: 'Fill this input',
    [SETTING_ACTION_TYPE.richtext_input]: 'Fill this input',
    [SETTING_ACTION_TYPE.multi_input]: 'Add your input and click + to add them',
    [SETTING_ACTION_TYPE.single_select]: 'Select an option',
    [SETTING_ACTION_TYPE.multi_select]: 'Select at least one option',
    [SETTING_ACTION_TYPE.single_dropdown]: 'Select an option',
    [SETTING_ACTION_TYPE.multi_dropdown]: 'Select an option and click + to add them',
    [SETTING_ACTION_TYPE.single_db]: 'Select an option',
    [SETTING_ACTION_TYPE.multi_db]: 'Select an option and click + to add them',
    [SETTING_ACTION_TYPE.toggle]: 'Select an option',
    [SETTING_ACTION_TYPE.nested_input]:
        'No Base Properties added yet. Click + Add Base Property to start adding Base Properties.',
    [SETTING_ACTION_TYPE.single_file]: 'Drag & drop a file to upload or click to browse',
    [SETTING_ACTION_TYPE.code_input]: 'Fill this input',
};

export const SHARED_SETTING_PLACEHOLDER = 'Edit in Client Settings';

export const SHARED_SETTING_VALUE_REGEXP = /\${context\.[^}]+}/;

export const SHARING_STATE = {
    enabled: 'enabled',
    disabled: 'disabled',
    unavailable: 'unavailable',
};

export const NEW_SUGGESTION_ACCESS_STATE = {
    on: 'on',
    lockedOnly: 'lockedOnly',
    off: 'off',
};

export const SETTING_TYPE = {
    agent: 'agent',
    client: 'client',
};

export const SUGGESTION_ACTION = {
    approve: 'approve',
    reject: 'reject',
    resubmit: 'resubmit',
};
