import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import { useFormState } from '../../../../hooks/useFormState';

import Modal from '../../../../design-system/Modal/Modal';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import { Button, Input, TextArea } from '../../../../design-system';

const EditSourceModal = ({ id, name, description, setBotDetail, onClose }) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { name, description }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSave = async () => {
        const areEmptyFields = Object.values(formData).some((value) => !value);
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !formData.name ? emptyFieldErrorMessage : '',
                description: !formData.description ? emptyFieldErrorMessage : '',
            });
            return;
        }

        const noChanges = formData.name === name && formData.description === description;
        if (noChanges) {
            onClose();
            return;
        }

        try {
            setIsLoading(true);

            const { data } = await client.patch(`${API.ROUTES.knowledge.source}${id}/`, formData);
            setBotDetail((botDetail) => {
                const sources = botDetail.sources.map((source) =>
                    source.id === data.id
                        ? { ...source, name: data.name, description: data.description }
                        : source
                );

                return { ...botDetail, sources };
            });

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader title="Edit Source" onClose={onClose} outlined />

            <div className="px-5 sm:px-6 md:px-8 py-4 md:py-5 flex flex-col gap-4">
                <Input
                    size="sm"
                    name="source-name"
                    value={formData.name}
                    label="Source Name"
                    isRequired
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    errorMessage={fieldErrorMessages.name}
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    placeholder="Enter source name"
                />

                <TextArea
                    name="source-description"
                    value={formData.description}
                    label="Source Description"
                    isRequired
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    errorMessage={fieldErrorMessages.description}
                    state={fieldErrorMessages.description ? 'error' : 'default'}
                    placeholder="Enter source description"
                />

                <div className="flex items-center justify-between gap-3">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        text="Save"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleSave}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default EditSourceModal;
