import React from 'react';

import Loading from '../../../components/Loading';
import DocsEmptyView from '../DocsEmptyView/DocsEmptyView';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';
import FoldersAndDocsList from '../FoldersAndDocsList/FoldersAndDocsList';

const DocsPaginatedContainer = ({ docsHookResponse, page, searchQuery }) => {
    const { data, total, count, loading } = docsHookResponse;

    if (!total && loading) {
        return (
            <div className="pt-5">
                <Loading />
            </div>
        );
    }

    const emptyStateShown = !total && !data?.length && !loading;

    return (
        <PaginatedContainer page={page} count={count} total={total}>
            {emptyStateShown && <DocsEmptyView isSearchQuery={!!searchQuery} />}
            <FoldersAndDocsList data={data} />
        </PaginatedContainer>
    );
};

export default DocsPaginatedContainer;
