import React, { useRef } from 'react';

import AboutSection from '../AboutSection/AboutSection';
import DataSourcesSection from '../DataSourcesSection/DataSourcesSection';

const LeftPanel = ({ botDetail, setBotDetail, setIsInstructionEditing }) => {
    const { id, name, description, default_version, sources } = botDetail;
    const instructions = default_version?.instructions;

    const panelRef = useRef(null);
    const scrollableAreaRef = useRef(null);

    return (
        <div className="lg:h-full lg:flex-1 relative z-40" ref={panelRef}>
            <div className="lg:h-full lg:overflow-y-auto bg-neutral-50" ref={scrollableAreaRef}>
                <div className="p-5 sm:p-6 xl:p-8 3xl:px-[40px] flex flex-col gap-5 md:gap-6">
                    <AboutSection
                        id={id}
                        name={name}
                        description={description}
                        instructions={instructions}
                        setBotDetail={setBotDetail}
                        setIsInstructionEditing={setIsInstructionEditing}
                    />

                    <DataSourcesSection
                        botId={id}
                        sources={sources}
                        setBotDetail={setBotDetail}
                        panelRef={panelRef}
                        scrollableAreaRef={scrollableAreaRef}
                    />
                </div>
            </div>
        </div>
    );
};

export default LeftPanel;
