import React from 'react';
import PropTypes from 'prop-types';

import { extractFormatsFromPattern, megabytesToBytes } from '../../helpers/fileUtils';

import FileInput from '../../design-system/FileInput/FileInput';

const SingleFileInputType = ({
    value,
    constraints,
    state,
    placeholder = 'Drag & drop a file to upload or click to browse',
    handleChange,
}) => {
    const handleFile = (file) => {
        handleChange((item) => ({ ...item, value: file }));
    };

    const acceptedFormats = extractFormatsFromPattern(constraints?.pattern);
    const maxFileSize = megabytesToBytes(constraints?.max);
    const minFileSize = megabytesToBytes(constraints?.min);

    return (
        <FileInput
            file={value}
            state={state}
            formatsArray={acceptedFormats}
            maxSizeInBytes={maxFileSize}
            minSizeInBytes={minFileSize}
            handleFile={handleFile}
            placeholder={placeholder}
            shouldShowPreview={false}
            view="simple"
        />
    );
};

SingleFileInputType.propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]),
    constraints: PropTypes.object,
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    placeholder: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
};

export default SingleFileInputType;
