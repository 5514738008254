import React from 'react';

import { formatToKey } from '../../../helpers/formatLabelToKey';
import { useFormState } from '../../../hooks/useFormState';
import { emptyFieldErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../Modal/Modal';
import ModalHeader from '../../ModalHeader/ModalHeader';
import { Button, Input } from '../../index';

const AddVariableModal = ({ addVariable, existedVariablesKey, onClose }) => {
    const { formData, handleInputChange, setFieldErrorMessages, fieldErrorMessages } = useFormState(
        { name: '' }
    );

    const handleAddVariableSubmit = () => {
        if (!formData.name) {
            setFieldErrorMessages({ name: emptyFieldErrorMessage });
            return;
        }
        const key = formatToKey(formData.name);

        const keyAlreadyExist = existedVariablesKey.includes(key);
        if (keyAlreadyExist) {
            setFieldErrorMessages({ name: 'A variable with the entered name already exists.' });
            return;
        }

        addVariable(key);
        onClose();
    };

    return (
        <Modal onClose={onClose} size="medium" resetPadding>
            <ModalHeader onClose={onClose} title="Add Variable" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                <Input
                    size="md"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    placeholder="Give your variable a name"
                />

                <div className="flex items-center gap-4 justify-between">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        text="Add to input"
                        onClick={handleAddVariableSubmit}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddVariableModal;
