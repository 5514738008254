import React, { useState } from 'react';

import client from '../../../services/knowledge-api';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import { CONTENT_TYPE, CONTENT_TYPE_LABEL, ROUTE } from '../../../constants/docs';
import { useFormState } from '../../../hooks/useFormState';
import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { handleUpdateFoldersState } from '../../../helpers/docsSidePanelUtils';
import { mutateDataAfterRenamingDoc } from '../../../helpers/docsUtils';

import Modal from '../../../design-system/Modal/Modal';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import { Button, Input } from '../../../design-system';

const RenameItemModal = ({ contentType, data, requestKeysToMutate, onRenameSuccess, onClose }) => {
    const { id, label, location } = data;

    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { label }
    );

    const { isMobile } = useResponsiveBreakpoints();

    const { setSidePanelFolders } = useDocsPageContext();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSave = async () => {
        if (!formData.label) {
            setFieldErrorMessages({ label: emptyFieldErrorMessage });
            return;
        }

        try {
            setIsLoading(true);

            const route = ROUTE[contentType] + id + '/';
            const { data } = await client.patch(route, { label: formData.label });
            mutateDataAfterRenamingDoc({
                requestKeysToMutate,
                docId: id,
                updatedLabel: data.label,
            });

            if (contentType === CONTENT_TYPE.folder) {
                handleUpdateFoldersState({
                    setFolders: setSidePanelFolders,
                    location: location?.map((loc) => loc.id),
                    cb: (folder) => ({ ...folder, label: data.label }),
                    shouldSort: true,
                });
            }

            if (onRenameSuccess) {
                onRenameSuccess({ id, updatedLabel: data.label });
            }

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    const saveButtonState = isLoading ? 'loading' : 'default';

    const contentTypeLabel = CONTENT_TYPE_LABEL[contentType];
    const title = `Rename ${contentTypeLabel}`;

    const input = (
        <Input
            size="md"
            name="label"
            value={formData.label}
            state={fieldErrorMessages.label ? 'error' : 'default'}
            errorMessage={fieldErrorMessages.label}
            onChange={(e) => handleInputChange('label', e.target.value)}
        />
    );

    const controls = (
        <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between gap-2">
            <Button size="md" type="neutral" text="Cancel" onClick={onClose} />
            <Button
                size="md"
                type="secondary"
                text="Save"
                state={saveButtonState}
                onClick={handleSave}
            />
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                bottomButtonsGroup={controls}
                containerCustomPaddings="!pt-0 pb-5"
                contentCustomPaddings="!py-0"
                bottomButtonsCustomPaddings="px-5"
                withCloseButton={false}
                customGap={32}
            >
                <div className="flex flex-col gap-5">
                    <ModalHeader
                        title={title}
                        outlined
                        customPaddings="px-5 py-3"
                        withEscapeButton={false}
                        onClose={onClose}
                    />
                    <div className="px-5">{input}</div>
                </div>

                <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            </MobilePopup>
        );
    }

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title={title} outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                {input}

                {controls}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default RenameItemModal;
