import React, { useEffect } from 'react';
import { useScrollTo, useScrollToBottom } from 'react-scroll-to-bottom';

import { CHAT_TYPE } from '../../../constants/assistant';

import ChatMessage from '../ChatMessage/ChatMessage';
import LoadingState from '../LoadingState/LoadingState';

const ChatContent = ({
    chatMessages,
    chatType,
    messageStreamState,
    handleRegenerateChatMessage,
    isRegenerateMessageDisabled,
    scrollPosition,
    setScrollPosition,
    containerRef,
    setChatMessages,
    isSimplifiedVersion = false,
}) => {
    const scrollToBottom = useScrollToBottom();
    const scrollTo = useScrollTo();

    const { isAssistantLoading } = messageStreamState;

    useEffect(() => {
        if (isAssistantLoading) {
            scrollToBottom({ behavior: 'smooth' });
        }
    }, [isAssistantLoading]);

    const shouldScroll = scrollPosition !== null && scrollPosition !== undefined;

    useEffect(() => {
        const scrollToSpecificMessage = async () => {
            await new Promise((resolve) => {
                const container = containerRef.current?.children?.[0]?.children?.[0];
                const hasScroll = container?.scrollTop !== 0;

                if (hasScroll) {
                    scrollTo(scrollPosition);
                } else {
                    scrollTo(0);
                }

                resolve();
            });
            setScrollPosition(null);
        };

        if (shouldScroll) {
            scrollToSpecificMessage();
        }
    }, [shouldScroll]);

    return (
        <div className="h-full">
            <div className="flex flex-col gap-6">
                {chatMessages.map((message) => {
                    if (!message) {
                        return;
                    }

                    return (
                        <ChatMessage
                            key={message.id}
                            message={message}
                            setChatMessages={setChatMessages}
                            isRegenerateDisabled={isRegenerateMessageDisabled}
                            handleRegenerateChatMessage={handleRegenerateChatMessage}
                            isSimplifiedVersion={isSimplifiedVersion}
                        />
                    );
                })}

                {isAssistantLoading && <LoadingState isDiviBot={chatType === CHAT_TYPE.divibot} />}
            </div>
        </div>
    );
};

export default ChatContent;
