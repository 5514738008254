import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SvgIcon } from '../index';

const SelectableListItem = ({
    item,
    icon: Icon,
    handleSelect,
    isSelected = false,
    keyToDisplayName = 'label',
    customIconBlock,
}) => {
    const handleItemClick = (event, item) => {
        event?.stopPropagation();
        event?.preventDefault();

        handleSelect(item);
    };

    const className = classNames(
        'px-3 py-2 w-full max-w-full flex items-center gap-1 truncate rounded-[6px] hover:bg-neutral-50 cursor-pointer min-h-[37px] transition-colors',
        {
            'bg-neutral-50': isSelected,
            'bg-white': !isSelected,
        }
    );

    return (
        <div className={className} onClick={(event) => handleItemClick(event, item)}>
            {customIconBlock}
            {Icon && <SvgIcon color="#754DCF" size="medium" icon={Icon} />}

            <p className="flex-1 font-body-bold text-body-bold-s text-[#25324B] truncate">
                {item[keyToDisplayName]}
            </p>
        </div>
    );
};

SelectableListItem.propTypes = {
    item: PropTypes.object.isRequired,
    handleSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    icon: PropTypes.func,
    keyToDisplayName: PropTypes.string,
    customIconBlock: PropTypes.node,
};

export default SelectableListItem;
