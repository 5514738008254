import React, { memo, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import DetailContainer from '../../../design-system/DetailContainer/DetailContainer';
import PlaybookDetailsPanel from '../PlaybookDetailsPanel/PlaybookDetailsPanel';
import PlaybookInfoBadges from '../PlaybookInfoBadges/PlaybookInfoBadges';
import PlaybookBuilderNavigation from '../PlaybookBuilderNavigation/PlaybookBuilderNavigation';
import CardCoverImageBlock from '../CardCoverImageBlock/CardCoverImageBlock';

const PlaybookCard = ({ playbook }) => {
    const {
        id,
        name,
        description,
        created_by,
        is_shared,
        sections,
        process_count,
        scenarios,
        last_updated_by: { updated_at },
    } = playbook;
    const [isDetailsPanelOpened, setIsDetailsPanelOpened] = useState(false);

    const formattedLastUpdated = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');

    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const descriptionContainerRef = useRef(null);

    const [descriptionLineClamp, setDescriptionLineClamp] = useState(2);

    useEffect(() => {
        const nameElement = nameRef.current;

        const setDescriptionAvailableLinesCount = () => {
            const descriptionElement = descriptionRef.current;
            const descriptionContainerElement = descriptionContainerRef.current;
            if (!descriptionElement || !descriptionContainerElement) {
                return;
            }

            const lineHeight = parseFloat(getComputedStyle(descriptionElement).lineHeight);
            const maxHeight = descriptionContainerElement.clientHeight;
            const computedLinesCount = Math.floor(maxHeight / lineHeight);
            setDescriptionLineClamp(computedLinesCount);
        };

        const resizeObserver = new ResizeObserver(setDescriptionAvailableLinesCount);

        if (nameElement) {
            resizeObserver.observe(nameElement);
        }

        return () => {
            if (nameElement) {
                resizeObserver.unobserve(nameElement);
            }
        };
    }, []);

    const openDetailsPanel = (e) => {
        e?.stopPropagation();
        setIsDetailsPanelOpened(true);
    };

    const closeDetailsPanel = (e) => {
        e?.stopPropagation();
        setIsDetailsPanelOpened(false);
    };

    return (
        <>
            <div
                className="self-stretch rounded-[20px] overflow-hidden flex flex-col cursor-pointer"
                onClick={openDetailsPanel}
            >
                <CardCoverImageBlock playbook={playbook} />
                <div className="h-[300px] bg-white p-4 flex flex-col gap-3 justify-between overflow-hidden">
                    <div className="flex-grow flex flex-col overflow-hidden">
                        <p
                            className="font-heading-regular font-[600] text-[24px] leading-[26px] text-black mb-1 line-clamp-3"
                            ref={nameRef}
                        >
                            {name}
                        </p>
                        <div className="flex-grow" ref={descriptionContainerRef}>
                            <p
                                className="font-body text-body-regular-s text-neutral-300 overflow-hidden"
                                ref={descriptionRef}
                                style={{
                                    WebkitLineClamp: descriptionLineClamp,
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                {description}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <PlaybookInfoBadges
                            processes={process_count}
                            sections={sections}
                            scenarios={scenarios}
                        />
                        <div className="flex items-start gap-4">
                            <DetailContainer
                                label="Last Updated"
                                desktopStartPixel={0}
                                customStyles="w-[calc((100%-16px)/2)]"
                            >
                                {formattedLastUpdated}
                            </DetailContainer>
                            <DetailContainer
                                label="Created by"
                                desktopStartPixel={0}
                                customStyles="w-[calc((100%-16px)/2)]"
                            >
                                {created_by.name}
                            </DetailContainer>
                        </div>
                        <PlaybookBuilderNavigation playbookId={id} isPlaybookShared={is_shared} />
                    </div>
                </div>
            </div>

            {isDetailsPanelOpened && (
                <PlaybookDetailsPanel playbook={playbook} onClose={closeDetailsPanel} />
            )}
        </>
    );
};

export default memo(PlaybookCard);
