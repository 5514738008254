import React, { useMemo, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import useUser from '../../../../hooks/useUser';
import { useFormState } from '../../../../hooks/useFormState';

import { SOURCE_TYPE, SOURCE_TYPE_LABEL } from '../../../../constants/bot';
import { defaultErrorMessage, invalidJsonErrorMessage } from '../../../../constants/errorMessages';

import { isValidJson } from '../../../../helpers/isValidJson';

import Panel from '../../../../components/Panel';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import PanelLayout from '../../../../components/PanelLayout/PanelLayout';
import CreateNewSourceForm from '../CreateNewSourceForm/CreateNewSourceForm';
import { Button } from '../../../../design-system';

const REQUIRED_FIELDS = ['name', 'description', 'folderId'];

const CreateNewSourcePanel = ({
    type,
    googleCloudConnectionId,
    onSuccessSourceCreate,
    onClose,
}) => {
    const formInitialState = { name: '', description: '', folderId: '', config: {} };
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } =
        useFormState(formInitialState);

    const { user } = useUser();
    const organizationId = user?.organization?.id;

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const typeLabel = SOURCE_TYPE_LABEL[type];
    const title = `Add New ${typeLabel} Source`;

    const createNewSource = async () => {
        try {
            const { name, description, config, folderId } = formData;
            const isConfigValid = typeof config === 'object' ? true : isValidJson(formData.config);
            if (!isConfigValid) {
                setFieldErrorMessages({ config: invalidJsonErrorMessage });
                return;
            }

            const requestBody = { name, description, organization: organizationId, type };

            let formattedConfig = config || {};
            if (typeof config !== 'object') {
                formattedConfig = JSON.parse(config) || {};
            }
            requestBody.config = formattedConfig;

            if (type === SOURCE_TYPE.diviup_folder) {
                const detail = {
                    folder_id: folderId,
                    file_types: ['document'],
                    organization_id: organizationId,
                };
                requestBody.detail = detail;
                requestBody.auto_refresh = true;
            }

            if (type === SOURCE_TYPE.google_folder) {
                const detail = {
                    drive_id: null,
                    folder_id: folderId,
                    file_types: ['document'],
                };
                requestBody.detail = detail;
                requestBody.connection = googleCloudConnectionId;
                requestBody.auto_refresh = false;
            }

            setIsLoading(true);
            const { data } = await client.post(API.ROUTES.knowledge.source, requestBody);

            const newSource = { id: data.id, name: data.name, type: data.type };
            onSuccessSourceCreate(newSource);

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });

            const errorMessage = e.response?.data?.error;
            if (typeof errorMessage === 'string') {
                setFieldErrorMessages({ config: errorMessage });
            }
        }
    };

    function checkIfRequiredFieldsAreFilled() {
        return REQUIRED_FIELDS.every((field) => !!formData[field]);
    }

    const allRequiredFieldsAreFilled = useMemo(checkIfRequiredFieldsAreFilled, [formData]);

    const submitButtonState = isLoading
        ? 'loading'
        : allRequiredFieldsAreFilled
        ? 'default'
        : 'disabled';

    const buttonsGroup = (
        <div className="flex justify-end">
            <Button
                type="primary"
                size="sm"
                text="Create Source"
                state={submitButtonState}
                onClick={createNewSource}
            />
        </div>
    );

    return (
        <Panel onClose={onClose} showControls={false} withOverflowProperty={false} resetPadding>
            <PanelLayout buttonsGroup={buttonsGroup} title={title} onClose={onClose}>
                <CreateNewSourceForm
                    type={type}
                    formData={formData}
                    fieldErrorMessages={fieldErrorMessages}
                    handleInputChange={handleInputChange}
                />
            </PanelLayout>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Panel>
    );
};

export default CreateNewSourcePanel;
