import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PanelHeader from '../PanelHeader/PanelHeader';

const PanelLayout = ({
    buttonsGroup,
    children,
    title,
    onClose, // is required only of title is provided
    titleMarginBottom = 12, // is required only of title is provided
    customPaddings = 'px-4 pt-4 pb-5 md:pb-8 sm:px-8',
}) => {
    const containerClassName = classNames(
        'flex flex-col max-w-full max-h-full h-full',
        customPaddings
    );

    const childrenStyle = title ? { paddingTop: `${Math.max(titleMarginBottom, 0)}px` } : {};

    return (
        <div className={containerClassName}>
            {title && (
                <div className="px-[1px]">
                    <PanelHeader title={title} onClose={onClose} />
                </div>
            )}

            <div
                className="flex-1 overflow-y-auto hide-scrollbar pb-5 pt-1 px-[1px]"
                style={childrenStyle}
            >
                {children}
            </div>

            <div className="pt-3 border-t-1 border-neutral-200 bg-white px-[1px]">
                {buttonsGroup}
            </div>
        </div>
    );
};

PanelLayout.propTypes = {
    buttonsGroup: PropTypes.node.isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
    onClose: PropTypes.func,
    titleMarginBottom: PropTypes.number,
    customPaddings: PropTypes.string,
};

export default PanelLayout;
