import React, { useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../../services/assistant-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { useModalAutoMinHeight } from '../../../../hooks/useModalAutoMinHeight';
import { useClientSideFilterBar } from '../../../../hooks/useClientSideFilterBar';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import { arePrimitiveArraysEqualUnordered } from '../../../../helpers/arePrimitiveArraysEqualUnordered';

import Modal from '../../../../design-system/Modal/Modal';
import SourceIcon from '../SourceIcon/SourceIcon';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import MobilePopup from '../../../../design-system/MobilePopup/MobilePopup';
import CheckboxListItem from '../../../../design-system/CheckboxListItem/CheckboxListItem';
import InfiniteScrollList from '../../../../components/InfiniteScrollList/InfiniteScrollList';
import ModalHeaderWithSearch from '../../../../design-system/ModalHeaderWithSearch/ModalHeaderWithSearch';
import { Button } from '../../../../design-system';

const AddExistingSourcesModal = ({
    botId,
    setUpdatedSources,
    sourcesOptionsHookResponse,
    savedSelectedSources,
    onClose,
}) => {
    const maxHeight = window.innerHeight >= 550 ? 480 : window.innerHeight - 70;
    const { isMobile } = useResponsiveBreakpoints();

    const { options, optionsLoading, totalOptions, setPage, canLoadMoreOptions } =
        sourcesOptionsHookResponse;

    const [selectedSources, setSelectedSources] = useState(savedSelectedSources || []);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const { filteredOptions, setFilteredOptions } = useClientSideFilterBar({
        options,
        showClientSideFilterBar: true,
    });

    const { modalRef, modalMinHeight } = useModalAutoMinHeight({
        maxHeight,
        shouldFixHeight: !isMobile,
        dependenciesArray: [filteredOptions],
    });

    const handleChange = (source) => {
        setSelectedSources((prevSelected) => {
            const isSelected = prevSelected.includes(source.id);
            if (isSelected) {
                return prevSelected.filter((id) => id !== source.id);
            }

            return [...prevSelected, source.id];
        });
    };

    const handleSave = async () => {
        try {
            const nothingChanged = arePrimitiveArraysEqualUnordered(
                selectedSources,
                savedSelectedSources
            );
            if (nothingChanged) {
                onClose();
                return;
            }

            setIsLoading(true);

            const { data } = await client.patch(`${API.ROUTES.assistant.customBot}${botId}/`, {
                sources: selectedSources,
            });
            setUpdatedSources({ data });

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const optionsListClassName = classNames('flex-1 pt-2 mt-1 md:pt-3 md:mt-2 px-5', {
        'md:pb-2': !!filteredOptions?.length,
        'min-h-[70px]': optionsLoading,
        'overflow-y-auto': isMobile || maxHeight > 400,
    });

    const topFixedContent = (
        <ModalHeaderWithSearch
            onClose={onClose}
            onCancelClick={onClose}
            title="Add Existing Sources"
            listLabel="All Sources"
            options={options}
            setFilteredOptions={setFilteredOptions}
            keyToFilter={['name']}
        />
    );

    const noSources = !optionsLoading && !options?.length && !totalOptions;

    const optionsList = (
        <div className={optionsListClassName}>
            {noSources && (
                <p className="font-body-bold text-body-bold-s text-neutral-300">No sources found</p>
            )}

            <InfiniteScrollList
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={filteredOptions}
                loading={optionsLoading}
                gap={0}
            >
                {filteredOptions.map((source) => {
                    const { id } = source;
                    const isChecked = selectedSources.includes(id);

                    return (
                        <CheckboxListItem
                            key={id}
                            item={source}
                            handleChange={handleChange}
                            isChecked={isChecked}
                            keyToDisplayName="name"
                            customIconBlock={<SourceIcon type={source.type} />}
                        />
                    );
                })}
            </InfiniteScrollList>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );

    const submitButtonState = isLoading ? 'loading' : 'default';

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                minHeight="86vh"
                maxHeight="86vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={topFixedContent}
                customGap={8}
                withCloseButton={false}
                errorAlert={errorAlert}
                setErrorAlert={setErrorAlert}
                bottomButtonsGroup={
                    <div className="flex flex-col gap-2">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Save"
                            state={submitButtonState}
                            onClick={handleSave}
                        />
                        <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                    </div>
                }
            >
                <div className="flex flex-col bg-white">{optionsList}</div>
            </MobilePopup>
        );
    }

    return (
        <Modal onClose={onClose} resetPadding size="extraLarge">
            <div
                className="flex flex-col rounded-2"
                style={{ maxHeight: `${maxHeight}px`, minHeight: `${modalMinHeight}px` }}
                ref={modalRef}
            >
                {topFixedContent}

                {optionsList}

                <div className="px-5 pb-3 pt-4 flex items-center justify-between gap-2">
                    <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Save"
                        state={submitButtonState}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddExistingSourcesModal;
