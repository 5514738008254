import React, { useCallback, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/assistant-api';

import {
    getRichTextWithLinkedDocsValueForSubmit,
    getRichTextWithDocsMappingInitialStateUsingPrevDocsMapping,
} from '../../../../helpers/richTextEditorWithDocsMappingUtils';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import EditLineIcon from '../../../../design-system/Icons/EditLineIcon';
import RichTextAreaWithDocsMapping from '../../../../design-system/RichTextAreaWithDocsMapping/RichTextAreaWithDocsMapping';
import { Button, ButtonIcon } from '../../../../design-system';

const InstructionsTextArea = ({
    instructions,
    setBotDetail,
    openNewChat,
    customBotId,
    onStartEditing,
    onStopEditing,
}) => {
    const instructionsValue = instructions?.[0]?.content || '';

    const [formData, setFormData] = useState(() => getFormInitialState(instructionsValue));
    const [instructionsErrorMessage, setInstructionsErrorMessage] = useState(null);

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const stopEditing = () => {
        setIsEditing(false);
        onStopEditing();
    };

    const startEditing = () => {
        setIsEditing(true);
        onStartEditing();
    };

    const handleInstructionsSave = async () => {
        try {
            if (!formData.instructions) {
                setInstructionsErrorMessage(emptyFieldErrorMessage);
                return;
            }

            setIsLoading(true);

            const formattedInstructions = [...(instructions || [{ role: 'system' }])];
            const formattedValue = getRichTextWithLinkedDocsValueForSubmit({
                value: formData.instructions,
                docsMapping: formData.docsMapping,
            });

            formattedInstructions[0].content = formattedValue;

            const { data } = await client.patch(
                `${API.ROUTES.assistant.customBot}${customBotId}/`,
                {
                    instructions: formattedInstructions,
                }
            );

            setBotDetail((prevData) => ({
                ...prevData,
                default_version: data.default_version,
            }));
            const updatedInstructionsValue = data.default_version?.instructions?.[0]?.content || '';
            setFormData(({ docsMapping }) =>
                getFormInitialState(updatedInstructionsValue, docsMapping)
            );

            stopEditing();
            openNewChat();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const handleInstructionChange = useCallback(
        (instructions) => {
            setFormData((prevData) => ({ ...prevData, instructions }));
            setInstructionsErrorMessage(null);
        },
        [setFormData]
    );

    const handleDocsMapping = useCallback(
        (cb) => {
            setFormData((prevData) => ({
                ...prevData,
                docsMapping: typeof cb === 'function' ? cb(prevData.docsMapping) : cb,
            }));
            setInstructionsErrorMessage(null);
        },
        [setFormData]
    );

    const handleCancel = () => {
        if (isLoading) return;

        stopEditing();
        setFormData(({ docsMapping }) => getFormInitialState(instructionsValue, docsMapping));
    };

    function getFormInitialState(instructionsValue, prevDocsMapping = {}) {
        const { value, docsMapping } = getRichTextWithDocsMappingInitialStateUsingPrevDocsMapping({
            initialValue: instructionsValue,
            prevDocsMapping,
            replaceOnlyFilledTags: true,
        });

        return { instructions: value, docsMapping };
    }

    const state = !isEditing ? 'disabled' : instructionsErrorMessage ? 'error' : 'default';

    return (
        <div className="flex flex-col gap-1">
            {!isEditing && (
                <div className="flex items-center gap-2 justify-between">
                    <p className="font-body-bold text-body-bold-s text-neutral-500">
                        Instructions
                        <sup className="text-red-500 leading-1 ml-0.5">*</sup>
                    </p>

                    <ButtonIcon type="link" size="xs" icon={EditLineIcon} onClick={startEditing} />
                </div>
            )}

            <RichTextAreaWithDocsMapping
                name="instructions"
                value={formData.instructions}
                docsMapping={formData.docsMapping}
                label={isEditing ? 'Instructions' : ''}
                isRequired
                state={state}
                errorMessage={instructionsErrorMessage}
                onValueChange={handleInstructionChange}
                onDocsMappingChange={handleDocsMapping}
                autoExpand
                minHeight={180}
            />

            {isEditing && (
                <div className="flex items-center justify-between gap-2">
                    <Button type="link" size="xs" text="Cancel" onClick={handleCancel} />
                    <Button
                        type="secondary"
                        size="xs"
                        text="Save"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleInstructionsSave}
                    />
                </div>
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default InstructionsTextArea;
